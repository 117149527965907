import * as React from 'react';
import AdminVenueTicketsManagement from './AdminVenueTicketsManagement';
import AdminVenueListManagement from './AdminVenueListManagement';
import VenueAddForm from './forms/VenueAddForm';
import TicketAddForm from './forms/TicketAddForm';

interface AdminVenueManagementProps {
    productionMode: boolean;
    onBack: () => void;
}

const AdminVenueManagement: React.FC<AdminVenueManagementProps> = ({ productionMode, onBack }) => {

    const [mode, setMode] = React.useState<number>(0);

    return (
        <>
            {mode < 2 && <div className="payment-approval-container">
                <div className="reg-module-view-header"><b>УПРАВЛЕНИЕ МЕРОПРИЯТИЯМИ</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                <div className="admin-panel-tab">
                    <div className={mode == 0 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setMode(0)}>Мероприятия</div>
                    <div className={mode == 1 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setMode(1)}>Покупки</div>
                </div>
                {mode == 0 && <AdminVenueListManagement productionMode={productionMode} onAddRecord={() => setMode(2)} />}
                {mode == 1 && <AdminVenueTicketsManagement productionMode={productionMode} onAddRecord={() => setMode(3)} />}
            </div>}
            {mode == 2 && <VenueAddForm productionMode={productionMode} onBack={() => setMode(0)} />}
            {mode == 3 && <TicketAddForm productionMode={productionMode} onBack={() => setMode(1)} />}
        </>
        )
};

export default AdminVenueManagement;
