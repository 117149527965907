import * as React from 'react';
import ReactQuill from 'react-quill';
import useFetch from '../../../../../../hooks/useFetch';
import { Account } from '../../../../../../store/Models';
import Autocomplete from '../../../simple/Autocomplete';
import Datetimepicker from '../../../simple/Datetimepicker';
import { MillionaireMorningItemExt } from '../AdminMillionaireMorningManagement';

interface MillionaireMorningEditFormProps {
    selectedItem: MillionaireMorningItemExt;
    onBack: () => void;
    productionMode: boolean;
}

const MillionaireMorningEditForm: React.FC<MillionaireMorningEditFormProps> = ({ selectedItem, onBack, productionMode }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [publishDateTime, setPublishDateTime] = React.useState<string>('');
    const [tz, setTz] = React.useState<string>('');
    const [content, setContent] = React.useState<string>(selectedItem.content);

    const clearAccount = () => {
        setSelectedAccount(null);
    }

    const validate = (): boolean => {
        return selectedAccount != null && publishDateTime.length > 0 && tz.length > 0 && content.length > 50;
    }

    const doEdit = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('ID', selectedItem.id.toString());
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('PublishDateTime', publishDateTime);
            formData.append('Tz', tz);
            formData.append('Content', content);
            fetch('api/admin/editMillionaireMorningItem', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Задание было успешно изменено.' });
                    }
                    else {
                        alert('Задание было успешно изменено.');
                    }
                    onBack();
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    const requestDelete = () => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите удаление", message: "Вы действительно хотите удалить задание?",
                buttons: [
                    { id: 'approve', type: 'default', text: "Подтверждаю" },
                    { id: '', type: 'default', text: "Нет" }]
            }, (id: string) => {
                if (id === 'approve') {
                    doDelete();
                }
            });
        }
        else {
            if (window.confirm("Вы действительно хотите удалить задание?")) {
                doDelete();
            }
        }
    }

    const doDelete = () => {
        fetch('api/admin/deleteMillionaireMorningItem?id=' + selectedItem.id)
            .then(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({ message: 'Задание было успешно удалено.' });
                }
                else {
                    alert('Задание было успешно удалено.');
                }
                onBack();
            })
            .catch(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка. Пожалуйста, попробуйте позже.' });
                }
                else {
                    alert('Произошла ошибка. Пожалуйста, попробуйте позже.');
                }
            });
    }

    React.useEffect(() => {
        fetch('/api/admin/getMillionaireMorningItemAuthors')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
                setSelectedAccount(data.filter(x => x.id === selectedItem.publishAccount.id)[0]);
                setTz('-5');
            });
    }, []);

    const restoreLayout = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className="payment-approval-container-vh">
            <div className="reg-module-view-header"><b>РЕДАКТИРОВАТЬ УТРЕННЕЕ ЗАДАНИЕ</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div className="reg-module-label">Автор (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите автора" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearAccount}>Изм.</button>
                        </>}
                    </div>
                </div>
                <div className="reg-module-block">
                    <div>
                        <Datetimepicker label1="Дата задания" label2="Время задания" onValueChanged={setPublishDateTime} defaultValue={selectedItem.publishDateTime} />
                    </div>
                </div>
                <div className="reg-module-block">
                    <div className="reg-module-label">Часовой пояс выхода задания</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setTz(e.target.value)}>
                            <option value="-5">GMT+5 (Астана)</option>
                        </select>
                    </div>
                </div>
                <div className="reg-module-block">
                    <div className="reg-module-label">Контент</div>
                    <div onBlur={restoreLayout}>
                        <ReactQuill
                            onChange={setContent}
                            modules={{
                                toolbar: null
                            }}
                            placeholder="Напишите задание здесь"
                            defaultValue={selectedItem.content}
                        />
                    </div>
                </div>
                <div className="reg-module-submit" style={{ display: 'flex' }}>
                    <button className="btn btn-success" style={{ margin: '0 5px'}} disabled={!validate()} onClick={doEdit}>Сохранить</button>
                    <button className="btn btn-danger" style={{ margin: '0 5px' }} onClick={requestDelete}>Удалить</button>
                </div>
            </div>
        </div>
    )
};

export default MillionaireMorningEditForm;
