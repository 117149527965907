import React, { useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { PaymentSession } from '../../../../../store/Models';
import { CostInfo, subscriptionsInfo } from '../../../../../subscriptions';
import { mockTelegramObject } from '../../../../../utils';
import './stepper.css';
import Stepper1 from './Stepper1';
import Stepper2 from './Stepper2';
import Stepper3 from './Stepper3';
import StepperCircles from './StepperCircles';

interface StepperProps {
    productionMode: boolean;
    paymentSession: PaymentSession;
    onBack: () => void;
    onFinishPayment: (subscription: CostInfo, paymentMethod: string) => void;
}

const StepperComponent: React.FC<StepperProps> = ({ productionMode, paymentSession, onBack, onFinishPayment }) => {
    const fetch = useFetch();

    const [activeStep, setActiveStep] = useState(paymentSession.status == 0 ? 1 : paymentSession.status < 3 ? 2 : 3);
    const [selectedSubscription, setSelectedSubscription] = useState<CostInfo | null>(paymentSession.subscriptionCode != null ? subscriptionsInfo[paymentSession.subscriptionCode] : null);
    const [selectedPayment, setSelectedPayment] = useState<any>(paymentSession.paymentMethod != null ? { Key: paymentSession.paymentMethod } : null);

    const [paymentSessionStepper, setPaymentSessionStepper] = React.useState<PaymentSession>(paymentSession);

    const params = new URLSearchParams(productionMode ? window.Telegram.WebApp.initData : window.location.search);
    var referralHash = params.get('start_param');

    React.useEffect(() => {
        var telegramUserId = productionMode ? window.Telegram.WebApp.initDataUnsafe.user.id : mockTelegramObject.initDataUnsafe.user.id;
        var queryString = 'api/payment/getPaymentSessionTg?telegramUserId=' + telegramUserId;
        if (referralHash != null) {
            queryString += '&referralHash=' + referralHash;
        }

        fetch(queryString)
            .then(res => res.json() as Promise<PaymentSession>)
            .then(data => {
                setPaymentSessionStepper(data);
                setSelectedSubscription(data.subscriptionCode != null ? subscriptionsInfo[data.subscriptionCode] : null);
                setSelectedPayment(data.paymentMethod != null ? { Key: data.paymentMethod } : null);
            });
    }, [activeStep]);

    const handleNext = () => setActiveStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep));
    const handleBack = () => setActiveStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));

    return (
        <div className="stepper-container">
            <StepperCircles activeStep={activeStep} />
            {activeStep == 1 && <Stepper1 paymentSession={paymentSessionStepper} onBack={onBack} onNext={(s) => { setSelectedSubscription(s); handleNext(); }} />}
            {(activeStep == 2 && selectedSubscription != null) && <Stepper2 productionMode={productionMode} paymentSession={paymentSessionStepper} selectedSubscription={selectedSubscription} onBack={handleBack} onNext={(p) => { setSelectedPayment(p); handleNext(); }} />}
            {(activeStep == 3 && selectedSubscription != null) && <Stepper3 paymentSession={paymentSessionStepper} paymentMethod={selectedPayment.Key} subscriptionCode={selectedSubscription.code} onBack={handleBack} onNext={() => onFinishPayment(selectedSubscription, selectedPayment.Key)} />}
        </div>
    );
};

export default StepperComponent;
