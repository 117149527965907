﻿import * as React from 'react';
import Autocomplete from '../../../simple/Autocomplete';
import { Account, Tariff, Venue } from '../../../../../../store/Models';
import { formatCost, makePeriod } from '../../../../../../utils';
import Datetimepicker from '../../../simple/Datetimepicker';
import useFetch from '../../../../../../hooks/useFetch';

interface TicketAddFormProps {
    onBack: () => void;
    productionMode: boolean;
}

const TicketAddForm: React.FC<TicketAddFormProps> = ({ onBack, productionMode }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);
    const [venues, setVenues] = React.useState<Venue[]>([]);
    const [tariffs, setTariffs] = React.useState<Tariff[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [selectedVenue, setSelectedVenue] = React.useState<Venue | null>(null);
    const [selectedTariff, setSelectedTariff] = React.useState<Tariff | null>(null);
    const [paymentMethod, setPaymentMethod] = React.useState<string>('');
    const [paymentDateTime, setPaymentDateTime] = React.useState<string>('');
    const [cost, setCost] = React.useState<number>(0);
    const [cashboxTz, setCashboxTz] = React.useState<string>('');
    const [comment, setComment] = React.useState<string>('');

    const [enableCostEdit, setEnableCostEdit] = React.useState<boolean>(false);

    const spacer = document.querySelector('.spacer') as HTMLDivElement;

    const clearForm = () => {
        clearVenueInfo();
        setSelectedAccount(null);
    }

    const clearVenueInfo = () => {
        clearTariffInfo();
        setSelectedVenue(null);
    }

    const clearTariffInfo = () => {
        setComment('');
        setCashboxTz('');
        setCost(0);
        setEnableCostEdit(false);
        setPaymentDateTime('');
        setPaymentMethod('');
        setSelectedTariff(null);
    }

    const validate = (): boolean => {
        return selectedAccount != null && selectedVenue != null && selectedTariff != null
            && paymentMethod.length > 0 && paymentDateTime.length > 0 && cashboxTz.length > 0;
    }

    const createTicket = () => {
        //send information to server
        var formData = new FormData();
        if (selectedVenue != null && selectedAccount != null && selectedTariff != null) {
            formData.append('VenueID', selectedVenue.id.toString());
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('TariffID', selectedTariff.id.toString());
            formData.append('PaymentMethod', paymentMethod);
            formData.append('PaymentDateTime', paymentDateTime);
            formData.append('Cost', cost.toString());
            formData.append('CashboxTz', cashboxTz);
            formData.append('Comment', comment);

            fetch('api/venue/addTicket', {
                method: 'POST',
                body: formData
            })
                .then(response => {
                    if (response.ok) {
                        if (productionMode) {
                            window.Telegram.WebApp.showPopup({ message: 'Покупка была успешно создана.' });
                        }
                        else {
                            alert('Покупка была успешно создана.');
                        }
                    }
                    else {
                        if (productionMode) {
                            window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                        }
                        else {
                            alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                        }
                    }
                    onBack();
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    const paymentOptions = [
        {
            label: 'Kaspi',
            value: 'Kaspi'
        },
        {
            label: 'Visa/MC',
            value: 'VisaMC'
        },
        {
            label: 'Сбербанк',
            value: 'Sberbank'
        },
        {
            label: 'Наличные',
            value: 'Cash'
        }
    ];

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    React.useEffect(() => {
        fetch('/api/venue/getVenues')
            .then(res => res.json() as Promise<Venue[]>)
            .then((data) => {
                setVenues(data);
            });
    }, []);

    React.useEffect(() => {
        if (selectedVenue != null) {
            fetch('/api/venue/getTariffsByVenue?venueId=' + selectedVenue.id)
                .then(res => res.json() as Promise<Tariff[]>)
                .then((data) => {
                    setTariffs(data);
                });
        }
    }, [selectedVenue]);

    React.useEffect(() => {
        if (paymentMethod.length > 0 && selectedTariff != null) {
            setCost(paymentMethod === 'Sberbank' ? selectedTariff.rubCost : selectedTariff.cost);
        }
    }, [paymentMethod]);

    return (
        <div className="payment-approval-container">
            <div className="reg-module-view-header"><b>ДОБАВИТЬ ПОКУПКУ</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div className="reg-module-label">Владелец (ID/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите владельца" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearForm}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount != null && <div className="reg-module-block">
                    <div className="reg-module-label">Мероприятие</div>
                    <div>
                        {selectedVenue == null && <Autocomplete options={venues.map(x => { return { label: x.name + " (" + makePeriod(x.dateStart, x.dateEnd) + ")", value: x.id.toString(), search: x.name } })}
                            placeholder="Выберите мероприятие" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedVenue(venues.filter(x => x.id.toString() === v)[0])} />}
                        {selectedVenue != null && <div>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedVenue.name + " (" + makePeriod(selectedVenue.dateStart, selectedVenue.dateEnd) + ")"}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearVenueInfo}>Изм.</button>
                        </div>}
                    </div></div>}
                {(selectedAccount != null && selectedVenue != null) && <div className="reg-module-block">
                    <div className="reg-module-label">Тариф</div>
                    <div>
                        {selectedTariff == null && <Autocomplete options={tariffs.map(x => { return { label: x.name, value: x.id.toString(), search: x.name } })}
                            placeholder="Выберите тариф" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedTariff(tariffs.filter(x => x.id.toString() === v)[0])} />}
                        {selectedTariff != null && <div>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedTariff.name}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearTariffInfo}>Изм.</button>
                        </div>}
                    </div></div>}
                {(selectedAccount != null && selectedVenue != null && selectedTariff != null) && <div className="reg-module-block">
                    <div className="reg-module-label">Способ оплаты</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setPaymentMethod(e.target.value)}>
                            <option style={{ display: 'none' }} value="" selected></option>
                            {paymentOptions.map(po => (
                                <option value={po.value}>{po.label}</option>
                            ))}
                        </select>
                    </div>
                </div>}
                {selectedTariff != null && paymentMethod.length > 0 && <div className="reg-module-block">
                    <div className="reg-module-label">Цена билета</div>
                    <div>₸<div style={{ display: 'inline-block' }}>{formatCost(selectedTariff.cost)}</div>
                    </div>
                </div>}
                {paymentMethod.length > 0 && <div className="reg-module-block">
                    <div className="reg-module-label">Сумма в валюте операции</div>
                    <div>{paymentMethod === 'Sberbank' ? '₽' : '₸'}
                        {enableCostEdit && <input placeholder="Введите стоимость" className="reg-module-input" style={{ width: '30%' }}
                            onChange={(e) => setCost(parseInt(e.target.value || '0'))} value={cost} disabled={!enableCostEdit} />}
                        {!enableCostEdit && <div style={{ display: 'inline-block' }}>{formatCost(cost)}</div>}
                        <button className="btn btn-primary btn-payment-edit" onClick={() => setEnableCostEdit(true)}>Изм.</button>
                    </div>
                </div>}
                {paymentMethod.length > 0 && <div className="reg-module-block">
                    <div>
                        <Datetimepicker label1="Дата квитанции" label2="Время квитанции" onValueChanged={setPaymentDateTime} />
                    </div>
                </div>}
                {paymentMethod.length > 0 && <div className="reg-module-block">
                    <div className="reg-module-label">Часовой пояс кассы</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setCashboxTz(e.target.value)} defaultValue="">
                            <option style={{ display: 'none' }} value=""></option>
                            <option value="-5">GMT+5 (Астана)</option>
                            <option value="-3">GMT+3 (Москва)</option>
                        </select>
                    </div>
                </div>}
                {paymentMethod.length > 0 && <div className="reg-module-block">
                    <div className="reg-module-label">Комментарий</div>
                    <div>
                        <textarea className="form-control" onChange={(e) => setComment(e.target.value)} onFocus={(e) => {
                            if (productionMode && window.Telegram.WebApp.platform === 'ios') {
                                spacer.style.height = '300px';
                                window.setTimeout(() => e.target.scrollIntoView({ behavior: 'smooth', block: 'center' }), 300);
                            }
                        }} onBlur={() => {
                            if (productionMode && window.Telegram.WebApp.platform === 'ios') {
                                spacer.style.height = '0';
                            }
                        }}/>
                    </div>
                </div>}
                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={createTicket}>Добавить</button>
                </div>
                <div className="spacer"></div>
            </div>
        </div>
    )
};

export default TicketAddForm;
