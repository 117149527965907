import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, Payment } from '../../../../../store/Models';
import { getAvailableSubscriptionsForClass, subscriptionsInfo } from '../../../../../subscriptions';
import { formatCost, getTransactionDescription, makeDate, makeDateTime, makeISODate, getClassSingle } from '../../../../../utils';
import AccountFilter from '../../simple/AccountFilter';
import Autocomplete from '../../simple/Autocomplete';
import Datetimepicker from '../../simple/Datetimepicker';
import Approval from './Approval';
import ESR from '../rewards/EvenSaleReward';
import FSR from '../rewards/FastStartReward';

interface PaymentApprovalsProps {
    productionMode: boolean;
    onBack: () => void;
}

interface WalletTransaction {
    transactionDateTime: string;
    transactionTypeCode: number;
    initiatorAccount: Account;
    account: Account;
    value: number;
    pointType: string;
}

const PaymentApprovals: React.FC<PaymentApprovalsProps> = ({ productionMode, onBack }) => {
    const fetch = useFetch();

    const [payments, setPayments] = React.useState<Payment[]>([]);
    const [walletTransactions, setWalletTransactions] = React.useState<WalletTransaction[]>([]);

    const [manualMode, setManualMode] = React.useState<boolean>(false);
    const [manualState, setManualState] = React.useState<number>(0);

    const [evenMode, setEvenMode] = React.useState<boolean>(false);

    const [fastStartMode, setFastStartMode] = React.useState<boolean>(false);

    const [adminPanelMode, setAdminPanelMode] = React.useState<number>(0);

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [paymentOption, setPaymentOption] = React.useState<string>('');
    const [residentAvailBalance, setResidentAvailBalance] = React.useState<number>(0);
    const [selectedSubscription, setSelectedSubscription] = React.useState<string>('');
    const [cost, setCost] = React.useState<number>(0);
    const [isDebt, setIsDebt] = React.useState<number>(0);
    const [paymentDateTime, setPaymentDateTime] = React.useState<string>('');
    const [tz, setTz] = React.useState<string>('');

    const [enableCostEdit, setEnableCostEdit] = React.useState<boolean>(false);

    const selectSubscription = (subscriptionCode: string) => {
        //set selected subscription and calculate cost for it
        setSelectedSubscription(subscriptionCode);
        var subscriptionObject = subscriptionsInfo[subscriptionCode];
        if (subscriptionObject != null && selectedAccount != null && paymentOption.length > 0) {
            if (paymentOption === 'Sberbank') {
                setCost(subscriptionObject.rubCost);
            }
            else if (paymentOption === 'Balance') {
                setCost(subscriptionObject.usdCost);
            }
            else {
                setCost(subscriptionObject.cost);
            }
        }
    }

    const validate = (): boolean => {
        return selectedAccount != null && selectedSubscription.length > 0 &&
            paymentOption.length > 0 && (paymentOption !== 'Balance' || residentAvailBalance >= cost) && cost > 0 && paymentDateTime.length > 0 && tz.length > 0;
    }

    const createPaymentLock = React.useRef<boolean>(false);

    const createPayment = () => {
        //send information to server
        if (selectedAccount != null && !createPaymentLock.current) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('SubscriptionCode', selectedSubscription);
            formData.append('PaymentMethod', paymentOption);
            formData.append('Cost', cost.toString());
            formData.append('IsDebt', isDebt.toString());
            formData.append('PaymentDateTime', paymentDateTime);
            formData.append('CashboxTz', tz);

            createPaymentLock.current = true;
            setShowLockScreen(true);
            fetch('api/payment/sendPaymentInfoManual', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    setManualState(1);
                })
                .catch(() => {
                    window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                })
                .finally(() => {
                    createPaymentLock.current = false;
                    setShowLockScreen(false);
                })
        }
    }

    const paymentOptions = [
        {
            label: 'Kaspi',
            value: 'Kaspi'
        },
        {
            label: 'Visa/MC',
            value: 'VisaMC'
        },
        {
            label: 'Сбербанк',
            value: 'Sberbank'
        },
        {
            label: 'Наличные',
            value: 'Cash'
        },
        {
            label: 'С баланса',
            value: 'Balance'
        }
    ]

    React.useEffect(() => {
        if (adminPanelMode == 0) {
            fetch('/api/payment/getPayments')
                .then(res => res.json() as Promise<Payment[]>)
                .then((data) => {
                    setPayments(data);
                });
        }
        else if (adminPanelMode == 1) {
            fetch('/api/payment/getWalletTransactions')
                .then(res => res.json() as Promise<WalletTransaction[]>)
                .then((data) => {
                    setWalletTransactions(data);
                });
        }
    }, [adminPanelMode]);

    const [paymentFilterAccountId, setPaymentFilterAccountId] = React.useState<string>('');

    React.useEffect(() => {
        if (!manualMode && !evenMode && !fastStartMode) {
            if (paymentFilterAccountId.length == 0) {
                fetch('/api/payment/getPayments')
                    .then(res => res.json() as Promise<Payment[]>)
                    .then((data) => {
                        setPayments(data);
                    });
            }
            else {
                fetch('/api/payment/getPayments?accountId=' + paymentFilterAccountId)
                    .then(res => res.json() as Promise<Payment[]>)
                    .then((data) => {
                        setPayments(data);
                    });
            }
        }
    }, [manualMode, evenMode, fastStartMode, paymentFilterAccountId]);

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    React.useEffect(() => {
        if (selectedSubscription.length > 0) {
            selectSubscription(selectedSubscription);
        }
    }, [paymentOption]);

    React.useEffect(() => {
        if (selectedAccount != null) {
            setAccountAvailBalance();
        }
    }, [selectedAccount]);

    const setAccountAvailBalance = async () => {
        try {
            if (selectedAccount != null) {
                const response = await fetch('/api/account/getAccountAvailBalance?accountId=' + selectedAccount.id);
                if (response.ok) {
                    const jsonData = await response.json();
                    setResidentAvailBalance(jsonData.balance);
                }
                else {
                    setResidentAvailBalance(-1);
                }
            }
        }
        catch (error) {
            console.error(error);
            setResidentAvailBalance(-1);
        }
    }

    const dropManualInfo = () => {
        setTz(''); setPaymentDateTime(''); setCost(0); setIsDebt(0);
        setResidentAvailBalance(0);
        setPaymentOption(''); setSelectedSubscription(''); setSelectedAccount(null);
        setPaymentFilterAccountId('');
    };

    const [showWalletFilters, setShowWalletFilters] = React.useState<boolean>(false);
    const [walletFilterDateFrom, setWalletFilterDateFrom] = React.useState<string>('');
    const [walletFilterDateTo, setWalletFilterDateTo] = React.useState<string>('');
    const [walletFilterRewardType, setWalletFilterRewardType] = React.useState<string>('');
    const [walletFilterRewardStatus, setWalletFilterRewardStatus] = React.useState<string>('');
    const [walletFilterBeneficiaryAccountId, setWalletFilterBeneficiaryAccountId] = React.useState<string>('');

    React.useEffect(() => {
        var queryStringList = [];
        if (walletFilterDateFrom.length > 0) {
            queryStringList.push('dateFrom=' + walletFilterDateFrom);
        }
        if (walletFilterDateTo.length > 0) {
            queryStringList.push('dateTo=' + walletFilterDateTo);
        }
        if (walletFilterRewardType.length > 0) {
            queryStringList.push('rewardType=' + walletFilterRewardType);
        }
        if (walletFilterRewardStatus.length > 0) {
            queryStringList.push('rewardStatus=' + walletFilterRewardStatus);
        }
        if (walletFilterBeneficiaryAccountId.length > 0) {
            queryStringList.push('beneficiaryAccountID=' + walletFilterBeneficiaryAccountId);
        }

        fetch('/api/payment/getWalletTransactions?' + queryStringList.join('&'))
            .then(res => res.json() as Promise<WalletTransaction[]>)
            .then((data) => {
                setWalletTransactions(data);
            });
    }, [walletFilterDateFrom, walletFilterDateTo, walletFilterRewardType, walletFilterRewardStatus, walletFilterBeneficiaryAccountId]);

    React.useEffect(() => {

    }, [paymentFilterAccountId]);

    const generateDateArray = (): Date[] => {
        const result = [];
        const currentDate = new Date();
        const start = new Date(2024, 9, 21);

        let current = new Date(currentDate); // Start from today
        while (current >= start) {
            result.push(new Date(current)); // Add a copy of the current date
            current.setDate(current.getDate() - 1); // Move one day back
        }

        return result;
    }

    const [showLockScreen, setShowLockScreen] = React.useState<boolean>(false);

    return (
        <div className="payment-approval-container">
            {(!manualMode && !evenMode && !fastStartMode) && <>
                <div className="reg-module-view-header"><b>ПЛАТЕЖИ, ВОЗНАГРАЖДЕНИЯ</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                <div className="admin-panel-tab">
                    <div className={adminPanelMode == 0 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setAdminPanelMode(0)}>Платёжные операции</div>
                    <div className={adminPanelMode == 1 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setAdminPanelMode(1)}>Вознаграждения</div>
                </div>
                {adminPanelMode == 0 && <div className="payment-approval-table-container">
                    <button className="btn btn-success btn-payment-add-manual" onClick={() => setManualMode(true)}><i className="fas fa-plus" /> Добавить вручную</button>
                    <div className="wallet-transactions-filter-panel">
                        <AccountFilter accounts={accounts} placeholder="" label="Резидент"
                            onSelect={(id) => setPaymentFilterAccountId(id.toString())}
                            selectAllOptionText="Все Резиденты"
                            onSelectAllOption={() => setSelectedAccount(null)}
                            defaultValue=""
                        />
                    </div>
                    <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                        <tbody>
                            {payments.map((payment, i) => (
                                <Approval productionMode={productionMode} payment={payment} onModify={setPayments} onLock={setShowLockScreen} />
                            ))}
                        </tbody>
                    </table>
                </div>}
                {adminPanelMode == 1 && <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'calc(100% - 65px)' }}>
                    <div style={{ marginTop: '5px' }}>
                        <button className="btn btn-primary btn-payment-add-manual" onClick={() => setEvenMode(true)}><i className="fas fa-plus" /> Чётная продажа</button>
                        <button className="btn btn-primary btn-payment-add-manual" onClick={() => setFastStartMode(true)}><i className="fas fa-plus" /> Быстрый старт</button>
                    </div>
                    {!showWalletFilters && <div className="wallet-transactions-filter-switcher">
                        <span onClick={() => setShowWalletFilters(true)}><i className="fas fa-chevron-down" /> Показать фильтры и итоги</span></div>}
                    {showWalletFilters && <div className="wallet-transactions-filter-switcher">
                        <span onClick={() => setShowWalletFilters(false)}><i className="fas fa-chevron-up" /> Скрыть фильтры и итоги</span></div>}
                    {showWalletFilters && <div className="wallet-transactions-filter-panel">
                        <div className="wallet-transactions-filter-block">
                            <div className="wallet-transactions-filter-label">Дата с</div>
                            <select className="wallet-transactions-filter-select" style={{ marginRight: '10px' }} onChange={(e) => setWalletFilterDateFrom(e.target.value)}>
                                <option value="" selected>Начала</option>
                                <>
                                    {generateDateArray().reverse().map(date => (
                                        <option value={makeISODate(date.toString())}>{makeDate(date.toString())}</option>
                                    ))}
                                </>
                            </select>
                            <div className="wallet-transactions-filter-label">Дата до</div>
                            <select className="wallet-transactions-filter-select" onChange={(e) => setWalletFilterDateTo(e.target.value)}>
                                <option value="" selected>Конца</option>
                                <>
                                    {generateDateArray().map(date => (
                                        <option value={makeISODate(date.toString())}>{makeDate(date.toString())}</option>
                                    ))}
                                </>
                            </select>
                        </div>
                        <div className="wallet-transactions-filter-block">
                            <div className="wallet-transactions-filter-label">Вид вознаграждения</div>
                            <select className="wallet-transactions-filter-select" onChange={(e) => setWalletFilterRewardType(e.target.value)}>
                                <option value="" selected>Все виды</option>
                                <option value="1">Личная рекомендация</option>
                                <option value="2">Чётная продажа</option>
                                <option value="3">Быстрый старт</option>
                                <option value="4">Пассивный</option>
                                <option value="5">Командный (бинар)</option>
                            </select>
                        </div>
                        <div className="wallet-transactions-filter-block">
                            <div className="wallet-transactions-filter-label">Статус</div>
                            <select className="wallet-transactions-filter-select" onChange={(e) => setWalletFilterRewardStatus(e.target.value)}>
                                <option value="" selected>Все статусы</option>
                                <option value="all">Всего</option>
                                <option value="earn">Заработано</option>
                                <option value="miss">Упущено</option>
                                <option value="avail">Доступно к выводу</option>
                                <option value="exp">Ожидается к выплате</option>
                            </select>
                        </div>

                        <AccountFilter accounts={accounts} placeholder="" label="Получатель (куратор)"
                            onSelect={(id) => setWalletFilterBeneficiaryAccountId(id.toString())}
                            selectAllOptionText="Все"
                            onSelectAllOption={() => setWalletFilterBeneficiaryAccountId('')}
                            defaultValue=""
                        />

                        <div className="wallet-transactions-filter-total">Итого:
                            &nbsp;
                            {walletFilterRewardStatus === 'all' && <span>{walletTransactions.reduce((accumulator, current) => { return accumulator + current.value }, 0)} у.е.</span>}
                            {(walletFilterRewardStatus === 'earn' || walletFilterRewardStatus === 'avail' || walletFilterRewardStatus === 'exp') && <span style={{ color: 'green' }}>{walletTransactions.reduce((accumulator, current) => { return accumulator + current.value }, 0)} у.е.</span>}
                            {walletFilterRewardStatus.length == 0 && <span style={{ color: 'green' }}>{walletTransactions.filter(x => x.pointType !== 'ncp').reduce((accumulator, current) => { return accumulator + current.value }, 0)} у.е.</span>}
                            {walletFilterRewardStatus.length == 0 && <span>&nbsp;/&nbsp;</span>}
                            {walletFilterRewardStatus.length == 0 && <span style={{ color: 'red' }}>{walletTransactions.filter(x => x.pointType === 'ncp').reduce((accumulator, current) => { return accumulator + current.value }, 0)} у.е.</span>}
                            {walletFilterRewardStatus === 'miss' && <span style={{ color: 'red' }}>{walletTransactions.reduce((accumulator, current) => { return accumulator + current.value }, 0)} у.е.</span>}
                        </div>
                    </div>}
                    <div style={{ overflowY: 'auto' }}>
                        <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                            <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                                <tr>
                                    <th>Начислено</th>
                                    <th>Вид</th>
                                    <th>Инициатор</th>
                                    <th>Получатель</th>
                                    <th>у.е.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {walletTransactions.map((w, i) => (
                                    <tr>
                                        <td>{makeDateTime(w.transactionDateTime)}</td>
                                        <td>{getTransactionDescription(w.transactionTypeCode)}</td>
                                        <td>{w.initiatorAccount.humanName}</td>
                                        <td>{w.account.humanName}</td>
                                        <td style={{ fontWeight: 'bold', color: w.pointType.length == 0 ? 'black' : w.pointType === 'ncp' ? 'red' : 'green' }}>{w.value >= 0 && w.pointType !== 'ncp' ? '+' : ''}{w.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </>}
            {(manualMode && manualState == 0) && <>
                <div className="reg-module-view-header"><b>ДОБАВИТЬ ПЛАТЁЖ ВРУЧНУЮ</b></div>
                <div className="btn-payment-add-back" onClick={() => { dropManualInfo(); setManualMode(false); }}><i className="fas fa-chevron-left" /></div>
                <div className="payment-approval-manual-container">
                    <div className="reg-module-block">
                        <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                        <div>
                            {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                                placeholder="Выберите Резидента" customValuesAllowed={false}
                                onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                            {selectedAccount != null && <>
                                <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                                <button className="btn btn-primary btn-payment-edit"
                                    onClick={dropManualInfo}>Изм.</button>
                            </>}
                        </div>
                    </div>
                    {selectedAccount && <div className="reg-module-block">
                        <div className="reg-module-label">Способ оплаты</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => setPaymentOption(e.target.value)}>
                                <option style={{ display: 'none' }} value="" selected></option>
                                {paymentOptions.map(po => (
                                    <option value={po.value}>{po.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    {selectedAccount && <div className="reg-module-block">
                        <div className="reg-module-label">За что оплата</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => selectSubscription(e.target.value)}>
                                <option style={{ display: 'none' }} value="" selected></option>
                                {getAvailableSubscriptionsForClass(selectedAccount.class).map(s => (
                                    <option value={s.code}>{s.displayName}</option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    {paymentOption.length > 0 && <div className="reg-module-block">
                        <div className="reg-module-label">Сумма платежа</div>
                        <div>{paymentOption === 'Sberbank' ? '₽' : paymentOption === 'Balance' ? '' : '₸'}
                            {enableCostEdit && <input placeholder="Введите сумму платежа" className="reg-module-input" style={{ width: '30%' }}
                                onChange={(e) => setCost(parseInt(e.target.value || '0'))} value={cost} disabled={!enableCostEdit} />}
                            {!enableCostEdit && <div style={{ display: 'inline-block' }}>{formatCost(cost)}</div>}
                            {paymentOption === 'Balance' ? ' у.е.' : ''}
                            <button className="btn btn-primary btn-payment-edit" onClick={() => setEnableCostEdit(true)}>Изм.</button>
                            {paymentOption === 'Balance' && <div className="reg-balance-description">
                                {residentAvailBalance < 0 && <span style={{ color: 'red' }}>Не удалось загрузить данные по доступному балансу. Попробуйте выбрать заново Резидента.</span>}
                                {(residentAvailBalance >= 0 && residentAvailBalance < cost) && <span style={{ color: 'red' }}>Доступно: <b>{residentAvailBalance} у.е.</b> Недостаточно средств для списания.</span>}
                                {(residentAvailBalance >= 0 && residentAvailBalance >= cost) && <span style={{ color: 'green' }}>Доступно: <b>{residentAvailBalance} у.е.</b> Средств для списания достаточно.</span>}
                            </div>}
                        </div>
                    </div>}
                    {paymentOption.length > 0 && <div className="reg-module-block">
                        <div className="reg-module-label">В долг</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => setIsDebt(parseInt(e.target.value))} defaultValue="0">
                                <option value="0">Нет</option>
                                <option value="1">Да</option>
                            </select>
                        </div>
                    </div>}
                    {selectedAccount != null && <div className="reg-module-block">
                        <div>
                            <Datetimepicker label1="Дата квитанции" label2="Время квитанции" onValueChanged={setPaymentDateTime} />
                        </div>
                    </div>}
                    {selectedAccount != null && <div className="reg-module-block">
                        <div className="reg-module-label">Часовой пояс кассы</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => setTz(e.target.value)} defaultValue="">
                                <option style={{ display: 'none' }} value=""></option>
                                <option value="-5">GMT+5 (Астана)</option>
                                <option value="-3">GMT+3 (Москва)</option>
                            </select>
                        </div>
                    </div>}
                    <div className="reg-module-submit">
                        <button className="btn btn-success" disabled={!validate()} onClick={() => {
                            if (selectedAccount != null && selectedSubscription != null) {
                                if (paymentOption === 'Balance') {
                                    if (productionMode) {
                                        window.Telegram.WebApp.showPopup({
                                            title: "Списание с баланса Резидента",
                                            message: `Вы подтверждаете списание средств со счёта Резидента «${selectedAccount.humanName}» за ${selectedSubscription.endsWith("RENEWAL") ? "продление тарифа" : selectedSubscription.endsWith("UPGRADE") ? "апгрейд на тариф" : selectedSubscription.endsWith("INITIAL") ? "вступление на тариф" : "тариф"} «${getClassSingle(subscriptionsInfo[selectedSubscription].subscriptionClass)}» в размере ${formatCost(cost)} у.е.?`,
                                            buttons: [
                                                { id: "approveBalanceWithdraw", text: "Да, подтверждаю" },
                                                { text: "Нет" }
                                            ]
                                        },
                                            (id: string) => {
                                                if (id === "approveBalanceWithdraw") {
                                                    createPayment();
                                                }
                                            });
                                    }
                                    else {
                                        if (window.confirm(`Вы подтверждаете списание средств со счёта Резидента «${selectedAccount.humanName}»­ за ${selectedSubscription.endsWith("RENEWAL") ? "продление тарифа" : selectedSubscription.endsWith("UPGRADE") ? "апгрейд на тариф" : selectedSubscription.endsWith("INITIAL") ? "вступление на тариф" : "тариф"} «${getClassSingle(subscriptionsInfo[selectedSubscription].subscriptionClass)}» в размере ${formatCost(cost)} у.е.?`)) {
                                            createPayment();
                                        }
                                    }
                                }
                                else {
                                    createPayment();
                                }
                            }
                        }}>Добавить</button>
                    </div>
                </div>
            </>}
            {(manualMode && manualState == 1 && selectedAccount != null) && <>
                <div className="reg-module-view-header"><b>ДОБАВИТЬ ПЛАТЁЖ ВРУЧНУЮ</b></div>
                <div className="payment-approval-success-container">
                    <div className="payment-approval-success-message">
                        <b>Платёж успешно добавлен!</b>
                        <br />
                        <u>Параметры:</u><br />
                        Резидент: <b>{selectedAccount.id + " " + selectedAccount.humanName}</b><br />
                        Способ оплаты: <b>{paymentOption}</b><br />
                        Подписка: <b>{subscriptionsInfo[selectedSubscription].displayName}</b><br />
                        Сумма: <b>{paymentOption === 'Sberbank' ? '₽' : paymentOption === 'Balance' ? '' : '₸'}{formatCost(cost)}{paymentOption === 'Balance' ? ' у.е.' : ''}</b><br />
                        Дата и время платежа в часовом поясе кассы: <b>{paymentDateTime}</b>
                        <br />
                        <br />
                        <div style={{ textDecoration: 'underline' }} onClick={() => {
                            setManualMode(false);
                            setManualState(0);
                            dropManualInfo();
                        }}>Вернуться к списку платёжных операций</div>
                    </div>
                </div>
            </>}
            {evenMode && <ESR onBack={() => setEvenMode(false)} accounts={accounts} />}
            {fastStartMode && <FSR onBack={() => setFastStartMode(false)} accounts={accounts} />}
            {showLockScreen && <div className="payment-lock-screen-cover">
                <div className="payment-lock-screen-content">
                    Операция в процессе проведения.<br />
                    Пожалуйста, ожидайте.
                </div>
            </div>}
        </div>
    )
};

export default PaymentApprovals;
