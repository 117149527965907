import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account } from '../../../../../store/Models';
import { makeDateTimeWithWeekDay } from '../../../../../utils';
import MillionaireMorningEditForm from './forms/MillionaireMorningEditForm';
import MillionaireMorningForm from './forms/MillionaireMorningForm';

interface AdminMillionaireMorningManagementProps {
    productionMode: boolean;
    onBack: () => void;
}

export interface MillionaireMorningItemExt {
    id: number;
    publishDateTime: string;
    publishAccount: Account;
    content: string;
    status: number;
}

const AdminMillionaireMorningManagement: React.FC<AdminMillionaireMorningManagementProps> = ({ productionMode, onBack }) => {
    const fetch = useFetch();

    const [selectedScreen, setSelectedScreen] = React.useState<number>(0);

    const [millionaireMorningItems, setMillionaireMorningItems] = React.useState<MillionaireMorningItemExt[]>([]);

    const [selectedItem, setSelectedItem] = React.useState<MillionaireMorningItemExt | null>(null);

    React.useEffect(() => {
        if (selectedScreen == 0) {
            fetch('/api/admin/getMillionaireMorningItems')
                .then(res => res.json() as Promise<MillionaireMorningItemExt[]>)
                .then(data => setMillionaireMorningItems(data));
        }
    }, [selectedScreen]);

    return (
        <>
            {selectedScreen == 0 && <div className="payment-approval-container">
                <div className="reg-module-view-header"><b>УТРО С МИЛЛИОНЕРОМ</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                <button style={{ marginTop: '10px', marginBottom: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={() => setSelectedScreen(1)}>Добавить задание</button>
                <div style={{ height: "calc(100% - 75px)", overflowY: 'auto' }}>
                    {millionaireMorningItems.length > 0 && <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                        <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                            <tr>
                                <th>Публикация</th>
                                <th>Автор</th>
                                <th>Контент</th>
                                <th>Статус</th>
                            </tr>
                        </thead>
                        <tbody>
                            {millionaireMorningItems.map((mmi, i) => (
                                <tr onClick={() => {
                                    setSelectedItem(mmi);
                                    setSelectedScreen(2);
                                }}>
                                    <td>{makeDateTimeWithWeekDay(mmi.publishDateTime)}</td>
                                    <td>{mmi.publishAccount.humanName}</td>
                                    <td><AdminMillionaireMorningManagementContentItem key={i} content={mmi.content} /></td>
                                    <td><div style={{ width: '10px', height: '10px', borderRadius: '50%', margin: 'auto', backgroundColor: mmi.status == 7 ? 'green' : mmi.status == 0 ? 'yellow' : 'initial' }}></div></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                </div>
            </div>}
            {selectedScreen == 1 && <MillionaireMorningForm onBack={() => setSelectedScreen(0)} productionMode={productionMode} />}
            {(selectedScreen == 2 && selectedItem != null) && <MillionaireMorningEditForm selectedItem={selectedItem} onBack={() => { setSelectedItem(null); setSelectedScreen(0); }} productionMode={productionMode} />}
        </>
    )
};

export default AdminMillionaireMorningManagement;

interface AdminMillionaireMorningManagementContentItemProps {
    content: string;
}

const AdminMillionaireMorningManagementContentItem: React.FC<AdminMillionaireMorningManagementContentItemProps> = ({ content }) => {

    const [expandText, setExpandText] = React.useState<boolean>(false);

    const expandTextStyle: React.CSSProperties = {
        color: '#047ff9',
        cursor: 'pointer',
        fontStyle: 'italic'
    }

    const dangerouslySetInnerHtmlAtInstant = (paragraph: string): string => {
        var parsedSpacesParagraph = paragraph.split(' ').map((t, i) => {
            if (t.length > 0) return `<span key=${i}>${t} </span>`;
            else return `<span key=${i}>&nbsp;</span>`;
        }).join('');

        return parsedSpacesParagraph.replace(/(http||https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g, t => `<a href=${t} target="_blank">${t}</a>`);
    }

    const renderTextInstant = (text: string) => {
        return <div>{text.split(/\\n+/).map((paragraph, index) =>
            <p key={index} dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHtmlAtInstant(paragraph) }}></p>)}</div>
    }

    const renderTextFull = (text: string) => {
        if (expandText) {
            return renderTextInstant(text);
        }
        else {
            var cutText = text.split(' ').slice(0, 10).join(' '); //10 words
            var splittedText = cutText.split(/\\n+/);
            return <div>{
                splittedText.map((paragraph, index) =>
                    <p key={index}><span dangerouslySetInnerHTML={{ __html: paragraph.length > 0 ? paragraph : '&nbsp;' }}></span>{cutText.length < text.length && index == splittedText.length - 1 ? <span>...<span style={expandTextStyle}
                        onClick={(e) => { e.stopPropagation(); setExpandText(true); }}>&nbsp;Читать далее</span></span> : ""}</p>)
            }
            </div>
        }
    }

    return <div className="full-post">{renderTextFull(content)}</div>
};
