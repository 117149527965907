import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, BinarAccount } from '../../store/Models';
import { getClassSingle, getResidentsText, trimName } from '../../utils';
import AccountAvatar from './AccountAvatar';
import BinarPtsTransactionsView from './components/complex/binar/BinarPtsTransactionsView';
import BinarView from './components/complex/binar/BinarView';
import FirstLineSVG from './svg/FirstLineSVG';

interface ClassicViewProps {
    currentUser: Account;
    productionMode: boolean;
    onAccountClick: (account: Account) => void;
    onClose: () => void;
    targetUser?: Account;
}

interface ClassicLine {
    account: Account;
    line: number;
    binarSide: string;
}

const ClassicView: React.FC<ClassicViewProps> = ({ currentUser, productionMode, onAccountClick, onClose, targetUser }) => {
    const fetch = useFetch();

    const [lines, setLines] = React.useState<ClassicLine[]>([]);
    const [selectedLine, setSelectedLine] = React.useState<number>(1);

    const [showBinar, setShowBinar] = React.useState<boolean>(false);
    const [currentAccountForBinarViewId, setCurrentAccountForBinarViewId] = React.useState<number>(0);

    const [selectedBinarForPts, setSelectedBinarForPts] = React.useState<BinarAccount | null>(null);

    React.useEffect(() => {
        if (targetUser != null) {
            fetch('/api/challenge/getLinesByAccount?id=' + targetUser.id)
                .then(r => r.json() as Promise<ClassicLine[]>)
                .then(data => {
                    setLines(data);
                });
        }
        else {
            fetch('/api/challenge/getLines')
                .then(r => r.json() as Promise<ClassicLine[]>)
                .then(data => {
                    setLines(data);
                });
        }
    }, []);

    return (
        <div className="classic-view">
            {!showBinar && <>
                <div className="classic-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
                <div className="classic-view-header"><b>КЛАССИЧЕСКАЯ СХЕМА</b><br /><em>(вся команда до 7-й линии: <b>{lines.length}</b> чел.)</em></div>
                <div className="classic-view-body">
                    <div className="classic-view-selector">
                        <div className={selectedLine == 1 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                            onClick={() => setSelectedLine(1)}>
                            <FirstLineSVG /> <span>1-я линия</span>
                        </div>
                        <div className={selectedLine == 2 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                            onClick={() => setSelectedLine(2)}                    >
                            <FirstLineSVG /> <span>2-я линия</span>
                        </div>
                        <div className={selectedLine == 3 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                            onClick={() => setSelectedLine(3)}>
                            <FirstLineSVG /> <span>3-я линия</span>
                        </div>
                    </div>
                    <div className="classic-view-selector">
                        <div className={selectedLine == 4 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                            onClick={() => setSelectedLine(4)}>
                            <FirstLineSVG /> <span>4-я линия</span>
                        </div>
                        <div className={selectedLine == 5 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                            onClick={() => setSelectedLine(5)}>
                            <FirstLineSVG /> <span>5-я линия</span>
                        </div>
                        <div className={selectedLine == 6 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                            onClick={() => setSelectedLine(6)}>
                            <FirstLineSVG /> <span>6-я линия</span>
                        </div>
                    </div>
                    <div className="classic-view-selector">
                        <div className={selectedLine == 7 ? "classic-view-selector-option active" : "classic-view-selector-option"}
                            onClick={() => setSelectedLine(7)}>
                            <FirstLineSVG /> <span>7-я линия</span>
                        </div>
                    </div>
                    <div className="classic-view-table-container">
                        <table className="table table-striped" style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Резидент</th>
                                    <th>Тариф</th>
                                    <th>Команда</th>
                                    <th style={{ width: '25%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {lines.filter(l => l.line == selectedLine).length == 0 &&
                                    <tr>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td style={{ width: '25%' }}>-</td>
                                    </tr>}
                                {lines.filter(l => l.line == selectedLine).map((item, i) =>
                                    <tr>
                                        <td style={{ verticalAlign: 'middle' }}>{i + 1}</td>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{trimName(item.account.name)}</span></div>
                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>{getClassSingle(item.account.class)}</td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.binarSide === 'Left' ? "Левая" : item.binarSide === 'Right' ? "Правая" : ""}</td>
                                        <td style={{ width: '25%' }}>
                                            <button className="btn btn-outline-primary" style={{ fontSize: '10px', float: 'right' }} onClick={() => {
                                                setCurrentAccountForBinarViewId(item.account.id);
                                                setShowBinar(true);
                                            }}>В бинаре</button>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="classic-view-line-count"><b>{lines.filter(l => l.line == selectedLine).length}</b> чел. — количество Резидентов {selectedLine}-й линии{lines.filter(l => l.line == selectedLine).length > 0 && <span>, из них:</span>}
                        {lines.filter(l => l.line == selectedLine).length > 0 &&
                            <span><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;<b>{lines.filter(l => l.line == selectedLine && l.account.isActive > 0).length}</b> чел. — с активной подпиской
                                <br />&nbsp;&nbsp;&nbsp;&nbsp;<b>{lines.filter(l => l.line == selectedLine && l.account.isActive == 0).length}</b> чел. — без активной подписки</span>}
                    </div>
                </div>
            </>}
            {(showBinar && !selectedBinarForPts && currentAccountForBinarViewId > 0) && <BinarView homeUserRef={currentUser} homeUserId={currentUser.id} homeUserName={currentUser.humanName} rootUserId={currentAccountForBinarViewId} onBack={() => setShowBinar(false)}
                onSelectBinarAccount={setCurrentAccountForBinarViewId} onShowBinarPts={setSelectedBinarForPts} productionMode={productionMode} />}
            {selectedBinarForPts != null && <BinarPtsTransactionsView homeUserRef={currentUser} productionMode={productionMode} homeUserId={currentUser.id} homeUserName={currentUser.humanName}
                rootBinarAccount={selectedBinarForPts} onBack={() => setSelectedBinarForPts(null)} onReloadView={setSelectedBinarForPts} />}
        </div>
    )
};

export default ClassicView;
