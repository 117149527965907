import * as React from 'react';

const FounderSVG: React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 282.000000 237.000000" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0.000000,237.000000) scale(0.100000,-0.100000)">
                <path d="M751 2342 c-45 -24 -153 -120 -186 -165 -47 -65 -106 -180 -120 -233
-38 -143 -52 -430 -26 -557 7 -37 15 -66 18 -64 2 3 6 51 8 108 9 212 15 303
25 338 15 55 45 98 89 125 22 14 45 33 53 43 7 10 20 19 28 21 8 2 33 12 55
22 23 11 60 20 83 21 23 2 42 5 42 9 0 11 59 31 91 30 19 0 61 3 94 6 38 3 71
1 90 -7 17 -7 34 -11 38 -10 4 1 32 -6 62 -15 51 -14 79 -39 80 -69 1 -5 7
-20 14 -33 7 -13 10 -29 8 -37 -3 -7 -1 -17 3 -21 5 -5 11 -20 13 -33 3 -22
22 -41 41 -41 6 0 1 7 -10 16 -18 14 -18 15 4 4 20 -10 22 -17 17 -47 -3 -19
-7 -39 -10 -43 -3 -5 1 -10 7 -13 7 -2 2 -5 -12 -5 -60 -2 -80 -5 -80 -14 0
-5 -5 -6 -10 -3 -6 4 -7 12 -3 18 5 8 2 8 -9 0 -8 -7 -18 -10 -21 -6 -4 3 -7
3 -7 -1 0 -3 12 -11 26 -16 17 -7 24 -15 20 -25 -6 -17 9 -21 19 -5 3 6 11 10
17 10 6 0 8 -3 4 -7 -3 -4 4 -13 16 -20 15 -8 18 -12 8 -13 -24 -1 10 -20 35
-20 19 0 52 -37 47 -53 -1 -4 6 -4 15 1 22 12 43 5 43 -14 0 -8 7 -14 15 -14
15 0 16 -4 21 -75 1 -22 4 -61 7 -87 2 -26 1 -45 -3 -43 -4 3 -11 -6 -14 -20
-4 -14 -11 -25 -17 -25 -6 0 -8 -4 -5 -9 4 -5 -4 -22 -17 -38 -13 -15 -34 -50
-47 -76 -18 -40 -20 -53 -11 -73 6 -13 15 -24 21 -24 5 0 10 -12 11 -27 l1
-28 7 25 8 25 5 -25 c5 -21 5 -20 7 8 1 40 21 34 21 -7 0 -15 4 -31 9 -36 6
-6 6 -28 -1 -59 -6 -30 -13 -44 -18 -36 -5 8 -10 2 -15 -19 -5 -24 -12 -31
-31 -31 -17 0 -25 -7 -30 -25 -3 -14 -10 -25 -14 -25 -5 0 -12 -15 -15 -33
-10 -43 -86 -155 -130 -190 -33 -27 -44 -29 -133 -32 -106 -3 -117 0 -181 57
-41 36 -70 50 -56 28 11 -18 -29 -10 -67 13 -20 12 -50 39 -67 61 -17 21 -31
36 -31 32 0 -3 -14 8 -31 26 -32 35 -33 36 54 -103 8 -13 2 -26 -23 -57 -18
-23 -42 -44 -52 -48 -10 -3 -49 -32 -85 -64 -98 -87 -164 -124 -319 -176 -76
-26 -151 -55 -165 -65 -34 -23 -57 -79 -67 -161 l-9 -68 554 0 c413 0 552 3
547 11 -4 7 -8 24 -10 38 -2 15 -17 60 -34 101 -35 87 -35 86 -15 70 13 -11
13 -7 -1 26 -9 22 -34 74 -56 117 -23 44 -37 82 -32 86 12 11 142 -16 173 -36
12 -8 39 -16 60 -18 33 -2 60 -11 116 -37 30 -13 85 -4 125 20 19 12 40 22 46
22 14 0 32 -43 36 -85 4 -45 43 -35 74 20 20 36 34 29 34 -16 0 -24 5 -105 12
-181 l11 -138 584 0 c320 0 583 2 583 5 0 22 -80 196 -105 230 -18 23 -36 51
-39 61 -14 40 -163 239 -177 236 -8 -2 -74 -3 -147 -2 -93 0 -152 -4 -197 -15
l-65 -17 -46 40 c-55 49 -109 69 -228 83 -91 11 -91 11 -134 57 l-43 47 4 105
c6 125 36 267 57 262 33 -8 70 20 81 62 6 22 23 63 37 91 22 44 25 63 26 155
1 78 4 104 14 103 16 -4 15 17 -2 40 -18 23 -26 22 -74 -13 -50 -36 -56 -37
-50 -6 3 17 0 23 -11 22 -13 -1 -17 14 -22 69 -6 75 -41 173 -124 350 -40 86
-57 110 -118 167 -116 109 -181 136 -468 192 -183 36 -193 37 -228 18z m1054
-941 c8 -76 -6 -128 -20 -75 -4 16 -7 52 -7 79 0 64 0 65 11 65 5 0 12 -31 16
-69z"/>
                <path d="M1195 1660 c3 -4 16 -10 30 -12 13 -3 22 -2 20 2 -3 4 -16 10 -30 12
-13 3 -22 2 -20 -2z"/>
                <path d="M1125 1641 c-22 -4 -57 -14 -77 -24 -36 -17 -39 -23 -29 -76 1 -2 7
2 14 8 8 6 17 8 20 4 4 -3 7 -2 7 4 0 6 17 15 38 21 64 18 117 40 115 47 -2
11 -26 25 -38 23 -5 0 -28 -4 -50 -7z m62 -7 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z"/>
                <path d="M1189 1541 c19 -12 7 -27 -28 -34 -28 -6 -91 -59 -91 -78 0 -21 26
-7 44 24 l19 32 9 -28 c8 -22 15 -27 32 -23 11 2 38 8 60 12 36 6 38 5 31 -15
-5 -16 -3 -20 9 -15 9 3 16 9 16 13 0 3 11 18 24 32 37 40 28 62 -30 76 -61
14 -115 16 -95 4z"/>
                <path d="M680 1523 c-44 -16 -99 -47 -94 -55 3 -4 0 -8 -5 -8 -6 0 -11 -4 -11
-9 0 -15 72 -1 102 20 15 10 31 19 37 19 11 0 22 26 14 34 -7 7 -22 7 -43 -1z"/>
                <path d="M1091 1504 c-12 -15 -21 -32 -21 -38 0 -6 6 -2 12 9 6 11 18 28 26
38 8 9 12 17 9 17 -3 0 -15 -12 -26 -26z"/>
                <path d="M550 1479 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
                <path d="M984 1462 c-14 -24 -7 -49 17 -55 22 -6 25 16 6 50 -11 21 -13 21
-23 5z"/>
                <path d="M470 1411 c-12 -24 -13 -31 -2 -31 5 0 14 11 20 25 14 29 -2 34 -18
6z"/>
                <path d="M634 1391 c-31 -19 -64 -57 -64 -74 0 -4 12 5 26 20 l25 28 -17 -30
c-10 -16 -24 -36 -33 -42 -13 -11 -13 -13 3 -13 22 0 80 30 72 37 -3 3 0 15 6
26 10 20 11 20 24 -6 15 -27 31 -27 97 3 32 16 33 24 7 50 -20 20 -60 28 -60
11 0 -5 -10 -11 -22 -14 -13 -3 -34 -8 -48 -12 l-25 -7 25 21 c31 26 24 27
-16 2z"/>
                <path d="M1218 1393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
                <path d="M720 1270 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
                <path d="M1113 1213 c-7 -3 -13 -13 -13 -23 0 -9 -7 -23 -15 -30 -12 -10 -16
-9 -21 4 -8 22 -39 4 -59 -33 -14 -29 -68 -56 -87 -45 -5 3 -21 3 -36 -1 -25
-6 -26 -8 -9 -20 29 -21 131 -18 175 6 64 36 99 67 104 93 6 28 -18 57 -39 49z"/>
                <path d="M434 1195 c21 -16 32 -13 21 4 -3 6 -14 11 -23 11 -15 -1 -15 -2 2
-15z"/>
                <path d="M1242 1019 c3 -12 -46 -29 -121 -44 -12 -2 -33 -10 -45 -18 -11 -8
-34 -17 -51 -20 -84 -15 -186 -49 -182 -60 2 -8 71 -36 90 -37 4 0 7 5 7 10 0
6 -7 10 -15 10 -48 0 18 44 90 60 115 26 180 38 187 34 14 -9 8 -34 -7 -34 -8
0 -23 -4 -33 -9 -45 -23 -72 -34 -103 -41 -24 -6 -28 -8 -11 -9 12 -1 39 6 59
14 41 17 57 19 48 5 -3 -5 1 -10 11 -10 14 0 15 2 3 16 -12 15 -11 16 10 9 18
-5 22 -4 16 5 -5 9 -4 11 3 6 18 -11 93 64 90 89 -2 11 -7 20 -13 20 -5 0 -16
4 -23 8 -8 6 -12 4 -10 -4z"/>
                <path d="M1432 955 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
                <path d="M794 915 c-7 -28 0 -55 16 -55 5 0 10 18 10 40 0 44 -16 54 -26 15z" />
                <path d="M665 881 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
                <path d="M1203 870 c-6 -23 -87 -80 -114 -81 -19 -1 -16 -3 10 -9 28 -7 41 -4
71 15 37 23 80 70 80 86 0 14 -43 5 -47 -11z"/>
                <path d="M1416 853 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
                <path d="M998 853 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
                <path d="M610 831 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            </g>
        </svg>
    );
};

export default FounderSVG;
