import * as React from 'react';

interface ProgressBarProps {
    value: number; // The current progress value (0-100)
    displayText: string;
    max?: number; // Optional max value, default is 100
    isSpecial?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, displayText, max = 100, isSpecial = false }) => {
    const percentage = Math.min((value / max) * 100, 100); // Clamp the value to 100%

    var className = "progress-bar-fill";

    if (percentage <= 30) {
        if (percentage <= 20) {
            className += " danger";
        }
        else {
            className += " warning";
        }
    }
    else if (isSpecial) {
        className += " special";
    }

    return (
        <div className="progress-bar-container">
            <div
                className={className}
                style={{ width: `${percentage}%` }}
                aria-valuenow={value}
                aria-valuemax={max}
            ></div>
            <div className="progress-bar-text">{displayText}</div>
        </div>
    );
};

export default ProgressBar;
