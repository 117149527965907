import * as React from 'react';

const FirstLineSVG: React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g id="_2605859221648">
                <circle cx="24.39" cy="6.61" r="6.61" />
                <path d="M24.39 11.51c4.49,0 8.13,6.18 8.13,9.29 0,3.12 -1.75,3.06 -2.76,3.06l-10.74 0c-1.02,0 -2.76,0.06 -2.76,-3.06 0,-3.12 3.64,-9.29 8.13,-9.29z" />
            </g>
            <g id="_2605859221024">
                <circle cx="8.13" cy="30.47" r="6.61" />
                <path d="M8.13 35.37c4.49,0 8.13,6.18 8.13,9.29 0,3.12 -1.75,3.06 -2.76,3.06l-10.74 0c-1.02,0 -2.76,0.06 -2.76,-3.06 0,-3.12 3.64,-9.29 8.13,-9.29z" />
            </g>
            <g id="_2605859222656">
                <circle cx="40.65" cy="30.47" r="6.61" />
                <path d="M40.66 35.37c4.49,0 8.13,6.18 8.13,9.29 0,3.12 -1.75,3.06 -2.76,3.06l-10.74 0c-1.02,0 -2.76,0.06 -2.76,-3.06 0,-3.12 3.64,-9.29 8.13,-9.29z" />
            </g>
            <g>
                <path d="M25.49 35.79c0,0.6 -0.49,1.09 -1.1,1.09 -0.61,0 -1.1,-0.49 -1.1,-1.09l2.21 0zm0 -8.02l0 8.02 -2.21 0 0 -8.02 2.21 0zm-2.21 0c0,-0.6 0.49,-1.09 1.1,-1.09 0.61,0 1.1,0.49 1.1,1.09l-2.21 0z" />
            </g>
            <g>
                <path d="M17.44 36.89c-0.61,0 -1.11,-0.49 -1.11,-1.1 0,-0.61 0.5,-1.1 1.11,-1.1l0 2.21zm13.89 0l-13.89 0 0 -2.21 13.89 0 0 2.21zm0 -2.21c0.61,0 1.11,0.49 1.11,1.1 0,0.61 -0.5,1.1 -1.11,1.1l0 -2.21z" />
            </g>
        </svg>

    );
};

export default FirstLineSVG;
