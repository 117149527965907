import * as React from 'react';
import useFetch from '../../../../hooks/useFetch';
import { Account } from '../../../../store/Models';
import Autocomplete from './Autocomplete';

interface SearchPanelProps {
    ownerAccountID: number;
    onHide: () => void;
    onSelect: (id: number) => void;
}

const SearchPanel = React.forwardRef((props: SearchPanelProps, ref) => {
    const fetch = useFetch();

    const inputContainerRef = React.useRef<HTMLDivElement | null>(null);

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    React.useEffect(() => {
        fetch('/api/binarReward/getAllDescendants?ownerAccountId=' + props.ownerAccountID)
            .then(res => res.json() as Promise<Account[]>)
            .then(data => {
                setAccounts(data);
            });
    }, []);

    React.useImperativeHandle(ref, () => ({
        focusFirstInput: () => {
            if (inputContainerRef.current) {
                const firstInput = inputContainerRef.current.querySelector<HTMLInputElement>("input");
                if (firstInput) {
                    firstInput.focus();
                }
            }
        }
    }));

    return (
        <div className="search-panel" onClick={props.onHide}>
            <div ref={inputContainerRef} onClick={(e) => e.stopPropagation()} className="search-panel-input">
            <Autocomplete options={accounts.map(x => { return { label: x.id + " " + x.humanName, value: x.id.toString(), search: x.id + " " + x.humanName + " " + x.name } })}
                placeholder="Поиск в моей команде"
                customValuesAllowed={false}
                    onValueSelected={(id) => { props.onSelect(parseInt(id)); props.onHide(); }} />
            </div>
        </div>
    )
});

export default SearchPanel;
