import * as React from 'react';
import ReactDOM from 'react-dom';
import useFetch from '../../hooks/useFetch';
import { Account, Reflection } from '../../store/Models';
import { makeDateHeaderRussian } from '../../utils';
import ReflectionComponent from '../Reflection';
import FocusLongPressMenu from './FocusLongPressMenu';

interface StreamModuleProps {
    currentUser: Account;
    visible: boolean;
    productionMode: boolean;
    selectedReflection: Reflection | null;
    items: Reflection[];
    streamsUnreadCount: number;
    setStreamsUnreadCount: React.Dispatch<React.SetStateAction<number>>;
    onSelectReflection: (item: Reflection | null) => void;
    showContextMenu: boolean;
    onOpenContextMenu: () => void;
    onCloseContextMenu: () => void;
    onDeleteReflection: (item: Reflection) => void;
    onStartEdit: (item: Reflection) => void;
    onStartReply: (item: Reflection) => void;
    onAccountClick: (account: Account) => void;
}

const StreamModule: React.FC<StreamModuleProps> = ({ currentUser, visible,
    productionMode,
    selectedReflection, items,
    streamsUnreadCount, setStreamsUnreadCount,
    onSelectReflection, showContextMenu, onOpenContextMenu, onCloseContextMenu,
    onDeleteReflection, onStartEdit, onStartReply, onAccountClick }) => {
    const fetch = useFetch();

    const streamsListRef = React.useRef<HTMLDivElement>(null);

    const [lastScrollPosition, setLastScrollPosition] = React.useState<number>(0);
    const [showScroller, setShowScroller] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (streamsListRef.current != null) {
            if (visible) {
                streamsListRef.current.style.display = '';
                let unreadStreams = getUnreadStreams();
                if (unreadStreams.length > 0) { //scroll to first unread message and show scroller with unread counter
                    setToStream(unreadStreams[0].id);
                    window.setTimeout(() => {
                        setShowScroller(true);
                    }, 100);
                }
                else {
                    streamsListRef.current.scrollTop = streamsListRef.current.scrollHeight;
                }
            }
            else {
                streamsListRef.current.style.display = 'none';
            }
        }
    }, [visible]);

    const pressTimer = React.useRef(0);

    const onContextMenu = (reflection: Reflection, isImmediate: boolean) => {
        //prevent window collapsing on iOS
        if (window.Telegram.WebApp.platform === 'ios') {
            if (window.scrollY === 0) {
                window.scrollTo(0, 1);
            }
        }

        if (isImmediate == false) {
            pressTimer.current = window.setTimeout(() => {
                onSelectReflection(reflection);
                onOpenContextMenu();
            }, 800);
        }
        else {
            onSelectReflection(reflection);
            onOpenContextMenu();
        }
    }

    const onTouchMove = () => {
        if (selectedReflection != null) {
            onSelectReflection(null);
        }
        window.clearTimeout(pressTimer.current);
    }

    const onTouchStop = () => {
        window.clearTimeout(pressTimer.current);
    }

    const setToStream = (id: number) => {
        var targetElement = document.getElementById(id.toString());

        if (targetElement != null) {
            targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
    }

    const scrollToBottom = () => {
        if (streamsListRef.current != null) {
            streamsListRef.current.scrollTo({
                top: streamsListRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
        setShowScroller(false);
    }

    const onScroll = () => {
        if (streamsListRef.current != null) {
            const scrollTop = streamsListRef.current.scrollTop;
            const scrollHeight = streamsListRef.current.scrollHeight;
            const offsetHeight = streamsListRef.current.offsetHeight;

            if (scrollTop < scrollHeight - offsetHeight - 30) {
                setShowScroller(true);
            } else {
                setShowScroller(false);
            }

            setLastScrollPosition(scrollTop <= 0 ? 0 : scrollTop);
        }
    }

    const getUnreadStreams = (): Reflection[] => {
        return items.filter(s => s.isRead == 0 && s.post.publishAccount.id != currentUser.id && new Date(s.post.publishDateTime) > new Date(currentUser.activeFrom));
    }

    const isFirstOnItsDateInLocalTime = (item: Reflection): boolean => {
        // Extract the target date from the target object
        let targetDateCopy = new Date(item.post.publishDateTime);
        targetDateCopy.setHours(targetDateCopy.getHours() - targetDateCopy.getTimezoneOffset() / 60); // to local time!
        const targetDate = new Date(targetDateCopy);

        // Filter all objects that share the same date as the target object
        const sameDateObjects = items.filter(r => {
            let dateCopy = new Date(r.post.publishDateTime);
            dateCopy.setHours(dateCopy.getHours() - dateCopy.getTimezoneOffset() / 60); // to local time!
            const objDate = new Date(dateCopy);
            return objDate.toDateString() === targetDate.toDateString();
        });

        // Find the object with the minimal date and time among the same date objects
        const minDateObject = sameDateObjects.reduce((minObj, obj) => {
            const objDate = new Date(obj.post.publishDateTime);
            return objDate < new Date(minObj.post.publishDateTime) ? obj : minObj;
        }, sameDateObjects[0]);

        // Check if the target object is the one with the minimal date
        return item === minDateObject;
    }

    return (
        <div ref={streamsListRef} className="stream-module-layout" onScroll={onScroll}
            style={{
                backgroundImage: 'url("assets/banners/wallpaper13.png")', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
            }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {items.map((reflection: Reflection) =>
                            <div className="mt-1 mb-1" key={reflection.id} data-id={reflection.id}
                                data-my={reflection.post.publishAccount.id === currentUser.id}>
                                {isFirstOnItsDateInLocalTime(reflection) && <div className="date-header">
                                    <span className="date-header-span-streams">{makeDateHeaderRussian(reflection.post.publishDateTime.toString())}</span></div>}
                                <ReflectionComponent currentUser={currentUser} reflection={reflection}
                                    isFirst={false}
                                    isSecond={false}
                                    isExpanded={false}
                                    productionMode={productionMode}
                                    onContextMenu={onContextMenu}
                                    onReactionContextMenu={() => { }}
                                    onTouchMove={onTouchMove}
                                    onTouchStop={onTouchStop}
                                    onGoToReply={() => { }}
                                    onAccountClick={onAccountClick}
                                    onExpand={() => { }}
                                    onRead={() => setStreamsUnreadCount(x => x - 1)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {(showContextMenu && selectedReflection != null) && ReactDOM.createPortal(<FocusLongPressMenu user={currentUser} visible={true}
                productionMode={productionMode}
                reflection={selectedReflection}
                isFirst={false}
                isSecond={false}
                onWriteReply={() => { }}
                onGoToProfile={() => { }}
                onStartEdit={() => { onStartEdit(selectedReflection) }}
                onDelete={() => { onDeleteReflection(selectedReflection) }}
                onGoToReply={() => { }}
                onClose={onCloseContextMenu}
            />, document.body)}
            {showScroller && <div className={currentUser.isAdmin > 0 ? "bottom-scroller" : "bottom-scroller no-input"} onClick={scrollToBottom}>
                {streamsUnreadCount > 0 &&
                    <div className="bottom-scroller-counter">{streamsUnreadCount}</div>}
                <i className="fa fa-chevron-down" />
            </div>}
        </div>
        );
};

export default StreamModule;
