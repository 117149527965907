import * as React from 'react';
import useFetch from '../../../../hooks/useFetch';
import { Account } from '../../../../store/Models';
import { getDaysTextRussian } from '../../../../utils';

interface PaymentReminderProps {
    currentUser: Account;
    onRenewal: () => void;
    onClose: () => void;
}

const PaymentReminder: React.FC<PaymentReminderProps> = ({ currentUser, onRenewal, onClose }) => {
    const fetch = useFetch();

    const [paymentSessionStatus, setPaymentSessionStatus] = React.useState<number|null>(null);

    React.useEffect(() => {
        fetch('/api/payment/getPaymentSessionStatus?accountId=' + currentUser.id)
            .then(res => res.json())
            .then(data => {
                setPaymentSessionStatus(parseInt(data.status));
            });
    }, []);

    if (currentUser.daysLeft === null || paymentSessionStatus === null)
        return null;
    else {
        var expirationText = currentUser.daysLeft > 0 ? `через ${currentUser.daysLeft} ${getDaysTextRussian(currentUser.daysLeft)}` : "сегодня";
        return (
            <div className="payment-reminder" style={{
                backgroundColor: paymentSessionStatus <= 0 ? 'rgba(255, 0, 0, 0.8)' : paymentSessionStatus > 0 && paymentSessionStatus <= 2 ? 'rgb(235, 125, 40, 0.8)' :
                    paymentSessionStatus > 2 && paymentSessionStatus < 7 ? 'rgba(204, 153, 0, 0.8)' : ''
                }}>
                <div className="payment-reminder-close-btn" onClick={onClose}><i className="fas fa-xmark" /></div>
                {paymentSessionStatus <= 0 && <div>
                    <p style={{ margin: 0 }}><i className="fas fa-triangle-exclamation" /> Внимание! Ваша подписка</p>
                    <p style={{ margin: 0 }}>заканчивается {expirationText}! <u onClick={onRenewal}>Продлить</u></p>
                </div>}
                {(paymentSessionStatus > 0 && paymentSessionStatus <= 2) && <div>
                    <p style={{ margin: 0 }}><i className="fas fa-triangle-exclamation" /> Внимание! Пожалуйста, завершите</p>
                    <p style={{ margin: 0 }}>оплату подписки. <u onClick={onRenewal}>Перейти</u></p>
                </div>}
                {(paymentSessionStatus > 2 && paymentSessionStatus < 7) && <div>
                    <p style={{ margin: 0 }}><i className="fas fa-triangle-exclamation" /> Внимание! Пожалуйста, отправьте</p>
                    <p style={{ margin: 0 }}>квитанцию в Службу Заботы. <u onClick={onRenewal}>Перейти</u></p>
                </div>}
            </div>
        )
    }
};

export default PaymentReminder;
