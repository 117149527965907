import * as React from 'react';
import { Account } from '../../store/Models';
import { getReactionByCode, trimName } from '../../utils';
import AccountAvatar from './AccountAvatar';
import Reaction from './components/simple/Reaction';

interface ReactionMenuProps {
    reactionCode: number;
    accounts: Account[];
    onClose: () => void;
}

const ReactionMenu: React.FC<ReactionMenuProps> = ({ reactionCode, accounts, onClose }) => {
    return (
        <div className="reaction-menu-cover" onClick={onClose}>
            <div className="reaction-menu-content">
                <div className="reaction-big"><Reaction code={reactionCode} /></div>
                <div className="reaction-counter"><Reaction code={reactionCode} />{accounts.length}</div>
                <div className="reaction-menu">
                    {accounts.map((a, index) => (
                        <>
                            {index > 0 && <div className="longpress-item-separator"></div>}
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex' }}>
                                    <AccountAvatar account={a} size={20} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                    <div style={{ flex: '1', paddingLeft: '5px', textOverflow: 'ellipsis' }}>{trimName(a.name)}</div>
                                    <div><Reaction code={reactionCode} /></div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default ReactionMenu;
