import React from 'react';

// Define the shape of the option object
interface OptionType {
    value: string;
    label: string;
    search?: string;
}

interface AutocompleteProps {
    options: OptionType[];
    placeholder: string;
    customValuesAllowed: boolean;
    onValueSelected: (value: string) => void;
    mode?: string;
    defaultValue?: string;
    clearBtn?: boolean;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ options, placeholder, customValuesAllowed, onValueSelected, mode = 'reg', defaultValue, clearBtn }) => {

    const inputRef = React.useRef<HTMLInputElement>(null);

    const [currentText, setCurrentText] = React.useState<string>('');
    const [showOptions, setShowOptions] = React.useState<boolean>(false);
    const [valueSelected, setValueSelected] = React.useState<boolean>(false);
    const [ignoreOptionsFiltering, setIgnoreOptionsFiltering] = React.useState<boolean>(false);

    const onTextChange = (newText: string) => {
        setIgnoreOptionsFiltering(false);
        setCurrentText(newText);
        setValueSelected(false);
    }

    const selectOption = (event: React.MouseEvent<HTMLDivElement>) => {
        var optionValue = event.currentTarget.getAttribute("data-value");
        if (optionValue != null) {
            setCurrentText(event.currentTarget.innerText);
            setValueSelected(true);
            onValueSelected(optionValue);
        }
    }

    const selectCustomOption = () => {
        setValueSelected(true);
        onValueSelected(currentText);
    }

    React.useEffect(() => {
        if (defaultValue != null) {
            var selectedOptionCandidate = options.filter(o => o.value === defaultValue);
            if (selectedOptionCandidate.length > 0) {
                setCurrentText(selectedOptionCandidate[0].label);
                setValueSelected(true);
                onValueSelected(defaultValue);
            }
        }
    }, []);

    return (
        <div className={`${mode}-module-autocomplete`}>
            <div className={`${mode}-module-autocomplete-input`}>
                <input ref={inputRef} className={`${mode}-module-input`} placeholder={placeholder} onChange={(e) => onTextChange(e.target.value)} value={currentText}
                    onFocus={() => {
                        setIgnoreOptionsFiltering(true);
                        setShowOptions(true);
                    }} onBlur={() => setShowOptions(false)} />
                {clearBtn && <i className="fas fa-xmark" onClick={() => {
                    setCurrentText('');
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }} />}
                <i className="fas fa-chevron-down" onClick={() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }} />
            </div>
            <div className={`${mode}-module-autocomplete-options`} style={{ display: showOptions ? '' : 'none' }}>
                {options.filter(o => {
                    if (ignoreOptionsFiltering) {
                        return true;
                    }
                    if (o.search != null) {
                        return o.search.toLowerCase().includes(currentText.toLowerCase());
                    }
                    else {
                        return o.label.toLowerCase().includes(currentText.toLowerCase());
                    }
                }).map(item => (
                    <div className={`${mode}-module-autocomplete-option`} onMouseDown={selectOption} data-value={item.value}>{item.label}</div>
                ))}
                {(customValuesAllowed && options.filter(o => o.label.toLowerCase().includes(currentText.toLowerCase())).length == 0)
                    && <div className={`${mode}-module-autocomplete-option`} onMouseDown={selectCustomOption}>{currentText} (свой вариант)</div>}
            </div>
        </div>
    );
};

export default Autocomplete;