import * as React from 'react';
import { Account } from '../../../../store/Models';
import Autocomplete from './Autocomplete';

interface AccountFilterProps {
    accounts: Account[];
    placeholder: string;
    label: string;
    onSelect: (id: number) => void;
    selectAllOptionText?: string;
    onSelectAllOption?: () => void;
    defaultValue?: string;
}

const AccountFilter: React.FC<AccountFilterProps> = ({ accounts, placeholder, label, onSelect, selectAllOptionText, onSelectAllOption, defaultValue }) => {
    return (
        <div style={{ display: 'flex' }}>
            {label.length > 0 && <div>{label}</div>}
            {!selectAllOptionText && <Autocomplete options={accounts.map(x => { return { label: x.id + " " + x.humanName, value: x.id.toString(), search: x.id + " " + x.humanName + " " + x.name } })}
                placeholder={placeholder}
                customValuesAllowed={false}
                onValueSelected={(id) => { onSelect(parseInt(id)); }} mode="filter" />}
            {selectAllOptionText && <Autocomplete options={[{ label: selectAllOptionText, value: '' }].concat(accounts.map(x => { return { label: x.id + " " + x.humanName, value: x.id.toString(), search: x.id + " " + x.humanName + " " + x.name } }))}
                placeholder={placeholder}
                customValuesAllowed={false}
                onValueSelected={(id) => {
                    if (id.length > 0) {
                        onSelect(parseInt(id));
                    }
                    else if (onSelectAllOption) {
                        onSelectAllOption();
                    }
                }} mode="filter" defaultValue={defaultValue} clearBtn={true} />}
        </div>
    )
};

export default AccountFilter;
