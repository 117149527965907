﻿//import { useLoading } from '../context/LoadingContext';

import { mockTelegramObject } from "../utils";

const useFetch = () => {
    //const { setLoading } = useLoading();

    const request = async (input: RequestInfo, init?: RequestInit) => {
        //setLoading(true);
        try {
            const telegram = window.Telegram.WebApp != null && window.Telegram.WebApp.initDataUnsafe != null && window.Telegram.WebApp.initDataUnsafe.user != null ?
                window.Telegram.WebApp : mockTelegramObject;
            const headers = new Headers(init != null ? init.headers : {});
            if (telegram != null
                && telegram.initDataUnsafe != null
                && telegram.initDataUnsafe.user != null
                && telegram.initDataUnsafe.user.id != null) {
                var telegramId = telegram.initDataUnsafe.user.id;
                headers.append('X-Telegram-ID', telegramId);
            }
            const response = await fetch(input, {
                ...init,
                headers
            });
            if (!response.ok) {
                console.error('Network response was not ok');
            }
            return response;
        } catch (error) {
            console.error('Fetch error:', error);
            throw error;
        } finally {
            //setLoading(false);
        }
    };

    return request;
};

export default useFetch;