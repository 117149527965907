import * as React from 'react';
import { Account, BinarAccount } from '../../store/Models';
import { getClassSingle, getMembersCountTextRussian, makeAgeString, makeDate, makeDateTimeRussian, makeYearsAndMonths, trimName } from '../../utils';
import CuratorSVG from './svg/CuratorSVG';
import FirstLineSVG from './svg/FirstLineSVG';
import CommandSVG from './svg/CommandSVG';
import useFetch from '../../hooks/useFetch';
import BinarView from './components/complex/binar/BinarView';
import BinarPtsTransactionsView from './components/complex/binar/BinarPtsTransactionsView';
import BinarSVG from './svg/BinarSVG';
import ClassicSVG from './svg/ClassicSVG';

interface AccountViewProps {
    productionMode: boolean;
    currentUser: Account;
    account: Account;
    onClose: () => void;
}

const AccountView: React.FC<AccountViewProps> = ({ productionMode, currentUser, account, onClose }) => {
    const fetch = useFetch();

    const [firstLineCount, setFirstLineCount] = React.useState<number | null>(null);
    const [totalCount, setTotalCount] = React.useState<number | null>(null);

    const [curator, setCurator] = React.useState<Account | null>(null);
    const [balance, setBalance] = React.useState<number>(0);

    const [viewMode, setViewMode] = React.useState<number>(1);

    const [showBinar, setShowBinar] = React.useState<boolean>(false);
    const [currentAccountForBinarViewId, setCurrentAccountForBinarViewId] = React.useState<number>(account.id);
    const [selectedBinarForPts, setSelectedBinarForPts] = React.useState<BinarAccount | null>(null);

    const [subscriptionTo, setSubscriptionTo] = React.useState<string>('');

    const [nextAccount, setNextAccount] = React.useState<Account | null>(null);

    React.useEffect(() => {
        fetch('api/account/getCommandInfo?id=' + account.id)
            .then(r => r.json())
            .then(data => {
                setFirstLineCount(data.firstLineCount);
                setTotalCount(data.totalCount);
            });

        fetch('api/challenge/getCurator?accountId=' + account.id)
            .then(r => r.json())
            .then(data => {
                if (data.account != null) {
                    setCurator(data.account);
                }
            });

        fetch('api/account/getAccountBalance?accountId=' + account.id)
            .then(r => r.json())
            .then(data => {
                if (data.balance != null) {
                    setBalance(data.balance);
                }
            });

        fetch('api/account/getSubscriptionInfo?accountId=' + account.id)
            .then(r => r.json())
            .then(data => {
                if (data.subscriptionTo != null) {
                    setSubscriptionTo(data.subscriptionTo);
                }
            })
    }, []);

    const checkShowBinar = () => {
        fetch('/api/binarReward/checkAccountAvailabiltyForMe?accountId=' + account.id)
            .then(res => res.json())
            .then(data => {
                if (parseInt(data.isAvailable) > 0) {
                    setCurrentAccountForBinarViewId(account.id);
                    setShowBinar(true);
                }
                else {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({
                            title: "Упс... Доступ ограничен)",
                            message: "К сожалению, " + account.humanName + " не является членом Вашей команды по бинару.",
                            buttons: [{ text: "Понятно" }]
                        });
                    }
                    else {
                        window.alert("К сожалению, " + account.humanName + " не является членом Вашей команды по бинару.");
                    }
                }
            });
    }

    return (
        <>
            {!nextAccount && <div className="account-view">
                {!showBinar && <>
                    <div className="account-profile-back-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
                    <div className="account-profile-container">
                        <div className="account-view-container-top">
                            <div className="account-view-photo" style={{
                                background:
                                    `url('${'https://pakhomov1907-001-site1.ctempurl.com/api/account/getAvatar?userId=' + account.id}') no-repeat center`,
                                backgroundSize: 'cover'
                            }}>
                                <img width={100} height={100} src={"https://pakhomov1907-001-site1.ctempurl.com/api/account/getLevelImage?level=" + account.level} />
                            </div>
                            <div className="account-profile-panel">
                                <table style={{ width: '100%', height: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td className="account-profile-panel-name">{trimName(account.name)}</td>
                                        </tr>
                                        <tr>
                                            <td className="account-profile-panel-class">{getClassSingle(account.class)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="account-profile-container-bottom">
                            <div className="account-profile-container-toggle">
                                <div className={viewMode == 1 ? "active" : ""} onClick={() => setViewMode(1)}>Личная информация</div>
                                <div className={viewMode == 2 ? "active" : ""} onClick={() => setViewMode(2)}>Работа с командой</div>
                            </div>
                            {viewMode == 1 && <div className="account-profile-rows">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td><i className="fas fa-cake-candles" /></td>
                                            <td>Дата рождения</td>
                                            <td>{makeAgeString(account.birthday)}</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fas fa-city" /></td>
                                            <td>Город</td>
                                            <td>{account.city || 'Не указан'}</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fas fa-briefcase" /></td>
                                            <td>Род деятельности</td>
                                            <td>{account.businessKind || 'Не указан'}</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fas fa-briefcase" /></td>
                                            <td>Сфера деятельности</td>
                                            <td>{account.businessSphere || 'Не указана'}</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fa-brands fa-instagram" /></td>
                                            <td>Instagram</td>
                                            <td>{account.instaProfile ? <a target="_blank" href={'https://instagram.com/' + account.instaProfile}>{account.instaProfile}</a> : 'Не указан'}</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fas fa-file-invoice-dollar" /></td>
                                            <td>Подписка до</td>
                                            <td>{subscriptionTo.length > 0 ? makeDate(subscriptionTo) : '?'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>}
                            {viewMode == 2 && <div className="account-profile-rows">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td><CuratorSVG /></td>
                                            <td>Куратор</td>
                                            <td className={curator != null ? "account-profile-clickable" : ""} onClick={() => {
                                                if (curator != null) {
                                                    setNextAccount(curator);
                                                }
                                            }}>{curator ? trimName(curator.name) : 'Не выбран'}</td>
                                        </tr>
                                        <tr>
                                            <td><FirstLineSVG /></td>
                                            <td>1-я линия</td>
                                            <td>{firstLineCount != null ? <b>{firstLineCount}</b> : '?'} {getMembersCountTextRussian(firstLineCount != null ? firstLineCount.toString() : '')}</td>
                                        </tr>
                                        <tr>
                                            <td><ClassicSVG /></td>
                                            <td>Классика</td>
                                            <td>{totalCount != null ? <b>{totalCount}</b> : '?'} {getMembersCountTextRussian(totalCount != null ? totalCount.toString() : '')}</td>
                                        </tr>
                                        <tr>
                                            <td><BinarSVG /></td>
                                            <td>Команда</td>
                                            <td className="account-profile-clickable" onClick={checkShowBinar}>Перейти к схеме</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    </div>
                </>}
                {(showBinar && !selectedBinarForPts) && <BinarView homeUserRef={currentUser} homeUserId={currentUser.id} homeUserName={currentUser.humanName} rootUserId={currentAccountForBinarViewId} onBack={() => setShowBinar(false)}
                    onSelectBinarAccount={setCurrentAccountForBinarViewId}
                    onShowBinarPts={setSelectedBinarForPts}
                    productionMode={productionMode} />}
                {selectedBinarForPts != null && <BinarPtsTransactionsView homeUserRef={currentUser} productionMode={productionMode} homeUserId={currentUser.id} homeUserName={currentUser.humanName}
                    rootBinarAccount={selectedBinarForPts} onBack={() => setSelectedBinarForPts(null)} onReloadView={setSelectedBinarForPts} />}
            </div>}
            {nextAccount != null && <AccountView productionMode={productionMode} currentUser={currentUser} account={nextAccount} onClose={() => setNextAccount(null)} />}
        </>
    );
};

export default AccountView;
