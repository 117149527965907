import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, BinarAccount, CommandRequest, ClassicShortLine } from '../../store/Models';
import { getClassSingle, getResidentsText, trimName } from '../../utils';
import AccountAvatar from './AccountAvatar';
import BinarPtsTransactionsView from './components/complex/binar/BinarPtsTransactionsView';
import BinarView from './components/complex/binar/BinarView';
import CommandRequestsView from './components/simple/CommandRequestsView';
import FirstLineSVG from './svg/FirstLineSVG';

interface ClassicViewProps {
    currentUser: Account;
    productionMode: boolean;
    onAccountClick: (account: Account) => void;
    onReloadProfile: () => void;
    onClose: () => void;
    targetUser?: Account;
}

const ClassicShortView: React.FC<ClassicViewProps> = ({ currentUser, productionMode, onAccountClick, onReloadProfile, onClose, targetUser }) => {
    const fetch = useFetch();

    const [line, setLine] = React.useState<ClassicShortLine[]>([]);
    const [commandRequests, setCommandRequests] = React.useState<CommandRequest[]>([]);

    const [showBinar, setShowBinar] = React.useState<boolean>(false);
    const [currentAccountForBinarViewId, setCurrentAccountForBinarViewId] = React.useState<number>(0);

    const [selectedBinarForPts, setSelectedBinarForPts] = React.useState<BinarAccount | null>(null);

    const [showCommandRequests, setShowCommandRequests] = React.useState<boolean>(false);

    const [reloadDummy, setReloadDummy] = React.useState<number>(0);

    React.useEffect(() => {
        if (targetUser != null) {
            fetch('/api/challenge/getFirstLineByAccount?id=' + targetUser.id)
                .then(r => r.json() as Promise<ClassicShortLine[]>)
                .then(data => {
                    setLine(data);
                });
        }
        else {
            fetch('/api/binarReward/myCommandRequests')
                .then(r => r.json() as Promise<CommandRequest[]>)
                .then(data => {
                    setCommandRequests(data);
                });

            fetch('/api/challenge/getFirstLine')
                .then(r => r.json() as Promise<ClassicShortLine[]>)
                .then(data => {
                    setLine(data);
                });
        }
    }, [reloadDummy]);

    return (
        <div className="classic-view">
            {(!showBinar && !showCommandRequests) && <>
                <div className="classic-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
                <div className="classic-view-header"><b>КЛАССИЧЕСКАЯ СХЕМА</b><br /><em>(количество Резидентов 1-й линии: <b>{line.length}</b> чел.)</em></div>
                {commandRequests.length > 0 && <button className="btn btn-warning" style={{ margin: "0 20%" }} onClick={() => setShowCommandRequests(true)}>Входящие запросы ({commandRequests.length})</button>}
                <div className="classic-view-table-container" style={{ maxHeight: commandRequests.length > 0 ? "calc(100% - 101.6px)" : "calc(100% - 64px)" }}>
                    <table className="table table-striped" style={{ fontSize: '13px' }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Резидент</th>
                                <th>Тариф</th>
                                <th>Команда</th>
                                <th style={{ width: '25%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {line.length == 0 &&
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td style={{ width: '25%' }}>-</td>
                                </tr>}
                            {line.map((item, i) =>
                                <tr>
                                    <td style={{ verticalAlign: 'middle' }}>{i + 1}</td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{trimName(item.account.name)}</span></div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>{getClassSingle(item.account.class)}</td>
                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.binarSide === 'Left' ? "Левая" : item.binarSide === 'Right' ? "Правая" : ""}</td>
                                    <td style={{ width: '25%' }}>
                                        <button className="btn btn-outline-primary" style={{ fontSize: '10px', float: 'right' }} onClick={() => {
                                            setCurrentAccountForBinarViewId(item.account.id);
                                            setShowBinar(true);
                                        }}>В бинаре</button>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
            </>}
            {(showBinar && !selectedBinarForPts && currentAccountForBinarViewId > 0) && <BinarView homeUserRef={currentUser} homeUserId={currentUser.id} homeUserName={currentUser.humanName} rootUserId={currentAccountForBinarViewId} onBack={() => setShowBinar(false)}
                onSelectBinarAccount={setCurrentAccountForBinarViewId} onShowBinarPts={setSelectedBinarForPts} productionMode={productionMode} />}
            {selectedBinarForPts != null && <BinarPtsTransactionsView homeUserRef={currentUser} productionMode={productionMode} homeUserId={currentUser.id} homeUserName={currentUser.humanName}
                rootBinarAccount={selectedBinarForPts} onBack={() => setSelectedBinarForPts(null)} onReloadView={setSelectedBinarForPts} />}
            {showCommandRequests && <CommandRequestsView productionMode={productionMode} currentUser={currentUser} items={commandRequests} onAccountClick={onAccountClick}
                onRequest={() => { setReloadDummy(x => x + 1); onReloadProfile(); }}
                onClose={() => setShowCommandRequests(false)} />}
        </div>
    )
};

export default ClassicShortView;
