import * as React from 'react';
import useFetch from '../../../../hooks/useFetch';
import { Account, CommandRequest } from '../../../../store/Models';
import { getClassSingleAlias, makeDateTimeShort, trimName } from '../../../../utils';
import AccountAvatar from '../../AccountAvatar';

interface CommandRequestsViewProps {
    productionMode: boolean;
    currentUser: Account;
    items: CommandRequest[];
    onAccountClick: (item: Account) => void;
    onRequest: () => void;
    onClose: () => void;
}

const CommandRequestsView: React.FC<CommandRequestsViewProps> = ({ productionMode, currentUser, items, onAccountClick, onRequest, onClose }) => {
    const fetch = useFetch();

    React.useEffect(() => {
        if (items.length == 0) {
            onClose();
        }
    }, [items]);

    const confirmApprove = (item: CommandRequest) => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: 'Подтвердите выбор',
                message: 'Вы действительно хотите принять запрос ' + item.initiatorAccount.humanName + '?\nСтоимость: 1 ERA Coin\nТекущий баланс: ' + currentUser.eraCoins + ' ERA Coin' + (currentUser.eraCoins > 1 ? 's' : ''),
                buttons: [
                    { id: 'confirm', type: 'default', text: 'Да, подтверждаю' },
                    { id: '', type: 'default', text: 'Нет' }
                ]
            }, (id: string) => {
                if (id === 'confirm') {
                    approve(item.id);
                }
            });
        }
        else {
            if (window.confirm('Вы действительно хотите принять запрос ' + item.initiatorAccount.humanName + '?\nСтоимость: 1 ERA Coin\nТекущий баланс: ' + currentUser.eraCoins + ' ERA Coin' + (currentUser.eraCoins > 1 ? 's' : ''))) {
                approve(item.id);
            }
        }
    }

    const confirmDecline = (item: CommandRequest) => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: 'Подтвердите выбор',
                message: 'Вы действительно хотите отклонить запрос ' + item.initiatorAccount.humanName + '?',
                buttons: [
                    { id: 'confirm', type: 'default', text: 'Да, подтверждаю' },
                    { id: '', type: 'default', text: 'Нет' }
                ]
            }, (id: string) => {
                if (id === 'confirm') {
                    decline(item.id);
                }
            });
        }
        else {
            if (window.confirm('Вы действительно хотите отклонить запрос ' + item.initiatorAccount.humanName + '?')) {
                decline(item.id);
            }
        }
    }

    const approve = (id: number) => {
        fetch('/api/binarReward/requestCommandApprove?id=' + id)
            .then(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({
                        message: 'Запрос успешно принят', buttons: [{ id: '', type: 'default', text: 'Понятно' }]
                    });
                }
                else {
                    window.alert('Запрос успешно принят');
                }
                onRequest();
            });
    }

    const decline = (id: number) => {
        fetch('/api/binarReward/requestCommandDecline?id=' + id)
            .then(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({
                        message: 'Запрос успешно отклонён', buttons: [{ id: '', type: 'default', text: 'Понятно' }]
                    });
                }
                else {
                    window.alert('Запрос успешно отклонён');
                }
                onRequest();
            });
    }

    return (
        <div className="classic-view">
            <div className="classic-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
            <div className="classic-view-header"><b>ОДОБРЕНИЕ ВХОДЯЩИХ ЗАПРОСОВ</b><br /></div>
            <div className="classic-view-table-container">
                <table className="table table-striped" style={{ fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}><i className="fas fa-calendar-days" /></th>
                            <th>Резидент</th>
                            <th style={{ textAlign: 'center' }}>Тариф</th>
                            <th style={{ width: '25%', textAlign: 'center' }}>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, i) =>
                            <tr>
                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{makeDateTimeShort(item.requestDateTime)}</td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.initiatorAccount} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.initiatorAccount) }}>{trimName(item.initiatorAccount.name)}</span></div>
                                </td>
                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{getClassSingleAlias(item.initiatorAccount.class)}</td>
                                <td style={{ width: '25%', textAlign: 'center' }}>
                                    <button className="btn btn-outline-success" style={{ fontSize: '18px', padding: '4px 8px' }} onClick={() => {
                                        confirmApprove(item)
                                    }}><i className="fas fa-check" /></button>
                                    <button className="btn btn-outline-danger" style={{ fontSize: '18px', padding: '4px 8px', marginLeft: '8px' }} onClick={() => {
                                        confirmDecline(item)
                                    }}><i className="fas fa-xmark" /></button>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default CommandRequestsView;
