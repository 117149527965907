import * as React from 'react';

const ClassicSVG: React.FC = () => {
    return (
        <svg width="35" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g>
                <g>
                    <circle cx="9.15" cy="7.43" r="7.43" />
                    <path d="M9.15 12.95c5.05,0 9.15,6.95 9.15,10.46 0,3.51 -1.97,3.45 -3.11,3.44l-12.09 0c-1.14,0 -3.11,0.06 -3.11,-3.44 0,-3.51 4.1,-10.46 9.15,-10.46z" />
                </g>
                <g>
                    <circle cx="30.99" cy="7.43" r="7.43" />
                    <path d="M30.99 12.95c5.05,0 9.15,6.95 9.15,10.46 0,3.51 -1.97,3.45 -3.11,3.44l-12.09 0c-1.14,0 -3.11,0.06 -3.11,-3.44 0,-3.51 4.1,-10.46 9.15,-10.46z" />
                </g>
                <g>
                    <circle cx="52.83" cy="7.43" r="7.43" />
                    <path d="M52.83 12.95c5.05,0 9.15,6.95 9.15,10.46 0,3.51 -1.97,3.45 -3.11,3.44l-12.09 0c-1.14,0 -3.11,0.06 -3.11,-3.44 0,-3.51 4.1,-10.46 9.15,-10.46z" />
                </g>
            </g>
            <g>
                <path d="M17.99 15.26c-0.68,0 -1.24,-0.56 -1.24,-1.24 0,-0.68 0.56,-1.24 1.24,-1.24l0 2.48zm4.1 0l-4.1 0 0 -2.48 4.1 0 0 2.48zm0 -2.48c0.68,0 1.24,0.56 1.24,1.24 0,0.68 -0.56,1.24 -1.24,1.24l0 -2.48z" />
            </g>
            <g>
                <path d="M40.09 15.26c-0.68,0 -1.24,-0.56 -1.24,-1.24 0,-0.68 0.56,-1.24 1.24,-1.24l0 2.48zm4.1 0l-4.1 0 0 -2.48 4.1 0 0 2.48zm0 -2.48c0.68,0 1.24,0.56 1.24,1.24 0,0.68 -0.56,1.24 -1.24,1.24l0 -2.48z" />
            </g>
        </svg >
    );
};

export default ClassicSVG;
