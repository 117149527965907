import * as React from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import { Account } from '../../../../../../store/Models';
import Autocomplete from '../../../simple/Autocomplete';

interface DanceAddRecordFormProps {
    onBack: () => void;
    selectedDay: string;
    productionMode: boolean;
}

const DanceAddRecordForm: React.FC<DanceAddRecordFormProps> = ({ onBack, selectedDay, productionMode }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [value, setValue] = React.useState<number>(0);

    const clearForm = () => {
        setValue(0);
        setSelectedAccount(null);
    }

    const validate = (): boolean => {
        return selectedAccount != null && value != 0;
    }

    const doAdd = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('Value', value.toString());
            formData.append('SelectedDay', selectedDay);
            fetch('api/admin/addDanceRecord', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Запись была успешно добавлена.' });
                    }
                    else {
                        alert('Запись была успешно добавлена.');
                    }
                    onBack();
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    return (
        <div className="payment-approval-container">
            <div className="reg-module-view-header"><b>ДОБАВИТЬ ЗАПИСЬ ПО ТАНЦАМ</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div><b>Запись за {selectedDay} ({['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'][new Date(selectedDay).getDay()]})</b></div>
                    <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите Резидента" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearForm}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Балл</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setValue(parseInt(e.target.value))}>
                            <option value="0" selected>0 баллов</option>
                            <option value="1">1 балл</option>
                            <option value="2">2 балла</option>
                        </select>
                    </div>
                </div>}
                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={doAdd}>Добавить</button>
                </div>
            </div>
        </div>
    )
};

export default DanceAddRecordForm;
