import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, PaymentSession } from '../../../../../store/Models';
import { CostInfo } from '../../../../../subscriptions';
import StepperRenewal from './StepperRenewal';

interface RenewalProps {
    productionMode: boolean;
    currentUser: Account;
    onBack: () => void;
    onFinishPayment: () => void;
}

const Renewal: React.FC<RenewalProps> = ({ productionMode, currentUser, onBack, onFinishPayment }) => {
    const fetch = useFetch();

    const [paymentSession, setPaymentSession] = React.useState<PaymentSession | null>(null);
    const [showLockScreen, setShowLockScreen] = React.useState<boolean>(false);

    const handlePaymentError = () => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
        }
        else {
            alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
        }
    }

    const sendPaymentInfo = async (subscription: CostInfo, paymentMethod: string) => {
        //send information to server
        var formData = new FormData();
        formData.append('SubscriptionCode', subscription.code);
        formData.append('PaymentMethod', paymentMethod);
        formData.append('Cost',
            paymentMethod === 'Sberbank' ? subscription.rubCost.toString()
                : paymentMethod === 'Balance' ? subscription.usdCost.toString()
                    : subscription.cost.toString());
        setShowLockScreen(true);
        try {
            const response = await fetch('api/account/sendPaymentInfo', {
                method: 'POST',
                body: formData
            });
            setShowLockScreen(false);
            if (response.ok) {
                onFinishPayment();
            }
            else {
                handlePaymentError();
            }
        }
        catch (error) {
            console.error(error);
            setShowLockScreen(false);
            handlePaymentError();
        }
    }

    // check payment session for user. If session not exists, it must be created.
    React.useEffect(() => {
        fetch('api/payment/getPaymentSession?accountId=' + currentUser.id)
            .then(res => res.json() as Promise<PaymentSession>)
            .then(data => setPaymentSession(data));
    }, []);

    return (
        <div className="reg-module-container">
            <div className="reg-module-view-header"><b>ПРОДЛЕНИЕ</b></div>
            {paymentSession != null && <StepperRenewal productionMode={productionMode} currentUser={currentUser} paymentSession={paymentSession} onBack={onBack} onFinishPayment={sendPaymentInfo} />}
            {showLockScreen && <div className="payment-lock-screen-cover">
                <div className="payment-lock-screen-content">
                    Операция в процессе проведения.<br />
                    Пожалуйста, ожидайте.
                </div>
            </div>}
        </div>
    )
};

export default Renewal;
