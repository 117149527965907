import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, PaymentSession } from '../../../../../store/Models';
import { CostInfo, subscriptionsInfo } from '../../../../../subscriptions';
import StepperCircles from '../stepper/StepperCircles';
import './stepper.css';
import StepperRenewal1 from './StepperRenewal1';
import StepperRenewal2 from './StepperRenewal2';
import StepperRenewal3 from './StepperRenewal3';

interface StepperProps {
    productionMode: boolean;
    currentUser: Account;
    paymentSession: PaymentSession;
    onBack: () => void;
    onFinishPayment: (subscription: CostInfo, paymentMethod: string) => void;
}

const StepperRenewal: React.FC<StepperProps> = ({ productionMode, currentUser, paymentSession, onBack, onFinishPayment }) => {
    const fetch = useFetch();

    const [activeStep, setActiveStep] = React.useState(paymentSession.status == 0 ? 1 : paymentSession.status < 3 ? 2 : 3);
    const [selectedSubscription, setSelectedSubscription] = React.useState<CostInfo | null>(paymentSession.subscriptionCode != null ? subscriptionsInfo[paymentSession.subscriptionCode] : null);
    const [selectedPayment, setSelectedPayment] = React.useState<any>(paymentSession.paymentMethod != null ? { Key: paymentSession.paymentMethod } : null);

    const [paymentSessionStepper, setPaymentSessionStepper] = React.useState<PaymentSession>(paymentSession);

    React.useEffect(() => {
        fetch('api/payment/getPaymentSession?accountId=' + currentUser.id)
            .then(res => res.json() as Promise<PaymentSession>)
            .then(data => {
                setPaymentSessionStepper(data);
                setSelectedSubscription(data.subscriptionCode != null ? subscriptionsInfo[data.subscriptionCode] : null);
                setSelectedPayment(data.paymentMethod != null ? { Key: data.paymentMethod } : null);
            });
    }, [activeStep]);

    const handleNext = () => setActiveStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep));
    const handleBack = () => setActiveStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));

    return (
        <div className="stepper-container">
            <StepperCircles activeStep={activeStep} />
            {activeStep == 1 && <StepperRenewal1 currentUser={currentUser} paymentSession={paymentSessionStepper} onBack={onBack} onNext={(s) => { setSelectedSubscription(s); handleNext(); }} />}
            {(activeStep == 2 && selectedSubscription != null) && <StepperRenewal2 productionMode={productionMode} paymentSession={paymentSessionStepper} selectedSubscription={selectedSubscription} onBack={handleBack} onNext={(p) => {
                setSelectedPayment(p);
                if (p && p.Key === 'Balance') {
                    onFinishPayment(selectedSubscription, 'Balance');
                }
                else {
                    handleNext();
                }
            }} />}
            {(activeStep == 3 && selectedSubscription != null) && <StepperRenewal3 paymentSession={paymentSessionStepper} paymentMethod={selectedPayment.Key} subscriptionCode={selectedSubscription.code} onBack={handleBack} onNext={() => onFinishPayment(selectedSubscription, selectedPayment.Key)} />}
        </div>
    );
};

export default StepperRenewal;
