import { SSL_OP_MSIE_SSLV2_RSA_PADDING } from 'constants';
import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account } from '../../../../../store/Models';
import { makeDateTime } from '../../../../../utils';
import CoinChargeForm from './forms/CoinChargeForm';
import CuratorAssignForm from './forms/CuratorAssignForm';

interface AdminCuratorBindingsProps {
    onBack: () => void;
    productionMode: boolean;
}

interface CuratorInfo {
    account: Account;
    curator: Account;
    curatorAssignDateTime: string;
}

interface CoinTransaction {
    id: number;
    transactionDateTime: string;
    account: Account;
    description: string;
    value: number;
}

const AdminCuratorBindings: React.FC<AdminCuratorBindingsProps> = ({ onBack, productionMode }) => {
    const fetch = useFetch();

    const [curatorsInfo, setCuratorsInfo] = React.useState<CuratorInfo[]>([]);
    const [coinTransactions, setCoinTransactions] = React.useState<CoinTransaction[]>([]);

    const [selectedScreen, setSelectedScreen] = React.useState<number>(0);

    const [adminCuratorBingingMode, setAdminCuratorBindingMode] = React.useState<number>(0);

    React.useEffect(() => {
        if (selectedScreen == 0) {
            if (adminCuratorBingingMode == 0) {
                fetch('/api/admin/getCuratorsInfo')
                    .then(res => res.json() as Promise<CuratorInfo[]>)
                    .then((data) => {
                        setCuratorsInfo(data);
                    });
            }
            else if (adminCuratorBingingMode == 1) {
                fetch('/api/admin/getCoinTransactions')
                    .then(res => res.json() as Promise<CoinTransaction[]>)
                    .then((data) => {
                        setCoinTransactions(data);
                    });
            }
        }
    }, [selectedScreen, adminCuratorBingingMode]);

    return (
        <>
            {selectedScreen == 0 && <div className="payment-approval-container">
                <div className="reg-module-view-header"><b>НАЗНАЧЕНИЕ КУРАТОРОВ, ERA COIN</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                <div className="admin-panel-tab">
                    <div className={adminCuratorBingingMode == 0 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setAdminCuratorBindingMode(0)}>Назначение Кураторов</div>
                    <div className={adminCuratorBingingMode == 1 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setAdminCuratorBindingMode(1)}>ERA Coin</div>
                </div>
                {adminCuratorBingingMode == 0 && <button style={{ marginTop: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={() => setSelectedScreen(1)}>Назначить куратора</button>}
                {adminCuratorBingingMode == 1 && <button style={{ marginTop: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={() => setSelectedScreen(2)}>Начислить/списать ERA Coin</button>}
                {adminCuratorBingingMode == 0 && <div style={{ height: "calc(100% - 105px)", overflowY: 'auto' }}>
                    <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                        <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                            <tr>
                                <th style={{ textAlign: 'center', fontSize: '14px', padding: '0' }} colSpan={3}>Данные по Кураторам</th>
                            </tr>
                            <tr>
                                <th>Дата-время</th>
                                <th>Резидент</th>
                                <th>Куратор</th>
                            </tr>
                        </thead>
                        <tbody>
                            {curatorsInfo.map((curatorInfo, i) => (
                                <tr>
                                    <td>{makeDateTime(curatorInfo.curatorAssignDateTime)}</td>
                                    <td>{curatorInfo.account.humanName}</td>
                                    <td>{curatorInfo.curator.humanName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                {adminCuratorBingingMode == 1 && <div style={{ height: "calc(100% - 105px)", overflowY: 'auto' }}>
                    <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                        <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                            <tr>
                                <th style={{ textAlign: 'center', fontSize: '14px', padding: '0' }} colSpan={4}>Операции по ERA Coin</th>
                            </tr>
                            <tr>
                                <th>Дата-время</th>
                                <th>Резидент</th>
                                <th>Описание</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {coinTransactions.map((coinTransaction, i) => (
                                <tr>
                                    <td>{makeDateTime(coinTransaction.transactionDateTime)}</td>
                                    <td>{coinTransaction.account.humanName}</td>
                                    <td>{coinTransaction.description}</td>
                                    <td style={coinTransaction.value > 0 ? { color: "green" } : { color: "red" }}><b>{coinTransaction.value > 0 ? "+" : ""}{coinTransaction.value}</b></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
            </div>}
            {selectedScreen == 1 && <CuratorAssignForm onBack={() => setSelectedScreen(0)} productionMode={productionMode} />}
            {selectedScreen == 2 && <CoinChargeForm onBack={() => setSelectedScreen(0)} productionMode={productionMode} />}
        </>
    )
};

export default AdminCuratorBindings;
