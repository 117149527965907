import * as React from 'react';
import useFetch from '../../../../hooks/useFetch';
import { Account } from '../../../../store/Models';
import { getDaysTextRussian } from '../../../../utils';

interface MainLoaderCoverScreenProps {
    showExpirationText: boolean;
    currentUser: Account | null;
    onProlongAction: () => void;
}

const MainLoaderCoverScreen: React.FC<MainLoaderCoverScreenProps> = ({ showExpirationText, currentUser, onProlongAction }) => {
    const fetch = useFetch();

    const [paymentSessionStatus, setPaymentSessionStatus] = React.useState<number | null>(null);

    React.useEffect(() => {
        if (currentUser != null) {
            fetch('/api/payment/getPaymentSessionStatus?accountId=' + currentUser.id)
                .then(res => res.json())
                .then(data => {
                    setPaymentSessionStatus(parseInt(data.status));
                });
        }
    }, [currentUser]);

    const currentDateTime = new Date();
    const hours = currentDateTime.getHours();

    var greetingTimeOfDayString = '';
    var coverMode = '';
    if (hours < 4 || hours >= 22) {
        greetingTimeOfDayString = 'Доброй ночи';
        coverMode = 'night';
    }
    else if (hours >= 4 && hours < 10) {
        greetingTimeOfDayString = 'Доброе утро';
        coverMode = 'morning';
    }
    else if (hours >= 10 && hours < 18) {
        greetingTimeOfDayString = 'Добрый день';
        coverMode = 'day';
    }
    else if (hours >= 18 && hours < 22) {
        greetingTimeOfDayString = 'Добрый вечер';
        coverMode = 'evening';
    }

    const getExpirationColor = (status: number): string => {
        if (status <= 0) return 'red';
        else if (status > 0 && status <= 2) return 'orange';
        else if (status > 2 && status < 7) return 'yellow';
        else return '';
    }

    if (currentUser != null) {
        return (
            <div className={"main-loader-cover " + coverMode}
                style={{ backgroundImage: `url("assets/banners/sib-winter-${coverMode}3.jpg")`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                {currentUser.humanName != null && currentUser.humanName.length > 0 && <>
                    <div className="main-loader-cover-username inter-regular">{greetingTimeOfDayString},<br />{currentUser.humanName.split(' ')[0]}!</div>
                    <div className="main-loader-cover-message">
                        <span className="stix-two-text-bold" style={{ fontSize: '16px' }}><b>{currentUser.personalMessageType === 'affirmation' ? "АФФИРМАЦИЯ ДНЯ" :
                        currentUser.personalMessageType === 'letter' ? "ПОСЛАНИЕ ДНЯ" :
                            currentUser.personalMessageType === 'thought' ? "МЫСЛЬ ДНЯ" :
                                currentUser.personalMessageType === 'quote' ? "ЦИТАТА ДНЯ" :
                                    currentUser.personalMessageType === 'humor' ? "ЮМОР ДНЯ" : ""
                        }</b></span>
                        <div style={{ width: "100%", height: '19px', lineHeight: '14px', margin: '5px 0' }}>
                            <img src={"assets/banners/" + currentUser.personalMessageType + "1.png"} width={83} height={15} />
                        </div>
                        {currentUser.personalMessageType === 'quote' && <>
                            <span className="montserrat-medium" style={{ fontSize: '14px' }}>{currentUser.personalMessage.split('@')[0]}</span>
                            <span style={{ marginTop: '5px', display: 'block' }}></span>
                        </>}
                        {currentUser.personalMessageType !== 'quote' && <span className="montserrat-medium" style={{ fontSize: '14px' }}>{currentUser.personalMessage}</span>}
                    </div>
                    {currentUser.personalMessageType === 'quote' && <div className="montserrat-regular"
                        style={{ position: 'absolute', bottom: '90px', opacity: '0', animation: 'fadeIn 1s ease-in forwards', animationDelay: '1s', fontSize: '10px' }}>
                        {currentUser.personalMessage.split('@')[1].indexOf('#') == 0 && <span>{currentUser.personalMessage.split('@')[1].substring(1)}</span>}
                        {currentUser.personalMessage.split('@')[1].indexOf('#') < 0 && <span>&copy; {currentUser.personalMessage.split('@')[1]}</span>}
                    </div>}
                    {(showExpirationText && currentUser.daysLeft != null && paymentSessionStatus != null) &&
                        <div className="main-loader-cover-expiration"
                        style={{ backgroundImage: `url("assets/banners/expiration-${getExpirationColor(paymentSessionStatus)}.png")`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}} onClick={onProlongAction}>
                            <span className="inter-bold" style={{ fontSize: '20px' }}>!</span>
                            <br />
                            <span className="inter-bold" style={{ fontSize: '10px' }}>Продлить подписку.</span>
                        <br />
                        {currentUser.daysLeft > 0 && <>
                            <span className="inter-regular" style={{ fontSize: '10px' }}>Осталось</span>
                            <br />
                            <span className="inter-regular" style={{ fontSize: '10px' }}>{currentUser.daysLeft} {getDaysTextRussian(currentUser.daysLeft)}</span>
                        </>}
                        {currentUser.daysLeft == 0 && <>
                            <span className="inter-regular" style={{ fontSize: '10px' }}>Заканчивается</span>
                            <br />
                            <span className="inter-regular" style={{ fontSize: '10px' }}>сегодня!</span>
                        </>}
                        {currentUser.daysLeft < 0 && <>
                            <span className="inter-regular" style={{ fontSize: '10px' }}>Подписка</span>
                            <br />
                            <span className="inter-regular" style={{ fontSize: '10px' }}>истекла!</span>
                        </>}
                        </div>}
                </>}
            </div>
        )
    }
    else {
        return (
            <div className={"main-loader-cover " + coverMode}
                style={{ backgroundImage: `url("assets/banners/sib-winter-${coverMode}3.jpg")`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}></div>
        );
    }
};

export default MainLoaderCoverScreen;
