import * as React from 'react';
import { Venue } from '../../../../../store/Models';
import useFetch from '../../../../../hooks/useFetch';
import { makePeriod } from '../../../../../utils';

interface AdminVenueListManagementProps {
    productionMode: boolean;
    onAddRecord: () => void;
}

const AdminVenueListManagement: React.FC<AdminVenueListManagementProps> = ({ productionMode, onAddRecord }) => {
    const fetch = useFetch();

    const [venues, setVenues] = React.useState<Venue[]>([]);

    const venueFormats = [
        "Вживую",
        "Онлайн",
        "Вживую + Онлайн"
    ];

    React.useEffect(() => {
        fetch('/api/venue/getVenues')
            .then(res => res.json() as Promise<Venue[]>)
            .then(data => {
                setVenues(data);
            });
    }, []);

    return (
        <div className="payment-approval-container">
            <button style={{ marginTop: '10px', marginBottom: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={onAddRecord}>Создать мероприятие</button>
            <div style={{ height: "calc(100% - 71.3px)", overflowY: 'auto' }}>
                {venues.length > 0 && <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                    <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                        <tr>
                            <th>Даты</th>
                            <th>Название</th>
                            <th>Место проведения</th>
                            <th>Формат</th>
                        </tr>
                    </thead>
                    <tbody>
                        {venues.length > 0 ? venues.map((v, i) => (
                            <tr>
                                <td>{makePeriod(v.dateStart, v.dateEnd)}</td>
                                <td>{v.name}</td>
                                <td>{v.locationInfo}</td>
                                <td>{venueFormats[v.format]}</td>
                            </tr>
                        )) : <tr>
                            <td colSpan={4}>Здесь пока нет мероприятий.</td>
                        </tr>}
                    </tbody>
                </table>}
            </div>
        </div>
    )
};

export default AdminVenueListManagement;
