import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { PaymentSession } from '../../../../../store/Models';
import { CostInfo } from '../../../../../subscriptions';
import { mockTelegramObject } from '../../../../../utils';
import StepperComponent from '../stepper/Stepper';

interface Reg2Props {
    productionMode: boolean;
    onBack: () => void;
    onFinishPayment: () => void;
}

const Reg2: React.FC<Reg2Props> = ({ productionMode, onBack, onFinishPayment }) => {
    const fetch = useFetch();

    const [paymentSession, setPaymentSession] = React.useState<PaymentSession | null>(null);

    const params = new URLSearchParams(productionMode ? window.Telegram.WebApp.initData : window.location.search);
    var referralHash = params.get('start_param');

    const sendPaymentInfo = (subscription: CostInfo, paymentMethod: string) => {
        //send information to server
        var formData = new FormData();
        formData.append('SubscriptionCode', subscription.code);
        formData.append('PaymentMethod', paymentMethod);
        formData.append('Cost', paymentMethod === 'Sberbank' ? subscription.rubCost.toString() : subscription.cost.toString());
        
        if (referralHash != null) {
            formData.append('ReferralHash', referralHash);
        }
        fetch('api/account/sendPaymentInfo', {
            method: 'POST',
            body: formData
        })
            .then(() => {
                onFinishPayment();
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: '��������� ������ �������� ������. ����������, ���������� �����.' });
            });
    }

    // check payment session for user. If session not exists, it must be created.
    React.useEffect(() => {
        var telegramUserId = productionMode ? window.Telegram.WebApp.initDataUnsafe.user.id : mockTelegramObject.initDataUnsafe.user.id;
        var queryString = 'api/payment/getPaymentSessionTg?telegramUserId=' + telegramUserId;
        if (referralHash != null) {
            queryString += '&referralHash=' + referralHash;
        }

        fetch(queryString)
            .then(res => res.json() as Promise<PaymentSession>)
            .then(data => setPaymentSession(data));
    }, []);

    return (
        <div className="reg-module-container">
            {paymentSession != null && <StepperComponent productionMode={productionMode} paymentSession={paymentSession} onBack={onBack} onFinishPayment={sendPaymentInfo} />}
        </div>
    )
};

export default Reg2;
