import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, DataVersion, ReactionExtended, Reflection, ReflectionsListEvent } from '../../store/Models';
import { makeDateHeaderRussian } from '../../utils';
import FocusLongPressMenu from './FocusLongPressMenu';
import ReactionMenu from './ReactionMenu';
import ReactDOM from 'react-dom';
import FounderChannelMessageComponent from '../FounderChannelMessage';
import FounderChannelLongPressMenu from './FounderChannelLongPressMenu';

interface FounderChannelModuleProps {
    currentUser: Account;
    visible: boolean;
    productionMode: boolean;
    selectedFounderChannelMessage: Reflection | null;
    items: Reflection[];
    founderChannelMessagesUnreadCount: number;
    setFounderChannelMessagesUnreadCount: React.Dispatch<React.SetStateAction<number>>;
    onSelectFounderChannelMessage: (item: Reflection | null) => void;
    showContextMenu: boolean;
    onOpenContextMenu: () => void;
    onCloseContextMenu: () => void;
    onDeleteFounderChannelMessage: (item: Reflection) => void;
    onStartEdit: (item: Reflection) => void;
    onAccountClick: (account: Account) => void;
}

const FounderChannelModule: React.FC<FounderChannelModuleProps> = ({ currentUser, visible,
    productionMode,
    selectedFounderChannelMessage, items, founderChannelMessagesUnreadCount, setFounderChannelMessagesUnreadCount,
    onSelectFounderChannelMessage, showContextMenu, onOpenContextMenu, onCloseContextMenu,
    onDeleteFounderChannelMessage, onStartEdit, onAccountClick }) => {
    const fetch = useFetch();

    const founderChannelMessagesListRef = React.useRef<HTMLDivElement>(null);

    const [lastScrollPosition, setLastScrollPosition] = React.useState<number>(-1);
    const [showScroller, setShowScroller] = React.useState<boolean>(false);
    const [showReactionsTeaser, setShowReactionsTeaser] = React.useState<boolean>(items.filter(i => i.unreadReactionsCount > 0).length > 0);

    const [selectedReactions, setSelectedReactions] = React.useState<ReactionExtended[]>([]);

    const showSelectedReactions = (postId: number, reactionCode: number) => {
        fetch('api/founderChannel/getReactions?postId=' + postId + '&reactionCode=' + reactionCode)
            .then(res => res.json() as Promise<ReactionExtended[]>)
            .then(data => {
                setSelectedReactions(data);
            });
    }

    const getUnreadFounderChannelMessages = (): Reflection[] => {
        return items.filter(
                r => r.isRead == 0 && r.visibilityMode < 100 && r.post.publishAccount.id != currentUser.id
                    && new Date(r.post.publishDateTime) > new Date(currentUser.activeFrom));
    }

    React.useEffect(() => {
        let unreadFounderChannelMessages = getUnreadFounderChannelMessages();
        if (founderChannelMessagesListRef.current != null) {
            if (visible) {
                founderChannelMessagesListRef.current.style.display = '';
                if (lastScrollPosition < 0) {
                    if (unreadFounderChannelMessages.length > 0) { //scroll to first unread message and show scroller with unread counter
                        setToMessage(unreadFounderChannelMessages[0].id);
                        window.setTimeout(() => {
                            setShowScroller(true);
                        }, 100);
                    }
                    else {
                        founderChannelMessagesListRef.current.scrollTop = founderChannelMessagesListRef.current.scrollHeight;
                    }
                }
                else {
                    founderChannelMessagesListRef.current.scrollTop = lastScrollPosition;
                }
            }
            else {
                founderChannelMessagesListRef.current.style.display = 'none';
            }
        }
    }, [visible]);

    const pressTimer = React.useRef(0);

    const onContextMenu = (founderChannelMessage: Reflection, isImmediate: boolean) => {
        //prevent window collapsing on iOS
        if (window.Telegram.WebApp.platform === 'ios') {
            if (window.scrollY === 0) {
                window.scrollTo(0, 1);
            }
        }

        if (founderChannelMessage.id == 0) { //not allow to use context menu on temporary object dummy
            return;
        }

        if (isImmediate == false) {
            pressTimer.current = window.setTimeout(() => {
                onSelectFounderChannelMessage(founderChannelMessage);
                onOpenContextMenu();
            }, 800);
        }
        else {
            onSelectFounderChannelMessage(founderChannelMessage);
            onOpenContextMenu();
        }
    }

    const onTouchMove = () => {
        if (selectedFounderChannelMessage != null) {
            onSelectFounderChannelMessage(null);
        }
        window.clearTimeout(pressTimer.current);
    }

    const onTouchStop = () => {
        window.clearTimeout(pressTimer.current);
    }

    const scrollToMessage = (id: number) => {
        if (founderChannelMessagesListRef.current != null) {
            var targetElement = document.getElementById(id.toString());

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    const scrollToMessageInstant = (id: number) => {
        if (founderChannelMessagesListRef.current != null) {
            var targetElement = document.getElementById(id.toString());

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'auto', block: 'center' });
            }
        }
    }

    const setToMessage = (id: number) => {
        if (founderChannelMessagesListRef.current != null) {
            var targetElement = document.getElementById(id.toString());

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
            }
        }
    }

    const scrollToBottom = () => {
        if (founderChannelMessagesListRef.current != null) {
            founderChannelMessagesListRef.current.scrollTo({
                top: founderChannelMessagesListRef.current.scrollHeight,
                behavior: 'smooth'
            });
            setShowScroller(false);
        }
        readAllUnreadFounderChannelMessages();
    }

    const onScroll = () => {
        if (founderChannelMessagesListRef.current != null) {
            const scrollTop = founderChannelMessagesListRef.current.scrollTop;
            const scrollHeight = founderChannelMessagesListRef.current.scrollHeight;
            const offsetHeight = founderChannelMessagesListRef.current.offsetHeight;

            if (scrollTop < scrollHeight - offsetHeight - 30) {
                setShowScroller(true);
            } else {
                setShowScroller(false);
            }

            setLastScrollPosition(scrollTop <= 0 ? 0 : scrollTop);
        }
    }

    const readAllUnreadFounderChannelMessages = () => {
        let unreadMessages = getUnreadFounderChannelMessages();
        for (let i = 0; i < unreadMessages.length; i++) {
            unreadMessages[i].isRead = 1;
            fetch('/api/counter/readPost?postId=' + unreadMessages[i].post.id);
        }
    }

    const scrollToUnreadReactions = () => {
        var itemsWithUnreadReactions = items.filter(x => x.unreadReactionsCount > 0);
        var lastItemWithUnreadReactions = itemsWithUnreadReactions[itemsWithUnreadReactions.length - 1];
        if (lastItemWithUnreadReactions != null) {
            lastItemWithUnreadReactions.unreadReactionsCount = 0;
            fetch('/api/counter/readReactions?postId=' + lastItemWithUnreadReactions.post.id);

            scrollToMessageInstant(lastItemWithUnreadReactions.id);
        }
    }

    const isFirstOnItsDateInLocalTime = (item: Reflection): boolean => {
        // Extract the target date from the target object
        let targetDateCopy = new Date(item.post.publishDateTime);
        targetDateCopy.setHours(targetDateCopy.getHours() - targetDateCopy.getTimezoneOffset() / 60); // to local time!
        const targetDate = new Date(targetDateCopy);

        // Filter all objects that share the same date as the target object
        const sameDateObjects = items.filter(r => {
            let dateCopy = new Date(r.post.publishDateTime);
            dateCopy.setHours(dateCopy.getHours() - dateCopy.getTimezoneOffset() / 60); // to local time!
            const objDate = new Date(dateCopy);
            return objDate.toDateString() === targetDate.toDateString();
        });

        // Find the object with the minimal date and time among the same date objects
        const minDateObject = sameDateObjects.reduce((minObj, obj) => {
            const objDate = new Date(obj.post.publishDateTime);
            return objDate < new Date(minObj.post.publishDateTime) ? obj : minObj;
        }, sameDateObjects[0]);

        // Check if the target object is the one with the minimal date
        return item === minDateObject;
    }

    const isFirstByUser = (item: Reflection): boolean => {
        // conditions:
        // 1. user that wrote reflection is newbie after 2024-09-01
        // 2. user is not admin, reflection is not reply
        // 3. this is first reflection posted by this user since 2024-09-01
        var candidates = items.filter(i => i.post.publishAccount.id == item.post.publishAccount.id &&
            new Date(item.post.publishAccount.activeFrom) > new Date(2024, 8, 1) &&
            i.post.publishAccount.isAdmin == 0 && i.replyReflection == null);
        return candidates.length > 0 && item.id == candidates[0].id;
    }

    const isSecondByUser = (item: Reflection): boolean => {
        // conditions:
        // 1. user that wrote reflection is newbie after 2024-09-01
        // 2. user is not admin, reflection is not reply
        // 3. this is second reflection posted by this user since 2024-09-01
        var candidates = items.filter(i => i.post.publishAccount.id == item.post.publishAccount.id &&
            new Date(item.post.publishAccount.activeFrom) > new Date(2024, 8, 1) &&
            i.post.publishAccount.isAdmin == 0 && i.replyReflection == null);
        return candidates.length > 1 && item.id == candidates[1].id;
    }

    return (
        <div ref={founderChannelMessagesListRef} className="reflection-module-layout" onScroll={onScroll}
            style={{
                backgroundImage: 'url("assets/banners/wallpaper5.png")', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
            }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {items.map((founderChannelMessage: Reflection, index: number) =>
                            <div className="mt-1 mb-1" key={founderChannelMessage.id} data-id={founderChannelMessage.id}
                                data-my={founderChannelMessage.post.publishAccount.id === currentUser.id}>
                                {isFirstOnItsDateInLocalTime(founderChannelMessage) && <div className="date-header">
                                    <span className="date-header-span-reflections">{makeDateHeaderRussian(founderChannelMessage.post.publishDateTime.toString())}</span></div>}
                                <FounderChannelMessageComponent currentUser={currentUser} founderChannelMessage={founderChannelMessage}
                                    isFirst={isFirstByUser(founderChannelMessage)}
                                    isSecond={isSecondByUser(founderChannelMessage)}
                                    isExpanded={false}
                                    productionMode={productionMode}
                                    onContextMenu={onContextMenu}
                                    onReactionContextMenu={showSelectedReactions}
                                    onTouchMove={onTouchMove}
                                    onTouchStop={onTouchStop}
                                    onAccountClick={onAccountClick}
                                    onExpand={() => { }}
                                    onRead={() => setFounderChannelMessagesUnreadCount(x => x - 1)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {(showContextMenu && selectedFounderChannelMessage != null) && ReactDOM.createPortal(
                <FounderChannelLongPressMenu user={currentUser} visible={true}
                    productionMode={productionMode}
                    founderChannelMessage={selectedFounderChannelMessage}
                    isFirst={isFirstByUser(selectedFounderChannelMessage)}
                    isSecond={isSecondByUser(selectedFounderChannelMessage)}
                    onStartEdit={() => { onStartEdit(selectedFounderChannelMessage) }}
                    onDelete={() => { onDeleteFounderChannelMessage(selectedFounderChannelMessage) }}
                    onClose={onCloseContextMenu}
            />, document.body)}
            {selectedReactions.length > 0 && <ReactionMenu reactionCode={selectedReactions[0].reactionCode}
                accounts={selectedReactions.map(s => s.account)} onClose={() => setSelectedReactions([])}
            />}
            {showScroller && <div className="bottom-scroller" onClick={scrollToBottom}>
                {founderChannelMessagesUnreadCount > 0 &&
                    <div className="bottom-scroller-counter">{founderChannelMessagesUnreadCount}</div>}
                <i className="fa fa-chevron-down" />
            </div>}
            {(showReactionsTeaser && items.reduce((acc, item) => { return acc + item.unreadReactionsCount }, 0) > 0) && <div className="reactions-teaser" onClick={scrollToUnreadReactions}>
                {items.reduce((acc, item) => { return acc + item.unreadReactionsCount }, 0) > 0 &&
                    <div className="bottom-scroller-counter">{items.reduce((acc, item) => { return acc + item.unreadReactionsCount }, 0)}</div>}
                <i className="fa-regular fa-heart" />
            </div>}
        </div>)
};

export default FounderChannelModule;
