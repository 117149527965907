import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, AccountDetailInfo, BinarAccount } from '../../../../../store/Models';
import { copyToClipboard, getClassSingle, getMembersCountTextRussian, makeDate } from '../../../../../utils';
import AccountView from '../../../AccountView';
import ClassicView from '../../../Classic';
import ClassicShortView from '../../../ClassicShort';
import BinarPtsTransactionsView from './BinarPtsTransactionsView';
import BinarView from './BinarView';

interface BinarAccountInfoProps {
    currentUser: Account;
    productionMode: boolean;
    account: Account;
    detailInfo: AccountDetailInfo;
    onClose: () => void;
}

const BinarAccountInfo: React.FC<BinarAccountInfoProps> = ({ currentUser, productionMode, account, detailInfo, onClose }) => {
    const fetch = useFetch();

    const [showProfileAccount, setShowProfileAccount] = React.useState<Account | null>(null);
    const [showFirstLineUser, setShowFirstLineUser] = React.useState<Account | null>(null);
    const [showAllLinesUser, setShowAllLinesUser] = React.useState<Account | null>(null);

    const [showBinar, setShowBinar] = React.useState<boolean>(false);
    const [currentAccountForBinarViewId, setCurrentAccountForBinarViewId] = React.useState<number>(account.id);
    const [selectedBinarForPts, setSelectedBinarForPts] = React.useState<BinarAccount | null>(null);

    return (
        <>
            {(showProfileAccount == null && showFirstLineUser == null && showAllLinesUser == null && !showBinar) && <div className="binar-account-info-cover" onClick={onClose}>
                <div className="binar-account-info-body" onClick={(e) => e.stopPropagation()}>
                    <div className="binar-account-info-header"><b>{account.humanName.toUpperCase()}</b></div>
                    <div className="binar-account-info-close-btn" onClick={onClose}><i className="fas fa-xmark" /></div>
                    {detailInfo != null && <div className="binar-account-info-content">
                        <div className="binar-account-info-chapter">
                            <div className="binar-account-info-chapter-header">ИНФОРМАЦИЯ О ПОДПИСКЕ</div>
                            <div className="binar-account-info-chapter-content">
                                <p><b>ID:</b> {account.id}</p>
                                <p><b>Тариф:</b> {getClassSingle(account.class)}</p>
                                <p><b>Активность:</b> {account.isActive > 0 ? "Есть" : "Нет"}</p>
                                <p><b>Квалификация:</b> Нет</p>
                                <p><b>Дата регистрации:</b> {makeDate(detailInfo.activeFrom)}</p>
                                <p><b>{account.isActive > 0 ? "Подписка до: " : "Неактивен с: "}</b> {detailInfo.activeTo ? makeDate(detailInfo.activeTo) : "Нет данных"}</p>
                            </div>
                        </div>
                        <div className="binar-account-info-chapter">
                            <div className="binar-account-info-chapter-header">КОНТАКТНАЯ ИНФОРМАЦИЯ</div>
                            <div className="binar-account-info-chapter-content">
                                <p><b>Написать в Telegram:</b> {detailInfo.username.length > 0 ? <span className="account-profile-clickable" onClick={() => { parent.location.href = "https://t.me/" + detailInfo.username }}>@{detailInfo.username}</span> : <span>Telegram UserName отсутствует</span>}</p>
                                <p><b>E-mail:</b> <span className="account-profile-clickable" onClick={() => copyToClipboard(detailInfo.accessGmail, () => {
                                    if (productionMode) {
                                        window.Telegram.WebApp.showPopup({ message: "E-Mail скопирован в буфер обмена", buttons: [{ text: "Понятно" }] });
                                    }
                                    else {
                                        alert("E-Mail скопирован в буфер обмена");
                                    }
                                })}>{detailInfo.accessGmail}</span></p>
                                <p className="account-profile-clickable" onClick={() => {
                                    fetch('/api/account/getAccountInfo?id=' + account.id)
                                        .then(res => res.json() as Promise<Account>)
                                        .then(data => setShowProfileAccount(data))
                                }}>Перейти в профиль</p>
                            </div>
                        </div>
                        <div className="binar-account-info-chapter">
                            <div className="binar-account-info-chapter-header">ИНФОРМАЦИЯ О КОМАНДЕ</div>
                            <div className="binar-account-info-chapter-content">
                                <p><b>1-я линия:</b> <span>{detailInfo.firstLineCount} {getMembersCountTextRussian(detailInfo.firstLineCount.toString())}</span>&nbsp;<span>(</span><span className="account-profile-clickable" onClick={() => setShowFirstLineUser(account)}>подробнее</span><span>)</span></p>
                                <p><b>Классика:</b> <span>{detailInfo.allLinesCount} {getMembersCountTextRussian(detailInfo.allLinesCount.toString())}</span>&nbsp;<span>(</span><span className="account-profile-clickable" onClick={() => setShowAllLinesUser(account)}>подробнее</span><span>)</span></p>
                            </div>
                        </div>
                        <div className="binar-account-info-chapter">
                            <div className="binar-account-info-chapter-header">КУРАТОР: <span style={{ fontWeight: 'normal' }}>{detailInfo.curator != null ? detailInfo.curator.humanName : "Не выбран"}</span></div>
                            {detailInfo.curator != null && <div className="binar-account-info-chapter-content">
                                <p><b>ID:</b> {detailInfo.curator.id}</p>
                                <p><b>Тариф:</b> {getClassSingle(detailInfo.curator.class)}</p>
                                <p><b>Активность:</b> {detailInfo.curator.isActive > 0 ? "Есть" : "Нет"}</p>
                                <p><b>Квалификация:</b> {detailInfo.curatorIsQualified ? "Есть" : "Нет"}</p>
                                <p><b>Дата регистрации:</b> {makeDate(detailInfo.curator.activeFrom)}</p>
                                <p><b>{account.isActive > 0 ? "Подписка до: " : "Неактивен с: "}</b> {detailInfo.curatorActiveTo ? makeDate(detailInfo.curatorActiveTo) : "Нет данных"}</p>
                            </div>}
                        </div>
                        {detailInfo.curator != null && <div className="binar-account-info-chapter">
                            <div className="binar-account-info-chapter-header">КОНТАКТНАЯ ИНФОРМАЦИЯ КУРАТОРА</div>
                            <div className="binar-account-info-chapter-content">
                                <p><b>Написать в Telegram:</b> {detailInfo.curator.username.length > 0 ? <span className="account-profile-clickable" onClick={() => {
                                    if (detailInfo.curator != null) {
                                        parent.location.href = "https://t.me/" + detailInfo.curator.username;
                                    }
                                }}>@{detailInfo.curator.username}</span> : <span>Telegram UserName отсутствует</span>}</p>
                                <p><b>E-mail:</b> <span className="account-profile-clickable" onClick={() => {
                                    if (detailInfo.curator != null) {
                                        copyToClipboard(detailInfo.curator.accessGmail, () => {
                                            if (productionMode) {
                                                window.Telegram.WebApp.showPopup({ message: "E-Mail скопирован в буфер обмена", buttons: [{ text: "Понятно" }] });
                                            }
                                            else {
                                                alert("E-Mail скопирован в буфер обмена");
                                            }
                                        }
                                        )
                                    }
                                }}>{detailInfo.curator.accessGmail}</span></p>
                                <p className="account-profile-clickable" onClick={() => {
                                    if (detailInfo.curator != null) {
                                        fetch('/api/account/getAccountInfo?id=' + detailInfo.curator.id)
                                            .then(res => res.json() as Promise<Account>)
                                            .then(data => setShowProfileAccount(data))
                                    }
                                }}>Перейти в профиль Куратора</p>
                                <p className="account-profile-clickable" onClick={() => {
                                    if (detailInfo.curator != null) {
                                        fetch('/api/binarReward/checkAccountAvailabiltyForMe?accountId=' + detailInfo.curator.id)
                                            .then(res => res.json())
                                            .then(data => {
                                                if (detailInfo.curator != null) {
                                                    if (parseInt(data.isAvailable) > 0) {
                                                        setCurrentAccountForBinarViewId(detailInfo.curator.id);
                                                        setShowBinar(true);
                                                    }
                                                    else {
                                                        if (productionMode) {
                                                            window.Telegram.WebApp.showPopup({
                                                                title: "Упс... Доступ ограничен)",
                                                                message: "К сожалению, " + detailInfo.curator.humanName + " не является членом Вашей команды по бинару.",
                                                                buttons: [{ text: "Понятно" }]
                                                            });
                                                        }
                                                        else {
                                                            window.alert("К сожалению, " + detailInfo.curator.humanName + " не является членом Вашей команды по бинару.");
                                                        }
                                                    }
                                                }
                                            });
                                    }
                                }}>Посмотреть Куратора в бинаре</p>
                            </div>
                        </div>}
                    </div>}
                    <div className="binar-account-info-bottom-panel">
                        <button className="btn btn-primary" onClick={onClose}>Закрыть</button>
                    </div>
                </div>
            </div>}
            {showProfileAccount != null && <AccountView productionMode={productionMode} currentUser={currentUser} account={showProfileAccount} onClose={() => setShowProfileAccount(null)} />}
            {showFirstLineUser != null && <ClassicShortView currentUser={currentUser} productionMode={productionMode} onAccountClick={setShowProfileAccount} targetUser={showFirstLineUser} onReloadProfile={() => { }} onClose={() => setShowFirstLineUser(null)} />}
            {showAllLinesUser != null && <ClassicView currentUser={currentUser} productionMode={productionMode} onAccountClick={setShowProfileAccount} targetUser={showAllLinesUser} onClose={() => setShowAllLinesUser(null)} />}
            {showBinar && <BinarView homeUserRef={currentUser} homeUserId={currentUser.id} homeUserName={currentUser.humanName} rootUserId={currentAccountForBinarViewId} onBack={() => setShowBinar(false)}
                onSelectBinarAccount={setCurrentAccountForBinarViewId}
                onShowBinarPts={setSelectedBinarForPts}
                productionMode={productionMode} />}
            {selectedBinarForPts != null && <BinarPtsTransactionsView homeUserRef={currentUser} productionMode={productionMode} homeUserId={currentUser.id} homeUserName={currentUser.humanName}
                rootBinarAccount={selectedBinarForPts} onBack={() => setSelectedBinarForPts(null)} onReloadView={setSelectedBinarForPts} />}
        </>
    )
};

export default BinarAccountInfo;
