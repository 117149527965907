import * as React from 'react';
import { Account, BinarAccount, Ticket, TicketGroup, Venue } from '../../store/Models';
import { copyToClipboard, formatMoney, getClassSingle, getCookie, getDaysInCurrentMonth, getDaysTextRussian, getDifferenceInDays, getFinDay, getRemainingDaysTextRussian, getMembersCountTextRussian, makeAgeString, makeDate, saveCookie, trimName, makeDateTime, makePeriod } from '../../utils';
import CuratorSVG from './svg/CuratorSVG';
import FirstLineSVG from './svg/FirstLineSVG';
import CommandSVG from './svg/CommandSVG';
import BinarView from './components/complex/binar/BinarView';
import useFetch from '../../hooks/useFetch';
import CuratorSelection from './components/simple/CuratorSelection';
import BinarPtsTransactionsView from './components/complex/binar/BinarPtsTransactionsView';
import AccountView from './AccountView';
import BinarSVG from './svg/BinarSVG';
import ClassicSVG from './svg/ClassicSVG';
import ProgressBar from './components/simple/ProgressBar';
import QRCodeComponent from './components/simple/QRCodeComponent';

interface AccountProfileProps {
    visible: boolean;
    account: Account;
    onShowClassic: (viewMode: number) => void;
    onShowWalletTransactions: () => void;
    productionMode: boolean;
    onShowCashbackTransactions: () => void;
    onShowSpeakershipRewardTransactions: () => void;
    isSubscriptionExpiring: boolean;
    onGoToRenewal: () => void;
    setAccountProfileChanges: React.Dispatch<React.SetStateAction<boolean>>;
    onReload: () => void;
    reloadAccountProfileDummy: number;
    onAccountClick: (item: Account) => void;
}

const AccountProfile: React.FC<AccountProfileProps> = ({ visible, account, onShowClassic, productionMode,
    onShowWalletTransactions, onShowCashbackTransactions, onShowSpeakershipRewardTransactions,
    isSubscriptionExpiring, onGoToRenewal, setAccountProfileChanges, onReload, reloadAccountProfileDummy, onAccountClick }) => {
    const fetch = useFetch();

    const [commandInfo, setCommandInfo] = React.useState({ firstLineCount: '', totalCount: '', incomingRequests: '' });

    const [viewMode, setViewMode] = React.useState<number>(3);

    const [balance, setBalance] = React.useState<number>(0);

    const [showBinar, setShowBinar] = React.useState<boolean>(false);
    const [currentAccountForBinarViewId, setCurrentAccountForBinarViewId] = React.useState<number>(account.id);

    const [binarChanges, setBinarChanges] = React.useState<boolean>(false);

    const [subscriptionTo, setSubscriptionTo] = React.useState<string>('');

    const [cashbackValue, setCashbackValue] = React.useState<number>(0);

    const [speakershipRewardValue, setSpeakershipRewardValue] = React.useState<number>(0);

    const [paymentSessionStatus, setPaymentSessionStatus] = React.useState<number | null>(null);

    const [, setUpdateDummy] = React.useState<number>(0);

    const curatorRef = React.useRef<Account | null>(null);

    const [firstLineHasChanges, setFirstLineHasChanges] = React.useState<boolean>(false);
    const [totalCountHasChanges, setTotalCountHasChanges] = React.useState<boolean>(false);
    const [balanceHasChanges, setBalanceHasChanges] = React.useState<boolean>(false);
    const [cashbackValueHasChanges, setCashbackValueHasChanges] = React.useState<boolean>(false);
    const [eraCoinsHasChanges, setERACoinsHasChanges] = React.useState<boolean>(false);
    const [speakershipRewardValueHasChanges, setSpeakershipRewardValueHasChanges] = React.useState<boolean>(false);

    const [seen, setSeen] = React.useState<boolean>(false);

    const [commandPendingRequestID, setCommandPendingRequestID] = React.useState<string>('');
    const [showCuratorSelection, setShowCuratorSelection] = React.useState<boolean>(false);

    const [curatorHasChanges, setCuratorHasChanges] = React.useState<boolean>(false);
    const [commandPendingRequestHasChanges, setPendingRequestHasChanges] = React.useState<boolean>(false);

    const [selectedBinarForPts, setSelectedBinarForPts] = React.useState<BinarAccount | null>(null);

    const [nextAccount, setNextAccount] = React.useState<Account | null>(null);

    const [showRenewal, setShowRenewal] = React.useState<boolean>(false);

    const [fastStartLastDate, setFastStartLastDate] = React.useState<string>('');

    const [upgradeLastDate, setUpgradeLastDate] = React.useState<string>('');

    const [progressBarsLoaded, setProgressBarsLoaded] = React.useState<boolean>(account.isActive == 0);

    const [tickets, setTickets] = React.useState<Ticket[]>([]);
    const [ticketGroups, setTicketGroups] = React.useState<TicketGroup[]>([]);
    const [selectedVenue, setSelectedVenue] = React.useState<Venue | null>(null);

    const [selectedTicket, setSelectedTicket] = React.useState<Ticket | null>(null);

    React.useEffect(() => {
        const promises = [
            fetch('api/account/getCommandInfo?id=' + account.id)
                .then(r => r.json())
                .then(data => {
                    setCommandInfo({
                        firstLineCount: data.firstLineCount,
                        totalCount: data.totalCount,
                        incomingRequests: data.incomingRequests
                    });

                    var firstLineCookie = getCookie("command_info_first_line");
                    if (firstLineCookie != null) {
                        if (parseInt(data.firstLineCount) != parseInt(firstLineCookie)) {
                            setFirstLineHasChanges(true);
                            saveCookie("command_info_first_line", data.firstLineCount.toString(), 180);
                        }
                    }
                    else {
                        saveCookie("command_info_first_line", data.firstLineCount.toString(), 180);
                    }

                    var totalCountCookie = getCookie("command_info_total_count");
                    if (totalCountCookie != null) {
                        if (parseInt(data.totalCount) != parseInt(totalCountCookie)) {
                            setTotalCountHasChanges(true);
                            saveCookie("command_info_total_count", data.totalCount.toString(), 180);
                        }
                    }
                    else {
                        saveCookie("command_info_total_count", data.totalCount.toString(), 180);
                    }
                }),
            fetch('api/challenge/getCurator?accountId=' + account.id)
                .then(r => r.json())
                .then(data => {
                    curatorRef.current = data.account;
                    var curatorCookie = getCookie("curator_id");
                    if (curatorCookie != null) {
                        if (data.account != null) {
                            if (parseInt(data.account.id) != parseInt(curatorCookie)) {
                                setCuratorHasChanges(true);
                                saveCookie("curator_id", data.account.id.toString(), 180);
                            }
                        }
                    }
                    else if (data.account != null) {
                        setCuratorHasChanges(true);
                        saveCookie("curator_id", data.account.id.toString(), 180);
                    }
                }),
            fetch('api/account/getCommandPendingRequest')
                .then(r => r.json())
                .then(data => {
                    setCommandPendingRequestID(data.commandPendingRequestId != null ? data.commandPendingRequestId.toString() : '');
                    var commandPendingRequestIDCookie = getCookie("command_pending_request_id");
                    if (commandPendingRequestIDCookie != null) {
                        if (data.commandPendingRequestId != null) {
                            if (parseInt(data.commandPendingRequestId) != parseInt(commandPendingRequestIDCookie)) {
                                setPendingRequestHasChanges(true);
                                saveCookie("command_pending_request_id", data.commandPendingRequestId.toString(), 180);
                            }
                        }
                    }
                    else if (data.commandPendingRequestId != null) {
                        setPendingRequestHasChanges(true);
                        saveCookie("command_pending_request_id", data.commandPendingRequestId.toString(), 180);
                    }
                }),
            fetch('api/account/getAccountBalance?accountId=' + account.id)
                .then(r => r.json())
                .then(data => {
                    if (data.balance != null) {
                        setBalance(data.balance);

                        var balanceCookie = getCookie("account_profile_balance");
                        if (balanceCookie != null) {
                            if (parseInt(data.balance) != parseInt(balanceCookie)) {
                                setBalanceHasChanges(true);
                                saveCookie("account_profile_balance", data.balance.toString(), 180);
                            }
                        }
                        else {
                            saveCookie("account_profile_balance", data.balance.toString(), 180);
                        }
                    }
                }),
            fetch('/api/binarReward/getBinarAccountByOwner?ownerAccountId=' + account.id)
                .then(res => res.json() as Promise<BinarAccount | null>)
                .then(data => {
                    if (data != null && data.leftBinarAccount != null && data.rightBinarAccount != null) {

                        Promise.all([
                            fetch('/api/binarReward/getBinarAccount?binarAccountID=' + data.leftBinarAccount.id)
                                .then(res => res.json() as Promise<BinarAccount | null>)
                                .then(left => { data.leftBinarAccount = left; }),
                            fetch('/api/binarReward/getBinarAccount?binarAccountID=' + data.rightBinarAccount.id)
                                .then(res => res.json() as Promise<BinarAccount | null>)
                                .then(right => { data.rightBinarAccount = right; })])
                            .then(() => {
                                //main numbers
                                var savedLeftSubtreeCount = parseInt(getCookie("leftSubtreeCount") || "0");
                                var savedLeftPtsValue = parseInt(getCookie("leftPtsValue") || "0");
                                var savedLeftLostPtsValue = parseInt(getCookie("leftLostPtsValue") || "0");
                                var savedRightSubtreeCount = parseInt(getCookie("rightSubtreeCount") || "0");
                                var savedRightPtsValue = parseInt(getCookie("rightPtsValue") || "0");
                                var savedRightLostPtsValue = parseInt(getCookie("rightLostPtsValue") || "0");
                                //child numbers - left
                                var savedLeftChildLeftPtsValue = parseInt(getCookie("leftChildLeftPtsValue") || "0");
                                var savedLeftChildLeftLostPtsValue = parseInt(getCookie("leftChildLeftLostPtsValue") || "0");
                                var savedLeftChildRightPtsValue = parseInt(getCookie("leftChildRightPtsValue") || "0");
                                var savedLeftChildRightLostPtsValue = parseInt(getCookie("leftChildRightLostPtsValue") || "0");
                                //child numbers - right
                                var savedRightChildLeftPtsValue = parseInt(getCookie("rightChildLeftPtsValue") || "0");
                                var savedRightChildLeftLostPtsValue = parseInt(getCookie("rightChildLeftLostPtsValue") || "0");
                                var savedRightChildRightPtsValue = parseInt(getCookie("rightChildRightPtsValue") || "0");
                                var savedRightChildRightLostPtsValue = parseInt(getCookie("rightChildRightLostPtsValue") || "0");

                                if (data.leftBinarAccount != null && data.rightBinarAccount != null) {
                                    setBinarChanges(
                                        savedLeftSubtreeCount - data.leftSubtreeCount != 0 ||
                                        savedLeftPtsValue - data.leftPtsValue != 0 ||
                                        savedLeftLostPtsValue - data.leftLostPtsValue != 0 ||
                                        savedRightSubtreeCount - data.rightSubtreeCount != 0 ||
                                        savedRightPtsValue - data.rightPtsValue != 0 ||
                                        savedRightLostPtsValue - data.rightLostPtsValue != 0 ||

                                        savedLeftChildLeftPtsValue - data.leftBinarAccount.leftPtsValue != 0 ||
                                        savedLeftChildLeftLostPtsValue - data.leftBinarAccount.leftLostPtsValue != 0 ||
                                        savedLeftChildRightPtsValue - data.leftBinarAccount.rightPtsValue != 0 ||
                                        savedLeftChildRightLostPtsValue - data.leftBinarAccount.rightLostPtsValue != 0 ||

                                        savedRightChildLeftPtsValue - data.rightBinarAccount.leftPtsValue != 0 ||
                                        savedRightChildLeftLostPtsValue - data.rightBinarAccount.leftLostPtsValue != 0 ||
                                        savedRightChildRightPtsValue - data.rightBinarAccount.rightPtsValue != 0 ||
                                        savedRightChildRightLostPtsValue - data.rightBinarAccount.rightLostPtsValue != 0
                                    );
                                }
                            });
                    }
                }),
            fetch('api/account/getSubscriptionInfo?accountId=' + account.id)
                .then(r => r.json())
                .then(data => {
                    if (data.subscriptionTo != null) {
                        setSubscriptionTo(data.subscriptionTo);
                        fetch('/api/payment/hasPendingPayments?accountId=' + account.id)
                            .then(p => p.json())
                            .then(data2 => {
                                setShowRenewal(parseInt(data2.hasPendingPayments) == 0);
                            });
                    }
                }),
            fetch('/api/payment/getPaymentSessionStatus?accountId=' + account.id)
                .then(res => res.json())
                .then(data => {
                    setPaymentSessionStatus(parseInt(data.status));
                }),
            fetch('/api/account/getCashbackInfo?accountId=' + account.id)
                .then(res => res.json())
                .then(data => {
                    setCashbackValue(parseInt(data.cashbackValue));

                    var cashbackValueCookie = getCookie("account_profile_cashback_value");
                    if (cashbackValueCookie != null) {
                        if (parseInt(data.cashbackValue) != parseInt(cashbackValueCookie)) {
                            setCashbackValueHasChanges(true);
                            saveCookie("account_profile_cashback_value", data.cashbackValue.toString(), 180);
                        }
                    }
                    else {
                        setCashbackValueHasChanges(true);
                        saveCookie("account_profile_cashback_value", data.cashbackValue.toString(), 180);
                    }
                }),
            fetch('/api/account/getSpeakershipRewardInfo?accountId=' + account.id)
                .then(res => res.json())
                .then(data => {
                    setSpeakershipRewardValue(parseInt(data.speakershipRewardValue));

                    var speakershipRewardValueCookie = getCookie("account_profile_speakership_reward_value");
                    if (speakershipRewardValueCookie != null) {
                        if (parseInt(data.speakershipRewardValue) != parseInt(speakershipRewardValueCookie)) {
                            setSpeakershipRewardValueHasChanges(true);
                            saveCookie("account_profile_speakership_reward_value", data.speakershipRewardValue.toString(), 180);
                        }
                    }
                    else {
                        setSpeakershipRewardValueHasChanges(true);
                        saveCookie("account_profile_speakership_reward_value", data.speakershipRewardValue.toString(), 180);
                    }
                }),
            fetch('/api/venue/getMyTickets')
                .then(res => res.json() as Promise<Ticket[]>)
                .then(data => {
                    setTickets(data);
                    setTicketGroups(data.filter(x => x.venue.showTicketsAsGroup == 0 || x.orderView == 0).map(x => {
                        return {
                            venue: x.venue,
                            account: x.account,
                            tariff: x.tariff,
                            paymentMethod: x.paymentMethod,
                            paymentDateTime: x.paymentDateTime,
                            cost: x.cost
                        }
                    }));
                })
        ];

        if (account.isActive > 0) {
            promises.push(fetch('/api/account/getProgressBarInfo')
                .then(res => res.json())
                .then(data => {
                    setFastStartLastDate(data.fastStartLastDate);
                    setUpgradeLastDate(data.upgradeLastDate);
                    setProgressBarsLoaded(true);
                }));
        }

        Promise.all(promises).then(() => {
            setUpdateDummy(x => x + 1);
        });
    }, [reloadAccountProfileDummy]);

    React.useEffect(() => {
        var eraCoinsCookie = getCookie("account_profile_era_coins");
        if (eraCoinsCookie != null) {
            if (account.eraCoins != parseInt(eraCoinsCookie)) {
                setERACoinsHasChanges(true);
                saveCookie("account_profile_era_coins", account.eraCoins.toString(), 180);
            }
        }
        else {
            saveCookie("account_profile_era_coins", account.eraCoins.toString(), 180);
        }
    }, []);

    React.useEffect(() => {
        if (seen) {
            setAccountProfileChanges(false);
        }
        else {
            setAccountProfileChanges(firstLineHasChanges || totalCountHasChanges || balanceHasChanges || cashbackValueHasChanges || eraCoinsHasChanges);
        }
    }, [firstLineHasChanges, totalCountHasChanges, balanceHasChanges, cashbackValueHasChanges, eraCoinsHasChanges, seen]);

    React.useEffect(() => {
        if (visible) {
            setSeen(true);
        }
    }, [visible]);

    React.useEffect(() => {
        if (selectedTicket != null) {
            startPolling(selectedTicket.id);
        }
    }, [selectedTicket]);

    const startPolling = async (id: number) => {
        try {
            const response = await fetch('/api/venue/getTicketStatus?ticketId=' + id);
            if (response.ok) {
                const ticketStatusData = await response.json();
                if (ticketStatusData.status == 0) {
                    window.setTimeout(() => startPolling(id), 1000);
                }
                else if (ticketStatusData.status == 7) {
                    setSelectedTicket(null);
                    fetch('/api/venue/getMyTickets')
                        .then(res => res.json() as Promise<Ticket[]>)
                        .then(data => {
                            setTickets(data);
                            setTicketGroups(data.filter(x => x.venue.showTicketsAsGroup == 0 || x.orderView == 0).map(x => {
                                return {
                                    venue: x.venue,
                                    account: x.account,
                                    tariff: x.tariff,
                                    paymentMethod: x.paymentMethod,
                                    paymentDateTime: x.paymentDateTime,
                                    cost: x.cost
                                }
                            }));
                            showPollingSuccessMessage();
                        });
                }
                else {
                    showPollingErrorMessage();
                }
            }
            else {
                showPollingErrorMessage();
            }
        }
        catch (error) {
            console.error(error);
            showPollingErrorMessage();
        }
    }

    const showPollingSuccessMessage = () => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({ title: "Активация билета", message: "Ваш билет успешно активирован.\nДобро пожаловать!", buttons: [{ text: "Понятно" }] });
        }
        else {
            alert("Ваш билет успешно активирован.\nДобро пожаловать!");
        }
    }

    const showPollingErrorMessage = () => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({ title: "Упс... Что-то пошло не так(", message: "Не удалось получить информацию о считывании билета. Попробуйте повторно зайти в приложение. В случае, если билет не активирован, обратитесь к Волонтёру.", buttons: [{ text: "Понятно" }] });
        }
        else {
            alert("Не удалось получить информацию о считывании билета. Попробуйте повторно зайти в приложение. В случае, если билет не активирован, обратитесь к Волонтёру.");
        }
    }

    const clickOnUnset = () => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: "Данные не указаны", message: "Ваши данные не указаны.\nДля заполнения данных обратитесь\nв Службу Заботы.",
                buttons: [
                    { id: 'go_to_service3', type: 'default', text: "Служба Заботы" },
                    { id: 'later', type: 'default', text: "Сделаю позже" },
                    { id: 'got_it', type: 'default', text: "Понятно" }]
            }, (id: string) => {
                if (id === 'go_to_service3') {
                    window.Telegram.WebApp.openTelegramLink('https://t.me/sib_era');
                }
            });
        }
        else {
            window.alert('Ваши данные не указаны.\nДля заполнения данных обратитесь\nв Службу Заботы.');
        }
    }

    const confirmCancelCommandPendingRequest = () => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: 'Подтвердите отмену запроса',
                message: 'Вы действительно хотите отменить запрос Куратору?',
                buttons: [
                    { id: 'confirm', type: 'default', text: 'Да, подтверждаю' },
                    { id: '', type: 'default', text: 'Нет' }
                ]
            }, (id: string) => {
                if (id === 'confirm') {
                    cancelCommandPendingRequest();
                }
            });
        }
        else {
            if (window.confirm('Вы действительно хотите отменить запрос Куратору?')) {
                cancelCommandPendingRequest();
            }
        }
    }

    const cancelCommandPendingRequest = () => {
        fetch('/api/account/cancelCommandPendingRequest?id=' + commandPendingRequestID)
            .then(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({
                        message: 'Запрос успешно отменён', buttons: [{ id: '', type: 'default', text: 'Понятно' }]
                    });
                }
                else {
                    window.alert('Запрос успешно отменён');
                }
                onReload();
            });
    }

    /*
     * <tr>
        <td><i className="fas fa-link" /> Моя ссылка для приглашений</td>
        <td><a href="#">Копировать ссылку</a></td>
       </tr>
     * 
     */

    const processReferralLink = () => {
        if (account.referralLink) {
            copyToClipboard('https://t.me/million_era_bot/club?startapp=' + account.referralLink,
                () => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: "Ссылка скопирована в буфер обмена.", buttons: [{ text: "Понятно" }] });
                    }
                    else {
                        alert("Ссылка скопирована в буфер обмена.");
                    }
                });
        }
        else {
            fetch('api/account/generateReferralLink?accountId=' + account.id)
                .then(res => res.json())
                .then(data => {
                    account.referralLink = data.linkValue;
                    copyToClipboard('https://t.me/million_era_bot/club?startapp=' + data.linkValue, () => {
                        if (productionMode) {
                            window.Telegram.WebApp.showPopup({ message: "Ссылка скопирована в буфер обмена.", buttons: [{ text: "Понятно" }] });
                        }
                        else {
                            alert("Ссылка скопирована в буфер обмена.");
                        }
                    });
                })
                .catch(() => {
                    alert('Ошибка получения реферральной ссылки. Пожалуйста, попробуйте позднее.');
                });
        }
    }

    return (
        <>
            {!nextAccount && <div className="account-profile" style={{ display: visible ? '' : 'none' }}>
                {(!showBinar && !showCuratorSelection) && <div className="account-profile-container">
                    <div className="account-profile-container-top">
                        {(progressBarsLoaded && subscriptionTo) && <div style={{ padding: '10px', width: '100%', height: '80%' }}>
                            {account.isActive > 0 && <table style={{ width: '100%', height: '30%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ fontSize: '10px' }}>Подписка</td>
                                        <td style={{ paddingLeft: '5px', paddingRight: '15px' }}><ProgressBar value={Math.max(0, Math.min(100, Math.round(100 * getDifferenceInDays(new Date(), new Date(subscriptionTo)) / getDaysInCurrentMonth())))}
                                            displayText={getDifferenceInDays(new Date(), new Date(subscriptionTo)) >= 0 ? getRemainingDaysTextRussian(getDifferenceInDays(new Date(), new Date(subscriptionTo))) + " " + getDifferenceInDays(new Date(), new Date(subscriptionTo)) + " " + getDaysTextRussian(getDifferenceInDays(new Date(), new Date(subscriptionTo))) : "Истекла " + makeDate(subscriptionTo)} /></td>

                                        <td style={{ fontSize: '10px' }}>Финансовая неделя</td>
                                        <td style={{ paddingLeft: '5px' }}><ProgressBar value={Math.max(0, Math.round(100 * getFinDay() / 7.0))}
                                            displayText={getRemainingDaysTextRussian(getFinDay()) + " " + getFinDay() + " " + getDaysTextRussian(getFinDay())} /></td>

                                    </tr>
                                    <tr>
                                        {account.isActive > 0 && <>
                                            <td style={{ fontSize: '10px' }}>Бонус быстрого старта</td>
                                            <td style={{ paddingLeft: '5px', paddingRight: '15px' }}><ProgressBar value={fastStartLastDate ? Math.max(0, 100 * getDifferenceInDays(new Date(), new Date(fastStartLastDate)) / 21.0) : 100}
                                                displayText={fastStartLastDate ? getDifferenceInDays(new Date(), new Date(fastStartLastDate)) >= 0 ? getRemainingDaysTextRussian(getDifferenceInDays(new Date(), new Date(fastStartLastDate))) + " " + getDifferenceInDays(new Date(), new Date(fastStartLastDate)) + " " + getDaysTextRussian(getDifferenceInDays(new Date(), new Date(fastStartLastDate))) : "Истёк " + makeDate(fastStartLastDate) : "Не начался"} /></td>
                                            <td style={{ fontSize: '10px' }}>Период апгрейда</td>
                                            {account.class < 30 && <td style={{ paddingLeft: '5px' }}><ProgressBar value={Math.max(0, Math.round(100 * getDifferenceInDays(new Date(), new Date(upgradeLastDate)) / 40.0))}
                                                displayText={getDifferenceInDays(new Date(), new Date(upgradeLastDate)) >= 0 ? getRemainingDaysTextRussian(getDifferenceInDays(new Date(), new Date(upgradeLastDate))) + " " + getDifferenceInDays(new Date(), new Date(upgradeLastDate)) + " " + getDaysTextRussian(getDifferenceInDays(new Date(), new Date(upgradeLastDate))) : "Истёк " + makeDate(upgradeLastDate)} /></td>}
                                            {account.class >= 30 && <td style={{ paddingLeft: '5px' }}><ProgressBar isSpecial={true} value={100} displayText="У Вас макс. тариф!" /></td>}
                                        </>}
                                        {account.isActive == 0 && <>
                                            <td style={{ fontSize: '10px' }}>Баллы сгорают</td>
                                            <td style={{ padding: '5px' }}><ProgressBar value={0} displayText={makeDate(subscriptionTo)} /></td>

                                            <td style={{ fontSize: '10px' }}>Кабинет сгорает</td>
                                            <td style={{ padding: '5px' }}><ProgressBar value={0} displayText={makeDate(subscriptionTo)} /></td>
                                        </>}
                                    </tr>
                                </tbody>
                            </table>}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '70%' }}>
                                <div className="account-profile-photo" style={{
                                    background:
                                        `url('${'https://pakhomov1907-001-site1.ctempurl.com/api/account/getAvatar?userId=' + account.id}') no-repeat center`,
                                    backgroundSize: 'cover'
                                }}>
                                    <img width={100} height={100} src={"https://pakhomov1907-001-site1.ctempurl.com/api/account/getLevelImage?level=" + account.level} />
                                </div>
                            </div>
                        </div>}
                        <div className="account-profile-panel">
                            <table style={{ width: '100%', height: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td className="account-profile-panel-name">{trimName(account.name)}</td>
                                        <td className="account-profile-panel-points" style={{ color: balanceHasChanges ? 'yellow' : 'inherit' }}><b>{balance}</b> у.е.</td>
                                    </tr>
                                    <tr>
                                        <td className="account-profile-panel-class">{getClassSingle(account.class)}</td>
                                        <td className="account-profile-panel-coins" style={{ color: cashbackValueHasChanges ? 'yellow' : 'inherit' }}><b>₸{formatMoney(cashbackValue)}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="account-profile-container-bottom">
                        <div className="account-profile-container-toggle">
                            <div className={viewMode == 1 ? "active" : ""} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => setViewMode(1)}>Личная информация</div>
                            <div className={viewMode == 2 ? "active" : ""} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => setViewMode(2)}>Работа с командой</div>
                            <div className={viewMode == 3 ? "active" : ""} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => setViewMode(3)}>Доход</div>
                        </div>
                        {viewMode == 1 && <div className="account-profile-rows">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <td><i className="fas fa-cake-candles" /></td>
                                        <td>Дата рождения</td>
                                        {account.birthday == null && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указана</td>}
                                        {account.birthday != null && <td>{makeAgeString(account.birthday)}</td>}
                                    </tr>
                                    <tr>
                                        <td><i className="fas fa-city" /></td>
                                        <td>Город</td>
                                        {!account.city && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указан</td>}
                                        {account.city && <td>{account.city}</td>}
                                    </tr>
                                    <tr>
                                        <td><i className="fas fa-briefcase" /></td>
                                        <td>Занятость</td>
                                        {!account.businessKind && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указана</td>}
                                        {account.businessKind && <td>{account.businessKind}</td>}
                                    </tr>
                                    <tr>
                                        <td><i className="fas fa-briefcase" /></td>
                                        <td>Ниша</td>
                                        {!account.businessSphere && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указана</td>}
                                        {account.businessSphere && <td>{account.businessSphere}</td>}
                                    </tr>
                                    <tr>
                                        <td><i className="fa-brands fa-instagram" /></td>
                                        <td>Instagram</td>
                                        {!account.instaProfile && <td className="account-profile-data-tbd" onClick={clickOnUnset}>Не указан</td>}
                                        {account.instaProfile && <td><a target="_blank" href={'https://instagram.com/' + account.instaProfile}>{account.instaProfile}</a></td>}
                                    </tr>
                                    <tr>
                                        <td><i className="fas fa-file-invoice-dollar" /></td>
                                        <td>Подписка до</td>
                                        <td><span style={{
                                            color: isSubscriptionExpiring && paymentSessionStatus != null ?
                                                paymentSessionStatus <= 0 ? 'rgba(255, 0, 0)' : paymentSessionStatus > 0 && paymentSessionStatus <= 2 ? 'rgb(235, 125, 40)' :
                                                    paymentSessionStatus > 2 && paymentSessionStatus < 7 ? 'rgba(204, 153, 0)' : 'initial' : 'initial',
                                            fontWeight: isSubscriptionExpiring ? 'bold' : 'initial'
                                        }}>
                                            {subscriptionTo.length > 0 ? makeDate(subscriptionTo) : '?'}</span>
                                            &nbsp;
                                            {showRenewal && <a href="#" onClick={(e) => { e.preventDefault(); onGoToRenewal(); }}>Продлить</a>}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                        {viewMode == 2 && <div className="account-profile-rows">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <td><i className="fas fa-link" /></td>
                                        <td>Пригласи друга</td>
                                        <td className="account-profile-clickable" onClick={processReferralLink}> Ссылка-приглашение</td>
                                    </tr>
                                    <tr>
                                        <td><CuratorSVG /></td>
                                        <td>Мой куратор</td>
                                        {commandPendingRequestID.length > 0 && <td><span style={{ backgroundColor: commandPendingRequestHasChanges ? 'yellow' : 'none' }}>Запрос отправлен <span className="account-profile-clickable" onClick={confirmCancelCommandPendingRequest}>Отменить</span></span></td>}
                                        {(commandPendingRequestID.length == 0 && curatorRef.current == null) &&
                                            <td className="account-profile-data-tbd" onClick={() => setShowCuratorSelection(true)}><span style={{ backgroundColor: commandPendingRequestHasChanges ? 'yellow' : 'none' }}>Выбрать</span></td>}
                                        {(commandPendingRequestID.length == 0 && curatorRef.current != null) &&
                                            <td className="account-profile-clickable" onClick={() => setNextAccount(curatorRef.current)}><span style={{ backgroundColor: curatorHasChanges ? 'yellow' : 'none' }}>{trimName(curatorRef.current.name)}</span></td>}
                                    </tr>
                                    <tr>
                                        <td><FirstLineSVG /></td>
                                        <td>1-я линия</td>
                                        <td className="account-profile-clickable" onClick={() => onShowClassic(1)}><span style={{ backgroundColor: firstLineHasChanges ? 'yellow' : 'none' }}>{commandInfo.firstLineCount !== '' ? <b>{commandInfo.firstLineCount}</b> : '?'} {getMembersCountTextRussian(commandInfo.firstLineCount)}
                                            {(commandInfo.incomingRequests !== '' && parseInt(commandInfo.incomingRequests) > 0) && <div className="incoming-requests-sticker">{commandInfo.incomingRequests}</div>}</span></td>
                                    </tr>
                                    <tr>
                                        <td><ClassicSVG /></td>
                                        <td>Классика</td>
                                        <td className="account-profile-clickable" onClick={() => onShowClassic(2)}><span style={{ backgroundColor: totalCountHasChanges ? 'yellow' : 'none' }}>{commandInfo.totalCount !== '' ? <b>{commandInfo.totalCount}</b> : '?'} {getMembersCountTextRussian(commandInfo.totalCount)}</span></td>
                                    </tr>
                                    <tr>
                                        <td><BinarSVG /></td>
                                        <td>Моя команда</td>
                                        <td className="account-profile-clickable" onClick={() => setShowBinar(true)}>Перейти к схеме{binarChanges && <div className="binar-changes-sticker"></div>}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                        {viewMode == 3 && <div className="account-profile-rows">
                            <table className="table table-striped" style={{ marginBottom: '5px' }}>
                                <tbody>
                                    <tr>
                                        <td><i className="fas fa-wallet" /></td>
                                        <td>Баланс</td>
                                        <td className="account-profile-clickable" onClick={onShowWalletTransactions}><span style={{ backgroundColor: balanceHasChanges ? 'yellow' : 'none' }} ><b>{balance}</b> у.е.</span></td>
                                    </tr>
                                    <tr>
                                        <td><i className="fas fa-money-bill-transfer" /></td>
                                        <td>Кэшбек*</td>
                                        <td className="account-profile-clickable" onClick={onShowCashbackTransactions}><span style={{ backgroundColor: cashbackValueHasChanges ? 'yellow' : 'none' }}><b>₸{formatMoney(cashbackValue)}</b></span></td>
                                    </tr>
                                    <tr>
                                        <td><i className="fas fa-microphone-lines" /></td>
                                        <td>За спикерство**</td>
                                        <td className="account-profile-clickable" onClick={onShowSpeakershipRewardTransactions}><span style={{ backgroundColor: speakershipRewardValueHasChanges ? 'yellow' : 'none' }}><b>${formatMoney(speakershipRewardValue)}</b></span></td>
                                    </tr>
                                    <tr>
                                        <td><i className="fas fa-coins" /></td>
                                        <td>Баланс ERA Coin***</td>
                                        <td><span style={{ backgroundColor: eraCoinsHasChanges ? 'yellow' : 'none' }}><b>{account.eraCoins || 0}</b> ERA Coin</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p className="account-profile-info-message" style={{ marginBottom: "5px" }}><a href="https://t.me/c/1639867222/28522/66883">* <span>Условия получения и использования кэшбека</span></a></p>
                            <p className="account-profile-info-message" style={{ marginBottom: "5px" }}><a href="https://t.me/c/1639867222/28522/66579">** <span>Условия получения и использования вознаграждений за спикерство</span></a></p>
                            <p className="account-profile-info-message" style={{ marginBottom: "5px" }}><a href="https://t.me/sib_era">*** <span>Условия получения и использования ERA Coin</span></a></p>
                        </div>}
                        {viewMode == 4 && <div className="account-profile-rows">
                            {selectedVenue == null && <table className="table table-striped" style={{ marginBottom: '5px' }}>
                                <tbody>
                                    {ticketGroups.length > 0 ? ticketGroups.map((t, i) => (
                                        <tr onClick={() => setSelectedVenue(t.venue)}>
                                            <td style={{ verticalAlign: 'middle', width: '10%' }}><i className="fas fa-ticket" /></td>
                                            <td style={{ verticalAlign: 'middle' }}>{t.venue.name} ({makePeriod(t.venue.dateStart, t.venue.dateEnd)}), тариф: {t.tariff.name}</td>
                                            <td style={{ verticalAlign: 'middle', width: '5%' }}><i className="fas fa-chevron-right" /></td>
                                        </tr>
                                    )) : <tr>
                                        <td colSpan={3}>Здесь пока нет билетов.</td>
                                    </tr>}
                                </tbody>
                            </table>}
                            {selectedVenue != null && <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', left: '10px', top: '10px' }} onClick={() => setSelectedVenue(null)}><i className="fas fa-chevron-left" /></div>
                                <div style={{ width: '100%', padding: '10px 20px', textAlign: 'center', fontWeight: 'bold' }}>{selectedVenue.name} ({makePeriod(selectedVenue.dateStart, selectedVenue.dateEnd)})</div>
                                <table className="table table-striped" style={{ marginBottom: '5px', fontSize: '14px' }}>
                                    <tbody>
                                        {tickets.map((t, i) => (
                                            <tr onClick={() => {
                                                if (i > 0 && tickets[i - 1].status == 0) {
                                                    if (productionMode) {
                                                        window.Telegram.WebApp.showPopup({
                                                            title: "Билет не активен", message: "Данный билет будет доступен только после активации предыдущего билета.",
                                                            buttons: [{ text: "Понятно" }]
                                                        });
                                                    }
                                                    else {
                                                        window.alert("Данный билет будет доступен только после активации предыдущего билета.");
                                                    }
                                                }
                                                else if (t.status == 7) {
                                                    if (productionMode) {
                                                        window.Telegram.WebApp.showPopup({
                                                            title: "Билет не активен", message: "Данный билет уже использован.",
                                                            buttons: [{ text: "Понятно" }]
                                                        });
                                                    }
                                                    else {
                                                        window.alert("Данный билет уже использован.");
                                                    }
                                                }
                                                else {
                                                    setSelectedTicket(t);
                                                }
                                            }}>
                                                <td style={{ verticalAlign: 'middle', width: '10%', color: t.status == 7 ? 'green' : 'initial' }} className={i > 0 && tickets[i - 1].status == 0 ? 'ticket-inactive' : ''}>
                                                    <i className={t.status == 7 ? "fas fa-circle-check" : "fas fa-ticket"} /></td>
                                                <td style={{ verticalAlign: 'middle' }} className={(i > 0 && tickets[i - 1].status == 0) || t.status == 7 ? 'ticket-inactive' : ''}>{t.title} {t.status == 7 ? "(активирован)" : ""}</td>
                                                <td style={{ verticalAlign: 'middle', width: '10%', fontSize: '20px' }} className={(i > 0 && tickets[i - 1].status == 0) || t.status == 7 ? 'ticket-inactive' : ''}><i className="fas fa-qrcode" /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                        </div>}
                    </div>
                </div>}
                {
                    (showBinar && !selectedBinarForPts) && <BinarView homeUserRef={account} homeUserId={account.id} homeUserName={account.humanName} rootUserId={currentAccountForBinarViewId} onBack={() => setShowBinar(false)}
                        onSelectBinarAccount={setCurrentAccountForBinarViewId}
                        onShowBinarPts={setSelectedBinarForPts}
                        productionMode={productionMode} />
                }
                {
                    showCuratorSelection && <CuratorSelection productionMode={productionMode} onAccountClick={onAccountClick}
                        onClose={() => setShowCuratorSelection(false)}
                        onRequest={onReload} />
                }
                {
                    selectedBinarForPts != null && <BinarPtsTransactionsView homeUserRef={account} productionMode={productionMode} homeUserId={account.id} homeUserName={account.humanName}
                        rootBinarAccount={selectedBinarForPts} onBack={() => setSelectedBinarForPts(null)} onReloadView={setSelectedBinarForPts} />
                }
            </div >}
            {nextAccount != null && <AccountView productionMode={productionMode} currentUser={account} account={nextAccount} onClose={() => setNextAccount(null)} />}
            {selectedTicket != null && <QRCodeComponent value={selectedTicket.id.toString()} header={selectedTicket.title} onClose={() => setSelectedTicket(null)} />}
        </>
    );
};

export default AccountProfile;
