import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, MillionaireMorningItem, Reflection } from '../../store/Models';
import { getReactionInfo, founderId } from '../../utils';
import ReactionsMenu from './ReactionsMenu';
import LongPressMenu from './LongPressMenu';
import FounderChannelMessageComponent from '../FounderChannelMessage';

interface FounderChannelLongPressMenuProps {
    visible: boolean;
    founderChannelMessage: Reflection;
    isFirst: boolean;
    isSecond: boolean;
    user: Account;
    productionMode: boolean;
    onStartEdit: () => void;
    onDelete: () => void;
    onClose: () => void;
}

const FounderChannelLongPressMenu: React.FC<FounderChannelLongPressMenuProps> = ({ visible, founderChannelMessage, isFirst, isSecond, user,
    productionMode, onStartEdit, onDelete, onClose }) => {
    if (!visible) return null;

    const setReaction = (reactionCode: number) => {
        let reactionInfo = getReactionInfo(founderChannelMessage.reactionsInfo, reactionCode);

        var formData = new FormData();
        formData.append('PostID', founderChannelMessage.post.id.toString());
        formData.append('ReactionCode', reactionCode.toString());

        //if my, decrease code value, else increase
        if (reactionInfo != null) {
            if (reactionInfo.my > 0) {
                founderChannelMessage.reactionsInfo =
                    founderChannelMessage.reactionsInfo.replace(`${reactionCode}=${reactionInfo.count}/${reactionInfo.my}`, `${reactionCode}=${reactionInfo.count - 1}/0`);
            }
            else {
                founderChannelMessage.reactionsInfo =
                    founderChannelMessage.reactionsInfo.replace(`${reactionCode}=${reactionInfo.count}/${reactionInfo.my}`, `${reactionCode}=${reactionInfo.count + 1}/1`);
            }
        }
        else {
            if (founderChannelMessage.reactionsInfo.length > 0) {
                founderChannelMessage.reactionsInfo += `;${reactionCode}=1/1`;
            }
            else {
                founderChannelMessage.reactionsInfo = `${reactionCode}=1/1`;
            }
        }

        fetch(reactionInfo != null && reactionInfo.my > 0 ? 'api/reflection/dropReaction' : 'api/reflection/setReaction', {
            method: 'POST',
            body: formData
        });
    }

    return (
        <div className="focus-longpress-menu" onClick={onClose}>
            <div style={{ 'position': 'relative', 'top': '25%', 'marginLeft': '15px' }} onClick={(e) => { e.stopPropagation(); }}>
                <ReactionsMenu visible={true} my={founderChannelMessage.post.publishAccount.id === user.id}
                    currentUserId={user.id}
                    productionMode={productionMode}
                    onSetReaction={(r) => { setReaction(r); onClose(); }} />
                <FounderChannelMessageComponent currentUser={user} founderChannelMessage={founderChannelMessage}
                    isFirst={isFirst}
                    isSecond={isSecond}
                    isExpanded={false}
                    productionMode={productionMode}
                    onContextMenu={() => { }}
                    onReactionContextMenu={() => { }}
                    onTouchMove={() => { }}
                    onTouchStop={() => { }}
                    onAccountClick={() => { }}
                    onExpand={() => { }}
                    onRead={() => { }}
                />
                <LongPressMenu visible={true} my={founderChannelMessage.post.publishAccount.id === user.id} replyTask={founderChannelMessage.replyTask}
                    visibilityMode={founderChannelMessage.visibilityMode}
                    onWriteReply={() => { }}
                    onStartEdit={() => { onStartEdit(); onClose(); }}
                    onDelete={() => { onDelete(); onClose(); }}
                    postId={founderChannelMessage.post.id}
                    canReply={false}
                    hasReactions={founderChannelMessage.reactionsInfo.length > 0}
                />
            </div>
        </div>
    );
};

export default FounderChannelLongPressMenu;
