import * as React from 'react';
import { getReactionByCode } from '../../../../utils';

interface ReactionProps {
    code: number;
}

const Reaction: React.FC<ReactionProps> = ({ code }) => {
    if (code >= 10000) {
        return (
            <img src={`assets/banners/founder${code}.png`} width="16" height="16" style={{ marginLeft: '5px' }} />
            )
    }
    else {
        return <span style={{ marginLeft: '3px' }}>{getReactionByCode(code)}</span>;
    }
};

export default Reaction;
