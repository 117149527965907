import * as React from 'react';
import { founderId } from '../../utils';

interface ReactionsMenuProps {
    visible: boolean;
    my: boolean;
    currentUserId: number;
    productionMode: boolean;
    onSetReaction: (reactionCode: number) => void;
}

const ReactionsMenu: React.FC<ReactionsMenuProps> = ({ visible, my, currentUserId, productionMode, onSetReaction }) => {
    if (!visible) return null;

    const reactionStyle: React.CSSProperties = {
        cursor: 'pointer',
        float: 'left',
        paddingLeft: '3px'
    };

    const lastReactionStyle: React.CSSProperties = {
        cursor: 'pointer',
        paddingLeft: '3px',
        paddingRight: '7px'
    }

    const founderReactionStyle: React.CSSProperties = {
        cursor: 'pointer',
        float: 'left',
        paddingLeft: '6px'
    }

    const panelRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (panelRef.current != null) {
            panelRef.current.style.marginTop = (-panelRef.current.clientHeight - 10) + 'px';
        }
    }, [visible]);

    const checkFounderReaction = (event: React.MouseEvent, reactionCode: number) => {
        if (currentUserId != founderId) {
            event.stopPropagation();
            if (productionMode) {
                window.Telegram.WebApp.showPopup({
                    title: "Упс... Недоступно!", message: "Данным набором эмодзи может пользоваться только основатель Клуба Ерлан Ахметов.", buttons: [{ text: "Понятно" }]
                });
            }
            else {
                window.alert("Упс... Недоступно! Данным набором эмодзи может пользоваться только основатель Клуба Ерлан Ахметов.");
            }
        }
        else {
            onSetReaction(reactionCode);
        }
    }

    const showFounderReactions = true;

    return (
        <div ref={panelRef} style={{
            'fontSize': '24px', 'background': 'white', 'borderRadius': '20px', 'position': 'absolute',
            right: my ? '10px' : '40px', 'paddingTop': '3px', 'paddingBottom': '3px'
        }}>
            <table style={{ margin: '0 5px' }}>
                <tr>
                    <td style={{ textAlign: 'center' }}><div onClick={() => onSetReaction(0)}>👍</div></td>
                    <td style={{ textAlign: 'center' }}><div onClick={() => onSetReaction(1)}>🔥</div></td>
                    <td style={{ textAlign: 'center' }}><div onClick={() => onSetReaction(3)}>😂</div></td>
                    <td style={{ textAlign: 'center' }}><div onClick={() => onSetReaction(2)}>😁</div></td>
                    <td style={{ textAlign: 'center' }}><div onClick={() => onSetReaction(4)}>❤️</div></td>
                    <td style={{ textAlign: 'center' }}><div onClick={() => onSetReaction(5)}>❤️‍🔥</div></td>
                </tr>
                {showFounderReactions && <>
                    <tr>
                        <td><div onClick={(e) => checkFounderReaction(e, 10000)}><img src="assets/banners/founder100/10000.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10001)}><img src="assets/banners/founder100/10001.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10002)}><img src="assets/banners/founder100/10002.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10003)}><img src="assets/banners/founder100/10003.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10004)}><img src="assets/banners/founder100/10004.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10005)}><img src="assets/banners/founder100/10005.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                    </tr>
                    <tr>
                        <td><div onClick={(e) => checkFounderReaction(e, 10006)}><img src="assets/banners/founder100/10006.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10007)}><img src="assets/banners/founder100/10007.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10008)}><img src="assets/banners/founder100/10008.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10009)}><img src="assets/banners/founder100/10009.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10010)}><img src="assets/banners/founder100/10010.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10011)}><img src="assets/banners/founder100/10011.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                    </tr>
                    <tr>
                        <td><div onClick={(e) => checkFounderReaction(e, 10012)}><img src="assets/banners/founder100/10012.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10013)}><img src="assets/banners/founder100/10013.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10014)}><img src="assets/banners/founder100/10014.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10015)}><img src="assets/banners/founder100/10015.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10016)}><img src="assets/banners/founder100/10016.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                        <td><div onClick={(e) => checkFounderReaction(e, 10017)}><img src="assets/banners/founder100/10017.png" width="30" height="30" style={{ margin: '0 0 5px 5px', imageRendering: 'auto' }} /></div></td>
                    </tr>
                </>}
            </table>
        </div>
    )
};

export default ReactionsMenu;
