import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, ClassicShortLine, PaymentReward } from '../../../../../store/Models';
import { getRewardName, getRewardValueByCode, subscriptionsInfo } from '../../../../../subscriptions';
import { makeDateTime } from '../../../../../utils';
import Autocomplete from '../../simple/Autocomplete';

interface EvenSaleRewardProps {
    onBack: () => void;
    accounts: Account[];
}

const ESR: React.FC<EvenSaleRewardProps> = ({ onBack, accounts }) => {
    const fetch = useFetch();

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);

    const [firstLine, setFirstLine] = React.useState<Account[]>([]);

    const [operationType1, setOperationType1] = React.useState<string>('');
    const [operationCode1, setOperationCode1] = React.useState<string>('');
    const [initiator1, setInitiator1] = React.useState<Account | null>(null);
    const [rewardValue1, setRewardValue1] = React.useState<number>(0);

    const [operationType2, setOperationType2] = React.useState<string>('');
    const [operationCode2, setOperationCode2] = React.useState<string>('');
    const [initiator2, setInitiator2] = React.useState<Account | null>(null);
    const [rewardValue2, setRewardValue2] = React.useState<number>(0);

    const operationTypes = [
        { label: 'Вступление', value: 'INITIAL' },
        { label: 'Апгрейд', value: 'UPGRADE' },
        { label: 'Вступление + Апгрейд', value: 'X' }
    ]

    const operationCodes = [
        'EXCLUSIVE_INITIAL',
        'BASIC_INITIAL',
        'STANDARD_INITIAL',
        'STANDARD_PLUS_INITIAL',
        'BUSINESS_INITIAL',

        'BUSINESS_FROM_STANDARD_PLUS_UPGRADE',
        'BUSINESS_FROM_STANDARD_UPGRADE',
        'BUSINESS_FROM_BASIC_UPGRADE',
        'BUSINESS_FROM_EXCLUSIVE_UPGRADE',

        'STANDARD_PLUS_FROM_STANDARD_UPGRADE',
        'STANDARD_PLUS_FROM_BASIC_UPGRADE',
        'STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE',
        'STANDARD_FROM_BASIC_UPGRADE',
        'STANDARD_FROM_EXCLUSIVE_UPGRADE',
        'BASIC_FROM_EXCLUSIVE_UPGRADE',

        'X_BUSINESS_FROM_STANDARD_PLUS_UPGRADE',
        'X_BUSINESS_FROM_STANDARD_UPGRADE',
        'X_BUSINESS_FROM_BASIC_UPGRADE',
        'X_BUSINESS_FROM_EXCLUSIVE_UPGRADE',
        'X_STANDARD_PLUS_FROM_STANDARD_UPGRADE',
        'X_STANDARD_PLUS_FROM_BASIC_UPGRADE',
        'X_STANDARD_PLUS_FROM_EXCLUSIVE_UPGRADE',
        'X_STANDARD_FROM_BASIC_UPGRADE',
        'X_STANDARD_FROM_EXCLUSIVE_UPGRADE',
        'X_BASIC_FROM_EXCLUSIVE_UPGRADE'
    ];

    const validate = (): boolean => {
        return selectedAccount != null && operationCode1.length > 0 && initiator1 != null && rewardValue1 > 0 &&
            operationCode2.length > 0 && initiator2 != null && rewardValue2 > 0;
    }

    const clearForm = () => {
        setRewardValue2(0);
        setInitiator2(null);
        setOperationCode2('');
        setOperationType2('');

        setRewardValue1(0);
        setInitiator1(null);
        setOperationCode1('');
        setOperationType1('');

        setSelectedAccount(null);
    }

    const createEvenSaleReward = () => {
        //send reward and return back
        if (selectedAccount != null && initiator1 != null && initiator2 != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('OperationCode1', operationCode1);
            formData.append('InitiatorID1', initiator1.id.toString());
            formData.append('OperationCode2', operationCode2);
            formData.append('InitiatorID2', initiator2.id.toString());
            formData.append('RewardValue', Math.min(rewardValue1, rewardValue2).toString());
            fetch('api/reward/processEvenSaleReward2', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    onBack();
                })
                .catch(() => {
                    window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                });
        }
    }

    React.useEffect(() => {
        if (selectedAccount != null) {
            fetch('/api/challenge/getFirstLineByAccount?id=' + selectedAccount.id)
                .then(res => res.json() as Promise<ClassicShortLine[]>)
                .then((data) => {
                    setFirstLine(data.map(x => x.account));
                });
        }
        else setFirstLine([]);
    }, [selectedAccount]);

    return (
        <>
            <div className="reg-module-view-header"><b>НАЧИСЛИТЬ ЧЁТНУЮ ПРОДАЖУ</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block nomargin">
                    <div className="reg-module-label">Резидент (ID/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите Резидента" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearForm}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount && <div className="reg-module-caption">Продажа 1</div>}
                {selectedAccount && <div>
                    <div className="reg-module-label">За кого</div>
                    <div>
                        <Autocomplete options={firstLine.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите, за кого начислить продажу 1" customValuesAllowed={false}
                            onValueSelected={(v) => setInitiator1(accounts.filter(a => a.id.toString() === v)[0])} />
                    </div>
                </div>}
                {selectedAccount && <div>
                    <div className="reg-module-label">Тип операции</div>
                    <div>
                        <Autocomplete options={operationTypes}
                            placeholder="Выберите тип операции 1" customValuesAllowed={false}
                            onValueSelected={setOperationType1} />
                    </div>
                </div>}
                {(selectedAccount && operationType1.length > 0) && <div>
                    <div className="reg-module-label">Операция</div>
                    <div>
                        <Autocomplete options={operationCodes.filter(op => {
                            if (operationType1 === 'X') {
                                return op.startsWith('X_');
                            }
                            else {
                                return !op.startsWith('X_') && op.endsWith(operationType1);
                            }
                        }).map(op => {
                            return {
                                label: getRewardName(op),
                                value: op
                            }
                        })}
                            placeholder="Выберите операцию 1" customValuesAllowed={false}
                            onValueSelected={(op) => {
                                setOperationCode1(op);
                                setRewardValue1(getRewardValueByCode(op));
                            }} />
                    </div>
                </div>}

                {(selectedAccount && initiator1 != null && operationCode1.length > 0) && <div className="reg-module-caption">Продажа 2</div>}
                {(selectedAccount && initiator1 != null && operationCode1.length > 0) && <div>
                    <div className="reg-module-label">За кого</div>
                    <div>
                        <Autocomplete options={firstLine.filter(x => x.id != initiator1.id).map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите, за кого начислить продажу 2" customValuesAllowed={false}
                            onValueSelected={(v) => setInitiator2(accounts.filter(a => a.id.toString() === v)[0])} />
                    </div>
                </div>}
                {(selectedAccount && initiator1 != null && operationCode1.length > 0) && <div>
                    <div className="reg-module-label">Тип операции</div>
                    <div>
                        <Autocomplete options={operationTypes}
                            placeholder="Выберите тип операции 2" customValuesAllowed={false}
                            onValueSelected={setOperationType2} />
                    </div>
                </div>}
                {(selectedAccount && initiator1 != null && operationCode1.length > 0 && operationType2.length > 0) && <div>
                    <div className="reg-module-label">Операция</div>
                    <div>
                        <Autocomplete options={operationCodes.filter(op => {
                            if (operationType2 === 'X') {
                                return op.startsWith('X_');
                            }
                            else {
                                return !op.startsWith('X_') && op.endsWith(operationType2);
                            }
                        }).map(op => {
                            return {
                                label: getRewardName(op),
                                value: op
                            }
                        })}
                            placeholder="Выберите операцию 2" customValuesAllowed={false}
                            onValueSelected={(op) => {
                                setOperationCode2(op);
                                setRewardValue2(getRewardValueByCode(op));
                            }} />
                    </div>
                </div>}
                
                {(selectedAccount && rewardValue1 > 0 && rewardValue2 > 0) && <div className="reg-module-block">
                    <div className="reg-module-caption">Сумма вознаграждения за выбранную пару:</div>
                    <div className="reg-module-caption" style={{ color: 'green', fontSize: '24px' }}>
                        {Math.min(rewardValue1, rewardValue2)} у.е.
                    </div>
                </div>}

                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={createEvenSaleReward}>Начислить</button>
                </div>
            </div>
        </>
    )
};

export default ESR;
