import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account } from '../../store/Models';
import { getPref, getTransactionDescription, makeDateTime, setPref, trimName } from '../../utils';
import AccountAvatar from './AccountAvatar';
import Switcher from './components/simple/Switcher';
import IncomeSVG from './svg/IncomeSVG';
import MissedSVG from './svg/MissedSVG';
import SpendSVG from './svg/SpendSVG';

interface WalletTransactionsViewProps {
    productionMode: boolean;
    currentUser: Account;
    onAccountClick: (account: Account) => void;
    onClose: () => void;
}

interface WalletTransaction {
    id: number;
    value: number;
    transactionDateTime: string;
    initiatorAccount: Account;
    transactionTypeCode: number;
    pointType: string | null;
}

const WalletTransactionsView: React.FC<WalletTransactionsViewProps> = ({ productionMode, currentUser, onAccountClick, onClose }) => {
    const fetch = useFetch();

    const [walletTransactions, setWalletTransactions] = React.useState<WalletTransaction[]>([]);
    const [walletTransactionsCurrent, setWalletTransactionsCurrent] = React.useState<WalletTransaction[]>([]);

    const [balance, setBalance] = React.useState<number>(0);
    const [avail, setAvail] = React.useState<number>(0);
    const [exp, setExp] = React.useState<number>(0);
    const [miss, setMiss] = React.useState<number>(0);

    const [lostPtsSwitcherState, setLostPtsSwitcherState] = React.useState<number>(parseInt(getPref(currentUser.prefs, "WALLET_HIDE_LOST_PTS") || "0"));

    const [currentFilter, setCurrentFilter] = React.useState<string>(''); //avail, exp, miss

    const onSelectLostPtsSwitcherState = (value: number) => {
        if (currentUser.class < 30) {
            if (productionMode) {
                window.Telegram.WebApp.showPopup({ title: "Упс... Доступ ограничен)", message: "Данная функция работает только у Резидентов на тарифе Бизнес и выше.", buttons: [{ text: "Понятно" }] });
            }
            else {
                alert("Данная функция работает только у Резидентов на тарифе Бизнес и выше.");
            }
        }
        else {
            setLostPtsSwitcherState(value);
            currentUser.prefs = setPref(currentUser.prefs, "WALLET_HIDE_LOST_PTS", value.toString());
            fetch('/api/account/setPrefs?newValue=' + setPref(currentUser.prefs, "WALLET_HIDE_LOST_PTS", value.toString()));
        }
    }

    React.useEffect(() => {
        fetch('/api/account/getWalletTransactions')
            .then(r => r.json() as Promise<WalletTransaction[]>)
            .then(data => {
                setWalletTransactions(data);
                setWalletTransactionsCurrent(data);
                setAvail(data.filter(x => x.pointType === 'cp' || (x.value < 0 && ![-7, -11].includes(x.transactionTypeCode))).reduce((accumulator, y) => { return accumulator + y.value }, 0));
                setExp(data.filter(x => x.pointType === 'ecp' || (x.value < 0 && [-7, -11].includes(x.transactionTypeCode))).reduce((accumulator, y) => { return accumulator + y.value }, 0));
                setMiss(data.filter(x => x.pointType === 'ncp').reduce((accumulator, y) => { return accumulator + y.value }, 0));
            });
        fetch('api/account/getAccountBalance?accountId=' + currentUser.id)
            .then(r => r.json())
            .then(data => {
                if (data.balance != null) {
                    setBalance(data.balance);
                }
            });
    }, []);

    React.useEffect(() => {
        switch (currentFilter) {
            case '':
                setWalletTransactionsCurrent(walletTransactions);
                break;
            case 'avail':
                setWalletTransactionsCurrent(walletTransactions.filter(x => x.pointType === 'cp' || (x.value < 0 && ![-7, -11].includes(x.transactionTypeCode)))); //+расходные, кроме возвратов
                break;
            case 'exp':
                setWalletTransactionsCurrent(walletTransactions.filter(x => x.pointType === 'ecp' || (x.value < 0 && [-7, -11].includes(x.transactionTypeCode)))); //+возвраты
                break;
            case 'miss':
                setWalletTransactionsCurrent(walletTransactions.filter(x => x.pointType === 'ncp'));
                break;
        }
    }, [currentFilter]);

    return (
        <div className="wallet-transactions-view">
            <div className="wallet-transactions-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
            <div className="wallet-transactions-view-header"><b>ИСТОРИЯ ОПЕРАЦИЙ</b></div>
            <div className={currentFilter.length == 0 ? "wallet-transactions-current-balance active" : "wallet-transactions-current-balance"} onClick={() => setCurrentFilter('')}>Баланс: <b>{balance}</b></div>
            <div className="wallet-transactions-balance-contents">
                <div className={currentFilter === 'avail' ? "wallet-transactions-balance-cp active" : "wallet-transactions-balance-cp"} onClick={() => setCurrentFilter('avail')}><em>Доступно:</em><br /><b>{avail}</b></div>
                <div className={currentFilter === 'exp' ? "wallet-transactions-balance-ecp active" : "wallet-transactions-balance-ecp"} onClick={() => setCurrentFilter('exp')}><em>Ожидается:</em><br /><b>{exp}</b></div>
                <div className={currentFilter === 'miss' ? "wallet-transactions-balance-ncp active" : "wallet-transactions-balance-ncp"} onClick={() => setCurrentFilter('miss')}><em>Упущено:</em><br /><b><span>{lostPtsSwitcherState == 1 ? 0 : miss}</span></b></div>
            </div>
            <div className="wallet-lost-pts-switcher">
                <div className="wallet-lost-pts-switcher-caption">Скрыть сумму упущенных у.е.</div>
                <div className="wallet-lost-pts-switcher-element"><Switcher initialState={lostPtsSwitcherState} onSelect={onSelectLostPtsSwitcherState} /></div>
            </div>
            <div className="wallet-transactions-view-table-container">
                {walletTransactionsCurrent.length > 0 && <table className="table">
                    <tbody>
                        {walletTransactionsCurrent.map((item, i) =>
                            <React.Fragment>
                                <tr>
                                    <td rowSpan={2}>{
                                        item.pointType === 'ncp' ? <div className="missed"><MissedSVG /></div> :
                                            item.value >= 0 ? <div className="income"><IncomeSVG /></div>
                                                : item.value < 0 ? <div className="spend"><SpendSVG /></div> : ''}</td>
                                    <td className="wallet-transaction-type">
                                        {getTransactionDescription(item.transactionTypeCode)}
                                    </td>
                                    <td className="wallet-transaction-value">
                                        {item.pointType === 'ncp' ?
                                            <span className="wallet-transaction-value-missed">{item.value}</span> :
                                            item.value >= 0 ?
                                                <span className="wallet-transaction-value-positive">+{item.value}</span> :
                                                <b>{item.value}</b>}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className="wallet-transaction-second-row">
                                            <div className="wallet-transaction-second-row-datetime">
                                                {makeDateTime(item.transactionDateTime)}
                                            </div>
                                            <div className="wallet-transaction-second-row-initiator">
                                                <div style={{ display: 'flex', float: 'right' }}><AccountAvatar size={19} account={item.initiatorAccount} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.initiatorAccount) }}>{trimName(item.initiatorAccount.name)}</span></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>)}
                    </tbody>
                </table>}
                {walletTransactionsCurrent.length == 0 && <div style={{ width: "100%", textAlign: 'center' }}>Здесь пока нет операций.</div>}
            </div>
        </div>
    )
};

export default WalletTransactionsView;
