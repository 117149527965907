﻿import * as React from 'react';
import { Ticket, TicketGroup } from '../../../../../store/Models';
import useFetch from '../../../../../hooks/useFetch';
import { formatMoney, makeDateTime, makePeriod } from '../../../../../utils';

interface AdminVenueTicketsManagementProps {
    productionMode: boolean;
    onAddRecord: () => void;
}

const AdminVenueTicketsManagement: React.FC<AdminVenueTicketsManagementProps> = ({ productionMode, onAddRecord }) => {
    const fetch = useFetch();

    const [ticketGroups, setTicketGroups] = React.useState<TicketGroup[]>([]);

    React.useEffect(() => {
        fetch('/api/venue/getTickets')
            .then(res => res.json() as Promise<Ticket[]>)
            .then(data => {
                setTicketGroups(data.filter(x => x.venue.showTicketsAsGroup == 0 || x.orderView == 0).map(x => {
                    return {
                        venue: x.venue,
                        account: x.account,
                        tariff: x.tariff,
                        paymentMethod: x.paymentMethod,
                        paymentDateTime: x.paymentDateTime,
                        cost: x.cost
                    }
                }));
            });
    }, []);

    return (
        <div className="payment-approval-container">
            <button style={{ marginTop: '10px', marginBottom: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={onAddRecord}>Добавить покупку</button>
            <div style={{ height: "calc(100% - 71.3px)", overflowY: 'auto' }}>
                {ticketGroups.length > 0 && <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                    <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                        <tr>
                            <th style={{ textAlign: 'center' }} ><i className="fas fa-calendar-days" /></th>
                            <th>Владелец</th>
                            <th>Мероприятие</th>
                            <th>Тариф</th>
                            <th>Цена</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ticketGroups.length > 0 ? ticketGroups.map((t, i) => (
                            <tr>
                                <td>{makeDateTime(t.paymentDateTime)}</td>
                                <td>{t.account.humanName}</td>
                                <td>{t.venue.name} ({makePeriod(t.venue.dateStart, t.venue.dateEnd)})</td>
                                <td>{t.tariff.name}</td>
                                <td>{t.paymentMethod === 'Sberbank' ? '₽' : '₸'}{formatMoney(t.cost)}</td>
                            </tr>
                        )) : <tr>
                            <td colSpan={5}>Здесь пока нет покупок.</td>
                        </tr>}
                    </tbody>
                </table>}
            </div>
        </div>
    )
};

export default AdminVenueTicketsManagement;
