import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account } from '../../../../../store/Models';
import PersonalMessageForm from './forms/PersonalMessageForm';

interface AdminPersonalMessageManagementProps {
    productionMode: boolean;
    onBack: () => void;
}

interface PersonalMessagesInfo {
    affirmations: number;
    letters: number;
    thoughts: number;
    quotes: number;
    humors: number;
    total: number;
}

interface PersonalMessageUsage {
    account: Account;
    currentLap: number;
    currentLapProgress: number;
}

const AdminPersonalMessageManagement: React.FC<AdminPersonalMessageManagementProps> = ({ productionMode, onBack }) => {
    const fetch = useFetch();

    const [selectedScreen, setSelectedScreen] = React.useState<number>(0);
    const [personalMessagesInfo, setPersonalMessagesInfo] = React.useState<PersonalMessagesInfo | null>(null);
    const [personalMessageUsages, setPersonalMessageUsages] = React.useState<PersonalMessageUsage[]>([]);

    React.useEffect(() => {
        fetch('/api/admin/getPersonalMessagesInfo')
            .then(res => res.json() as Promise<PersonalMessagesInfo>)
            .then((data) => {
                setPersonalMessagesInfo(data);
                fetch('/api/admin/getPersonalMessageUsages')
                    .then(res => res.json() as Promise<PersonalMessageUsage[]>)
                    .then((data2) => {
                        setPersonalMessageUsages(data2);
                    });
            });
    }, []);

    return (
        <>
            {selectedScreen == 0 && <div className="payment-approval-container">
                <div className="reg-module-view-header"><b>ФРАЗЫ ДНЯ</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>

                {personalMessagesInfo != null &&
                    <table className="table table-striped payment-approval-table" style={{ marginBottom: '5px', fontSize: '10px' }}>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }}>Аффирмации</th>
                                <th style={{ textAlign: 'center' }}>Послания</th>
                                <th style={{ textAlign: 'center' }}>Мысли</th>
                                <th style={{ textAlign: 'center' }}>Цитаты</th>
                                <th style={{ textAlign: 'center' }}>Юмор</th>
                                <th style={{ textAlign: 'center' }}>Итого</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'center' }}>{personalMessagesInfo.affirmations}</td>
                                <td style={{ textAlign: 'center' }}>{personalMessagesInfo.letters}</td>
                                <td style={{ textAlign: 'center' }}>{personalMessagesInfo.thoughts}</td>
                                <td style={{ textAlign: 'center' }}>{personalMessagesInfo.quotes}</td>
                                <td style={{ textAlign: 'center' }}>{personalMessagesInfo.humors}</td>
                                <td style={{ textAlign: 'center' }}><b>{personalMessagesInfo.total}</b></td>
                            </tr>
                        </tbody>
                    </table>}

                <div style={{ height: "calc(100% - 113px)", overflowY: 'auto' }}>
                    {personalMessagesInfo != null && <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                        <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                            <tr>
                                <th style={{ textAlign: 'center', fontSize: '14px', padding: '0' }} colSpan={3}>Отчёт по использованию фраз Резидентами</th>
                            </tr>
                            <tr>
                                <th>#</th>
                                <th>Резидент</th>
                                <th>Фразы</th>
                            </tr>
                        </thead>
                        <tbody>
                            {personalMessageUsages.map((pmu, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{pmu.account.humanName} {pmu.account.isActive == 0 ? "(не активен)" : ""}</td>
                                    <td>{pmu.currentLapProgress}/{personalMessagesInfo.total}{pmu.currentLap > 1 ? <span> ({pmu.currentLap}-й круг)</span> : ""}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                </div>
            </div>}
            {selectedScreen == 1 && <PersonalMessageForm onBack={() => setSelectedScreen(0)} productionMode={productionMode} />}
        </>
    )
};

export default AdminPersonalMessageManagement;
