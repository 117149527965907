﻿import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account } from '../../../../../store/Models';
import { formatCost, getClassSingle, makeDateForCharity } from '../../../../../utils';
import CharityAddPeriodForm from './forms/CharityAddPeriodForm';
import CharityAddRecordForm from './forms/CharityAddRecordForm';

interface AdminCharityManagementProps {
    onBack: () => void;
    productionMode: boolean;
}

interface CharityChallenge {
    account: Account;
    value: number;
    currency: string;
    usdValue: number;
}

interface CharityDay {
    charityDate: string;
    week: number;
}

interface CharityDateData {
    charityDate: string;
    week: number;
    data: CharityChallenge[];
}

const AdminCharityManagement: React.FC<AdminCharityManagementProps> = ({ onBack, productionMode }) => {

    const fetch = useFetch();

    const charityDataRef = React.useRef<CharityDateData[]>([]);

    const [charityChallengeData, setCharityChallengeData] = React.useState<CharityChallenge[]>([]);

    const [charityDays, setCharityDays] = React.useState<CharityDay[]>([]);
    const [currentCharityDate, setCurrentCharityDate] = React.useState<CharityDay | null>(null);

    const [selectedScreen, setSelectedScreen] = React.useState<number>(0);

    const [currentUSDRate, setCurrentUSDRate] = React.useState<number>(0);

    React.useEffect(() => {
        if (selectedScreen == 0) {
            fetch('/api/challenge/getCharityDays')
                .then(response => response.json())
                .then(data => {
                    setCharityDays(data);
                    setCurrentCharityDate(data[data.length - 1]);
                });
        }
    }, [selectedScreen]);

    React.useEffect(() => {
        if (currentCharityDate != null) {
            if (charityDataRef.current.filter(c => c.week == currentCharityDate.week).length == 0) {
                fetch('/api/challenge/getCharityChallengeResultsOnWeek?week=' + currentCharityDate.week)
                    .then(response => response.json() as Promise<CharityChallenge[]>)
                    .then(data => {
                        setCharityChallengeData(data);
                        charityDataRef.current.push({
                            charityDate: currentCharityDate.charityDate,
                            week: currentCharityDate.week,
                            data: data
                        });
                    });
            }
            else {
                setCharityChallengeData(charityDataRef.current.filter(c => c.week == currentCharityDate.week)[0].data);
            }
        }
    }, [currentCharityDate]);

    const switchToBeginning = () => {
        setCharityChallengeData([]);
        setCurrentCharityDate(charityDays[0]);
    }

    const switchToPrev = () => {
        if (currentCharityDate != null) {
            setCharityChallengeData([]);
            setCurrentCharityDate(charityDays.filter(c => c.week == currentCharityDate.week - 1)[0]);
        }
    }

    const switchToNext = () => {
        if (currentCharityDate != null) {
            setCharityChallengeData([]);;
            setCurrentCharityDate(charityDays.filter(c => c.week == currentCharityDate.week + 1)[0]);
        }
    }

    const switchToEnd = () => {
        setCharityChallengeData([]);
        setCurrentCharityDate(charityDays[charityDays.length - 1]);
    }

    return (
        <>
            {selectedScreen == 0 && <div className="payment-approval-container">
                <div className="reg-module-view-header"><b>ERA CHARITY</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                <button style={{ marginBottom: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={() => setSelectedScreen(1)}>Открыть новый период</button>

                {currentCharityDate && <div className="challenge-view-mode-description">
                    <i className={"fas fa-angles-left challenge-switcher challenge-switcher-begin" + (currentCharityDate.week == 1 ? " inactive" : "")}
                        onClick={() => {
                            if (currentCharityDate.week == 1) return; else switchToBeginning();
                        }}
                    />
                    <i className={"fas fa-angle-left challenge-switcher challenge-switcher-left" + (currentCharityDate.week == 1 ? " inactive" : "")}
                        onClick={() => {
                            if (currentCharityDate.week == 1) return; else switchToPrev();
                        }}
                    />
                    <div className="charity-date">{makeDateForCharity(currentCharityDate.charityDate)}<br /><em style={{ fontWeight: 'normal', fontSize: '14px' }}>({currentCharityDate.week}-й период)</em></div>
                    <i className={"fas fa-angle-right challenge-switcher challenge-switcher-right" + (currentCharityDate.week == charityDays.length ? " inactive" : "")}
                        onClick={() => {
                            if (currentCharityDate.week == charityDays.length) return; else switchToNext();
                        }}
                    />
                    <i className={"fas fa-angles-right challenge-switcher challenge-switcher-end" + (currentCharityDate.week == charityDays.length ? " inactive" : "")}
                        onClick={() => {
                            if (currentCharityDate.week == charityDays.length) return; else switchToEnd();
                        }}
                    />
                </div>}
                <button style={{ marginBottom: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={() => setSelectedScreen(2)}>Доб. запись</button>
                <div style={{ height: "calc(100% - 163px)", overflowY: "auto" }}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Резидент</th>
                                <th>Тариф</th>
                                <th>Сумма</th>
                            </tr>
                        </thead>
                        <tbody>
                            {charityChallengeData.map((item, i) => {
                                return (<tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.account.humanName}</td>
                                    <td>{getClassSingle(item.account.class)}</td>
                                    <td style={{ fontWeight: 'bold' }}>{item.currency === 'KZT' ? '₸' : item.currency === 'RUB' ? '₽' : ''}{formatCost(item.value)}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>}
            {selectedScreen == 1 && <CharityAddPeriodForm onBack={() => setSelectedScreen(0)} productionMode={productionMode} />}
            {(selectedScreen == 2 && currentCharityDate != null) && <CharityAddRecordForm onBack={() => setSelectedScreen(0)}
                selectedDay={currentCharityDate.charityDate} productionMode={productionMode} />}
        </>
    )
};

export default AdminCharityManagement;
