import * as React from 'react';
import * as QRCode from "qrcode";

interface QRCodeComponentProps {
    value: string;
    header: string;
    onClose: () => void;
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ value, header, onClose }) => {

    const [qrCodeUrl, setQrCodeUrl] = React.useState<string>('');

    const getQRCodeValidThru = (minutes: number): string => {
        // �������� ������� �����
        const now = new Date();

        // �������� ��������� ������
        now.setMinutes(now.getMinutes() + minutes);

        // ������������� � ������ ������� ISO (UTC)
        return now.toISOString();
    }

    const generateQRCode = async () => {
        try {
            // ��������� QR-���� �� ������
            const url = await QRCode.toDataURL(value, {
                errorCorrectionLevel: "L", // ����������� ������� ��������� ������
                margin: 2,                // ����������� �������
                scale: 12                 // ����������� ������ �����
            });
            setQrCodeUrl(url);
        } catch (error) {
            console.error("������ ��������� QR-����:", error);
        }
    }

    React.useEffect(() => {
        if (value) {
            generateQRCode();
        }
        else {
            setQrCodeUrl('');
        }
    }, [value]);

    return (
        <div className="qr-modal" onClick={onClose}>
            <div className="qr-modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="qr-wrapper-container">
                    <img src={qrCodeUrl} alt="QR Code" style={{ width: '285px' }} />
                </div>
                <p className="qr-modal-title">{header}</p>
            </div>
        </div>
    )
};

export default QRCodeComponent;
