import * as React from 'react';

interface BinarWarningModalProps {
    currentUserName: string;
    onNext: () => void;
    onClose: () => void;
}

const BinarWarningModal: React.FC<BinarWarningModalProps> = ({ currentUserName, onNext, onClose }) => {

    const [nextEnabled, setNextEnabled] = React.useState<boolean>(false);

    return (
        <div className="binar-warning-modal-layer" onClick={onClose}>
            <div className="binar-warning-modal" onClick={(e) => { e.stopPropagation(); }}>
                <div className="binar-warning-modal-btn-close" onClick={onClose}><i className="fas fa-xmark" /></div>
                <div className="binar-warning-modal-header">СОГЛАШЕНИЕ</div>
                <div className="binar-warning-text">
                    <b>Уважаемый(-ая) {currentUserName},</b>
                    <p>В настоящий момент Вами производится активация нового партнёра компании, и мы настоятельно просим Вас подтвердить,
                        что вся информация о продуктах Компании и условиях сотрудничества в рамках Партнёрского соглашения донесена Вами в полном объёме, в частности, пункт,
                        касасющийся того, что в случае введения в заблуждение потенциальных партнёров относительно возможных доходов, получаемых за рекомендацию услуг Компании,
                        последняя оставляет за собой право одностороннего расторжения оферты с правом возложения на Вас субсидиарной ответственности в случае возникновения убытков
                        у Компании.</p>
                </div>
                <div className="binar-warning-check">
                    <input type="checkbox" onChange={(e) => { setNextEnabled(e.currentTarget.checked) }} /> С информацией выше согласен(-на) и подтверждаю
                </div>
                <div className="binar-warning-next">
                    <button className="btn btn-success" disabled={!nextEnabled} onClick={() => { onClose(); onNext(); }}>Продолжить</button>
                </div>
            </div>
        </div>
    )
};

export default BinarWarningModal;
