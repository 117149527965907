import * as React from 'react';
import { Account } from '../../store/Models';
import { eventTarget, getClassSingle, trimName } from '../../utils';
import AccountAvatar from './AccountAvatar';
import FirstLineSVG from './svg/FirstLineSVG';
import CommandSVG from './svg/CommandSVG';
import useFetch from '../../hooks/useFetch';

interface CommandChallengeTableViewProps {
    currentUser: Account;
    viewMode: number;
    onAccountClick: (account: Account) => void;
}

interface NetChallenge {
    account: Account;
    quantity: number;
    totalMoney: number;
}

const CommandChallengeTableView: React.FC<CommandChallengeTableViewProps> = ({ currentUser, viewMode, onAccountClick }) => {
    const fetch = useFetch();

    const [netChallengeFullView, setNetChallengeFullView] = React.useState<boolean>(false);

    const [netChallengeFirstLineTotalData, setNetChallengeFirstLineTotalData] = React.useState<NetChallenge[]>([]);
    const [netChallengeCommandTotalData, setNetChallengeCommandTotalData] = React.useState<NetChallenge[]>([]);

    React.useEffect(() => {
        fetch('/api/challenge/getFirstLineChallengeTotalResults')
            .then(response => response.json() as Promise<NetChallenge[]>)
            .then(data => {
                setNetChallengeFirstLineTotalData(data);
            });

        fetch('/api/challenge/getCommandChallengeTotalResults')
            .then(response => response.json() as Promise<NetChallenge[]>)
            .then(data => {
                setNetChallengeCommandTotalData(data);
                eventTarget.dispatchEvent(new CustomEvent('read_command_challenge'));
            });
    }, []);

    React.useEffect(() => {
        setNetChallengeFullView(false);
    }, [viewMode]);

    return (
        <div className="challenge-table-container command-table-container">
            {viewMode == 0 && <table className="table table-striped challenge-view-table command-view-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Резидент</th>
                        <th>Тариф</th>
                        <th style={{ textAlign: 'center' }}><FirstLineSVG /></th>
                    </tr>
                </thead>
                <tbody>
                    {netChallengeFirstLineTotalData.map((item, i) => {
                        if ((i < 3 || (i > netChallengeFirstLineTotalData.filter(b => b.quantity > 0).length - 4 && i < netChallengeFirstLineTotalData.filter(b => b.quantity > 0).length)
                            || netChallengeFullView) && item.account.id != currentUser.id) {
                            return (<tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{trimName(item.account.name)}</span>
                                        {(i < 3 && item.quantity > 1) && <img src="assets/banners/travel.jpg" className="travel-logo" width="15" height="10" />}
                                        {(i >= 3 && item.quantity > 1) && <span>&nbsp;🏖</span>}
                                    </div>
                                </td>
                                <td>{getClassSingle(item.account.class)}</td>
                                <td><b>{item.quantity} чел.</b></td>
                            </tr>)
                        }
                        else if (item.account.id == currentUser.id) {
                            return (<tr key={i}>
                                <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                <td style={{ color: '#047ff9' }}>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{trimName(item.account.name)} (Я)
                                        {(i < 3 && item.quantity > 1) && <img src="assets/banners/travel.jpg" className="travel-logo" width="15" height="10" />}
                                        {(i >= 3 && item.quantity > 1) && <span>&nbsp;🏖</span>}
                                    </div>
                                </td>
                                <td style={{ color: '#047ff9' }}>{getClassSingle(item.account.class)}</td>
                                <td style={{ color: '#047ff9' }}><b>{item.quantity} чел.</b></td>
                            </tr>)
                        }
                        else if (netChallengeFirstLineTotalData.findIndex(b => b.account.id == currentUser.id) > -1
                            && netChallengeFirstLineTotalData.findIndex(b => b.account.id == currentUser.id) < 3 && i == 3) { // me at top 3: break is one row
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (netChallengeFirstLineTotalData.findIndex(b => b.account.id == currentUser.id) > netChallengeFirstLineTotalData.filter(b => b.quantity > 0).length - 4
                            && netChallengeFirstLineTotalData.findIndex(b => b.account.id == currentUser.id) < netChallengeFirstLineTotalData.filter(b => b.quantity > 0).length && i == 3) { // me at bottom non-zero result 3: break is one row
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (netChallengeFirstLineTotalData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            netChallengeFirstLineTotalData.filter(b => b.account.id == currentUser.id)[0].quantity > 0
                            && Math.abs(i - netChallengeFirstLineTotalData.findIndex(b => b.account.id == currentUser.id)) == 1) { //me at middle (but my result is not zero!): break is two rows
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && netChallengeFirstLineTotalData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            netChallengeFirstLineTotalData.filter(b => b.account.id == currentUser.id)[0].quantity == 0) {
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && netChallengeFirstLineTotalData.filter(b => b.account.id == currentUser.id).length == 0) {
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (i == netChallengeFirstLineTotalData.findIndex(b => b.quantity == 0)) { // first 0 row
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else {
                            return null;
                        }
                    })}
                </tbody>
            </table>}
            {viewMode == 1 && <table className="table table-striped challenge-view-table command-view-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Резидент</th>
                        <th>Тариф</th>
                        <th style={{ textAlign: 'center' }}><CommandSVG /></th>
                    </tr>
                </thead>
                <tbody>
                    {netChallengeCommandTotalData.map((item, i) => {
                        if ((i < 3 || (i > netChallengeCommandTotalData.filter(b => b.quantity > 0).length - 4 && i < netChallengeCommandTotalData.filter(b => b.quantity > 0).length)
                            || netChallengeFullView) && item.account.id != currentUser.id) {
                            return (<tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.account) }}>{trimName(item.account.name)}</span></div>
                                </td>
                                <td>{getClassSingle(item.account.class)}</td>
                                <td><b>{item.quantity} чел.</b></td>
                            </tr>)
                        }
                        else if (item.account.id == currentUser.id) {
                            return (<tr key={i}>
                                <td style={{ color: '#047ff9' }}>{i + 1}</td>
                                <td style={{ color: '#047ff9' }}>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item.account} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;{trimName(item.account.name)} (Я)</div>
                                </td>
                                <td style={{ color: '#047ff9' }}>{getClassSingle(item.account.class)}</td>
                                <td style={{ color: '#047ff9' }}><b>{item.quantity} чел.</b></td>
                            </tr>)
                        }
                        else if (netChallengeCommandTotalData.findIndex(b => b.account.id == currentUser.id) > -1
                            && netChallengeCommandTotalData.findIndex(b => b.account.id == currentUser.id) < 3 && i == 3) { // me at top 3: break is one row
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (netChallengeCommandTotalData.findIndex(b => b.account.id == currentUser.id) > netChallengeCommandTotalData.filter(b => b.quantity > 0).length - 4
                            && netChallengeCommandTotalData.findIndex(b => b.account.id == currentUser.id) < netChallengeCommandTotalData.filter(b => b.quantity > 0).length && i == 3) { // me at bottom non-zero result 3: break is one row
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (netChallengeCommandTotalData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            netChallengeCommandTotalData.filter(b => b.account.id == currentUser.id)[0].quantity > 0
                            && Math.abs(i - netChallengeCommandTotalData.findIndex(b => b.account.id == currentUser.id)) == 1) { //me at middle (but my result is not zero!): break is two rows
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && netChallengeCommandTotalData.filter(b => b.account.id == currentUser.id).length > 0 &&
                            netChallengeCommandTotalData.filter(b => b.account.id == currentUser.id)[0].quantity == 0) {
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (i == 3 && netChallengeCommandTotalData.filter(b => b.account.id == currentUser.id).length == 0) {
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else if (i == netChallengeCommandTotalData.findIndex(b => b.quantity == 0)) { // first 0 row
                            return (<tr key={i} onClick={() => setNetChallengeFullView(true)}>
                                <td></td>
                                <td className="challenge-table-expander" colSpan={3}>...</td>
                            </tr>)
                        }
                        else {
                            return null;
                        }
                    })}
                </tbody>
            </table>}
        </div>
    )
};

export default CommandChallengeTableView;
