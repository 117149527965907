import * as React from 'react';

interface TooltipComponentProps {
    x: number;
    y: number;
    text: string;
    color: string;
    onClose: () => void;
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({ x, y, text, color, onClose }) => {
    
    React.useEffect(() => {
        window.addEventListener('click', onClose);
        return () => {
            window.removeEventListener('click', onClose);
        }
    }, []);
    

    if (x + 100 > window.innerWidth) {
        return (
            <div className="tooltip-component" style={{ right: window.innerWidth - (x + 5), bottom: window.innerHeight - (y - 15), color: color }} onClick={(e) => e.stopPropagation()}>
                {text}
            </div>
        )
    }
    else return (
        <div className="tooltip-component" style={{ left: x + 5, bottom: window.innerHeight - (y - 15), color: color }} onClick={(e) => e.stopPropagation()}>
            {text}
        </div>
    )

    
};

export default TooltipComponent;
