import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, BinarAccount } from '../../../../../store/Models';
import { copyToClipboard, getClassSingle } from '../../../../../utils';
import AccountAvatar from '../../../AccountAvatar';
import InfoSVG from '../../../svg/InfoSVG';
import BinarWarningModal from './BinarWarningModal';

interface BinarMediumBlockProps {
    binarAccount: BinarAccount | null | undefined;
    showOnEmpty: string;
    parentBinarAccount: BinarAccount | null;
    currentUserName: string;
    onSelect: (binarAccount: BinarAccount) => void;
    onLoad: (ownerAccountId: number) => void;
    onShowInfo: (account: Account) => void;
    productionMode: boolean;
}

const BinarMediumBlock: React.FC<BinarMediumBlockProps> = ({ binarAccount, showOnEmpty, parentBinarAccount, currentUserName, onSelect, onLoad, onShowInfo, productionMode }) => {
    const fetch = useFetch();

    const [showBinarWarningModal, setShowBinarWarningModal] = React.useState<boolean>(false);

    const requestLink = () => {
        if (parentBinarAccount != null)
            fetch('/api/binarReward/requestBinarPlaceLink?parentBinarAccountID=' + parentBinarAccount.id + '&side=' + parentBinarAccount.curatorLeg)
                .then(res => res.json())
                .then(data => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({
                            title: "Ваша реферальная ссылка", message: "https://t.me/million_era_bot/club?startApp=" + data.referralBinarLink,
                            buttons: [
                                { id: 'copy', type: 'default', text: "Скопировать ссылку" },
                                { id: '', type: 'default', text: "Закрыть" }]
                        }, (id: string) => {
                            if (id === 'copy') {
                                copyToClipboard('https://t.me/million_era_bot/club?startapp=' + data.referralBinarLink, () => {
                                    if (productionMode) {
                                        window.Telegram.WebApp.showPopup({ message: "Ссылка скопирована в буфер обмена.", buttons: [{ text: "Понятно" }] });
                                    }
                                    else {
                                        alert("Ссылка скопирована в буфер обмена.");
                                    }
                                });
                            }
                        });
                    }
                    else {
                        var result = window.confirm("https://t.me/million_era_bot/club?startApp=" + data.referralBinarLink);
                        if (result) {
                            copyToClipboard('https://t.me/million_era_bot/club?startapp=' + data.referralBinarLink, () => {
                                if (productionMode) {
                                    window.Telegram.WebApp.showPopup({ message: "Ссылка скопирована в буфер обмена.", buttons: [{ text: "Понятно" }] });
                                }
                                else {
                                    alert("Ссылка скопирована в буфер обмена.");
                                }
                            });
                        }
                    }
                });
    }

    React.useEffect(() => {
        if (binarAccount != null && binarAccount.ownerAccount != null) {
            onLoad(binarAccount.ownerAccount.id);
        }
    }, [binarAccount]);

    return (
        <div className="binar-medium-block">
            {(binarAccount != null && binarAccount.ownerAccount != null) && <div onClick={() => onSelect(binarAccount)}>
                <div className="binar-medium-block-info" onClick={(e) => { e.stopPropagation(); onShowInfo(binarAccount.ownerAccount); }}><InfoSVG /></div>
                <div className="binar-medium-avatar">
                    <AccountAvatar size={40} account={binarAccount.ownerAccount} showAsAdmin={false} classArg={null} onClick={() => { }} />
                    <div className="binar-medium-class">{getClassSingle(binarAccount.ownerAccount.class)}</div>
                </div>
                <div className="binar-medium-block-name">
                    <div>{binarAccount.ownerAccount.humanName}</div>
                    <div className="binar-medium-block-activity-info">
                        {binarAccount.isLeftQualified > 0 && <div className="binar-medium-block-activity-info-item-green"></div>}
                        {binarAccount.isLeftQualified == 0 && <div className="binar-medium-block-activity-info-item-red"></div>}
                        {binarAccount.isRightQualified > 0 && <div className="binar-medium-block-activity-info-item-green"></div>}
                        {binarAccount.isRightQualified == 0 && <div className="binar-medium-block-activity-info-item-red"></div>}
                    </div>
                </div>
                <div className="binar-medium-block-id">ID {binarAccount.ownerAccount.id}</div>
            </div>}
            {(binarAccount == null && showOnEmpty === 'plus') && <div className="binar-medium-block-plus" onClick={() => setShowBinarWarningModal(true)}><i className="fas fa-circle-plus" /></div>}
            {(binarAccount == null && showOnEmpty === 'lock') && <div className="binar-medium-block-lock"><i className="fas fa-lock" /></div>}
            {showBinarWarningModal && <BinarWarningModal currentUserName={currentUserName} onNext={requestLink} onClose={() => setShowBinarWarningModal(false)} />}
        </div>
    )
};

export default BinarMediumBlock;
