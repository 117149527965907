import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, BinarAccount, BinarPtsTransaction } from '../../../../../store/Models';
import { subscriptionsInfo } from '../../../../../subscriptions';
import { formatMoney, getClassSingleAlias, getPref, makeDateTime } from '../../../../../utils';
import AccountAvatar from '../../../AccountAvatar';
import BinarBigBlock from './BinarBigBlock';
import BinarView from './BinarView';

interface BinarPtsTransactionsViewProps {
    productionMode: boolean;
    homeUserRef: Account;
    homeUserId: number;
    homeUserName: string;
    rootBinarAccount: BinarAccount;
    onBack: () => void;
    onReloadView: (binarAccount: BinarAccount) => void;
}

const BinarPtsTransactionsView: React.FC<BinarPtsTransactionsViewProps> = ({ homeUserRef, productionMode, homeUserId, homeUserName, rootBinarAccount, onBack, onReloadView }) => {
    const fetch = useFetch();

    const [currentTeamView, setCurrentTeamView] = React.useState<number>(-1);

    const [leftPtsTransactions, setLeftPtsTransactions] = React.useState<BinarPtsTransaction[]>([]);
    const [leftPtsLoaded, setLeftPtsLoaded] = React.useState<boolean>(false);
    const [rightPtsTransactions, setRightPtsTransactions] = React.useState<BinarPtsTransaction[]>([]);
    const [rightPtsLoaded, setRightPtsLoaded] = React.useState<boolean>(false);

    const hideLostPts = parseInt(getPref(homeUserRef.prefs, "BINAR_VIEW_HIDE_LOST_PTS") || "0") == 1 && homeUserId == rootBinarAccount.ownerAccount.id;

    React.useEffect(() => {
        if (currentTeamView == -1) {
            fetch('/api/binarReward/getLeftPtsTransactions?binarAccountId=' + rootBinarAccount.id)
                .then(res => res.json() as Promise<BinarPtsTransaction[]>)
                .then(data => {
                    setLeftPtsTransactions(data);
                    if (!leftPtsLoaded) {
                        setLeftPtsLoaded(true);
                    }
                });
        }
        else if (currentTeamView == 1) {
            fetch('/api/binarReward/getRightPtsTransactions?binarAccountId=' + rootBinarAccount.id)
                .then(res => res.json() as Promise<BinarPtsTransaction[]>)
                .then(data => {
                    setRightPtsTransactions(data);
                    if (!rightPtsLoaded) {
                        setRightPtsLoaded(true);
                    }
                });
        }
    }, [currentTeamView]);

    /* !!! This code is used as a fallback for binar pts transactions before 03/01/2025, when table BinarPtsTransactions hadn't reference to table Payments.
     * This code assumes subscription code based on value on neighbour operations.
     */
    const assumeLeftSubscriptionCode = (p: BinarPtsTransaction, index: number): string => {
        if (p.subscriptionCode != null && p.subscriptionCode.length > 0) {
            return p.subscriptionCode;
        }
        else {
            var totalValue = p.ptsValue;
            if (index > 0 && leftPtsTransactions[index - 1].initiatorAccount.id == p.initiatorAccount.id && leftPtsTransactions[index - 1].transactionType != p.transactionType) {
                totalValue += leftPtsTransactions[index - 1].ptsValue;
            }
            else if (index < leftPtsTransactions.length - 1 && leftPtsTransactions[index + 1].initiatorAccount.id == p.initiatorAccount.id && leftPtsTransactions[index + 1].transactionType != p.transactionType) {
                totalValue += leftPtsTransactions[index + 1].ptsValue;
            }
            switch (totalValue) {
                case 1:
                    return "STANDARD_RENEWAL";
                case 4:
                    return "EXCLUSIVE_INITIAL";
                case 5:
                    return "BUSINESS_RENEWAL";
                case 16:
                    return "BASIC_FROM_EXCLUSIVE_UPGRADE";
                case 20:
                    return "BASIC_INITIAL";
                case 50:
                    return "BUSINESS_PLUS_RENEWAL";
                case 80:
                    return "STANDARD_FROM_BASIC_UPGRADE";
                case 100:
                    return "STANDARD_INITIAL";
                case 400:
                    return "BUSINESS_FROM_STANDARD_UPGRADE";
                case 500:
                    return "BUSINESS_INITIAL";
                default:
                    return "";
            }
        }
    }

    const assumeRightSubscriptionCode = (p: BinarPtsTransaction, index: number): string => {
        if (p.subscriptionCode != null && p.subscriptionCode.length > 0) {
            return p.subscriptionCode;
        }
        else {
            var totalValue = p.ptsValue;
            if (index > 0 && rightPtsTransactions[index - 1].initiatorAccount.id == p.initiatorAccount.id && rightPtsTransactions[index - 1].transactionType != p.transactionType) {
                totalValue += rightPtsTransactions[index - 1].ptsValue;
            }
            else if (index < rightPtsTransactions.length - 1 && rightPtsTransactions[index + 1].initiatorAccount.id == p.initiatorAccount.id && rightPtsTransactions[index + 1].transactionType != p.transactionType) {
                totalValue += rightPtsTransactions[index + 1].ptsValue;
            }
            switch (totalValue) {
                case 1:
                    return "STANDARD_RENEWAL";
                case 4:
                    return "EXCLUSIVE_INITIAL";
                case 5:
                    return "BUSINESS_RENEWAL";
                case 16:
                    return "BASIC_FROM_EXCLUSIVE_UPGRADE";
                case 20:
                    return "BASIC_INITIAL";
                case 50:
                    return "BUSINESS_PLUS_RENEWAL";
                case 80:
                    return "STANDARD_FROM_BASIC_UPGRADE";
                case 100:
                    return "STANDARD_INITIAL";
                case 400:
                    return "BUSINESS_FROM_STANDARD_UPGRADE";
                case 500:
                    return "BUSINESS_INITIAL";
                default:
                    return "";
            }
        }
    }

    const getLeftTeamText = (p: BinarPtsTransaction, index: number): string => {
        if (index < leftPtsTransactions.length - 1 && leftPtsTransactions[index + 1].initiatorAccount.id == p.initiatorAccount.id && leftPtsTransactions[index + 1].ptsValue == -p.ptsValue) {
            return 'в левой команде';
        }
        else {
            return 'в правой команде';
        }
    }

    const getRightTeamText = (p: BinarPtsTransaction, index: number): string => {
        if (index < rightPtsTransactions.length - 1 && rightPtsTransactions[index + 1].initiatorAccount.id == p.initiatorAccount.id && rightPtsTransactions[index + 1].ptsValue == -p.ptsValue) {
            return 'в правой команде';
        }
        else {
            return 'в левой команде';
        }
    }

    const [showBinar, setShowBinar] = React.useState<boolean>(false);
    const [currentAccountForBinarViewId, setCurrentAccountForBinarViewId] = React.useState<number>(homeUserId);

    return (
        <>
            {!showBinar && <div style={{ height: '100%' }}>
                <div className="reg-module-view-header"><b>История начисления баллов (pts)</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                <BinarBigBlock binarAccount={rootBinarAccount} onSelect={() => { }} showInfo={false} />
                <div className="binar-team-side-switcher">
                    <div className={currentTeamView == -1 ? "binar-team-side-switcher-variant-selected" : "binar-team-side-switcher-variant"} onClick={() => setCurrentTeamView(-1)}>Левая команда</div>
                    <div className={currentTeamView == 1 ? "binar-team-side-switcher-variant-selected" : "binar-team-side-switcher-variant"} onClick={() => setCurrentTeamView(1)}>Правая команда</div>
                </div>
                <div className="binar-pts">
                    <div className="binar-pts-middle-block">
                        <span style={{ color: 'green' }}><b>{currentTeamView == -1 ? formatMoney(rootBinarAccount.leftPtsValue) : currentTeamView == 1 ? formatMoney(rootBinarAccount.rightPtsValue) : ""}</b></span>
                        <span>/</span>
                        <span style={{ color: 'red' }}><b>{currentTeamView == -1 ? formatMoney(hideLostPts ? 0 : rootBinarAccount.leftLostPtsValue) : currentTeamView == 1 ? formatMoney(hideLostPts ? 0 : rootBinarAccount.rightLostPtsValue) : ""}</b></span> pts</div>
                </div>
                <div style={{ maxHeight: 'calc(100% - 259px)', overflowY: 'auto', fontSize: '12px' }}>
                    {(currentTeamView == -1 && leftPtsLoaded) &&
                        <table className="table table-striped">
                            <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                                <tr>
                                    <th style={{ textAlign: 'center' }}><i className="fas fa-calendar-days" /></th>
                                    <th style={{ textAlign: 'center' }}>Инициатор</th>
                                    <th style={{ textAlign: 'center' }}>За что</th>
                                    <th style={{ textAlign: 'center' }}>Pts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leftPtsTransactions.map((p, i) => (<tr>
                                    <td style={{ textAlign: 'center' }}>{makeDateTime(p.transactionDateTime)}</td>
                                    <td><div style={{ display: 'flex' }}><AccountAvatar size={19} account={p.initiatorAccount.ownerAccount} showAsAdmin={false} classArg={null} onClick={() => { setCurrentAccountForBinarViewId(p.initiatorAccount.ownerAccount.id); setShowBinar(true); }} />&nbsp;<span>{p.initiatorAccount.ownerAccount.humanName} [{getClassSingleAlias(p.initiatorAccount.ownerAccount.class)}]</span></div></td>
                                    <td style={{ textAlign: 'center' }}>{assumeLeftSubscriptionCode(p, i).length > 0 ? subscriptionsInfo[assumeLeftSubscriptionCode(p, i)].displayName : ""} {p.ptsValue < 0 ? getLeftTeamText(p, i) : ""}</td>
                                    <td style={{ color: p.transactionType < 0 ? 'red' : p.transactionType > 0 ? 'green' : 'black', textAlign: 'center' }}><b>{p.transactionType > 0 ? "+" : ""}{p.ptsValue}</b></td>
                                </tr>))}
                            </tbody>
                        </table>}
                    {(currentTeamView == 1 && rightPtsLoaded) &&
                        <table className="table table-striped">
                            <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                                <tr>
                                    <th style={{ textAlign: 'center' }}><i className="fas fa-calendar-days" /></th>
                                    <th style={{ textAlign: 'center' }}>Инициатор</th>
                                    <th style={{ textAlign: 'center' }}>За что</th>
                                    <th style={{ textAlign: 'center' }}>Pts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rightPtsTransactions.map((p, i) => (<tr>
                                    <td style={{ textAlign: 'center' }}>{makeDateTime(p.transactionDateTime)}</td>
                                    <td>
                                        <div style={{ display: 'flex' }}><AccountAvatar size={19} account={p.initiatorAccount.ownerAccount} showAsAdmin={false} classArg={null} onClick={() => { setCurrentAccountForBinarViewId(p.initiatorAccount.ownerAccount.id); setShowBinar(true); }} />&nbsp;<span>{p.initiatorAccount.ownerAccount.humanName} [{getClassSingleAlias(p.initiatorAccount.ownerAccount.class)}]</span></div>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>{assumeRightSubscriptionCode(p, i).length > 0 ? subscriptionsInfo[assumeRightSubscriptionCode(p, i)].displayName : ""} {p.ptsValue < 0 ? getRightTeamText(p, i) : ""}</td>
                                    <td style={{ color: p.transactionType < 0 ? 'red' : p.transactionType > 0 ? 'green' : 'black', textAlign: 'center' }}><b>{p.transactionType > 0 ? "+" : ""}{p.ptsValue}</b></td>
                                </tr>))}
                            </tbody>
                        </table>}
                </div>
            </div>}
            {showBinar && <BinarView homeUserRef={homeUserRef} homeUserId={homeUserId} homeUserName={homeUserName} rootUserId={currentAccountForBinarViewId} onBack={() => setShowBinar(false)}
                onSelectBinarAccount={setCurrentAccountForBinarViewId}
                onShowBinarPts={(b) => {
                    setShowBinar(false);
                    onReloadView(b);
                }}
                productionMode={productionMode} />}
        </>
    )
};

export default BinarPtsTransactionsView;
