import * as React from 'react';
import useFetch from '../../../../hooks/useFetch';
import { Account } from '../../../../store/Models';
import { getClassSingle, trimName } from '../../../../utils';
import AccountAvatar from '../../AccountAvatar';

interface CuratorSelectionProps {
    productionMode: boolean;
    onAccountClick: (item: Account) => void;
    onRequest: () => void;
    onClose: () => void;
}

const CuratorSelection: React.FC<CuratorSelectionProps> = ({ productionMode, onAccountClick, onRequest, onClose }) => {
    const fetch = useFetch();

    const [availableCuratorsLoaded, setAvailableCuratorsLoaded] = React.useState<boolean>(false);
    const [availableCurators, setAvailableCurators] = React.useState<Account[]>([]);

    React.useEffect(() => {
        fetch('/api/binarReward/getAvailableCurators')
            .then(res => res.json() as Promise<Account[]>)
            .then(data => {
                setAvailableCurators(data);
                setAvailableCuratorsLoaded(true);
            });
    }, []);

    const confirmCuratorSelection = (curatorAccount: Account) => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: 'Подтвердите выбор',
                message: 'Вы действительно хотите отправить запрос ' + curatorAccount.humanName + '?',
                buttons: [
                    { id: 'confirm', type: 'default', text: 'Да, подтверждаю' },
                    { id: '', type: 'default', text: 'Нет' }
                ]
            }, (id: string) => {
                if (id === 'confirm') {
                    requestCommand(curatorAccount.id);
                }
            });
        }
        else {
            if (window.confirm('Вы действительно хотите отправить запрос ' + curatorAccount.humanName + '?')) {
                requestCommand(curatorAccount.id);
            }
        }
    }

    const requestCommand = (id: number) => {
        fetch('/api/binarReward/requestCommand?curatorAccountId=' + id)
            .then(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({
                        message: 'Запрос успешно отправлен', buttons: [{ id: '', type: 'default', text: 'Понятно' }]
                    });
                }
                else {
                    window.alert('Запрос успешно отправлен');
                }
                onRequest();
                onClose();
            });
    }

    return (
        <div className="classic-view">
            <div className="classic-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
            <div className="classic-view-header" style={{ height: '84px' }}><b>ВЫБОР КУРАТОРА</b><br /><em>
                <p style={{ marginBottom: '0px' }}>(вышестоящие Резиденты,</p>
                <p style={{ marginBottom: '0px' }}>удовлетворяющие <a href="https://t.me/c/1639867222/28522/67366">Условиям</a>)</p>
            </em></div>
            <div className="classic-view-table-container" style={{ maxHeight: "calc(100% - 84px)" }}>
                <table className="table table-striped" style={{ fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Резидент</th>
                            <th>Тариф</th>
                            <th style={{ width: '25%' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(availableCuratorsLoaded && availableCurators.length == 0) &&
                            <tr>
                            <td colSpan={4}>К сожалению, на данный момент свободные Кураторы отсутствуют. Попробуйте подать заявку через несколько дней.</td>
                            </tr>}
                        {(availableCuratorsLoaded && availableCurators.length > 0) && availableCurators.map((item, i) =>
                            <tr>
                                <td style={{ verticalAlign: 'middle' }}>{i + 1}</td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <div style={{ display: 'flex' }}><AccountAvatar size={19} account={item} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item) }}>{trimName(item.name)}</span></div>
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>{getClassSingle(item.class)}</td>
                                <td style={{ width: '25%' }}>
                                    <button className="btn btn-outline-primary" style={{ fontSize: '10px', float: 'right' }} onClick={() => {
                                        confirmCuratorSelection(item)
                                    }}>Отправить запрос</button>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default CuratorSelection;
