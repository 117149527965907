import * as React from 'react';
import './custom.css';
import MainLayout from './components/telegramApp/layout/MainLayout';

function App() {
    var productionMode = true;
    var skipInitialDataLoading = false;

    if (productionMode && window.Telegram.WebApp.isVersionAtLeast('8.0') && window.Telegram.WebApp.requestFullscreen) {
        window.Telegram.WebApp.requestFullscreen();
    }

    return (
        <MainLayout productionMode={productionMode} skipInitialDataLoading={skipInitialDataLoading} />
        )
}
export default App;