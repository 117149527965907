import * as React from 'react';
import useFetch from '../../../../hooks/useFetch';
import { Account } from '../../../../store/Models';

interface MotivationFactProps {
    productionMode: boolean;
    currentUser: Account;
    onClose: () => void;
}

const MotivationFact: React.FC<MotivationFactProps> = ({ productionMode, currentUser, onClose }) => {
    
    const fetch = useFetch();

    const [motivationFact, setMotivationFact] = React.useState<string>('');

    React.useEffect(() => {
        fetch('/api/account/getMotivationFact')
            .then(res => res.json())
            .then(data => {
                setMotivationFact(data.motivationFact);
            })
    }, []);

    if (motivationFact.length == 0) {
        return null;
    }
    else return (
        <div className="motivation-fact-cover" onClick={onClose}>
            <div className="motivation-fact-body" onClick={(e) => e.stopPropagation()}>
                <div className="motivation-fact-header"><b>{currentUser.humanName.split(' ')[0]}, а Ты знал{currentUser.gender === 'F' ? 'а' : ''}, что...</b></div>
                <div className="motivation-fact-close-btn" onClick={onClose}><i className="fas fa-xmark" /></div>
                <div className="motivation-fact-content">
                    <span>{motivationFact}</span>
                </div>
                <div className="motivation-fact-bottom-panel">
                    <button className="btn btn-primary" onClick={onClose}>Вдохновляет!</button>
                </div>
            </div>
        </div>
    )
};

export default MotivationFact;
