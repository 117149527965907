import * as React from 'react';
import { Account, BinarAccount } from '../../../../../store/Models';
import { getClassSingle } from '../../../../../utils';
import AccountAvatar from '../../../AccountAvatar';

interface BinarSmallBlockProps {
    binarAccount: BinarAccount | null | undefined;
    showOnEmpty: string;
    onSelect: (binarAccount: BinarAccount) => void;
}

const BinarSmallBlock: React.FC<BinarSmallBlockProps> = ({ binarAccount, showOnEmpty, onSelect }) => {
    return (
        <div className="binar-small-block">
            {(binarAccount != null && binarAccount.ownerAccount != null) && <div onClick={() => onSelect(binarAccount)}>
                <div className="binar-small-avatar">
                    <AccountAvatar size={20} account={binarAccount.ownerAccount} showAsAdmin={false} classArg={null} onClick={() => { }} />
                    <div className="binar-small-class">{getClassSingle(binarAccount.ownerAccount.class)}</div>
                </div>
                <div className="binar-small-block-id">ID {binarAccount.ownerAccount.id}</div>
                <div className="binar-small-block-activity-info">
                    {binarAccount.isLeftQualified > 0 && <div className="binar-small-block-activity-info-item-green"></div>}
                    {binarAccount.isLeftQualified == 0 && <div className="binar-small-block-activity-info-item-red"></div>}
                    {binarAccount.isRightQualified > 0 && <div className="binar-small-block-activity-info-item-green"></div>}
                    {binarAccount.isRightQualified == 0 && <div className="binar-small-block-activity-info-item-red"></div>}
                </div>
            </div>}
            {((binarAccount == null || binarAccount.ownerAccount == null) && showOnEmpty === 'plus') && <div className="binar-small-block-plus"><i className="fas fa-circle-plus" /></div>}
            {((binarAccount == null || binarAccount.ownerAccount == null) && showOnEmpty === 'lock') && <div className="binar-small-block-lock"><i className="fas fa-lock" /></div>}
        </div>
    )
};

export default BinarSmallBlock;
