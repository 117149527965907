import * as React from 'react';
import Datetimepicker from '../../../simple/Datetimepicker';
import useFetch from '../../../../../../hooks/useFetch';

interface VenueAddFormProps {
    onBack: () => void;
    productionMode: boolean;
}

const VenueAddForm: React.FC<VenueAddFormProps> = ({ onBack, productionMode }) => {
    const fetch = useFetch();

    const [name, setName] = React.useState<string>('');
    const [venueStartDate, setVenueStartDate] = React.useState<string>('');
    const [venueEndDate, setVenueEndDate] = React.useState<string>('');
    const [locationInfo, setLocationInfo] = React.useState<string>('');
    const [format, setFormat] = React.useState<string>('');

    const [ticketOnEveryDay, setTicketOnEveryDay] = React.useState<string>('0');
    const [checkInAndCheckOut, setCheckInAndCheckOut] = React.useState<string>('0');

    const validate = (): boolean => {
        return name.length > 0 && venueStartDate.length > 0 && venueEndDate.length > 0 && locationInfo.length > 0 && format.length > 0
            && ticketOnEveryDay.length > 0 && checkInAndCheckOut.length > 0;
    }

    const doAdd = () => {
        //send information to server
        var formData = new FormData();
        formData.append('Name', name);
        formData.append('DateStart', venueStartDate);
        formData.append('DateEnd', venueEndDate);
        formData.append('LocationInfo', locationInfo);
        formData.append('Format', format);
        formData.append('TicketOnEveryDay', ticketOnEveryDay);
        formData.append('CheckInAndCheckOut', checkInAndCheckOut);
        
        fetch('api/venue/addVenue', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: '����������� ���� ������� �������.' });
                    }
                    else {
                        alert('����������� ���� ������� �������.');
                    }
                }
                else {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: '��������� ������ �������� ������. ����������, ���������� �����.' });
                    }
                    else {
                        alert('��������� ������ �������� ������. ����������, ���������� �����.');
                    }
                }
                onBack();
            })
            .catch(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({ message: '��������� ������ �������� ������. ����������, ���������� �����.' });
                }
                else {
                    alert('��������� ������ �������� ������. ����������, ���������� �����.');
                }
            });
    }

    return (
        <div className="payment-approval-manual-container">
            <div className="reg-module-view-header"><b>������� �����������</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="reg-module-block">
                <div className="reg-module-label">�������� ����������� ��� �����</div>
                <div>
                    <input className="form-control" onChange={(e) => setName(e.target.value)} />
                </div>
            </div>
            <div className="reg-module-block">
                <div>
                    <div>
                        <Datetimepicker label1="���� ������ �����������" label2="" dateOnly={true} startYear={(new Date()).getFullYear() + 1} onValueChanged={setVenueStartDate} />
                    </div>
                </div>
            </div>
            <div className="reg-module-block">
                <div>
                    <div>
                        <Datetimepicker label1="���� ��������� �����������" label2="" dateOnly={true} startYear={(new Date()).getFullYear() + 1} onValueChanged={setVenueEndDate} />
                    </div>
                </div>
            </div>
            <div className="reg-module-block">
                <div className="reg-module-label">����� ���������� ��� �����</div>
                <div>
                    <input className="form-control" onChange={(e) => setLocationInfo(e.target.value)} />
                </div>
            </div>
            <div className="reg-module-block">
                <div className="reg-module-label">������</div>
                <div>
                    <select style={{ 'width': '100%' }} onChange={(e) => setFormat(e.target.value)} defaultValue="">
                        <option value=""></option>
                        <option value="0">������</option>
                        <option value="1">������</option>
                        <option value="2">������+������</option>
                    </select>
                </div>
            </div>
            <div className="reg-module-block">
                <div className="reg-module-label">��������� ��������� ����� �� ������ ����</div>
                <div>
                    <select style={{ 'width': '100%' }} onChange={(e) => setTicketOnEveryDay(e.target.value)} defaultValue="0">
                        <option value="0">���</option>
                        <option value="1">��</option>
                    </select>
                </div>
            </div>
            <div className="reg-module-block">
                <div className="reg-module-label">��������� ������ �� ���� � �� �����</div>
                <div>
                    <select style={{ 'width': '100%' }} onChange={(e) => setCheckInAndCheckOut(e.target.value)} defaultValue="0">
                        <option value="0">���</option>
                        <option value="1">��</option>
                    </select>
                </div>
            </div>
            <div className="reg-module-submit">
                <button className="btn btn-success" disabled={!validate()} onClick={doAdd}>��������</button>
            </div>
        </div>
    )
};

export default VenueAddForm;
