import * as React from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import { Account } from '../../../../../../store/Models';
import Autocomplete from '../../../simple/Autocomplete';

interface CharityAddRecordFormProps {
    onBack: () => void;
    selectedDay: string;
    productionMode: boolean;
}

interface DefaultRate {
    usdRate: number;
}

const CharityAddRecordForm: React.FC<CharityAddRecordFormProps> = ({ onBack, selectedDay, productionMode }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const inputUSDRateRef = React.useRef<HTMLInputElement | null>(null);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [value, setValue] = React.useState<number>(0);
    const [currency, setCurrency] = React.useState<string>('KZT');
    const [usdRate, setUSDRate] = React.useState<number>(0);

    const clearForm = () => {
        setUSDRate(0);
        setCurrency('');
        setValue(0);
        setSelectedAccount(null);
    }

    const validate = (): boolean => {
        return selectedAccount != null && value > 0 && currency.length > 0 && usdRate > 0;
    }

    const doAdd = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('Value', value.toString());
            formData.append('Currency', currency);
            formData.append('Value', value.toString());
            formData.append('USDRate', usdRate.toString());
            formData.append('SelectedDay', selectedDay);
            fetch('api/admin/addCharityRecord', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Запись была успешно добавлена.' });
                    }
                    else {
                        alert('Запись была успешно добавлена.');
                    }
                    onBack();
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    React.useEffect(() => {
        if (selectedAccount != null) {
            fetch('/api/admin/getDefaultRate?currency=' + currency + '&selectedDay=' + selectedDay)
                .then(res => res.json() as Promise<DefaultRate>)
                .then(data => {
                    window.setTimeout(() => {
                        if (inputUSDRateRef.current != null) {
                            inputUSDRateRef.current.value = data.usdRate > 0 ? data.usdRate.toString() : '';
                        }
                    }, 0);
                });
        }
    }, [selectedAccount, currency]);

    return (
        <div className="payment-approval-container">
            <div className="reg-module-view-header"><b>ДОБАВИТЬ ЗАПИСЬ</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div><b>Запись за {selectedDay}</b></div>
                    <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите Резидента" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearForm}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Сумма</div>
                    <div>
                        <div>
                            <input className="form-control" onChange={(e) => setValue(parseInt(e.target.value))} />
                        </div>
                    </div>
                </div>}
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Валюта</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setCurrency(e.target.value)}>
                            <option value="KZT" selected>Тенге</option>
                            <option value="RUB">Российский рубль</option>
                        </select>
                    </div>
                </div>}
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Курс доллара США в выбранный день (по НБ РК или ЦБ РФ)</div>
                    <div>
                        <div>
                            <input ref={inputUSDRateRef} className="form-control" onChange={(e) => setUSDRate(parseFloat(e.target.value))} />
                        </div>
                    </div>
                </div>}
                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={doAdd}>Добавить</button>
                </div>
            </div>
        </div>
    )
};

export default CharityAddRecordForm;
