import * as React from 'react';

interface SwitcherProps {
    initialState: number;
    onSelect: (value: number) => void;
}

const Switcher: React.FC<SwitcherProps> = ({ initialState, onSelect }) => {

    const [state, setState] = React.useState<number>(initialState);

    const handleToggle = () => {
        const newState = state == 1 ? 0 : 1;
        onSelect(newState);
    };

    React.useEffect(() => {
        setState(initialState);
    }, [initialState]);

    return (
        <div
            className={`switcher ${state > 0 ? "on" : "off"}`}
            onClick={handleToggle}
        >
            <div className="switcher-slider"></div>
        </div>
    );
};

export default Switcher;
