import * as React from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import { Account } from '../../../../../../store/Models';
import { formatMoney, makeDate, makeDateAddDays, makePeriod } from '../../../../../../utils';
import Autocomplete from '../../../simple/Autocomplete';
import Datetimepicker from '../../../simple/Datetimepicker';

interface SpeakershipRewardAddRecordFormProps {
    onBack: () => void;
    productionMode: boolean;
}

const SpeakershipRewardAddRecordForm: React.FC<SpeakershipRewardAddRecordFormProps> = ({ onBack, productionMode }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [type, setType] = React.useState<number>(1);
    const [value, setValue] = React.useState<number>(0);

    const [venueType, setVenueType] = React.useState<number>(0);
    const [venueName, setVenueName] = React.useState<number>(0);
    const [venueFormat, setVenueFormat] = React.useState<number>(0);
    const [venueStartDate, setVenueStartDate] = React.useState<string>('');
    const [venueEndDate, setVenueEndDate] = React.useState<string>('');
    const [ticketCategory, setTicketCategory] = React.useState<number>(0);

    const [originalStreamDate, setOriginalStreamDate] = React.useState<string>('');
    const [speakershipRewardType, setSpeakershipRewardType] = React.useState<number>(0);
    const [issueDate, setIssueDate] = React.useState<string>('');

    const speakershipRewardTypes = ["", "Свободный микрофон", "Тематический эфир", "Радио-эфир"];

    const venueTypes = ["", "Мастер-класс", "Интенсив", "Тренинг-марафон", "Тренинг"];
    const venueNames = ["", "#PRODеньги", "#PROSекреты", "#PROЖизнь", "#PROLюбовь", "#PRODажи"];
    const venueFormats = ["", "Офлайн", "Онлайн"];
    const ticketCategories = ["", "Стандарт", "Онлайн", "Бизнес", "ВИП"];

    const clearInputFields = () => {
        setTicketCategory(0);
        setVenueStartDate('');
        setVenueEndDate('');
        setVenueFormat(0);
        setVenueName(0);
        setVenueType(0);
    }

    const clearForm = () => {
        clearInputFields();

        setType(1);
        setValue(0);
        setSelectedAccount(null);
    }

    const validate = (): boolean => {
        return selectedAccount != null && value > 0 &&
            (type == -1 && venueType > 0 && venueName > 0 && venueFormat > 0 &&
                venueStartDate.length > 0 && venueEndDate.length > 0 && ticketCategory > 0
            || type == 1 && originalStreamDate.length > 0 && speakershipRewardType > 0 && issueDate.length > 0);
    }

    const doAdd = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('Value', (type * value).toString());
            formData.append('Description',
                type == -1 ? `Приобретение билета на мероприятие: ${venueTypes[venueType]} ${venueNames[venueName]} (${venueFormats[venueFormat]}); Дат${venueStartDate != venueEndDate ? 'ы' : 'а'} мероприятия: ${makePeriod(venueStartDate, venueEndDate)}; Категория билета: ${ticketCategories[ticketCategory]}`
                    : type == 1 ? `Вознаграждение за проведение эфира в формате «${speakershipRewardTypes[speakershipRewardType]}» от ${makeDate(originalStreamDate)}, дата выдачи: ${makeDate(issueDate)}, срок действия: ${makeDateAddDays(issueDate, 180)};`
                        : "");
            fetch('api/admin/addSpeakershipRewardRecord', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Запись была успешно добавлена.' });
                    }
                    else {
                        alert('Запись была успешно добавлена.');
                    }
                    onBack();
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    React.useEffect(() => {
        clearInputFields();
    }, [type]);

    return (
        <div className="payment-approval-container">
            <div className="reg-module-view-header"><b>ДОБАВИТЬ ВОЗНАГР. ЗА СПИКЕРСТВО</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container" style={{ overflowY: 'auto' }}>
                <div className="reg-module-block">
                    <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите Резидента" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearForm}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Вид операции</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setType(parseInt(e.target.value))} defaultValue="1">
                            <option value="1">Начисление</option>
                            <option value="-1">Списание</option>
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">Тип мероприятия</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setVenueType(parseInt(e.target.value))} defaultValue="0">
                            {venueTypes.map((tc, i) => (
                                <option value={i}>{tc}</option>
                            ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">Название мероприятия</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setVenueName(parseInt(e.target.value))} defaultValue="0">
                            {venueNames.map((tc, i) => (
                                <option value={i}>{tc}</option>
                            ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">Формат участия</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setVenueFormat(parseInt(e.target.value))} defaultValue="0">
                            {venueFormats.map((tc, i) => (
                                <option value={i}>{tc}</option>
                            ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div>
                        <div>
                            <Datetimepicker label1="Дата начала мероприятия" label2="" dateOnly={true} startYear={(new Date()).getFullYear() + 1} onValueChanged={setVenueStartDate} />
                        </div>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div>
                        <div>
                            <Datetimepicker label1="Дата окончания мероприятия" label2="" dateOnly={true} startYear={(new Date()).getFullYear() + 1} onValueChanged={setVenueEndDate} />
                        </div>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">Категория билета</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setTicketCategory(parseInt(e.target.value))} defaultValue="0">
                            {ticketCategories.map((tc, i) => (
                                <option value={i}>{tc}</option>
                                ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == 1) && <div className="reg-module-block">
                    <div>
                        <div>
                            <Datetimepicker label1="Дата эфира" label2="" dateOnly={true} onValueChanged={setOriginalStreamDate} />
                        </div>
                    </div>
                </div>}
                {(selectedAccount && type == 1) && <div className="reg-module-block">
                    <div className="reg-module-label">За что начисление</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setSpeakershipRewardType(parseInt(e.target.value))} defaultValue="0">
                            {speakershipRewardTypes.map((srt, i) => (
                                <option value={i}>{srt}</option>
                            ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == 1) && <div className="reg-module-block">
                    <div>
                        <div>
                            <Datetimepicker label1="Дата вручения" label2="" dateOnly={true} onValueChanged={setIssueDate} />
                        </div>
                    </div>
                </div>}
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Сумма в долларах</div>
                    <div>
                        <div>
                            <input value={formatMoney(value)} className="form-control" onChange={(e) => setValue(parseInt(
                                e.target.value.length > 0 ? e.target.value.replace(/,/g, '') : '0'))} />
                        </div>
                    </div>
                </div>}

                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={doAdd}>Добавить</button>
                </div>
            </div>
        </div>
    )
};

export default SpeakershipRewardAddRecordForm;
