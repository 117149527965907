import * as React from 'react';
import { Account } from '../../../../../../store/Models';
import Autocomplete from '../../../simple/Autocomplete';

interface PersonalMessageFormProps {
    onBack: () => void;
    productionMode: boolean;
}

const PersonalMessageForm: React.FC<PersonalMessageFormProps> = ({ onBack, productionMode }) => {
    return (
        <div className="payment-approval-container">
            
        </div>
    )
};

export default PersonalMessageForm;
