import * as React from 'react';
import useFetch from '../../hooks/useFetch';
import { Account, SpeakershipRewardTransaction } from '../../store/Models';
import { formatMoney, makeDateTime } from '../../utils';
import IncomeSVG from './svg/IncomeSVG';
import SpendSVG from './svg/SpendSVG';

interface SpeakershipRewardTransactionsViewProps {
    currentUser: Account;
    onClose: () => void;
}

const SpeakershipRewardTransactionsView: React.FC<SpeakershipRewardTransactionsViewProps> = ({ currentUser, onClose }) => {
    const fetch = useFetch();

    const [speakershipRewardTransactions, setSpeakershipRewardTransactions] = React.useState<SpeakershipRewardTransaction[]>([]);

    const [speakershipRewardValue, setSpeakershipRewardValue] = React.useState<number>(0);

    React.useEffect(() => {
        fetch('/api/account/getSpeakershipRewardTransactions?accountId=' + currentUser.id)
            .then(r => r.json() as Promise<SpeakershipRewardTransaction[]>)
            .then(data => {
                setSpeakershipRewardTransactions(data);
            });
        fetch('api/account/getSpeakershipRewardInfo?accountId=' + currentUser.id)
            .then(r => r.json())
            .then(data => {
                if (data.speakershipRewardValue != null) {
                    setSpeakershipRewardValue(data.speakershipRewardValue);
                }
            });
    }, [])

    return (
        <div className="wallet-transactions-view">
            <div className="wallet-transactions-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
            <div className="wallet-transactions-view-header"><b>ОПЕРАЦИИ ПО СЕРТ. ЗА СПИКЕРСТВО</b></div>
            <div className="wallet-transactions-current-balance">Баланс: <b>${formatMoney(speakershipRewardValue)}</b></div>
            <div className="cashback-transactions-balance-contents">
                <div className="wallet-transactions-balance-ecp"><em>Всего заработано:</em><br /><b>${formatMoney(speakershipRewardTransactions.filter(ct => ct.value < 0).reduce((accumulator, y) => { return accumulator + Math.abs(y.value) }, 0))}</b></div>
            </div>
            <div className="cashback-transactions-view-table-container">
                <table className="table">
                    <tbody>
                        {speakershipRewardTransactions.length > 0 ? speakershipRewardTransactions.map((item, i) =>
                            <>
                                <tr>
                                    <td rowSpan={2}>{item.value >= 0 ? <div className="income"><IncomeSVG /></div>
                                        : item.value < 0 ? <div className="missed"><SpendSVG /></div> : ''}</td>
                                    <td rowSpan={2}>
                                        {item.description}
                                    </td>
                                    <td className="cashback-transaction-value">
                                        {item.value >= 0 ?
                                            <span className="cashback-transaction-value-positive">+${formatMoney(item.value)}</span> :
                                            <b>-${formatMoney(Math.abs(item.value))}</b>}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="cashback-transaction-second-row-datetime">
                                            {makeDateTime(item.transactionDateTime)}
                                        </div>
                                    </td>
                                </tr>
                            </>) :
                            <tr>
                                <td colSpan={4}>Здесь пока нет ни одной операции.</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default SpeakershipRewardTransactionsView;
