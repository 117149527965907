import * as React from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import { Account } from '../../../../../../store/Models';
import { formatMoney, getClassSingle, makeDate, makePeriod } from '../../../../../../utils';
import Autocomplete from '../../../simple/Autocomplete';
import Datetimepicker from '../../../simple/Datetimepicker';

interface CashbackAddRecordFormProps {
    onBack: () => void;
    productionMode: boolean;
}

const CashbackAddRecordForm: React.FC<CashbackAddRecordFormProps> = ({ onBack, productionMode }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [cashbackValue, setCashbackValue] = React.useState<number>(0);

    const [type, setType] = React.useState<number>(1);
    const [value, setValue] = React.useState<number>(0);

    const [recipient, setRecipient] = React.useState<string>('');
    const [venueType, setVenueType] = React.useState<number>(0);
    const [venueName, setVenueName] = React.useState<number>(0);
    const [venueFormat, setVenueFormat] = React.useState<number>(0);
    const [venueStartDate, setVenueStartDate] = React.useState<string>('');
    const [venueEndDate, setVenueEndDate] = React.useState<string>('');
    const [ticketCategory, setTicketCategory] = React.useState<number>(0);

    const [originalPaymentDate, setOriginalPaymentDate] = React.useState<string>('');
    const [cashbackType, setCashbackType] = React.useState<number>(0);
    const [tariffFrom, setTariffFrom] = React.useState<number>(0);
    const [tariffTo, setTariffTo] = React.useState<number>(0);

    const venueTypes = ["", "Мастер-класс", "Интенсив", "Тренинг-марафон", "Тренинг"];
    const venueNames = ["", "#PRODеньги", "#PROSекреты", "#PROЖизнь", "#PROLюбовь", "#PRODажи"];
    const venueFormats = ["", "Офлайн", "Онлайн"];
    const ticketCategories = ["", "Стандарт", "Онлайн", "Бизнес", "ВИП"];

    const clearInputFields = () => {
        setTicketCategory(0);
        setVenueStartDate('');
        setVenueEndDate('');
        setVenueFormat(0);
        setVenueName(0);
        setVenueType(0);
        setRecipient('');
    }

    const clearForm = () => {
        clearInputFields();

        setType(1);
        setValue(0);
        setCashbackValue(0);
        setSelectedAccount(null);
    }

    const validate = (): boolean => {
        return selectedAccount != null && value > 0 &&
            (type == -1 && recipient.length > 0 && venueType > 0 && venueName > 0 && venueFormat > 0 &&
                venueStartDate.length > 0 && venueEndDate.length > 0 && ticketCategory > 0 && value <= cashbackValue
                || type == 1 && originalPaymentDate.length > 0 && tariffFrom > 0 && (
                    cashbackType == 0 || cashbackType > 0 && tariffTo > 0
                ));
    }

    const doAdd = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('Value', (type * value).toString());
            formData.append('Description',
                type == -1 ? `Приобретение билета в пользу: ${recipient}; Мероприятие: ${venueTypes[venueType]} ${venueNames[venueName]} (${venueFormats[venueFormat]}); Дат${venueStartDate != venueEndDate ? 'ы' : 'а'} мероприятия: ${makePeriod(venueStartDate, venueEndDate)}; Категория билета: ${ticketCategories[ticketCategory]}`
                    : type == 1 ?
                        cashbackType == 0 ? `Кэшбек за оплату вступительного взноса на пакет «${getClassSingle(tariffFrom)}» от ${makeDate(originalPaymentDate)};`
                            : cashbackType == 1 ? `Кэшбек за апгрейд с пакета «${getClassSingle(tariffFrom)}» на пакет «${getClassSingle(tariffTo)}» от ${makeDate(originalPaymentDate)};`
                                : cashbackType == 2 ? `Бонусный кэшбек за апгрейд с пакета «${getClassSingle(tariffFrom)}» на пакет «${getClassSingle(tariffTo)}» в течение 40 дней с момента регистрации в Клубе от ${makeDate(originalPaymentDate)};`
                                    : ""
                        : "");
            fetch('api/admin/addCashbackRecord', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Запись была успешно добавлена.' });
                    }
                    else {
                        alert('Запись была успешно добавлена.');
                    }
                    onBack();
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    React.useEffect(() => {
        if(selectedAccount != null) {
            fetch('/api/account/getCashbackInfo?accountId=' + selectedAccount.id)
                .then(res => res.json())
                .then((data) => {
                    setCashbackValue(parseInt(data.cashbackValue));
                });
        }
    }, [selectedAccount]);

    React.useEffect(() => {
        clearInputFields();
    }, [type]);

    return (
        <div className="payment-approval-container">
            <div className="reg-module-view-header"><b>ДОБАВИТЬ ОПЕРАЦИЮ ПО КЭШБЕКУ</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container" style={{ overflowY: 'auto' }}>
                <div className="reg-module-block">
                    <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите Резидента" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearForm}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Вид операции</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setType(parseInt(e.target.value))} defaultValue="1">
                            <option value="1">Начисление</option>
                            <option value="-1">Списание</option>
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div style={{ margin: '5px 0', fontWeight: 'bold' }}>Баланс кэшбека: ₸{formatMoney(cashbackValue)}</div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">В пользу кого приобретён билет (Имя Фамилия)</div>
                    <div>
                        <div>
                            <input className="form-control" onChange={(e) => setRecipient(e.target.value)} />
                        </div>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">Тип мероприятия</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setVenueType(parseInt(e.target.value))} defaultValue="0">
                            {venueTypes.map((tc, i) => (
                                <option value={i}>{tc}</option>
                            ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">Название мероприятия</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setVenueName(parseInt(e.target.value))} defaultValue="0">
                            {venueNames.map((tc, i) => (
                                <option value={i}>{tc}</option>
                            ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">Формат участия человека</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setVenueFormat(parseInt(e.target.value))} defaultValue="0">
                            {venueFormats.map((tc, i) => (
                                <option value={i}>{tc}</option>
                            ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div>
                        <div>
                            <Datetimepicker label1="Дата начала мероприятия" label2="" dateOnly={true} startYear={(new Date()).getFullYear() + 1} onValueChanged={setVenueStartDate} />
                        </div>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div>
                        <div>
                            <Datetimepicker label1="Дата окончания мероприятия" label2="" dateOnly={true} startYear={(new Date()).getFullYear() + 1} onValueChanged={setVenueEndDate} />
                        </div>
                    </div>
                </div>}
                {(selectedAccount && type == -1) && <div className="reg-module-block">
                    <div className="reg-module-label">Категория билета</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setTicketCategory(parseInt(e.target.value))} defaultValue="0">
                            {ticketCategories.map((tc, i) => (
                                <option value={i}>{tc}</option>
                                ))}
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == 1) && <div className="reg-module-block">
                    <div>
                        <div>
                            <Datetimepicker label1="Дата оригинального платежа" label2="" dateOnly={true} onValueChanged={setOriginalPaymentDate} />
                        </div>
                    </div>
                </div>}
                {(selectedAccount && type == 1) && <div className="reg-module-block">
                    <div className="reg-module-label">За что начисление</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setCashbackType(parseInt(e.target.value))} defaultValue="0">
                            <option value="0">За оплату вступительного взноса</option>
                            <option value="1">За апгрейд</option>
                            <option value="2">Бонусный за апгрейд в течение 40 дней с момента регистрации</option>
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == 1 && cashbackType == 0) && <div className="reg-module-block">
                    <div className="reg-module-label">Тариф в Клубе</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setTariffFrom(parseInt(e.target.value))} defaultValue="0">
                            <option value="0"></option>
                            <option value="5">Эксклюзив</option>
                            <option value="10">Базовый</option>
                            <option value="20">Стандарт</option>
                            <option value="25">Стандарт Плюс</option>
                            <option value="30">Бизнес</option>
                            <option value="40">Бизнес Плюс</option>
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == 1 && cashbackType > 0) && <div className="reg-module-block">
                    <div className="reg-module-label">Тариф в Клубе до апгрейда</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setTariffFrom(parseInt(e.target.value))} defaultValue="0">
                            <option value="0"></option>
                            <option value="5">Эксклюзив</option>
                            <option value="10">Базовый</option>
                            <option value="20">Стандарт</option>
                            <option value="25">Стандарт Плюс</option>
                            <option value="30">Бизнес</option>
                            <option value="40">Бизнес Плюс</option>
                        </select>
                    </div>
                </div>}
                {(selectedAccount && type == 1 && cashbackType > 0) && <div className="reg-module-block">
                    <div className="reg-module-label">Тариф в Клубе после апгрейда</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setTariffTo(parseInt(e.target.value))} defaultValue="0">
                            <option value="0"></option>
                            <option value="10">Базовый</option>
                            <option value="20">Стандарт</option>
                            <option value="25">Стандарт Плюс</option>
                            <option value="30">Бизнес</option>
                            <option value="40">Бизнес Плюс</option>
                        </select>
                    </div>
                </div>}
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Сумма в тенге</div>
                    <div>
                        <div className={type == -1 && value > cashbackValue ? "validation-error-field" : ""}>
                            <input value={formatMoney(value)} className="form-control" onChange={(e) => setValue(parseInt(
                                e.target.value.length > 0 ? e.target.value.replace(/,/g, '') : '0'))} />
                        </div>
                        {(type == -1 && value > cashbackValue) && <div className="validation-error-text">Недостаточно средств для списания кэшбека</div>}
                    </div>
                </div>}

                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={doAdd}>Добавить</button>
                </div>
            </div>
        </div>
    )
};

export default CashbackAddRecordForm;
