import * as React from 'react';
import { ReactionExtended } from '../../../../store/Models';
import { makeDateTimeForReaction, trimName } from '../../../../utils';
import AccountAvatar from '../../AccountAvatar';
import Reaction from './Reaction';

interface ReactionsTableProps {
    source: ReactionExtended[];
}

interface ReactionSummary {
    reactionCode: number;
    count: number;
}

const ReactionsTable: React.FC<ReactionsTableProps> = ({ source }) => {

    const [selectedReaction, setSelectedReaction] = React.useState(-1);

    const groupedReactions = Object.values(
        source.reduce<Record<number, ReactionSummary>>((acc, item) => {
            const { reactionCode } = item;
            if (!acc[reactionCode]) {
                acc[reactionCode] = { reactionCode, count: 0 };
            }
            acc[reactionCode].count += 1;
            return acc;
        }, {})
    );

    return (
        <div>
            <div className="longpress-item-separator"></div>
            <div style={{ padding: '10px' }}>
                <div className={selectedReaction == -1 ? "reaction-counter-selected" : "reaction-counter-bg"}
                    onClick={() => setSelectedReaction(-1)}><i className="fa-regular fa-heart" /> {source.length}</div>
                {groupedReactions.map(x => (
                    <div className={selectedReaction == x.reactionCode ? "reaction-counter-selected" : "reaction-counter-bg"}
                        onClick={() => setSelectedReaction(x.reactionCode)}>
                        <Reaction code={x.reactionCode} />
                        {x.count}</div>
                    ))}
            </div>        
            {selectedReaction == -1 && <div>
                {source.map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AccountAvatar account={r.account} size={30} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px' }}>
                                    <div style={{ textOverflow: 'ellipsis', lineHeight: '16px' }}>{trimName(r.account.name)}</div>
                                    {r.reactionDateTime != null && <div className="reaction-datetime"><i className="fa-regular fa-heart" /> {makeDateTimeForReaction(r.reactionDateTime)}</div>}
                                </div>
                                <div><Reaction code={r.reactionCode} /></div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
            {selectedReaction >= 0 && <div>
                {source.filter(s => s.reactionCode == selectedReaction).map(r => (
                    <>
                        <div className="longpress-item-separator"></div>
                        <div style={{ padding: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <AccountAvatar account={r.account} size={30} classArg={null} showAsAdmin={false} onClick={() => { }} />
                                <div style={{ flex: '1', paddingLeft: '5px' }}>
                                    <div style={{ textOverflow: 'ellipsis', lineHeight: '16px' }}>{trimName(r.account.name)}</div>
                                    {r.reactionDateTime != null && <div className="reaction-datetime"><i className="fa-regular fa-heart" /> {makeDateTimeForReaction(r.reactionDateTime)}</div>}
                                </div>
                                <div><Reaction code={r.reactionCode}/ ></div>
                            </div>
                        </div>
                    </>
                ))}
            </div>}
        </div>
    )
};

export default ReactionsTable;
