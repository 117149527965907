import * as React from 'react';
import ReactQuill from 'react-quill';
import useFetch from '../../../../../../hooks/useFetch';
import { Account } from '../../../../../../store/Models';
import Autocomplete from '../../../simple/Autocomplete';
import Datetimepicker from '../../../simple/Datetimepicker';

interface MillionaireMorningFormProps {
    onBack: () => void;
    productionMode: boolean;
}

const MillionaireMorningForm: React.FC<MillionaireMorningFormProps> = ({ onBack, productionMode }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [publishDateTime, setPublishDateTime] = React.useState<string>('');
    const [tz, setTz] = React.useState<string>('');
    const [content, setContent] = React.useState<string>('');

    const clearForm = () => {
        setContent('');
        setTz('');
        setPublishDateTime('');
        setSelectedAccount(null);
    }

    const validate = (): boolean => {
        return selectedAccount != null && publishDateTime.length > 0 && tz.length > 0 && content.length > 50;
    }

    const doAdd = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('PublishDateTime', publishDateTime);
            formData.append('Tz', tz);
            formData.append('Content', content);
            fetch('api/admin/addMillionaireMorningItem', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Задание было успешно добавлено.' });
                    }
                    else {
                        alert('Задание было успешно добавлено.');
                    }
                    onBack();
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    React.useEffect(() => {
        fetch('/api/admin/getMillionaireMorningItemAuthors')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    const restoreLayout = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className="payment-approval-container-vh">
            <div className="reg-module-view-header"><b>ДОБАВИТЬ УТРЕННЕЕ ЗАДАНИЕ</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div className="reg-module-label">Автор (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите автора" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearForm}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount != null && <div className="reg-module-block">
                    <div>
                        <Datetimepicker label1="Дата задания" label2="Время задания" onValueChanged={setPublishDateTime} />
                    </div>
                </div>}
                {selectedAccount != null && <div className="reg-module-block">
                    <div className="reg-module-label">Часовой пояс выхода задания</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setTz(e.target.value)} defaultValue="">
                            <option style={{ display: 'none' }} value=""></option>
                            <option value="-5">GMT+5 (Астана)</option>
                        </select>
                    </div>
                </div>}
                {selectedAccount != null && <div className="reg-module-block">
                    <div className="reg-module-label">Контент</div>
                    <div onBlur={restoreLayout}>
                        <ReactQuill
                            onChange={setContent}
                            modules={{
                                toolbar: null
                            }}
                            placeholder="Напишите задание здесь"
                        />
                    </div>
                </div>}
                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={doAdd}>Добавить</button>
                </div>
            </div>
        </div>
    )
};

export default MillionaireMorningForm;
