import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { PaymentSession } from '../../../../../store/Models';
import { CostInfo } from '../../../../../subscriptions';
import { copyToClipboard, formatCost, getClassSingle } from '../../../../../utils';

interface StepperRenewal2Props {
    productionMode: boolean;
    paymentSession: PaymentSession;
    selectedSubscription: CostInfo;
    onBack: () => void;
    onNext: (selectedOption: any) => void;
}

const StepperRenewal2: React.FC<StepperRenewal2Props> = ({ productionMode, paymentSession, selectedSubscription, onBack, onNext }) => {
    const fetch = useFetch();

    const delay = 90;

    const [balance, setBalance] = React.useState<number>(0);

    React.useEffect(() => {
        fetch('api/account/getMyAvailBalance')
            .then(r => r.json())
            .then(data => {
                if (data.balance != null) {
                    setBalance(data.balance);
                }
            });
    }, []);

    const paymentOptions = [
        {
            Key: 'Kaspi',
            Name: 'Kaspi',
            PaymentLink: 'https://kaspi.kz/pay/_gate?action=service_with_subservice&service_id=3025&subservice_id=16836&region_id=19',
            Instructions: (selectedSubscription: CostInfo) => (
                <>
                    <p></p>
                    <br />
                    <p>1. Город и Название компании — <em>НЕ МЕНЯЕМ!</em></p>
                    <p>2. ФИО учащегося — <em>заполнить Фамилию Имя Отчество Участника, за которого делается оплата.</em></p>
                    <p>3. Номер телефона — <em>номер телефона Участника (вводить 11 цифр, начиная с цифры "8" или "7", без "+", скобок, пробелов и других символов).</em></p>
                    <p>4. Назначение платежа — <em>Клуб MillionERA.</em></p>
                    <p>5. Пакет/Категория билета — <em>{selectedSubscription.displayName}.</em></p>
                    <p>6. Город участия — <em>Город, в котором проживает Участник.</em></p>
                    <p>7. Сумма платежа — <em>₸{formatCost(selectedSubscription.cost)}.</em></p>
                </>
            )
        },
        {
            Key: 'VisaMC',
            Name: 'Visa/MasterCard',
            PaymentLink: (selectedSubscription: CostInfo) =>
                `https://auth.robokassa.kz/Merchant/Index.aspx?MerchantLogin=SmartInternationalBusiness&InvId=0&Culture=ru&Encoding=utf-8&OutSum=${selectedSubscription.cost}&shp_interface=link&SignatureValue=${selectedSubscription.signatureValue}`,
            Instructions: (selectedSubscription: CostInfo) => (
                <>
                    <p></p>
                    <br />
                    <p>1. Заполните E-Mail для отправки квитанции.</p>
                    <p>2. Введите 16 цифр номера карты.</p>
                    <p>3. Введите срок действия и CVV-код.</p>
                    <p>4. На экране подтверждения платежа банка, выпустившего Вашу карту, выполните необходимые действия (ввести SMS или другие способы подтверждения).</p>
                </>
            )
        },
        {
            Key: 'Sberbank',
            Name: 'Сбербанк',
            PaymentLink: null, // No payment link
            Instructions: (selectedSubscription: CostInfo) => (
                <>
                    <p></p>
                    <br />
                    <p>ПЕРЕВОД НА СБЕРБАНК РФ</p>
                    <p><em>(только для граждан РФ)</em></p>
                    <br />
                    <p>Номер карты:</p>
                    <p><b>4279 3800 2192 4487</b> (<span style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                        onClick={() => {
                            copyToClipboard('4279380021924487', () => {
                                if (productionMode) {
                                    window.Telegram.WebApp.showPopup({ message: "Номер карты скопирован.", buttons: [{ text: "Понятно" }] });
                                }
                                else {
                                    alert("Номер карты скопирован.");
                                }
                            }); tapPaymentLinkSber();
                        }}>скопировать</span>)</p>
                    <br />
                    <p>Получатель:</p>
                    <p><b>Ерлан Бахытович А.</b></p>
                    <br />
                    <p>Сумма перевода:</p>
                    <p><b>₽{selectedSubscription ? formatCost(selectedSubscription.rubCost) : '—'}</b></p>
                    <br />
                    <p><b><u>ВАЖНО!</u></b></p>
                    <p>В комментариях к переводу</p>
                    <p><b>НЕ ПИСАТЬ ЗА ЧТО ПЕРЕВОД!!!</b></p>
                </>
            )
        },
        {
            Key: 'Balance',
            Name: 'С моего баланса*',
            PaymentLink: null, // No payment link
            Instructions: (selectedSubscription: CostInfo) => (
                <>
                    <p></p>
                    <p><span style={{ fontSize: '12px' }}><em>* Только при наличии достаточной суммы, доступной к использованию (выводу)</em></span></p>
                    <br />
                    <p>К оплате: <b>{formatCost(selectedSubscription.usdCost)} у.е.</b></p>
                    <br />
                    <p>Доступно на вашем счёте: <span style={{ color: balance < selectedSubscription.usdCost ? 'red' : 'green' }}><b>{formatCost(balance)} у.е.</b></span></p>
                    {balance < selectedSubscription.usdCost && <p style={{ color: 'red', fontStyle: 'italic', fontSize: '12px' }}> К сожалению, у Вас недостаточно средств для оплаты с баланса</p>}
                </>
            )
        }
    ];

    const handlePaymentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const selected = paymentOptions.find(pay => pay.Key === selectedValue);
        setSelectedPayment(selected || null);
        if (selected && selected.Key === 'balance') {
            setLinkTapped(false);
        }
    };

    const [selectedPayment, setSelectedPayment] = React.useState<any>(paymentSession.paymentMethod != null ?
        paymentOptions.filter(x => x.Key === paymentSession.paymentMethod)[0] : null);
    const [linkTapped, setLinkTapped] = React.useState<boolean>(paymentSession.linkDateTime != null);

    const tapPaymentLink = () => {
        if (selectedPayment != null) {
            fetch('/api/payment/tapPaymentLink?sessionId=' + paymentSession.id
                + '&paymentMethod=' + selectedPayment.Key
                + '&cost=' + (selectedPayment.Key === 'Sberbank' ? selectedSubscription.rubCost : selectedSubscription.cost))
                .then(() => {
                    window.setTimeout(() => setLinkTapped(true), delay * 1000);
                });
        }
    }

    const tapPaymentLinkSber = () => {
        fetch('/api/payment/tapPaymentLink?sessionId=' + paymentSession.id
            + '&paymentMethod=Sberbank'
            + '&cost=' + selectedSubscription.rubCost)
            .then(() => {
                window.setTimeout(() => setLinkTapped(true), delay * 1000);
            });
    }

    const proceedPaymentSession = () => {
        if (selectedPayment.Key === "Balance") {
            if (productionMode) {
                window.Telegram.WebApp.showPopup({
                    title: "Списание с баланса",
                    message: `Вы подтверждаете списание средств с Вашего баланса в счёт продления подписки на тарифе «${getClassSingle(selectedSubscription.subscriptionClass)}» в размере ${formatCost(selectedSubscription.usdCost)} у.е.?`,
                    buttons: [
                        { id: "approveBalanceWithdrawReg", text: "Да, подтверждаю" },
                        { text: "Нет" }
                    ]
                },
                    (id: string) => {
                        if (id === "approveBalanceWithdrawReg") {
                            fetch('/api/payment/completePaymentSession?sessionId=' + paymentSession.id)
                                .then(() => onNext(selectedPayment));
                        }
                    });
            }
            else {
                if (window.confirm(`Вы подтверждаете списание средств с Вашего баланса в счёт продления подписки на тарифе «${getClassSingle(selectedSubscription.subscriptionClass)}» в размере ${formatCost(selectedSubscription.usdCost)} у.е.?`)) {
                    fetch('/api/payment/completePaymentSession?sessionId=' + paymentSession.id)
                        .then(() => onNext(selectedPayment));
                }
            }
        }
        else {
            fetch('/api/payment/proceedPaymentSession?sessionId=' + paymentSession.id)
                .then(() => onNext(selectedPayment));
        }
    }

    return (
        <React.Fragment>
            <div className="stepper-empty-space-top">
                <div className="stepper-select-caption">Выберите способ оплаты</div>
                <div className="stepper-select-wrapper">
                    <select className="stepper-select" style={{ fontStyle: selectedPayment == null ? 'italic' : 'normal' }} onChange={handlePaymentSelect} defaultValue={selectedPayment != null ? selectedPayment.Key : ""}>
                        <option value="" style={{ display: 'none', visibility: 'hidden' }} disabled hidden>Нажмите сюда</option>
                        {paymentOptions.map((option) => (
                            <option key={option.Key} value={option.Key}>
                                {option.Name}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedPayment && selectedPayment.PaymentLink && (
                    <a
                        href={typeof selectedPayment.PaymentLink === 'function' ? selectedPayment.PaymentLink(selectedSubscription) : selectedPayment.PaymentLink}
                        className="stepper-payment-link"
                        target="_blank"
                        onClick={(e) => {
                            e.preventDefault();
                            window.Telegram.WebApp.openLink(e.currentTarget.href, { confirm: false });
                            tapPaymentLink();
                        }}
                    >
                        Ссылка для оплаты {selectedPayment.Key === 'Kaspi' ? 'через Каспи' :
                            selectedPayment.Key === 'VisaMC' ? 'картой Visa/Mastercard' : ''
                        }
                    </a>
                )}

                <div className={selectedPayment == null || selectedPayment.Key === 'Sberbank' || selectedPayment.Key === 'Balance' ? "stepper-payment-instructions no-top" : "stepper-payment-instructions"}>
                    {selectedPayment ? (
                        <>
                            {selectedPayment.Key === 'Sberbank' ? <br /> : ''}
                            {selectedPayment.Key !== 'Balance' && <b>Инструкция по оплате {selectedPayment.Key === 'Kaspi' ? 'через Каспи' :
                                selectedPayment.Key === 'VisaMC' ? 'картой Visa/Mastercard' :
                                    selectedPayment.Key === 'Sberbank' ? 'переводом на Сбербанк РФ' : ''
                            }:</b>}
                            {typeof selectedPayment.Instructions === 'function'
                                ? selectedPayment.Instructions(selectedSubscription)
                                : <p>{selectedPayment.Instructions}</p>}
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div style={{ 'marginBottom': '20px' }}>
                <div className="stepper-button-container-nomargin">
                    <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>
                    <button className="stepper-button" onClick={proceedPaymentSession} disabled={(!selectedPayment || !linkTapped) && !(selectedPayment && selectedPayment.Key === 'Balance' && balance >= selectedSubscription.usdCost)}>
                        Далее
                    </button>

                </div>
                <div className="stepper-terms">
                    Производя оплату, Вы соглашаетесь<br />с условиями <a>Договора-оферты</a>
                </div>
            </div>

        </React.Fragment>
    )
};

export default StepperRenewal2;
