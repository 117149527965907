import * as React from 'react';
import { Account } from '../../store/Models';
import { getMaximumChallengeValue, getMonthChallengeTextRussian, getPtsTextRussian, getResidentsText, makeDateChallengeRussian, makeDateForCharity } from '../../utils';
import AccountAvatar from './AccountAvatar';
import BasicChallengeTableView from './BasicChallengeTableView';
import CoinChallengeTableView from './CoinChallengeTableView';
import CommandChallengeTableView from './CommandChallengeTableView';
import LevelChallengeTableView from './LevelChallengeTableView';
import FirstLineSVG from './svg/FirstLineSVG';
import CommandSVG from './svg/CommandSVG';
import CharityChallengeTableView from './CharityChallengeTableView';
import useFetch from '../../hooks/useFetch';

interface ChallengeViewProps {
    visible: boolean;
    currentUser: Account;
    mode: number;
    onAccountClick: (account: Account) => void;
}

const ChallengeView: React.FC<ChallengeViewProps> = ({ visible, currentUser, mode, onAccountClick }) => {
    const fetch = useFetch();

    const [viewMode, setViewMode] = React.useState<number>(0);

    const [residentsCount, setResidentsCount] = React.useState<number>(0);

    const [lastOperDate, setLastOperDate] = React.useState<string>('');

    

    React.useEffect(() => {
        fetch('/api/challenge/getLastOperDay')
            .then(response => response.json())
            .then(data => setLastOperDate(data.operDate));
        fetch('/api/challenge/getResidentsCount')
            .then(response => response.json())
            .then(data => setResidentsCount(data.count));
    }, []);

    return (
        <div className="challenge-view-container" style={{ display: visible ? '' : 'none' }}>
            {mode == 2 && <div className="challenge-view-header"><b>ЧЕЛЛЕНДЖ ЯНВАРЯ</b><br /><em>(утренние танцы и рефлексии)*</em></div>}
            {mode == 1 && <div className="challenge-view-header">Личные приглашения</div>}
            {mode == 0 && <div className="challenge-view-header"><b>КОМАНДНЫЙ ЧЕЛЛЕНДЖ</b><br /><em>(общая цель: 3,000 Резидентов)*</em></div>}
            {mode == 3 && <div className="challenge-view-header"><b>РЕЙТИНГ ПО УРОВНЯМ</b><br /><em>(месяцам непрерывного резидентства)*</em></div>}
            {mode == 4 && <div className="challenge-view-header"><b>РЕЙТИНГ ПО ERA COIN</b><br /><em>(монеты для покупок внутри Клуба)*</em></div>}
            {mode == 5 && <div className="challenge-view-header"><b>ERA ТВОРИТ ДОБРО</b></div>}

            {mode == 2 && <div className="challenge-banner-basic"></div>}
            {mode == 0 && <div className="challenge-banner-command"></div>}
            {mode == 3 && <div className="challenge-banner-level"></div>}
            {mode == 4 && <div className="challenge-banner-coin"></div>}
            {mode == 5 && <div className="challenge-banner-charity"></div>}

            {(mode == 0 && residentsCount > 0) && <div className="challenge-residents-count">
                Нас уже: <b>{residentsCount}</b> {getResidentsText(residentsCount)}
                <br /><em>(ближайшая цель: 220 Резидентов)</em>
            </div>}

            {mode == 2 && <div className="challenge-view-selector">
                <div className={viewMode == 0 ? "challenge-view-daily active" : "challenge-view-daily"} onClick={() => { setViewMode(0); }}>
                    <i className="fas fa-calendar-day" /> <span>Баллы за день</span>
                </div>
                <div className={viewMode == 1 ? "challenge-view-total active" : "challenge-view-total"} onClick={() => { setViewMode(1); }}>
                    <i className="fas fa-list-ol" /> <span>Общий итог</span>
                </div>
            </div>}
            {mode == 0 && <div className="challenge-view-selector">
                <div className={viewMode == 0 ? "challenge-view-daily active" : "challenge-view-daily"} onClick={() => { setViewMode(0); }}>
                    <FirstLineSVG /> <span>1-я линия</span>
                </div>
                <div className={viewMode == 1 ? "challenge-view-total active" : "challenge-view-total"} onClick={() => { setViewMode(1); }}>
                    <CommandSVG /> <span>Вся команда</span>
                </div>
            </div>}
            {mode == 4 && <div className="challenge-view-selector">
                <div className={viewMode == 0 ? "challenge-view-daily active" : "challenge-view-daily"} onClick={() => { setViewMode(0); }}>
                    <i className="fas fa-coins" /> <span>Текущий баланс</span>
                </div>
                <div className={viewMode == 1 ? "challenge-view-total active" : "challenge-view-total"} onClick={() => { setViewMode(1); }}>
                    <i className="fas fa-wallet" /> <span>Суммарно заработано</span>
                </div>
            </div>}
            {mode == 5 && <div className="challenge-view-selector">
                <div className={viewMode == 0 ? "challenge-view-daily active" : "challenge-view-daily"} onClick={() => { setViewMode(0); }}>
                    <i className="fas fa-calendar-day" /> <span>По неделям</span>
                </div>
                <div className={viewMode == 1 ? "challenge-view-total active" : "challenge-view-total"} onClick={() => { setViewMode(1); }}>
                    <i className="fas fa-list-ol" /> <span>Общий итог</span>
                </div>
            </div>}


            {(lastOperDate && mode == 2) && <div className="challenge-view-mode-description">{(viewMode == 0 ? 'Баллы за ' : 'Итоги на ') + makeDateChallengeRussian(lastOperDate)}</div>}
            {(lastOperDate && mode == 2 && viewMode == 1) && <div style={{ fontSize: '13px', textAlign: 'center' }}><em>(максимально возможный балл в Январе: <b>{getMaximumChallengeValue()}</b>)</em></div>}
            {mode == 2 && <BasicChallengeTableView currentUser={currentUser} viewMode={viewMode} onAccountClick={onAccountClick} />}
            {mode == 1 && <CommandChallengeTableView currentUser={currentUser} viewMode={viewMode} onAccountClick={onAccountClick} />}
            {mode == 0 && <CommandChallengeTableView currentUser={currentUser} viewMode={viewMode} onAccountClick={onAccountClick} />}
            {mode == 3 && <LevelChallengeTableView currentUser={currentUser} onAccountClick={onAccountClick} />}
            {mode == 4 && <CoinChallengeTableView currentUser={currentUser} viewMode={viewMode} onAccountClick={onAccountClick} />}
            {mode == 5 && <CharityChallengeTableView currentUser={currentUser} viewMode={viewMode} onAccountClick={onAccountClick} />}

            <div className="challenge-conditions-link">
                {mode == 2 && <a href="https://t.me/c/1639867222/28522/67294">* <span>Условия {getMonthChallengeTextRussian()} Челленджа</span></a>}
                {mode == 2 && <br />}
                {mode == 2 && <br />}
                {mode == 2 && <a href="https://t.me/c/1639867222/28679/67534">* <span>Итоги Декабрьского Челленджа</span></a>}
                {mode == 0 && <a href="https://t.me/c/1639867222/28522/56647">* <span>Условия Командного Челленджа</span></a>}
                {mode == 3 && <a href="https://t.me/c/1639867222/28522/42582">* <span>Условия получения бонусов за уровни</span></a>}
                {mode == 4 && <a href="https://t.me/sib_era">* <span>Условия получения и использования ERA Coin</span></a>}
            </div>
        </div>
    );
};

export default ChallengeView;
