import * as React from 'react';
import { Account, BinarAccount } from '../../../../../store/Models';
import { getClassSingle } from '../../../../../utils';
import AccountAvatar from '../../../AccountAvatar';
import InfoSVG from '../../../svg/InfoSVG';

interface BinarBigBlockProps {
    binarAccount: BinarAccount | null | undefined;
    onSelect: (binarAccount: BinarAccount) => void;
    showInfo: boolean;
    onShowInfo?: (account: Account) => void;
}

const BinarBigBlock: React.FC<BinarBigBlockProps> = ({ binarAccount, onSelect, showInfo, onShowInfo }) => {
    if (binarAccount != null) return (
        <div className="binar-big-block" onClick={() => onSelect(binarAccount)}>
            {(showInfo && onShowInfo) && <div className="binar-big-block-info" onClick={(e) => { e.stopPropagation(); onShowInfo(binarAccount.ownerAccount); }}><InfoSVG /></div>}
            <div className="binar-big-avatar">
                <AccountAvatar size={50} account={binarAccount.ownerAccount} showAsAdmin={false} classArg={null} onClick={() => { }} />
                <div className="binar-big-class">{getClassSingle(binarAccount.ownerAccount.class)}</div>
            </div>
            <div className="binar-big-block-right-section">
                <div className="binar-big-block-name">
                    <span>{binarAccount.ownerAccount.humanName}</span>
                    {binarAccount.binarAccountType == -1 && <span> (лев. доп.)</span>}
                    {binarAccount.binarAccountType == 1 && <span> (прав. доп.)</span>}
                </div>
                <div className="binar-big-block-id">ID {binarAccount.ownerAccount.id}</div>
                <div className="binar-big-block-activity-info">
                    {binarAccount.ownerAccount.isActive > 0 && <div className="binar-big-block-activity-info-item-green">Активен</div>}
                    {binarAccount.ownerAccount.isActive == 0 && <div className="binar-big-block-activity-info-item-red">Не активен</div>}
                    {binarAccount.isQualified > 0 && <div className="binar-big-block-activity-info-item-green">Квалифицирован</div>}
                    {binarAccount.isQualified == 0 && <div className="binar-big-block-activity-info-item-red">Не квалифицирован</div>}
                </div>
            </div>
        </div>
    );
    else return null;
};

export default BinarBigBlock;
