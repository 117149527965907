﻿import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Payment } from '../../../../../store/Models';
import { subscriptionsInfo } from '../../../../../subscriptions';
import { formatCost, makeDateTimeWithSeconds } from '../../../../../utils';

interface ApprovalProps {
    productionMode: boolean;
    payment: Payment;
    onModify: React.Dispatch<React.SetStateAction<Payment[]>>;
    onLock: (lockState: boolean) => void;
}

const Approval: React.FC<ApprovalProps> = ({ productionMode, payment, onModify, onLock }) => {
    const fetch = useFetch();

    const lockRef = React.useRef<boolean>(false);

    const getCostInfo = (payment: Payment) => {
        if (payment.currency === 'RUB') {
            return "₽" + formatCost(payment.cost);
        }
        else if (payment.currency === 'CU') {
            return formatCost(payment.cost) + ' у.е.';
        }
        else {
            return "₸" + formatCost(payment.cost);
        }
    }

    const confirmApprove = (paymentId: string) => {
        if (!productionMode) {
            confirmApproveRequest(paymentId);
        }
        else {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите оплату", message: "Вы действительно подтверждаете поступление денежных средств?",
                buttons: [
                    { id: 'approve', type: 'default', text: "Подтверждаю" },
                    { id: '', type: 'default', text: "Нет" }]
            }, (id: string) => {
                if (id === 'approve' && !lockRef.current) {
                    confirmApproveRequest(paymentId);
                }
            });
        }
    }

    const confirmApproveRequest = (paymentId: string) => {
        lockRef.current = true;
        onLock(true);
        fetch('/api/payment/approve?id=' + paymentId)
            .then(() => {
                onModify(x => x.map(t => {
                    if (t.id.toString() === paymentId) {
                        t.status = 7;
                        return t;
                    }
                    else return t;
                }));
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
            })
            .finally(() => {
                lockRef.current = false;
                onLock(false);
            });
    }

    const confirmDecline = (paymentId: string, subscriptionCode: string, accountId: string) => {
        if (!productionMode) {
            confirmDeclineRequest(paymentId, subscriptionCode, accountId);
        }
        else {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите отсутствие оплаты", message: "Вы действительно хотите отклонить операцию?",
                buttons: [
                    { id: 'decline', type: 'default', text: "Отклоняю" },
                    { id: '', type: 'default', text: "Нет" }]
            }, (id: string) => {
                if (id === 'decline' && !lockRef.current) {
                    confirmDeclineRequest(paymentId, subscriptionCode, accountId);
                }
            });
        }
    }

    const confirmDeclineRequest = (paymentId: string, subscriptionCode: string, accountId: string) => {
        lockRef.current = true;
        onLock(true);
        fetch('/api/payment/decline?id=' + paymentId + '&subscriptionCode=' + subscriptionCode + '&accountId=' + accountId)
            .then(() => {
                onModify(x => x.map(t => {
                    if (t.id.toString() === paymentId) {
                        t.status = 100;
                        return t;
                    }
                    else return t;
                }));
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
            })
            .finally(() => {
                lockRef.current = false;
                onLock(false);
            });
    }

    const confirmRevert = (paymentId: string) => {
        if (!productionMode) {
            confirmRevertRequest(paymentId);
        }
        else {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите возврат денежных средств", message: "Вы действительно хотите выполнить возврат?",
                buttons: [
                    { id: 'revert', type: 'default', text: "Подтверждаю" },
                    { id: '', type: 'default', text: "Нет" }]
            }, (id: string) => {
                if (id === 'revert' && !lockRef.current) {
                    confirmRevertRequest(paymentId);
                }
            });
        }
    }

    const confirmRevertRequest = (paymentId: string) => {
        lockRef.current = true;
        onLock(true);
        fetch('/api/payment/revert?id=' + paymentId)
            .then(() => {
                onModify(x => x.map(t => {
                    if (t.id.toString() === paymentId) {
                        t.status = 200;
                        return t;
                    }
                    else return t;
                }));
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
            })
            .finally(() => {
                lockRef.current = false;
                onLock(false);
            });
    }

    return (
        <tr>
            <td style={{ verticalAlign: 'middle' }}><div className={"payment-approval-status payment-approval-status-" + payment.status}>
                {payment.isManual == 0 && <i className="fas fa-mobile" />}
                {payment.isManual == 1 && <i className="fas fa-user" />}
                {payment.isManual == 2 && <i className="fas fa-gear" />}
            </div></td>
            <td style={{ verticalAlign: 'middle' }}>
                <div>{payment.account.humanName}</div>
                <div>{subscriptionsInfo[payment.subscriptionCode].displayName}</div>
                <div>Куратор: {payment.account.curatorName || 'Не выбран'}</div>
            </td>
            <td>
                <div>{getCostInfo(payment)} [{payment.paymentMethod}]</div>
                <div>{makeDateTimeWithSeconds(payment.paymentDateTime)}</div>
                {payment.isDebt > 0 && <div style={{ color: 'red' }}><b>В долг</b></div>}
            </td>
            <td style={{ verticalAlign: 'middle', width: '35%' }}>
                <div>
                    <button className="btn-approval btn-approval-confirm" data-id={payment.id} disabled={payment.status == 7 || payment.status == 200}
                        onClick={(e) => {
                            if (e.currentTarget != null) {
                                var id = e.currentTarget.getAttribute("data-id");
                                if (id) {
                                    confirmApprove(id);
                                }
                            }
                        }}>
                        <i className="fas fa-check" /></button>
                    <button className="btn-approval btn-approval-decline" data-id={payment.id} disabled={payment.status > 0}
                        onClick={(e) => {
                            if (e.currentTarget != null) {
                                var id = e.currentTarget.getAttribute("data-id");
                                if (id) {
                                    confirmDecline(id, payment.subscriptionCode, payment.account.id.toString());
                                }
                            }
                        }}>
                        <i className="fas fa-xmark" /></button>
                    <button className="btn-approval btn-approval-revert" data-id={payment.id} disabled={payment.status != 7 || payment.subscriptionCode.endsWith('RENEWAL')}
                        onClick={(e) => {
                            if (e.currentTarget != null) {
                                var id = e.currentTarget.getAttribute("data-id");
                                if (id) {
                                    confirmRevert(id);
                                }
                            }
                        }}>
                        <i className="fas fa-rotate-left" /></button>
                </div>
            </td>
        </tr>
        )
};

export default Approval;
