import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, AccountDetailInfo, BinarAccount } from '../../../../../store/Models';
import { formatMoney, getCookie, getPref, saveCookie, setPref } from '../../../../../utils';
import SearchPanel from '../../simple/SearchPanel';
import Switcher from '../../simple/Switcher';
import TooltipComponent from '../../simple/TooltipComponent';
import BinarAccountInfo from './BinarAccountInfo';
import BinarBigBlock from './BinarBigBlock';
import BinarMediumBlock from './BinarMediumBlock';
import BinarSmallBlock from './BinarSmallBlock';

interface BinarViewProps {
    homeUserRef: Account;
    homeUserId: number;
    homeUserName: string;
    rootUserId: number;
    onBack: () => void;
    onSelectBinarAccount: (ownerId: number) => void;
    onShowBinarPts: (binarAccount: BinarAccount) => void;
    productionMode: boolean;
}

const BinarView: React.FC<BinarViewProps> = ({ homeUserRef, homeUserId, homeUserName, rootUserId, onBack, onSelectBinarAccount, onShowBinarPts, productionMode }) => {
    const fetch = useFetch();

    const mainBinarAccountRef = React.useRef<BinarAccount | null>(null);
    const [mainBinarAccountLoaded, setMainBinarAccountLoaded] = React.useState<boolean>(false);

    const leftmostBinarAccountRef = React.useRef<BinarAccount | null>(null);
    const [leftmostBinarAccountLoaded, setLeftmostBinarAccountLoaded] = React.useState<boolean>(false);

    const rightmostBinarAccountRef = React.useRef<BinarAccount | null>(null);
    const [rightmostBinarAccountLoaded, setRightmostBinarAccountLoaded] = React.useState<boolean>(false);

    const [currentTeamView, setCurrentTeamView] = React.useState<number>(0);
    const [forceCurrentTeamView, setForceCurrentTeamView] = React.useState<boolean>(false);

    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
    const [tooltipX, setTooltipX] = React.useState<number>(0);
    const [tooltipY, setTooltipY] = React.useState<number>(0);
    const [tooltipText, setTooltipText] = React.useState<string>('');
    const [tooltipColor, setTooltipColor] = React.useState<string>('');
    //main numbers
    const [leftSubtreeCountChanges, setLeftSubtreeCountChanges] = React.useState<boolean>(false);
    const [leftPtsValueChanges, setLeftPtsValueChanges] = React.useState<boolean>(false);
    const [leftLostPtsValueChanges, setLeftLostPtsValueChanges] = React.useState<boolean>(false);
    const [rightSubtreeCountChanges, setRightSubtreeCountChanges] = React.useState<boolean>(false);
    const [rightPtsValueChanges, setRightPtsValueChanges] = React.useState<boolean>(false);
    const [rightLostPtsValueChanges, setRightLostPtsValueChanges] = React.useState<boolean>(false);
    //child numbers - left
    const [leftChildLeftPtsValueChanges, setLeftChildLeftPtsValueChanges] = React.useState<boolean>(false);
    const [leftChildLeftLostPtsValueChanges, setLeftChildLeftLostPtsValueChanges] = React.useState<boolean>(false);
    const [leftChildRightPtsValueChanges, setLeftChildRightPtsValueChanges] = React.useState<boolean>(false);
    const [leftChildRightLostPtsValueChanges, setLeftChilRightLostPtsValueChanges] = React.useState<boolean>(false);
    //child numbers - right
    const [rightChildLeftPtsValueChanges, setRightChildLeftPtsValueChanges] = React.useState<boolean>(false);
    const [rightChildLeftLostPtsValueChanges, setRightChildLeftLostPtsValueChanges] = React.useState<boolean>(false);
    const [rightChildRightPtsValueChanges, setRightChildRightPtsValueChanges] = React.useState<boolean>(false);
    const [rightChildRightLostPtsValueChanges, setRightChilRightLostPtsValueChanges] = React.useState<boolean>(false);

    // detail infos
    const [detailInfoMain, setDetailInfoMain] = React.useState<AccountDetailInfo | null>(null);
    const [detailInfoLeftLeft, setDetailInfoLeftLeft] = React.useState<AccountDetailInfo | null>(null);
    const [detailInfoLeftRight, setDetailInfoLeftRight] = React.useState<AccountDetailInfo | null>(null);
    const [detailInfoRightLeft, setDetailInfoRightLeft] = React.useState<AccountDetailInfo | null>(null);
    const [detailInfoRightRight, setDetailInfoRightRight] = React.useState<AccountDetailInfo | null>(null);
    const [detailInfoLeftmost, setDetailInfoLeftmost] = React.useState<AccountDetailInfo | null>(null);
    const [detailInfoRightmost, setDetailInfoRightmost] = React.useState<AccountDetailInfo | null>(null);

    const [, setTreeRenderDummy] = React.useState<number>(0);

    const showEarnTooltip = (e: React.MouseEvent) => {
        e.stopPropagation();
        setTooltipX(e.clientX);
        setTooltipY(e.clientY);
        setTooltipText("начислено");
        setShowTooltip(true);
        setTooltipColor('green');
    }

    const showMissTooltip = (e: React.MouseEvent) => {
        e.stopPropagation();
        setTooltipX(e.clientX);
        setTooltipY(e.clientY);
        setTooltipText("упущено");
        setShowTooltip(true);
        setTooltipColor('red');
    }

    const closeTooltip = () => {
        setShowTooltip(false);
        setTooltipX(0);
        setTooltipY(0);
        setTooltipText('');
        setTooltipColor('');
    }

    const showBinarPts = () => {
        if (mainBinarAccountRef.current != null) {
            onShowBinarPts(mainBinarAccountRef.current);
        }
    }

    const showLeftBinarPts = () => {
        if (mainBinarAccountRef.current != null && mainBinarAccountRef.current.leftBinarAccount != null) {
            onShowBinarPts(mainBinarAccountRef.current.leftBinarAccount );
        }
    }

    const showRightBinarPts = () => {
        if (mainBinarAccountRef.current != null && mainBinarAccountRef.current.rightBinarAccount != null) {
            onShowBinarPts(mainBinarAccountRef.current.rightBinarAccount);
        }
    }

    var midX = 0.5 * (window.innerWidth - 20);

    const deepLoad = (rootNode: BinarAccount, side: number, level: number): Promise<void> => {
        if (level > 3) {
            return Promise.resolve();
        }
        if (side == -1 && rootNode.leftBinarAccount != null) {
            return fetch('/api/binarReward/getBinarAccount?binarAccountID=' + rootNode.leftBinarAccount.id)
                .then(res => res.json() as Promise<BinarAccount>)
                .then(data => {
                    rootNode.leftBinarAccount = data;
                    setTreeRenderDummy(x => x + 1); //force update interface
                    deepLoad(rootNode.leftBinarAccount, -1, level + 1);
                    deepLoad(rootNode.leftBinarAccount, 1, level + 1);
                });
        }
        else if (side == 1 && rootNode.rightBinarAccount != null) {
            return fetch('/api/binarReward/getBinarAccount?binarAccountID=' + rootNode.rightBinarAccount.id)
                .then(res => res.json() as Promise<BinarAccount>)
                .then(data => {
                    rootNode.rightBinarAccount = data;
                    setTreeRenderDummy(x => x + 1); //force update interface
                    deepLoad(rootNode.rightBinarAccount, -1, level + 1);
                    deepLoad(rootNode.rightBinarAccount, 1, level + 1);
                });
        }
        else return Promise.resolve();
    }

    const getDetailInfo = (id: number, callback: (data: AccountDetailInfo) => void) => {
        return fetch('/api/account/getDetails?id=' + id)
            .then(res => res.json())
            .then(data => {
                fetch('api/account/getCommandInfo?id=' + id)
                    .then(r => r.json())
                    .then(cmd => {
                        data.firstLineCount = cmd.firstLineCount;
                        data.allLinesCount = cmd.totalCount;
                        callback(data);
                    });
            });
    }

    React.useEffect(() => {
        setMainBinarAccountLoaded(false);
        fetch('/api/binarReward/getBinarAccountByOwner?ownerAccountId=' + rootUserId)
            .then(res => res.json() as Promise<BinarAccount | null>)
            .then(data => {
                if (data != null) {
                    mainBinarAccountRef.current = data;
                    
                    if(!forceCurrentTeamView) {
                        setCurrentTeamView(data.curatorLeg === "left" ? -1 : data.curatorLeg === "right" ? 1 : 0);
                    }
                    else {
                        setForceCurrentTeamView(false);
                    }

                    var lastLeftPromise = fetch('/api/binarReward/getBinarDescendantLastLeft?binarAccountId=' + data.id)
                        .then(res1 => res1.json() as Promise<BinarAccount | null>)
                        .then(data1 => {
                            leftmostBinarAccountRef.current = data1;
                            setLeftmostBinarAccountLoaded(true);
                        });

                    var lastRightPromise = fetch('/api/binarReward/getBinarDescendantLastRight?binarAccountId=' + data.id)
                        .then(res2 => res2.json() as Promise<BinarAccount | null>)
                        .then(data2 => {
                            rightmostBinarAccountRef.current = data2;
                            setRightmostBinarAccountLoaded(true);
                        });

                    Promise.all([
                        deepLoad(mainBinarAccountRef.current, -1, 1),
                        deepLoad(mainBinarAccountRef.current, 1, 1),
                        lastLeftPromise,
                        lastRightPromise
                    ])
                        .then(() => {
                            var secondPromiseArray = [];
                            if (homeUserId != rootUserId && mainBinarAccountRef.current != null) {
                                secondPromiseArray.push(getDetailInfo(mainBinarAccountRef.current.ownerAccount.id, (d) => setDetailInfoMain(d)));
                            }
                            if (leftmostBinarAccountRef.current != null) {
                                secondPromiseArray.push(getDetailInfo(leftmostBinarAccountRef.current.ownerAccount.id, (d) => setDetailInfoLeftmost(d)));
                            }
                            if (rightmostBinarAccountRef.current != null) {
                                secondPromiseArray.push(getDetailInfo(rightmostBinarAccountRef.current.ownerAccount.id, (d) => setDetailInfoRightmost(d)));
                            }
                            return Promise.all(secondPromiseArray);
                        })
                        .then(() => {
                            setMainBinarAccountLoaded(true);

                            if (homeUserId === rootUserId && mainBinarAccountRef.current != null &&
                                mainBinarAccountRef.current.leftBinarAccount != null &&
                                mainBinarAccountRef.current.rightBinarAccount != null) {
                                //main numbers
                                var savedLeftSubtreeCount = parseInt(getCookie("leftSubtreeCount") || "0");
                                var savedLeftPtsValue = parseInt(getCookie("leftPtsValue") || "0");
                                var savedLeftLostPtsValue = parseInt(getCookie("leftLostPtsValue") || "0");
                                var savedRightSubtreeCount = parseInt(getCookie("rightSubtreeCount") || "0");
                                var savedRightPtsValue = parseInt(getCookie("rightPtsValue") || "0");
                                var savedRightLostPtsValue = parseInt(getCookie("rightLostPtsValue") || "0");
                                //child numbers - left
                                var savedLeftChildLeftPtsValue = parseInt(getCookie("leftChildLeftPtsValue") || "0");
                                var savedLeftChildLeftLostPtsValue = parseInt(getCookie("leftChildLeftLostPtsValue") || "0");
                                var savedLeftChildRightPtsValue = parseInt(getCookie("leftChildRightPtsValue") || "0");
                                var savedLeftChildRightLostPtsValue = parseInt(getCookie("leftChildRightLostPtsValue") || "0");
                                //child numbers - right
                                var savedRightChildLeftPtsValue = parseInt(getCookie("rightChildLeftPtsValue") || "0");
                                var savedRightChildLeftLostPtsValue = parseInt(getCookie("rightChildLeftLostPtsValue") || "0");
                                var savedRightChildRightPtsValue = parseInt(getCookie("rightChildRightPtsValue") || "0");
                                var savedRightChildRightLostPtsValue = parseInt(getCookie("rightChildRightLostPtsValue") || "0");

                                setLeftSubtreeCountChanges(savedLeftSubtreeCount - mainBinarAccountRef.current.leftSubtreeCount != 0);
                                setLeftPtsValueChanges(savedLeftPtsValue - mainBinarAccountRef.current.leftPtsValue != 0);
                                setLeftLostPtsValueChanges(savedLeftLostPtsValue - mainBinarAccountRef.current.leftLostPtsValue != 0);
                                setRightSubtreeCountChanges(savedRightSubtreeCount - mainBinarAccountRef.current.rightSubtreeCount != 0);
                                setRightPtsValueChanges(savedRightPtsValue - mainBinarAccountRef.current.rightPtsValue != 0);
                                setRightLostPtsValueChanges(savedRightLostPtsValue - mainBinarAccountRef.current.rightLostPtsValue != 0);

                                setLeftChildLeftPtsValueChanges(savedLeftChildLeftPtsValue - mainBinarAccountRef.current.leftBinarAccount.leftPtsValue != 0);
                                setLeftChildLeftLostPtsValueChanges(savedLeftChildLeftLostPtsValue - mainBinarAccountRef.current.leftBinarAccount.leftLostPtsValue != 0);
                                setLeftChildRightPtsValueChanges(savedLeftChildRightPtsValue - mainBinarAccountRef.current.leftBinarAccount.rightPtsValue != 0);
                                setLeftChilRightLostPtsValueChanges(savedLeftChildRightLostPtsValue - mainBinarAccountRef.current.leftBinarAccount.rightLostPtsValue != 0);

                                setRightChildLeftPtsValueChanges(savedRightChildLeftPtsValue - mainBinarAccountRef.current.rightBinarAccount.leftPtsValue != 0);
                                setRightChildLeftLostPtsValueChanges(savedRightChildLeftLostPtsValue - mainBinarAccountRef.current.rightBinarAccount.leftLostPtsValue != 0);
                                setRightChildRightPtsValueChanges(savedRightChildRightPtsValue - mainBinarAccountRef.current.rightBinarAccount.rightPtsValue != 0);
                                setRightChilRightLostPtsValueChanges(savedRightChildRightLostPtsValue - mainBinarAccountRef.current.rightBinarAccount.rightLostPtsValue != 0);

                                saveCookie("leftSubtreeCount", mainBinarAccountRef.current.leftSubtreeCount.toString(), 180);
                                saveCookie("leftPtsValue", mainBinarAccountRef.current.leftPtsValue.toString(), 180);
                                saveCookie("leftLostPtsValue", mainBinarAccountRef.current.leftLostPtsValue.toString(), 180);
                                saveCookie("rightSubtreeCount", mainBinarAccountRef.current.rightSubtreeCount.toString(), 180);
                                saveCookie("rightPtsValue", mainBinarAccountRef.current.rightPtsValue.toString(), 180);
                                saveCookie("rightLostPtsValue", mainBinarAccountRef.current.rightLostPtsValue.toString(), 180);

                                saveCookie("leftChildLeftPtsValue", mainBinarAccountRef.current.leftBinarAccount.leftPtsValue.toString(), 180);
                                saveCookie("leftChildLeftLostPtsValue", mainBinarAccountRef.current.leftBinarAccount.leftLostPtsValue.toString(), 180);
                                saveCookie("leftChildRightPtsValue", mainBinarAccountRef.current.leftBinarAccount.rightPtsValue.toString(), 180);
                                saveCookie("leftChildRightLostPtsValue", mainBinarAccountRef.current.leftBinarAccount.rightLostPtsValue.toString(), 180);

                                saveCookie("rightChildLeftPtsValue", mainBinarAccountRef.current.rightBinarAccount.leftPtsValue.toString(), 180);
                                saveCookie("rightChildLeftLostPtsValue", mainBinarAccountRef.current.rightBinarAccount.leftLostPtsValue.toString(), 180);
                                saveCookie("rightChildRightPtsValue", mainBinarAccountRef.current.rightBinarAccount.rightPtsValue.toString(), 180);
                                saveCookie("rightChildRightLostPtsValue", mainBinarAccountRef.current.rightBinarAccount.rightLostPtsValue.toString(), 180);
                            }
                            else {
                                setLeftSubtreeCountChanges(false);
                                setLeftPtsValueChanges(false);
                                setLeftLostPtsValueChanges(false);
                                setRightSubtreeCountChanges(false);
                                setRightPtsValueChanges(false);
                                setRightLostPtsValueChanges(false);

                                setLeftChildLeftPtsValueChanges(false);
                                setLeftChildLeftLostPtsValueChanges(false);
                                setLeftChildRightPtsValueChanges(false);
                                setLeftChilRightLostPtsValueChanges(false);

                                setRightChildLeftPtsValueChanges(false);
                                setRightChildLeftLostPtsValueChanges(false);
                                setRightChildRightPtsValueChanges(false);
                                setRightChilRightLostPtsValueChanges(false);
                            }
                        });
                }
            });
    }, [rootUserId]);

    const determineLeftEmptyPositionAvailability = (): string => {
        if (homeUserId == rootUserId) {
            //home mode: available if me at curator's right team, otherwise if i am left-qualified
            if (mainBinarAccountRef.current != null) {
                if (mainBinarAccountRef.current.curatorLeg === 'left') {
                    return 'plus';
                }
                else {
                    if (mainBinarAccountRef.current.isRightQualified > 0) {
                        return 'plus';
                    }
                    else return 'lock';
                }
            }
            else return 'lock';
        }
        else {
            //curator mode: available if descendant is in curator's right team, otherwise if descendant is fully qualified
            if (mainBinarAccountRef.current != null) {
                if (mainBinarAccountRef.current.curatorLeg === 'left') {
                    return 'plus';
                }
                else {
                    if (mainBinarAccountRef.current.isQualified > 0) {
                        return 'plus';
                    }
                    else return 'lock';
                }
            }
            else return 'lock';
        }
    }

    const determineRightEmptyPostiionAvailability = (): string => {
        if (homeUserId == rootUserId) {
            //home mode: available if me at curator's right team, otherwise if i am left-qualified
            if (mainBinarAccountRef.current != null) {
                if (mainBinarAccountRef.current.curatorLeg === 'right') {
                    return 'plus';
                }
                else {
                    if (mainBinarAccountRef.current.isLeftQualified > 0) {
                        return 'plus';
                    }
                    else return 'lock';
                }
            }
            else return 'lock';
        }
        else {
            //curator mode: available if descendant is in curator's right team, otherwise if descendant is fully qualified
            if (mainBinarAccountRef.current != null) {
                if (mainBinarAccountRef.current.curatorLeg === 'right') {
                    return 'plus';
                }
                else {
                    if (mainBinarAccountRef.current.isQualified > 0) {
                        return 'plus';
                    }
                    else return 'lock';
                }
            }
            else return 'lock';
        }
    }

    const [leftEmptyPosAvailable, setLeftEmptyPosAvailable] = React.useState<string>('lock');
    const [rightEmptyPosAvailable, setRightEmptyPosAvailable] = React.useState<string>('lock');

    const [showSearchPanel, setShowSearchPanel] = React.useState<boolean>(false);

    const searchPanelRef = React.useRef<any>(null);

    React.useEffect(() => {
        if (mainBinarAccountLoaded) {
            setLeftEmptyPosAvailable(determineLeftEmptyPositionAvailability());
            setRightEmptyPosAvailable(determineRightEmptyPostiionAvailability());
        }
    }, [mainBinarAccountLoaded]);

    const showLeftPacksInfo = () => {
        fetch('/api/binarReward/getLeftPacksInfo?ownerAccountId=' + rootUserId)
            .then(res => res.json() as Promise<Account[]>)
            .then(data => {
                var message = `ВСЕГО: ${data.length}\nИз них:\nАктивных — ${data.filter(x => x.isActive > 0).length}\nНеактивных — ${data.filter(x => x.isActive == 0).length}\n\nПО ТАРИФАМ:\nБизнес Плюс — ${data.filter(x => x.class == 40).length}\nБизнес — ${data.filter(x => x.class == 30).length}\nСтандарт Плюс — ${data.filter(x => x.class == 25).length}\nСтандарт — ${data.filter(x => x.class == 20).length}\nБазовый — ${data.filter(x => x.class == 10).length}\nЭксклюзив — ${data.filter(x => x.class == 5).length}`;
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({ title: 'Информация о команде', message: message, buttons: [{ id: '', type: 'default', text: 'Понятно' }] });
                }
                else {
                    alert(message);
                }
            });
    }

    const showRightPacksInfo = () => {
        fetch('/api/binarReward/getRightPacksInfo?ownerAccountId=' + rootUserId)
            .then(res => res.json() as Promise<Account[]>)
            .then(data => {
                var message = `ВСЕГО: ${data.length}\nИз них:\nАктивных — ${data.filter(x => x.isActive > 0).length}\nНеактивных — ${data.filter(x => x.isActive == 0).length}\n\nПО ТАРИФАМ:\nБизнес Плюс — ${data.filter(x => x.class == 40).length}\nБизнес — ${data.filter(x => x.class == 30).length}\nСтандарт Плюс — ${data.filter(x => x.class == 25).length}\nСтандарт — ${data.filter(x => x.class == 20).length}\nБазовый — ${data.filter(x => x.class == 10).length}\nЭксклюзив — ${data.filter(x => x.class == 5).length}`;
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({ title: 'Информация о команде', message: message, buttons: { id: '', type: 'default', text: 'Понятно' } });
                }
                else {
                    alert(message);
                }
            });
    }

    const onShowMain = (account: Account) => {
        setAccountInfo(account);
        setAccountDetailInfo(detailInfoMain);
    }

    const onShowLeftLeft = (account: Account) => {
        setAccountInfo(account);
        setAccountDetailInfo(detailInfoLeftLeft);
    }

    const onShowLeftRight = (account: Account) => {
        setAccountInfo(account);
        setAccountDetailInfo(detailInfoLeftRight);
    }

    const onShowRightLeft = (account: Account) => {
        setAccountInfo(account);
        setAccountDetailInfo(detailInfoRightLeft);
    }

    const onShowRightRight = (account: Account) => {
        setAccountInfo(account);
        setAccountDetailInfo(detailInfoRightRight);
    }

    const onShowLeftmost = (account: Account) => {
        setAccountInfo(account);
        setAccountDetailInfo(detailInfoLeftmost);
    }

    const onShowRightmost = (account: Account) => {
        setAccountInfo(account);
        setAccountDetailInfo(detailInfoRightmost);
    }

    const [accountInfo, setAccountInfo] = React.useState<Account | null>(null);
    const [accountDetailInfo, setAccountDetailInfo] = React.useState<AccountDetailInfo | null>(null);

    const [lostPtsSwitcherState, setLostPtsSwitcherState] = React.useState<number>(parseInt(getPref(homeUserRef.prefs, "BINAR_VIEW_HIDE_LOST_PTS") || "0")); // to do take from prefs

    const onSelectLostPtsSwitcherState = (value: number) => {
        if (homeUserRef.class < 30) {
            if (productionMode) {
                window.Telegram.WebApp.showPopup({ title: "Упс... Доступ ограничен)", message: "Данная функция работает только у Резидентов на тарифе Бизнес и выше.", buttons: [{ text: "Понятно" }] });
            }
            else {
                alert("Данная функция работает только у Резидентов на тарифе Бизнес и выше.");
            }
        }
        else {
            setLostPtsSwitcherState(value);
            homeUserRef.prefs = setPref(homeUserRef.prefs, "BINAR_VIEW_HIDE_LOST_PTS", value.toString());
            fetch('/api/account/setPrefs?newValue=' + setPref(homeUserRef.prefs, "BINAR_VIEW_HIDE_LOST_PTS", value.toString()));
        }
    }

    return (
        <div style={{ height: '100%' }}>
            <div className="reg-module-view-header"><b>Схема команды</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="btn-header-right" onClick={() => {
                setShowSearchPanel(true);
                /*
                window.setTimeout(() => {
                    if (searchPanelRef.current) {
                        searchPanelRef.current.focusFirstInput();
                    }
                }, 50);
                */
            }}><i className="fas fa-magnifying-glass" /></div>
            {(mainBinarAccountLoaded && mainBinarAccountRef.current != null) && <>
                <BinarBigBlock binarAccount={mainBinarAccountRef.current} onSelect={() => { }} showInfo={homeUserId != rootUserId} onShowInfo={onShowMain} />
                <div className="binar-team-side-switcher">
                    <div className={currentTeamView == -1 ? "binar-team-side-switcher-variant-selected" : "binar-team-side-switcher-variant"} onClick={() => setCurrentTeamView(-1)}>Левая команда</div>
                    <div className={currentTeamView == 1 ? "binar-team-side-switcher-variant-selected" : "binar-team-side-switcher-variant"} onClick={() => setCurrentTeamView(1)}>Правая команда</div>
                </div>
                {currentTeamView == -1 && <>
                    <div className="binar-pts">
                        <div className="binar-pts-right-block" style={{ textDecoration: 'underline' }}>
                            <span style={{ color: 'green', background: leftPtsValueChanges ? 'yellow' : '' }} onClick={showBinarPts}><b>{formatMoney(mainBinarAccountRef.current.leftPtsValue)}</b></span>
                            <span>/</span>
                            <span style={{ color: 'red', background: leftLostPtsValueChanges ? 'yellow' : '' }} onClick={showBinarPts}><b>{formatMoney(lostPtsSwitcherState == 1 && homeUserId == rootUserId ? 0 : mainBinarAccountRef.current.leftLostPtsValue)}</b></span> pts</div>
                        <div className="binar-pts-left-block binar-packs" onClick={showLeftPacksInfo}><span style={{ background: leftSubtreeCountChanges ? 'yellow' : '' }}>{mainBinarAccountRef.current.leftSubtreeCount}</span> packs</div>
                    </div>
                    {homeUserId == rootUserId && <div className="binar-lost-pts-switcher">
                        <div className="binar-lost-pts-switcher-caption">Скрыть упущенные баллы («красные» points)</div>
                        <div className="binar-lost-pts-switcher-element"><Switcher initialState={lostPtsSwitcherState} onSelect={onSelectLostPtsSwitcherState} /></div>
                    </div>}
                    <div className={homeUserId == rootUserId ? "binar-team-scrollable-container-my" : "binar-team-scrollable-container"}>
                        <BinarBigBlock binarAccount={mainBinarAccountRef.current.leftBinarAccount} onSelect={() => { }} showInfo={false} />
                        <svg width="calc(100% - 20px)" height="70px" style={{ margin: '0 10px' }}>
                            <line x1={midX} y1={0} x2={midX} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.5 * midX - 5} y1={35} x2={1.5 * midX + 5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.5 * midX - 5} y1={35} x2={0.5 * midX - 5} y2={45} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.5 * midX - 5} y1={60} x2={0.5 * midX - 5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.5 * midX + 5} y1={35} x2={1.5 * midX + 5} y2={45} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.5 * midX + 5} y1={60} x2={1.5 * midX + 5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <foreignObject x="0" y="37.5" width={midX - 10} height="20">
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <span style={{ color: 'green', background: leftChildLeftPtsValueChanges ? 'yellow' : '' }} onClick={showLeftBinarPts}><b>{formatMoney(mainBinarAccountRef.current.leftBinarAccount?.leftPtsValue || 0)}</b></span>
                                    <span>/</span>
                                    <span style={{ color: 'red', background: leftChildLeftLostPtsValueChanges ? 'yellow' : '' }} onClick={showLeftBinarPts}><b>{formatMoney(lostPtsSwitcherState == 1 && homeUserId == rootUserId ? 0 : mainBinarAccountRef.current.leftBinarAccount?.leftLostPtsValue || 0)}</b></span>
                                </div>
                            </foreignObject>

                            <foreignObject x={midX + 10} y="37.5" width={midX - 10} height="20">
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <span style={{ color: 'green', background: leftChildRightPtsValueChanges ? 'yellow' : '' }} onClick={showLeftBinarPts}><b>{formatMoney(mainBinarAccountRef.current.leftBinarAccount?.rightPtsValue || 0)}</b></span>
                                    <span>/</span>
                                    <span style={{ color: 'red', background: leftChildRightLostPtsValueChanges ? 'yellow' : '' }} onClick={showLeftBinarPts}><b>{formatMoney(lostPtsSwitcherState == 1 && homeUserId == rootUserId ? 0 : mainBinarAccountRef.current.leftBinarAccount?.rightLostPtsValue || 0)}</b></span>
                                </div>
                            </foreignObject>
                        </svg>
                        <div className="binar-view-second-line">
                            <BinarMediumBlock parentBinarAccount={mainBinarAccountRef.current.leftBinarAccount}
                                binarAccount={mainBinarAccountRef.current.leftBinarAccount?.leftBinarAccount}
                                currentUserName={homeUserName}
                                showOnEmpty={leftEmptyPosAvailable} onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)}
                                onLoad={(id) => getDetailInfo(id, (d) => setDetailInfoLeftLeft(d))}
                                onShowInfo={onShowLeftLeft}
                                productionMode={productionMode} />
                            <BinarMediumBlock parentBinarAccount={mainBinarAccountRef.current.leftBinarAccount}
                                binarAccount={mainBinarAccountRef.current.leftBinarAccount?.rightBinarAccount}
                                currentUserName={homeUserName}
                                showOnEmpty="lock"
                                onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)}
                                onLoad={(id) => getDetailInfo(id, (d) => setDetailInfoLeftRight(d))}
                                onShowInfo={onShowLeftRight}
                                productionMode={productionMode} />
                        </div>
                        <svg width="calc(100% - 20px)" height="70px" style={{ margin: '0 10px' }}>
                            <line x1={0.5 * midX - 5} y1={0} x2={0.5 * midX - 5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.5 * midX + 5} y1={0} x2={1.5 * midX + 5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.25 * midX - 7.5} y1={35} x2={0.75 * midX - 2.5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.25 * midX - 7.5} y1={35} x2={0.25 * midX - 7.5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.75 * midX - 2.5} y1={35} x2={0.75 * midX - 2.5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.25 * midX + 2.5} y1={35} x2={1.75 * midX + 7.5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.25 * midX + 2.5} y1={35} x2={1.25 * midX + 2.5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.75 * midX + 7.5} y1={35} x2={1.75 * midX + 7.5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                        </svg>
                        <div className="binar-view-third-line">
                            <BinarSmallBlock binarAccount={mainBinarAccountRef.current.leftBinarAccount?.leftBinarAccount?.leftBinarAccount} showOnEmpty="lock" onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)} />
                            <BinarSmallBlock binarAccount={mainBinarAccountRef.current.leftBinarAccount?.leftBinarAccount?.rightBinarAccount} showOnEmpty="lock" onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)} />
                            <BinarSmallBlock binarAccount={mainBinarAccountRef.current.leftBinarAccount?.rightBinarAccount?.leftBinarAccount} showOnEmpty="lock" onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)} />
                            <BinarSmallBlock binarAccount={mainBinarAccountRef.current.leftBinarAccount?.rightBinarAccount?.rightBinarAccount} showOnEmpty="lock" onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)} />
                        </div>
                        {(leftmostBinarAccountLoaded && leftmostBinarAccountRef.current != null && leftmostBinarAccountRef.current.ownerAccount != null &&
                            leftmostBinarAccountRef.current.ownerAccount.id != rootUserId) && <div className="binar-last-account">
                            <div className="binar-last-account-label">Левый нижний участник</div>
                            <BinarBigBlock binarAccount={leftmostBinarAccountRef.current} onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)}
                                showInfo={true}
                                onShowInfo={onShowLeftmost} />
                            </div>}
                        {homeUserId != mainBinarAccountRef.current.ownerAccount.id &&
                            <div className="binar-nav-panel">
                                <div className="binar-nav-panel-btn" onClick={() => onSelectBinarAccount(homeUserId)}><i className="fas fa-home" /> В начало</div>
                                <div className="binar-nav-panel-btn" onClick={() => {
                                    if (mainBinarAccountRef.current != null
                                        && mainBinarAccountRef.current.parentBinarAccount != null
                                        && mainBinarAccountRef.current.parentBinarAccount.ownerAccount != null) {
                                        onSelectBinarAccount(mainBinarAccountRef.current.parentBinarAccount.ownerAccount.id);
                                    }
                                }}><i className="fas fa-arrow-up" /> На 1 уровень</div>
                            </div>}
                    </div>
                </>}
                {currentTeamView == 1 && <>
                    <div className="binar-pts">
                        <div className="binar-pts-right-block binar-packs" onClick={showRightPacksInfo}><span style={{ background: rightSubtreeCountChanges ? 'yellow' : '' }}>{mainBinarAccountRef.current.rightSubtreeCount}</span> packs</div>
                        <div className="binar-pts-left-block" style={{ textDecoration: 'underline' }}>
                            <span style={{ color: 'green', background: rightPtsValueChanges ? 'yellow' : '' }} onClick={showBinarPts}><b>{formatMoney(mainBinarAccountRef.current.rightPtsValue)}</b></span>
                            <span>/</span>
                            <span style={{ color: 'red', background: rightLostPtsValueChanges ? 'yellow' : '' }} onClick={showBinarPts}><b>{formatMoney(lostPtsSwitcherState == 1 && homeUserId == rootUserId ? 0 : mainBinarAccountRef.current.rightLostPtsValue)}</b></span> pts</div>
                    </div>
                    {homeUserId == rootUserId && <div className="binar-lost-pts-switcher">
                        <div className="binar-lost-pts-switcher-caption">Скрыть упущенные баллы («красные» points)</div>
                        <div className="binar-lost-pts-switcher-element"><Switcher initialState={lostPtsSwitcherState} onSelect={onSelectLostPtsSwitcherState} /></div>
                    </div>}
                    <div className={homeUserId == rootUserId ? "binar-team-scrollable-container-my" : "binar-team-scrollable-container"}>
                        <BinarBigBlock binarAccount={mainBinarAccountRef.current.rightBinarAccount} onSelect={() => { }} showInfo={false} />
                        <svg width="calc(100% - 20px)" height="70px" style={{ margin: '0 10px' }}>
                            <line x1={midX} y1={0} x2={midX} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.5 * midX - 5} y1={35} x2={1.5 * midX + 5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.5 * midX - 5} y1={35} x2={0.5 * midX - 5} y2={45} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.5 * midX - 5} y1={60} x2={0.5 * midX - 5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.5 * midX + 5} y1={35} x2={1.5 * midX + 5} y2={45} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.5 * midX + 5} y1={60} x2={1.5 * midX + 5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <foreignObject x="0" y="37.5" width={midX - 10} height="20">
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <span style={{ color: 'green', background: rightChildLeftPtsValueChanges ? 'yellow' : '' }} onClick={showRightBinarPts}><b>{formatMoney(mainBinarAccountRef.current.rightBinarAccount?.leftPtsValue || 0)}</b></span>
                                    <span>/</span>
                                    <span style={{ color: 'red', background: rightChildLeftLostPtsValueChanges ? 'yellow' : '' }} onClick={showRightBinarPts}><b>{formatMoney(lostPtsSwitcherState == 1 && homeUserId == rootUserId ? 0 : mainBinarAccountRef.current.rightBinarAccount?.leftLostPtsValue || 0)}</b></span>
                                </div>
                            </foreignObject>

                            <foreignObject x={midX + 10} y="37.5" width={midX - 10} height="20">
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <span style={{ color: 'green', background: rightChildRightPtsValueChanges ? 'yellow' : '' }} onClick={showRightBinarPts}><b>{formatMoney(mainBinarAccountRef.current.rightBinarAccount?.rightPtsValue || 0)}</b></span>
                                    <span>/</span>
                                    <span style={{ color: 'red', background: rightChildRightLostPtsValueChanges ? 'yellow' : '' }} onClick={showRightBinarPts}><b>{formatMoney(lostPtsSwitcherState == 1 && homeUserId == rootUserId ? 0 : mainBinarAccountRef.current.rightBinarAccount?.rightLostPtsValue || 0)}</b></span>
                                </div>
                            </foreignObject>
                        </svg>
                        <div className="binar-view-second-line">
                            <BinarMediumBlock parentBinarAccount={mainBinarAccountRef.current.rightBinarAccount}
                                binarAccount={mainBinarAccountRef.current.rightBinarAccount?.leftBinarAccount}
                                currentUserName={homeUserName}
                                showOnEmpty="lock"
                                onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)}
                                onLoad={(id) => getDetailInfo(id, (d) => setDetailInfoRightLeft(d))}
                                onShowInfo={onShowRightLeft}
                                productionMode={productionMode} />
                            <BinarMediumBlock parentBinarAccount={mainBinarAccountRef.current.rightBinarAccount}
                                binarAccount={mainBinarAccountRef.current.rightBinarAccount?.rightBinarAccount}
                                currentUserName={homeUserName}
                                showOnEmpty={rightEmptyPosAvailable}
                                onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)}
                                onLoad={(id) => getDetailInfo(id, (d) => setDetailInfoRightRight(d))}
                                onShowInfo={onShowRightRight}
                                productionMode={productionMode} />
                        </div>
                        <svg width="calc(100% - 20px)" height="70px" style={{ margin: '0 10px' }}>
                            <line x1={0.5 * midX - 5} y1={0} x2={0.5 * midX - 5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.5 * midX + 5} y1={0} x2={1.5 * midX + 5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.25 * midX - 7.5} y1={35} x2={0.75 * midX - 2.5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.25 * midX - 7.5} y1={35} x2={0.25 * midX - 7.5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={0.75 * midX - 2.5} y1={35} x2={0.75 * midX - 2.5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.25 * midX + 2.5} y1={35} x2={1.75 * midX + 7.5} y2={35} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.25 * midX + 2.5} y1={35} x2={1.25 * midX + 2.5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                            <line x1={1.75 * midX + 7.5} y1={35} x2={1.75 * midX + 7.5} y2={70} stroke="#7d7d7d" strokeWidth="1" strokeDasharray="5,5" />
                        </svg>
                        <div className="binar-view-third-line">
                            <BinarSmallBlock binarAccount={mainBinarAccountRef.current.rightBinarAccount?.leftBinarAccount?.leftBinarAccount} showOnEmpty="lock" onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)} />
                            <BinarSmallBlock binarAccount={mainBinarAccountRef.current.rightBinarAccount?.leftBinarAccount?.rightBinarAccount} showOnEmpty="lock" onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)} />
                            <BinarSmallBlock binarAccount={mainBinarAccountRef.current.rightBinarAccount?.rightBinarAccount?.leftBinarAccount} showOnEmpty="lock" onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)} />
                            <BinarSmallBlock binarAccount={mainBinarAccountRef.current.rightBinarAccount?.rightBinarAccount?.rightBinarAccount} showOnEmpty="lock" onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)} />
                        </div>
                        {(rightmostBinarAccountLoaded && rightmostBinarAccountRef.current != null && rightmostBinarAccountRef.current.ownerAccount != null &&
                            rightmostBinarAccountRef.current.ownerAccount.id != rootUserId) && <div className="binar-last-account">
                                <div className="binar-last-account-label">Правый нижний участник</div>
                            <BinarBigBlock binarAccount={rightmostBinarAccountRef.current} onSelect={(v) => onSelectBinarAccount(v.ownerAccount.id)}
                                showInfo={true} onShowInfo={onShowRightmost}
                            />
                            </div>}
                        {homeUserId != mainBinarAccountRef.current.ownerAccount.id &&
                            <div className="binar-nav-panel">
                            <div className="binar-nav-panel-btn" onClick={() => {
                                onSelectBinarAccount(homeUserId);
                                setCurrentTeamView(-1);
                            }}><i className="fas fa-home" /> В начало</div>
                                <div className="binar-nav-panel-btn" onClick={() => {
                                    if (mainBinarAccountRef.current != null
                                        && mainBinarAccountRef.current.parentBinarAccount != null
                                        && mainBinarAccountRef.current.parentBinarAccount.ownerAccount != null) {
                                        onSelectBinarAccount(mainBinarAccountRef.current.parentBinarAccount.ownerAccount.id);
                                        if (mainBinarAccountRef.current.curatorLeg === 'left') {
                                            setCurrentTeamView(-1);
                                            setForceCurrentTeamView(true);
                                        }
                                        else if (mainBinarAccountRef.current.curatorLeg === 'right') {
                                            setCurrentTeamView(1);
                                            setForceCurrentTeamView(true);
                                        }
                                    }
                                }}><i className="fas fa-arrow-up" /> На 1 уровень</div>
                            </div>}
                    </div>
                </>}
            </>}
            {showTooltip && <TooltipComponent x={tooltipX} y={tooltipY} text={tooltipText} color={tooltipColor} onClose={closeTooltip} />}
            {showSearchPanel && <SearchPanel ref={searchPanelRef} ownerAccountID={homeUserId} onHide={() => setShowSearchPanel(false)} onSelect={onSelectBinarAccount} />}
            {(accountInfo && accountDetailInfo) && <BinarAccountInfo currentUser={homeUserRef} productionMode={productionMode} account={accountInfo} detailInfo={accountDetailInfo} onClose={() => setAccountInfo(null)} />}
        </div>
    )

};

export default BinarView;
