import * as React from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import { Account } from '../../../../../../store/Models';
import Autocomplete from '../../../simple/Autocomplete';

interface CoinChargeFormProps {
    onBack: () => void;
    productionMode: boolean;
}

const CoinChargeForm: React.FC<CoinChargeFormProps> = ({ onBack, productionMode }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);
    const [description, setDescription] = React.useState<string>('');
    const [coinAction, setCoinAction] = React.useState<number>(0);

    const clearForm = () => {
        setCoinAction(0);
        setDescription('');
        setSelectedAccount(null);
    }

    const validate = (): boolean => {
        return selectedAccount != null && description.length > 0 && coinAction != 0;
    }

    const doCharge = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('Description', description);
            formData.append('CoinAction', coinAction.toString());
            fetch('api/admin/chargeCoin', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Операция выполнена успешно.' });
                    }
                    else {
                        alert('Операция выполнена успешно.');
                    }
                    onBack();
                })
                .catch(() => {
                    if (productionMode) {
                        window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                    }
                    else {
                        alert('Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.');
                    }
                });
        }
    }

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    return (
        <div className="payment-approval-container">
            <div className="reg-module-view-header"><b>НАЧИСЛИТЬ/СПИСАТЬ ERA COIN</b></div>
            <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
            <div className="payment-approval-manual-container">
                <div className="reg-module-block">
                    <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                    <div>
                        {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.id + " " + a.humanName, value: a.id.toString(), search: a.id + " " + a.humanName + " " + a.name } })}
                            placeholder="Выберите Резидента" customValuesAllowed={false}
                            onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                        {selectedAccount != null && <>
                            <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.id + " " + selectedAccount.humanName}</b></div>
                            <button className="btn btn-primary btn-payment-edit"
                                onClick={clearForm}>Изм.</button>
                        </>}
                    </div>
                </div>
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Описание начисления/списания</div>
                    <div>
                        <input className="form-control" onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>}
                {selectedAccount && <div className="reg-module-block">
                    <div className="reg-module-label">Что делать с ERA Coin</div>
                    <div>
                        <select style={{ 'width': '100%' }} onChange={(e) => setCoinAction(parseInt(e.target.value))}>
                            <option value="0" selected>Ничего не делать</option>
                            <option value="1">Начислить</option>
                            <option value="-1">Списать</option>
                        </select>
                    </div>
                </div>}
                <div className="reg-module-submit">
                    <button className="btn btn-success" disabled={!validate()} onClick={doCharge}>Выполнить</button>
                </div>
            </div>
        </div>
    )
};

export default CoinChargeForm;
