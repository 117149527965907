import * as React from 'react';
import { Reflection, Post, PostFragment, Account } from '../store/Models';
import { makeDateTime, getClassRussian, founderId, getReactionInfo, getReactionsInfo } from '../utils';
import AudioPlayer from '../components/AudioPlayer';
import YouTubeEmbed from '../components/YouTubeEmbed';
import useFetch from '../hooks/useFetch';
import AccountAvatar from './telegramApp/AccountAvatar';
import Reaction from './telegramApp/components/simple/Reaction';

interface FounderChannelMessageProps {
    currentUser: Account;
    founderChannelMessage: Reflection;
    isFirst: boolean;
    isSecond: boolean;
    isExpanded: boolean;
    productionMode: boolean;
    onContextMenu: (founderChannelMessage: Reflection, isImmediate: boolean) => void;
    onReactionContextMenu: (postId: number, reactionCode: number) => void;
    onTouchMove: () => void;
    onTouchStop: () => void;
    onAccountClick: (account: Account) => void;
    onExpand: () => void;
    onRead: () => void;
    onView?: (index: number) => void;
}

const FounderChannelMessageComponent: React.FC<FounderChannelMessageProps> = ({ currentUser, founderChannelMessage, isFirst, isSecond, isExpanded,
    productionMode,
    onContextMenu, onReactionContextMenu, onTouchMove, onTouchStop, onAccountClick, onExpand, onRead, onView }) => {
    const fetch = useFetch();

    const mainElementRef = React.useRef<HTMLDivElement>(null);

    const [expandText, setExpandText] = React.useState(isExpanded);

    const openText = () => {
        //not allow if message is not available for user
        if (Math.max(currentUser.class, currentUser.bonusClass || 0) >= parseInt(founderChannelMessage.visibilityMode.toString())) {
            setExpandText(true);
            onExpand();
        }
        else showFounderClassRestrictionModal(parseInt(founderChannelMessage.visibilityMode.toString()));
    }

    const showFounderClassRestrictionModal = (messageClass: number) => {
        if (productionMode) {
            window.Telegram.WebApp.showPopup({
                title: "Упс... Доступ ограничен)", message: "Ерлан установил видимость данного сообщения только для тарифа " + getClassRussian(messageClass), buttons: [{ text: "Понятно" }]
            });
        }
        else {
            window.alert("Ерлан установил видимость данного сообщения только для тарифа " + getClassRussian(messageClass));
        }
    }

    const expandTextStyle: React.CSSProperties = {
        color: '#047ff9',
        cursor: 'pointer',
        fontStyle: 'italic'
    }

    const renderTextInstant = (text: string) => {
        return <div>{text.split(/\\n+/).map((paragraph, index) =>
            <p key={index} dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHtmlAtInstant(paragraph) }}></p>)}</div>
    }

    const renderTextFull = (text: string) => {
        if (expandText) {
            return renderTextInstant(text);
        }
        else {
            var cutText = text.split(' ').slice(0, 10).join(' '); //10 words
            var splittedText = cutText.split(/\\n+/);
            return <div>{
                splittedText.map((paragraph, index) =>
                    <p key={index}><span dangerouslySetInnerHTML={{ __html: paragraph.length > 0 ? paragraph : '&nbsp;' }}></span>{cutText.length < text.length && index == splittedText.length - 1 ? <span>...<span style={expandTextStyle}
                        onClick={openText}>&nbsp;Читать далее</span></span> : ""}</p>)
            }
            </div>
        }
    }

    const dangerouslySetInnerHtmlAtInstant = (paragraph: string): string => {
        var parsedSpacesParagraph = paragraph.split(' ').map((t, i) => {
            if (t.length > 0) return `<span key=${i}>${t} </span>`;
            else return `<span key=${i}>&nbsp;</span>`;
        }).join('');

        //find and parse links
        return parsedSpacesParagraph.replace(/(http||https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g, t => `<a href=${t} target="_blank">${t}</a>`);
    }

    const renderPost = (post: Post) => {
        return <div>
            <div className="full-post">
                {renderTextFull(post.contentText)}
            </div>
        </div>
    }

    const doRead = () => {
        if (founderChannelMessage.isRead == 0 && founderChannelMessage.post.publishAccount.id != currentUser.id
            && new Date(founderChannelMessage.post.publishDateTime) > new Date(currentUser.activeFrom)) {
            founderChannelMessage.isRead = 1;
            onRead();
            updateState(); //force component re-render after read
            fetch('/api/counter/readPost?postId=' + founderChannelMessage.post.id);
        }
        if (founderChannelMessage.unreadReactionsCount > 0 &&
            new Date(founderChannelMessage.post.publishDateTime) > new Date(currentUser.activeFrom)) {
            founderChannelMessage.unreadReactionsCount = 0;
            updateState(); //force component re-render after read
            fetch('/api/counter/readReactions?postId=' + founderChannelMessage.post.id);
        }
    }

    const reactionsStyle: React.CSSProperties = {
        position: 'absolute',
        left: '7px',
        bottom: '5px',
        fontSize: '14px',
        display: 'flex'
    }

    const singleReactionStyle: React.CSSProperties = {
        borderRadius: '15px',
        marginLeft: '7px',
        padding: '2px 7px 2px 0',
        cursor: 'pointer',
        background: '#ccc',
        display: 'flex',
        alignItems: 'center'
    }

    const singleMyReactionStyle: React.CSSProperties = {
        borderRadius: '15px',
        marginLeft: '7px',
        padding: '2px 7px 2px 0',
        background: 'lightblue',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    }

    const hasReactions = (): boolean => {
        return founderChannelMessage.reactionsInfo.length > 0;
    }

    const hasManyReactions = (): boolean => {
        return founderChannelMessage.reactionsInfo.split(';').length > 5;
    }

    const setReaction = (reactionCode: number) => {
        if (currentUser.id != founderId && reactionCode >= 10000) {
            if (productionMode) {
                window.Telegram.WebApp.showPopup({
                    title: "Упс... Недоступно!", message: "Данным набором эмодзи может пользоваться только основатель Клуба Ерлан Ахметов.", buttons: [{ text: "Понятно" }]
                });
            }
            else {
                window.alert("Упс... Недоступно! Данным набором эмодзи может пользоваться только основатель Клуба Ерлан Ахметов.");
            }
            return;
        }

        let reactionInfo = getReactionInfo(founderChannelMessage.reactionsInfo, reactionCode);

        var formData = new FormData();
        formData.append('ReflectionID', founderChannelMessage.id.toString());
        formData.append('PostID', founderChannelMessage.post.id.toString());
        formData.append('ReactionCode', reactionCode.toString());

        //if my, decrease code value, else increase
        if (reactionInfo != null) {
            if (reactionInfo.my > 0) {
                founderChannelMessage.reactionsInfo =
                    founderChannelMessage.reactionsInfo.replace(`${reactionCode}=${reactionInfo.count}/${reactionInfo.my}`, `${reactionCode}=${reactionInfo.count - 1}/0`);
            }
            else {
                founderChannelMessage.reactionsInfo =
                    founderChannelMessage.reactionsInfo.replace(`${reactionCode}=${reactionInfo.count}/${reactionInfo.my}`, `${reactionCode}=${reactionInfo.count + 1}/1`);
            }
        }
        else {
            if (founderChannelMessage.reactionsInfo.length > 0) {
                founderChannelMessage.reactionsInfo += `;${reactionCode}=1/1`;
            }
            else {
                founderChannelMessage.reactionsInfo = `${reactionCode}=1/1`;
            }
        }

        updateState(); //force component re-render after like click

        fetch(reactionInfo != null && reactionInfo.my > 0 ? 'api/founderChannel/dropReaction' : 'api/founderChannel/setReaction', {
            method: 'POST',
            body: formData
        });
    }

    const cardBodyStyle: React.CSSProperties = {
        'borderRadius': '20px',
        'border': 'none',
        'paddingBottom': hasManyReactions() ? '20px' : hasReactions() ? '15px' : '10px',
        'paddingTop': '3px',
        'paddingLeft': '10px',
        'marginRight': '40px'
    }

    const bottomStyle: React.CSSProperties = {
        position: 'absolute',
        right: '7px',
        bottom: '5px',
        fontSize: '12px',
        textAlign: 'right',
        color: '#888'
    }

    const modifiedStyle: React.CSSProperties = {
        fontSize: '10px'
    }

    /*
     * Touch Events 
     */

    React.useEffect(() => {

        const handleTouchStart = () => {
            if (mainElementRef.current != null) {
                onContextMenu(founderChannelMessage, false);
            }
        }

        const handleRightClick = (event: MouseEvent) => {
            event.preventDefault();
            if (mainElementRef.current != null) {
                onContextMenu(founderChannelMessage, true);
            }
        }

        if (window.Telegram.WebApp.platform === 'ios') {
            if (mainElementRef.current != null) {
                mainElementRef.current.addEventListener('touchstart', handleTouchStart, { passive: false });
                mainElementRef.current.addEventListener('touchmove', onTouchMove, { passive: false });
                mainElementRef.current.addEventListener('touchend', onTouchStop, { passive: false });

            }
        }
        else {
            if (mainElementRef.current != null) {
                mainElementRef.current.addEventListener('contextmenu', handleRightClick, { passive: false });
            }
        }

        return () => {
            if (window.Telegram.WebApp.platform === 'ios') {
                if (mainElementRef.current != null) {
                    mainElementRef.current.removeEventListener('touchstart', handleTouchStart);
                    mainElementRef.current.removeEventListener('touchmove', onTouchMove);
                    mainElementRef.current.removeEventListener('touchend', onTouchStop);
                }
            }
            else {
                if (mainElementRef.current != null) {
                    mainElementRef.current.removeEventListener('contextmenu', handleRightClick);
                }
            }
        }
    }, []);

    const reactionTouchTimerRef = React.useRef<number>(0);

    const setDefaultReaction = () => {
        setReaction(4);
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const isInView = entry.isIntersecting;

                if (isInView) {

                    doRead();
                    if (onView) {
                        onView(founderChannelMessage.id);
                    }
                }
            },
            {
                threshold: 1
            }
        );

        if (mainElementRef.current) {
            observer.observe(mainElementRef.current);
        }

        return () => {
            if (mainElementRef.current) {
                observer.unobserve(mainElementRef.current);
            }
        };
    }, []);

    const [refreshState, setRefreshState] = React.useState<number>(0);

    const updateState = () => setRefreshState(x => x + 1);

    React.useEffect(() => {
        console.log('Component state was updated. Will it work?');
    }, [refreshState]);

    return (
        <div ref={mainElementRef} id={founderChannelMessage.id.toString()}>
            <div className="reflection-card">
                {founderChannelMessage.post.publishAccount.id != currentUser.id && <AccountAvatar size={40} account={founderChannelMessage.post.publishAccount}
                    showAsAdmin={false}
                    classArg={40} onClick={onAccountClick} />}
                <div style={cardBodyStyle} className={founderChannelMessage.post.publishAccount.id == currentUser.id ? "card card-body card-mine" : "card card-body"}
                    onDoubleClick={setDefaultReaction}>

                    {founderChannelMessage.post.publishAccount.id == currentUser.id && <div className="reflection-value-resident"><i className="fas fa-eye" /> {getClassRussian(founderChannelMessage.visibilityMode)}</div>}

                    <div className="name busplus">
                        <span>{founderChannelMessage.post.publishAccount.name}</span>
                    </div>
                    <div className="card-text">{renderPost(founderChannelMessage.post)}</div>
                    <div style={reactionsStyle}>
                        {getReactionsInfo(founderChannelMessage.reactionsInfo).filter(x => x.count > 0).map(x => (
                            <div style={x.my > 0 ? singleMyReactionStyle : singleReactionStyle} onClick={() => setReaction(x.code)}>&nbsp;
                                <Reaction code={x.code} />
                                {x.count}&nbsp;</div>
                        ))}
                    </div>

                    <div style={bottomStyle}>
                        {founderChannelMessage.isModified > 0 && <span style={modifiedStyle}>изменено </span>}{makeDateTime(founderChannelMessage.post.publishDateTime.toString())}
                        {founderChannelMessage.post.publishAccount.id == currentUser.id && <span className="reflection-send-status"> {founderChannelMessage.id == 0 ? <i className="fas fa-clock" /> : <i className="fas fa-check" />}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FounderChannelMessageComponent;
