﻿//import { useLoading } from '../context/LoadingContext';

import { mockTelegramObject } from "../utils";

const useFetchWithTimeout = () => {
    //const { setLoading } = useLoading();

    const request = async (input: RequestInfo, options: RequestInit = {}, timeout: number = 5000) => {
        const controller = new AbortController();
        const { signal } = controller;

        const timeoutId = setTimeout(() => controller.abort(), timeout);

        try {
            const telegram = window.Telegram.WebApp != null && window.Telegram.WebApp.initDataUnsafe != null && window.Telegram.WebApp.initDataUnsafe.user != null ?
                window.Telegram.WebApp : mockTelegramObject;
            const headers = new Headers(options != null ? options.headers : {});
            if (telegram != null
                && telegram.initDataUnsafe != null
                && telegram.initDataUnsafe.user != null
                && telegram.initDataUnsafe.user.id != null) {
                var telegramId = telegram.initDataUnsafe.user.id;
                headers.append('X-Telegram-ID', telegramId);
            }

            const response = await fetch(input, { ...options, headers, signal });
            clearTimeout(timeoutId); // Clear the timeout if the request is successful
            return response;
        } catch (error: any) {
            if (error.name === 'AbortError') {
                throw new Error('Request timed out');
            }
            throw error;
        }
    };

    return request;
}

export default useFetchWithTimeout;