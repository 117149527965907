import * as React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Account, SpeakershipRewardTransactionExt } from '../../../../../store/Models';
import { formatMoney, makeDateTime } from '../../../../../utils';
import AccountFilter from '../../simple/AccountFilter';
import SpeakershipRewardAddRecordForm from './forms/SpeakershipRewardAddRecordForm';

interface AdminSpeakershipRewardManagementProps {
    productionMode: boolean;
    onAddRecord: () => void;
}

const AdminSpeakershipRewardManagement: React.FC<AdminSpeakershipRewardManagementProps> = ({ productionMode, onAddRecord }) => {
    const fetch = useFetch();

    const [accounts, setAccounts] = React.useState<Account[]>([]);

    const [speakershipRewardFilterAccountId, setSpeakershipRewardFilterAccountId] = React.useState<string>('');
    const [speakershipRewardFilterStatus, setSpeakershipRewardFilterStatus] = React.useState<string>('');

    const [speakershipRewardTransactions, setSpeakershipRewardTransactions] = React.useState<SpeakershipRewardTransactionExt[]>([]);

    React.useEffect(() => {
        fetch('/api/payment/getAccounts')
            .then(res => res.json() as Promise<Account[]>)
            .then((data) => {
                setAccounts(data);
            });
    }, []);

    React.useEffect(() => {
        var queryStringList = [];
        if (speakershipRewardFilterAccountId.length > 0) {
            queryStringList.push('accountId=' + speakershipRewardFilterAccountId);
        }
        if (speakershipRewardFilterStatus.length > 0) {
            queryStringList.push('status=' + speakershipRewardFilterStatus);
        }
        fetch('/api/admin/getSpeakershipRewardTransactions?' + queryStringList.join('&'))
            .then(res => res.json() as Promise<SpeakershipRewardTransactionExt[]>)
            .then(data => setSpeakershipRewardTransactions(data));
    }, [speakershipRewardFilterAccountId, speakershipRewardFilterStatus]);

    return (
        <div className="payment-approval-container">
            <button style={{ marginTop: '10px', marginBottom: '10px' }} className="btn btn-success btn-payment-add-manual" onClick={onAddRecord}>Добавить операцию</button>
            <div className="wallet-transactions-filter-panel">
                <AccountFilter accounts={accounts} placeholder="" label="Резидент"
                    onSelect={(id) => setSpeakershipRewardFilterAccountId(id.toString())}
                    selectAllOptionText="Все Резиденты"
                    onSelectAllOption={() => setSpeakershipRewardFilterAccountId('')}
                    defaultValue=""
                />
                <div className="cashback-transactions-filter-block">
                    <div className="wallet-transactions-filter-label">Статус</div>
                    <select className="wallet-transactions-filter-select" onChange={(e) => setSpeakershipRewardFilterStatus(e.target.value)} defaultValue="">
                        <option value="">Все статусы</option>
                        <option value="income">Начислено</option>
                        <option value="earn">Заработано</option>
                    </select>
                </div>
            </div>
            <div className="wallet-transactions-filter-total">Итого:
                &nbsp;
                {speakershipRewardFilterStatus !== 'earn' && <span style={{ color: 'green' }}>${formatMoney(speakershipRewardTransactions.filter(x => x.value > 0).reduce((accumulator, current) => { return accumulator + current.value }, 0))}</span>}
                {speakershipRewardFilterStatus.length == 0 && <span>&nbsp;/&nbsp;</span>}
                {speakershipRewardFilterStatus !== 'income' && <span>${formatMoney(speakershipRewardTransactions.filter(x => x.value < 0).reduce((accumulator, current) => { return accumulator + Math.abs(current.value) }, 0))}</span>}
            </div>
            <div style={{ height: "calc(100% - 198.3px)", overflowY: 'auto' }}>
                {speakershipRewardTransactions.length > 0 && <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                    <thead style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                        <tr>
                            <th>Дата и время операции</th>
                            <th>Резидент</th>
                            <th>Описание</th>
                            <th style={{ textAlign: 'right' }}>Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                        {speakershipRewardTransactions.length > 0 ? speakershipRewardTransactions.map((ct, i) => (
                            <tr>
                                <td>{makeDateTime(ct.transactionDateTime)}</td>
                                <td>{ct.account.humanName}</td>
                                <td>{ct.description}</td>
                                <td style={{ width: '25%', textAlign: 'right' }}>{ct.value > 0 ? <span style={{ color: 'green' }}><b>+${formatMoney(ct.value)}</b></span>
                                    : <span style={{ color: 'red' }}><b>-${formatMoney(Math.abs(ct.value))}</b></span>}</td>
                            </tr>
                        )) : <tr>
                            <td colSpan={4}>Здесь пока нет операций.</td>
                        </tr>}
                    </tbody>
                </table>}
            </div>
        </div>
    )
};

export default AdminSpeakershipRewardManagement;
