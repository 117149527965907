import * as React from 'react';
import useFetch from '../../../hooks/useFetch';
import { Account, MillionaireMorningItem, Reflection } from '../../../store/Models';
import { getCookie, mockTelegramObject, saveCookie, reflectionsBatchSize } from '../../../utils';
import AccountProfile from '../AccountProfile';
import AccountView from '../AccountView';
import ChallengeView from '../ChallengeView';
import ClassicView from '../Classic';
import ClassicShortView from '../ClassicShort';
import TestComponent from '../components/simple/TestComponent';
import MillionaireMorningModule from '../MillionaireMorningModule';
import PaymentReminder from '../components/simple/PaymentReminder';
import ReflectionModule from '../ReflectionModule';
import RegModule from '../components/complex/reg/RegModule';
import StreamsModule from '../StreamsModule';
import WalletTransactionsView from '../WalletTransactions';
import BottomPanel from './BottomPanel';
import InputPanel from './InputPanel';
import TopPanel from './TopPanel';
import Renewal from '../components/complex/renewal/Renewal';
import AdminMainScreen from '../components/complex/admin/AdminMainScreen';
import MainLoaderCoverScreen from '../components/simple/MainLoaderCoverScreen';
import FounderChannelModule from '../FounderChannelModule';
import CashbackTransactionsView from '../CashbackTransactions';
import SpeakershipRewardTransactionsView from '../SpeakershipRewardTransactions';
import Dashboard from '../components/simple/Dashboard';
import MotivationFact from '../components/simple/MotivationFact';

interface MainLayoutProps {
    productionMode: boolean;
    skipInitialDataLoading: boolean;
}

interface ReferralData {
    referrerID: number;
    companyEventID: number;
}

interface ModalButton {
    id: string;
    type: string;
    text: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({ productionMode, skipInitialDataLoading }) => {
    const fetch = useFetch();

    const appState = {
        MillionaireMorning: 'MILLIONAIRE_MORNING',
        Streams: 'STREAMS',
        Reflections: 'REFLECTIONS',
        FounderChannel: 'FOUNDER_CHANNEL',
        Challenge: 'CHALLENGE',
        AccountProfile: 'ACCOUNT_PROFILE',
        Admin: 'ADMIN',
        Test: 'TEST',
        Reg0: 'REG0',
        Reg1: 'REG1',
        Reg2: 'REG2',
        Renewal: 'RENEWAL',
        Deny: 'DENY'
    }

    const [state, setState] = React.useState<string>('');

    const userRef = React.useRef<Account>({
        id: 0, class: -1, isActive: 0, name: '',
        isAdmin: 0, level: 0, profileUrl: '', isAmbassador: 0, birthday: '',
        username: '', city: '', businessKind: '', businessSphere: '', eraCoins: 0,
        instaProfile: '', activeFrom: '2024-08-01', canEvaluate: 0, humanName: '', accessGmail: '', daysLeft: null, referralLink: null,
        displayAchievements: null, bonusClass: null, personalMessageType: '', personalMessage: '', gender: '', prefs: ''
    })

    const [millionaireMorningItems, setMillionaireMorningItems] = React.useState<MillionaireMorningItem[]>([]);
    const reflectionsInitialRef = React.useRef<Reflection[]>([]);
    const [reflections, setReflections] = React.useState<Reflection[]>([]);
    const [unreadReflectionsCount, setUnreadReflectionsCount] = React.useState<number>(0);
    const [streams, setStreams] = React.useState<Reflection[]>([]);
    const [unreadStreamsCount, setUnreadStreamsCount] = React.useState<number>(0);

    const [founderChannelMessages, setFounderChannelMessages] = React.useState<Reflection[]>([]);
    const [unreadFounderChannelMessagesCount, setUnreadFounderChannelMessagesCount] = React.useState<number>(0);
    const [accountProfileChanges, setAccountProfileChanges] = React.useState<boolean>(false);
    const [reloadAccountProfileDummy, setReloadAccountProfileDummy] = React.useState<number>(0);

    const mainLayoutRef = React.useRef<HTMLDivElement>(null);

    const [showSendMenu, setShowSendMenu] = React.useState<boolean>(false);
    const [showReflectionContextMenu, setShowReflectionContextMenu] = React.useState<boolean>(false);
    const [showFounderChannelContextMenu, setShowFounderChannelContextMenu] = React.useState<boolean>(false);
    const [showMillionaireMorningItemContextMenu, setShowMillionaireMorningItemContextMenu] = React.useState<boolean>(false);
    const [showFormattingMenu, setShowFormattingMenu] = React.useState<boolean>(false);
    const [showChallengeModes, setShowChallengeModes] = React.useState<boolean>(false);
    const [hidePaymentReminder, setHidePaymentReminder] = React.useState<boolean>(false);
    const [showExpirationText, setShowExpirationText] = React.useState<boolean>(false);

    const editModeRef = React.useRef<boolean>(false);
    const replyModeRef = React.useRef<boolean>(false);

    const [selectedReflection, setSelectedReflection] = React.useState<Reflection | null>(null);
    const [selectedMillionaireMorningItem, setSelectedMillionaireMorningItem] = React.useState<MillionaireMorningItem | null>(null);
    const [selectedFounderChannelMessage, setSelectedFounderChannelMessage] = React.useState<Reflection | null>(null);
    const [selectedChallengeMode, setSelectedChallengeMode] = React.useState<number>(0);
    const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(null);

    const [showClassic, setShowClassic] = React.useState<number>(0);
    const [showWalletTransactions, setShowWalletTransactions] = React.useState<boolean>(false);
    const [showCashbackTransactions, setShowCashbackTransactions] = React.useState<boolean>(false);
    const [showSpeakershipRewardTransactions, setShowSpeakershipRewardTransactions] = React.useState<boolean>(false);
    const [referrerID, setReferrerID] = React.useState<number>(0);
    const [companyEventID, setCompanyEventID] = React.useState<number>(0);

    const [showMotivationFact, setShowMotivationFact] = React.useState<boolean>(false);

    const reflectionsView = {
        All: 'ALL',
        NotLowerThanMine: 'NOT_LOWER_THAN_MINE',
        My: 'MY'
    }

    const [reflectionsViewState, setReflectionsViewState] = React.useState<string>('');
    const [showFilter, setShowFilter] = React.useState<boolean>(false);

    const stopInitialProcessViewRef = React.useRef<boolean>(false);

    const onSelectMillionaireMorning = () => {
        setState(appState.MillionaireMorning);
        saveCookie("state", appState.MillionaireMorning, 1);
    }

    const onSelectStreams = () => {
        setState(appState.Streams);
        saveCookie("state", appState.Streams, 1);
    }

    const onSelectReflections = () => {
        setState(appState.Reflections);
        saveCookie("state", appState.Reflections, 1);
    }

    const onSelectFounderChannel = () => {
        setState(appState.FounderChannel);
        saveCookie("state", appState.FounderChannel, 1);
    }

    const onSelectChallenge = () => {
        setState(appState.Challenge);
        saveCookie("state", appState.Challenge, 1);
    }

    const onSelectAccountProfile = () => {
        setState(appState.AccountProfile);
        saveCookie("state", appState.AccountProfile, 1);
    }

    const onSelectAdmin = () => {
        setState(appState.Admin);
    }

    const onAddDefaultMillionaireMorningItem = (item: MillionaireMorningItem) => {
        setMillionaireMorningItems(x => x.concat(item));
        window.setTimeout(() => {
            var targetElement = document.getElementById('0');

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 200);
    }

    const onEnrichMillionaireMorningItem = (item: MillionaireMorningItem) => {
        setMillionaireMorningItems(x => x.filter(m => m.id != 0).concat(item));
    }

    const onAddDefaultStream = (item: Reflection) => {
        setStreams(x => x.concat(item));
        window.setTimeout(() => {
            var targetElement = document.getElementById('0');

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 200);
    }

    const onEnrichStream = (item: Reflection) => {
        setStreams(x => x.filter(s => s.id != 0).concat(item));
    }

    const onAddDefaultReflection = (item: Reflection) => {
        //enrich reply reflection data
        if (item.replyReflection != null) {
            item.replyReflection = reflections.filter(r => item.replyReflection != null && r.id == item.replyReflection.id)[0];
        }
        setReflections(x => x.concat(item));

        if (item.replyReflection != null) {
            window.setTimeout(() => {
                var targetElement = document.getElementById('0');

                if (targetElement != null) {
                    targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
                }
            }, 200);
        }
        else {
            window.setTimeout(() => {
                var targetElement = document.getElementById('0');

                if (targetElement != null) {
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 200);
        }
    }

    const onEnrichReflection = (item: Reflection) => {
        //enrich reply reflection data
        if (item.replyReflection != null) {
            item.replyReflection = reflections.filter(r => item.replyReflection != null && r.id == item.replyReflection.id)[0];
        }
        setReflections(x => x.filter(r => r.id != 0).concat(item));
        reflectionsInitialRef.current.push(item);
    }

    const onAddDefaultFounderChannelMessage = (item: Reflection) => {
        setFounderChannelMessages(x => x.concat(item));
        window.setTimeout(() => {
            var targetElement = document.getElementById('0');

            if (targetElement != null) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 200);
    }

    const onEnrichFounderChannelMessage = (item: Reflection) => {
        setFounderChannelMessages(x => x.filter(f => f.id != 0).concat(item));
    }

    const telegram = productionMode ? window.Telegram.WebApp : mockTelegramObject;

    const modalHeader = "Упс... Что-то пошло не так)";

    const okModalButton: ModalButton = { id: '', type: 'default', text: 'Понятно' };
    const serviceModalButton: ModalButton = { id: 'go_to_service', type: 'default', text: 'Служба Заботы' };
    const reloadModalButton: ModalButton = { id: 'reload', type: 'default', text: 'Обновить' };
    const renewalModalButton: ModalButton = { id: 'renewal', type: 'default', text: 'Продлить подписку' };

    const modalButtonHandler = (id: string) => {
        switch (id) {
            case 'go_to_service':
                window.Telegram.WebApp.openTelegramLink('https://t.me/sib_era');
                break;
            case 'reload':
                window.location.reload();
                break;
            case 'renewal':
                setState(appState.Renewal);
                break;
        }
    }

    const setError = (error: any, message: string, buttons: ModalButton[]) => {
        var errorMessage = '';
        var trace = '';

        if (typeof error === "string") {
            errorMessage = "Error (string): " + error;
            trace = '<no trace>';
        } else if (error instanceof Error) {
            errorMessage = error.message;
            trace = error.stack || '<no trace>';
        } else {
            errorMessage = "Unknown error: " + error;
            trace = '<no trace>';
        }

        fetch('/api/frontend/logException?message=' + errorMessage + '&trace=' + trace)
            .then(() => {
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({ title: modalHeader, message: message, buttons: buttons }, modalButtonHandler);
                }
                else {
                    alert(message);
                    console.log(message);
                }
            })
            .catch((e) => {
                var exceptionMessage = '';
                var exceptionTrace = '';
                if (typeof e === "string") {
                    exceptionMessage = "Error (string): " + error;
                    exceptionTrace = '<no trace>';
                } else if (e instanceof Error) {
                    exceptionMessage = error.message;
                    exceptionTrace = error.stack || '<no trace>';
                } else {
                    exceptionMessage = "Unknown error: " + error;
                    exceptionTrace = '<no trace>';
                }
                var finalMessage = "Ошибка #9001:\nПроизошла непредвиденная ошибка.\nПожалуйста, передайте скриншот этой ошибки в Службу Заботы.\nИнформация об ошибке:\n"
                    + message + "\n"
                    + "ErrorMessage: " + errorMessage + "\n"
                    + "Trace: " + trace + "\n"
                    + "ExceptionMessage: " + exceptionMessage + "\n"
                    + "ExceptionTrace: " + exceptionTrace;
                if (productionMode) {
                    window.Telegram.WebApp.showPopup({
                        title: modalHeader,
                        message: finalMessage,
                        buttons: [serviceModalButton, okModalButton]
                    }, modalButtonHandler);
                }
                else {
                    alert(finalMessage);
                    console.log(finalMessage);
                }
            });
    }

    const selectAllReflections = () => {
        if (userRef.current != null) {
            setReflections(reflectionsInitialRef.current.filter(r =>
                r.visibilityMode < 100
            ));
            setReflectionsViewState(reflectionsView.All);
        }
    }

    const selectNotLowerThanMine = () => {
        if (userRef.current != null) {
            setReflections(reflectionsInitialRef.current.filter(r =>
                (r.class >= Math.max(userRef.current.class, userRef.current.bonusClass || 0) && r.visibilityMode < 100)
            ));
            setReflectionsViewState(reflectionsView.NotLowerThanMine);
        }
    }

    const selectMy = () => {
        if (userRef.current != null) {
            setReflections(reflectionsInitialRef.current.filter(r =>
                r.post.publishAccount.id === userRef.current.id && r.visibilityMode < 100));
            setReflectionsViewState(reflectionsView.My);
        }
    }

    const selectStreams = () => {
        setStreams(reflectionsInitialRef.current.filter(r =>
            (userRef.current.isAdmin > 0 && r.visibilityMode > 100)
            ||
            (r.visibilityMode > 100 && 100 + Math.max(userRef.current.class, userRef.current.bonusClass || 0) >= r.visibilityMode)
            ||
            (r.visibilityMode == 110 && userRef.current.class == 5) // for exclusive
            ||
            (r.visibilityMode > 200 && 200 + Math.max(userRef.current.class, userRef.current.bonusClass || 0) == r.visibilityMode)
            ||
            (r.visibilityMode == 210 && userRef.current.class == 5) // for exclusive
        ));
    }

    const processView = React.useCallback(() => {
        if (productionMode && telegram.isVersionAtLeast('8.0') && telegram.exitFullscreen) {
            telegram.exitFullscreen();
        }
        var cookieState = getCookie("state");
        if (cookieState != null) {
            setState(cookieState);
        }
        else {
            onSelectMillionaireMorning();
        }
        //window.setTimeout(() => setShowMotivationFact(true), 3000);
    }, [state]);

    const [loadingState, setLoadingState] = React.useState<number>(0);

    React.useEffect(() => {
        console.log('loading state: ' + loadingState);
        console.log('prevent redirect: ' + stopInitialProcessViewRef.current);
        if (loadingState == 2 && !stopInitialProcessViewRef.current) {
            console.log('process view...');
            processView();
        }
    }, [loadingState]);

    const getInitialData = () => {
        /*
        const params = new URLSearchParams({
            dateFrom: filterState != null && filterState.dateFrom ? filterState.dateFrom : '',
            dateTo: filterState != null && filterState.dateTo ? filterState.dateTo : ''
        });
        */
        fetch(`api/millionaireMorningItem`)
            .then(response => response.json() as Promise<MillionaireMorningItem[]>)
            .then(data => {
                setMillionaireMorningItems(x => x.concat(data));
                fetch(`api/reflection/getReflections`)
                    .then(response => response.json() as Promise<Reflection[]>)
                    .then(data => {
                        // enrich reply reflections with their all data
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].replyReflection != null) {
                                let replyReflection = data[i].replyReflection;
                                data[i].replyReflection = data.filter(r => replyReflection != null && r.id == replyReflection.id)[0];
                            }
                        }
                        reflectionsInitialRef.current = data;
                        setUnreadReflectionsCount(
                            data.slice(data.length - reflectionsBatchSize, data.length)
                                .filter(
                                    r => r.isRead == 0 && r.visibilityMode < 100 && r.post.publishAccount.id != userRef.current.id
                                        && new Date(r.post.publishDateTime) > new Date(userRef.current.activeFrom)).length);
                        setUnreadStreamsCount(
                            data.slice(data.length - reflectionsBatchSize, data.length).filter(r =>
                                (userRef.current.isAdmin > 0 && r.visibilityMode > 100)
                                ||
                                (r.visibilityMode > 100 && 100 + Math.max(userRef.current.class, userRef.current.bonusClass || 0) >= r.visibilityMode)
                                ||
                                (r.visibilityMode == 110 && userRef.current.class == 5) // for exclusive
                                ||
                                (r.visibilityMode > 200 && 200 + Math.max(userRef.current.class, userRef.current.bonusClass || 0) == r.visibilityMode)
                                ||
                                (r.visibilityMode == 210 && userRef.current.class == 5) // for exclusive
                            ).filter(s => s.isRead == 0 && s.post.publishAccount.id != userRef.current.id
                                && new Date(s.post.publishDateTime) > new Date(userRef.current.activeFrom)).length);

                        selectStreams();
                        selectAllReflections();
                        fetch('/api/founderChannel/getMessages')
                            .then(response => response.json() as Promise<Reflection[]>)
                            .then(data => {
                                setFounderChannelMessages(data);
                                setUnreadFounderChannelMessagesCount(data.filter(
                                    f => f.isRead == 0 && f.post.publishAccount.id != userRef.current.id
                                        && new Date(f.post.publishDateTime) > new Date(userRef.current.activeFrom)).length);

                                if (userRef.current.daysLeft !== null && userRef.current.daysLeft >= 0 && userRef.current.daysLeft <= 30 && (new Date()).getDate() >= 21) {
                                    window.setTimeout(() => {
                                        console.log('getInitialData is ready');
                                        setLoadingState(x => x + 1);
                                    }, 1000);
                                }
                                else if (userRef.current.daysLeft !== null && userRef.current.daysLeft < 0) {
                                    window.setTimeout(() => setState(appState.Renewal), 1000);
                                }
                                else {
                                    console.log('getInitialData is ready');
                                    setLoadingState(x => x + 1);
                                }
                            })
                            .catch(e => {
                                setError(e, "Ошибка #1003:\nОшибка загрузки раздела «Ерлан».\nПожалуйста, обновите приложение.", [reloadModalButton, okModalButton]);
                            });
                    }).catch(e => {
                        setError(e, "Ошибка #1001:\nОшибка загрузки раздела «Рефлексии».\nПожалуйста, обновите приложение.", [reloadModalButton, okModalButton]);
                    });
            })
            .catch(e => {
                setError(e, "Ошибка #1002:\nОшибка загрузки раздела «Утро».\nПожалуйста, обновите приложение.", [reloadModalButton, okModalButton]);
            });
    }

    const processRegViews = (status: number) => {
        switch (status) {
            case 10:
                if (productionMode && telegram.isVersionAtLeast('8.0') && telegram.exitFullscreen) {
                    telegram.exitFullscreen();
                }
                setState(appState.Reg0);
                break;
            case 20:
                if (productionMode && telegram.isVersionAtLeast('8.0') && telegram.exitFullscreen) {
                    telegram.exitFullscreen();
                }
                setState(appState.Reg1);
                break;
            case 30:
                if (productionMode && telegram.isVersionAtLeast('8.0') && telegram.exitFullscreen) {
                    telegram.exitFullscreen();
                }
                setState(appState.Reg2);
                break;
            case -30:
                if (productionMode && telegram.isVersionAtLeast('8.0') && telegram.exitFullscreen) {
                    telegram.exitFullscreen();
                }
                setState(appState.Deny);
                break;
        }
    }

    const formDataToString = (formData: FormData): string => {
        const keyValuePairs: string[] = [];

        formData.forEach((value, key) => {
            keyValuePairs.push(`${key}=${value}`);
        });

        return keyValuePairs.join("&");
    }

    const processLogin = (formData: FormData) => {
        fetch('/api/account/login', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (response.status === 200) {
                response.json().then(loginData => {
                    var classValue = parseInt(loginData.class);
                    if (classValue < 0) {
                        if (formData.has('ReferralHash')) {
                            fetch('api/account/getReferralInfo?hash=' + formData.get('ReferralHash'))
                                .then(response => {
                                    if (response.status === 200) {
                                        (response.json() as Promise<ReferralData>).then(referralData => {
                                            setReferrerID(referralData.referrerID);
                                            setCompanyEventID(referralData.companyEventID);
                                            processRegViews(parseInt(loginData.regStatus));
                                        });
                                    }
                                    else if (response.status === 400) {
                                        setError('api/account/getReferralInfo?hash=' + formData.get('ReferralHash') + ' (code: 400)',
                                            'Ошибка #2001:\nВаша реферальная ссылка недействительна.\nПожалуйста, обратитесь в Службу Заботы.', [serviceModalButton, okModalButton]);
                                    }
                                    else {
                                        setError('api/account/getReferralInfo?hash=' + formData.get('ReferralHash') + ` (code: ${response.status})`,
                                            'Ошибка #2002', [okModalButton]);
                                    }
                                })
                                .catch(e => {
                                    setError(e, 'Ошибка #2003', [okModalButton]);
                                });
                        }
                        else {
                            processRegViews(parseInt(loginData.regStatus));
                        }
                    }
                    else {
                        if (loginData.isActive > 0 || loginData.isActive) {
                            if (formData.has('ReferralHash')) {
                                //don't allow paid users go to full app version via referral links, redirect to chat
                                goToChat();
                            }
                            else {
                                userRef.current = loginData as Account;
                                if (userRef.current.daysLeft != null && userRef.current.daysLeft <= 30 && (new Date()).getDate() >= 21) {
                                    setShowExpirationText(true);
                                }
                                console.log('login is ready');
                                window.setTimeout(() => setLoadingState(x => x + 1), 7000);
                                if (!skipInitialDataLoading) {
                                    getInitialData();
                                }
                                else {
                                    // simply go further
                                    setLoadingState(x => x + 1);
                                }
                            }
                        }
                        else {
                            if (productionMode) {
                                window.Telegram.WebApp.showPopup({
                                    title: "Упс... Доступ ограничен)",
                                    message: "Ошибка #2007:\nВаша подписка истекла.\nДля доступа к приложению продлите подписку в Клубе MillionERA и/или обратитесь в Службу Заботы.",
                                    buttons: [renewalModalButton, serviceModalButton]
                                }, modalButtonHandler);
                            }
                            else {
                                window.alert("Ошибка #2007:\nВаша подписка истекла.\nДля доступа к приложению продлите подписку в Клубе MillionERA и/или обратитесь в Службу Заботы.");
                            }
                        }
                    }
                });
            }
            else if (response.status === 400) {
                setError('/api/account/login (code: 400), FormData: ' + formDataToString(formData),
                    'Ошибка #2004:\nДанная реферальная ссылка не может быть использована Вами для регистрации в приложении, так как Вы уже были зарегистрированы ранее.\nПожалуйста, обратитесь в Службу Заботы.',
                    [serviceModalButton, okModalButton]);
            }
            else {
                setError(`/api/account/login (code: ${response.status}), FormData: ` + formDataToString(formData),
                    'Ошибка #2005', [okModalButton]);
            }
        }).catch(e => {
            setError(e, 'Ошибка #2006', [okModalButton]);
        });
    }

    const getStartParam = (): string | null => {
        const params = new URLSearchParams(productionMode ? window.Telegram.WebApp.initData : window.location.search);
        return params.get('start_param');
    }

    const goToChat = () => {
        if (productionMode) {
            telegram.openLink("https://t.me/+k_Jjg-x0UFE5NThi", { confirm: false });
        }
        else {
            window.location.href = "https://t.me/+k_Jjg-x0UFE5NThi";
        }
    }

    /* first, get current user */
    React.useEffect(() => {
        window.onerror = () => {
            window.onerror = (e, u, l) => {
                setError(e, e + ' URL:' + u + ' Line:' + l, [okModalButton]);
            };
        };

        try {
            var formData = new FormData();
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.ready();
                if (telegram != null && telegram.initDataUnsafe != null && telegram.initDataUnsafe.user != null) {
                    if (telegram.initDataUnsafe.user.id == null) {
                        setError('telegram.initDataUnsafe.user.id = null', 'Ошибка #3004', [okModalButton]);
                    }
                    else {
                        var userId = telegram.initDataUnsafe.user.id != null ? telegram.initDataUnsafe.user.id : '';
                        formData.append('TelegramId', userId);
                        var username = telegram.initDataUnsafe.user.username != null ? telegram.initDataUnsafe.user.username : '';
                        formData.append('Username', username);
                        var firstName = telegram.initDataUnsafe.user.first_name;
                        formData.append('FirstName', firstName);
                        var lastName = telegram.initDataUnsafe.user.last_name;
                        formData.append('LastName', lastName);
                        formData.append('LastDeviceInfo', 'Bot API Version: ' + telegram.version + ' Platform: ' + telegram.platform + ' User-Agent: ' + navigator.userAgent);
                        var referralHash = getStartParam();
                        if (referralHash != null) {
                            console.log(referralHash);
                            formData.append('ReferralHash', referralHash);
                        }
                        processLogin(formData);
                    }
                }
                else {
                    if (telegram == null) {
                        setError('telegram = null', 'Ошибка #3001', [okModalButton]);
                    }
                    else if (telegram.initDataUnsafe == null) {
                        setError('telegram.initDataUnsafe = null', 'Ошибка #3002', [okModalButton]);
                    }
                    else if (telegram.initDataUnsafe.user == null) {
                        setError('telegram.initDataUnsafe.user = null', 'Ошибка #3003', [okModalButton]);
                    }
                }
            }
            else {
                setError('window.Telegram or window.Telegram.WebApp is null', 'Ошибка #3006', [okModalButton]);
            }
        }
        catch (e) {
            setError(e, 'Ошибка #3005', [okModalButton]);
        }
    }, []);

    React.useEffect(() => {
        const handleClickOutside = () => {
            if (showSendMenu) {
                setShowSendMenu(false);
            }
            if (showReflectionContextMenu || showMillionaireMorningItemContextMenu || showFounderChannelContextMenu) {
                onCloseContextMenu();
            }
            if (showFilter) {
                setShowFilter(false);
            }
            if (showFormattingMenu) {
                setShowFormattingMenu(false);
            }
            if (showChallengeModes) {
                setShowChallengeModes(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showSendMenu, showReflectionContextMenu, showMillionaireMorningItemContextMenu, showFounderChannelContextMenu, showFilter, showFormattingMenu, showChallengeModes]);


    const startSession = () => {
        sessionTimerRef.current.elapsed = false;
        sessionTimerRef.current.reference = window.setTimeout(stopSession, 5 * 60000); // 5 min
        if (userRef.current != null) {
            var tz = - new Date().getTimezoneOffset() / 60;
            fetch('/api/account/startUserSession?accountId=' + userRef.current.id + '&tz=' + tz);
        }
    }

    const stopSession = () => {
        sessionTimerRef.current.elapsed = true;
        window.clearTimeout(sessionTimerRef.current.reference);
        if (userRef.current != null) {
            fetch('/api/account/stopUserSession?accountId=' + userRef.current.id);
        }
    }

    const sessionTimerRef = React.useRef({
        elapsed: true,
        reference: 0
    });

    React.useEffect(() => {
        const handleUserInteraction = () => {
            if (userRef.current != null) {
                window.clearTimeout(sessionTimerRef.current.reference);
                if (sessionTimerRef.current.elapsed) {
                    startSession();
                }
                else {
                    sessionTimerRef.current.reference = window.setTimeout(stopSession, 5 * 60000); // 5 min
                }
            }
        };

        // Attach listeners for multiple interaction types
        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('scroll', handleUserInteraction);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('scroll', handleUserInteraction);
        };
    }, []);

    const onDeleteReflection = (item: Reflection) => {
        setReflections(x => x.filter(r => r.id !== item.id));
        reflectionsInitialRef.current = reflectionsInitialRef.current.filter(r => r.id !== item.id);
        fetch('/api/reflection/deletePost?id=' + item.post.id + '&reflectionId=' + item.id);
    };

    const onDeleteStream = (item: Reflection) => {
        setStreams(x => x.filter(r => r.id !== item.id));
        fetch('/api/reflection/deletePost?id=' + item.post.id + '&reflectionId=' + item.id);
    }

    const onDeleteMillionaireMorningItem = (item: MillionaireMorningItem) => {
        setMillionaireMorningItems(x => x.filter(m => m.id !== item.id));
        fetch('/api/millionairemorningitem/deletePost?id=' + item.postPreview.post.id);
    }

    const onDeleteFounderChannelMessage = (item: Reflection) => {
        setFounderChannelMessages(x => x.filter(r => r.id !== item.id));
        fetch('/api/founderChannel/deletePost?id=' + item.post.id);
    }

    const onCloseContextMenu = () => {
        if (selectedReflection != null && !editModeRef.current && !replyModeRef.current) {
            setSelectedReflection(null);
        }
        if (selectedMillionaireMorningItem && !editModeRef.current) {
            setSelectedMillionaireMorningItem(null);
        }
        if (selectedFounderChannelMessage != null && !editModeRef.current) {
            setSelectedFounderChannelMessage(null);
        }

        setShowReflectionContextMenu(false);
        setShowMillionaireMorningItemContextMenu(false);
        setShowFounderChannelContextMenu(false);
    }

    const setEditMode = (mode: boolean) => {
        editModeRef.current = mode;
    }

    const setReplyMode = (mode: boolean) => {
        replyModeRef.current = mode;
    }

    const onStartEditReflection = (item: Reflection) => {
        setSelectedReflection(item);
        setEditMode(true);
    }

    const onStartEditMillionaireMorningItem = (item: MillionaireMorningItem) => {
        setSelectedMillionaireMorningItem(item);
        setEditMode(true);
    }

    const onStartEditFounderChannelMessage = (item: Reflection) => {
        setSelectedFounderChannelMessage(item);
        setEditMode(true);
    }

    const onStartReply = (item: Reflection) => {
        setSelectedReflection(item);
        setReplyMode(true);
    }

    const onStopEdit = () => {
        setEditMode(false);
        setSelectedReflection(null);
        setSelectedMillionaireMorningItem(null);
        setSelectedFounderChannelMessage(null);
    }

    const onStopReply = () => {
        setReplyMode(false);
        setSelectedReflection(null);
    }

    const getMessagesCount = () => {
        switch (state) {
            case appState.MillionaireMorning:
                return millionaireMorningItems.length;
            case appState.Streams:
                return streams.length;
            case appState.Reflections:
                return reflections.length;
            case appState.FounderChannel:
                return founderChannelMessages.length;
            default:
                return 0;
        }
    }

    const closeTopLevelComponents = () => {
        setSelectedAccount(null);
        setShowClassic(0);
        setShowWalletTransactions(false);
        setShowCashbackTransactions(false);
        setShowSpeakershipRewardTransactions(false);
    }

    if (state === '') {
        return (
            <MainLoaderCoverScreen showExpirationText={showExpirationText} currentUser={userRef.current}
                onProlongAction={() => {
                    stopInitialProcessViewRef.current = true;
                    if (productionMode && telegram.isVersionAtLeast('8.0') && telegram.exitFullscreen) {
                        telegram.exitFullscreen();
                    }
                    setState(appState.Renewal);
                }} />
        )
    }
    else if (state === appState.Test) {
        return (
            <TestComponent />
        )
    }
    else return (
        <div className="main-layout" ref={mainLayoutRef}>
            {(state !== '' && state !== appState.Renewal && !hidePaymentReminder && userRef.current && userRef.current.daysLeft !== null && userRef.current.daysLeft <= 30 && (new Date()).getDate() >= 21) &&
                <PaymentReminder currentUser={userRef.current} onRenewal={() => setState(appState.Renewal)} onClose={() => setHidePaymentReminder(true)} />}
            <RegModule productionMode={productionMode} state={state} visible={state === appState.Reg0 || state === appState.Reg1 || state === appState.Reg2 || state === appState.Deny}
                referrerID={referrerID} companyEventID={companyEventID} onReg0={() => setState(appState.Reg0)} onFinishReg={goToChat} />
            <TopPanel state={state} messagesCount={getMessagesCount()} reflectionsViewState={reflectionsViewState} showFilter={showFilter} onOpenFilter={() => setShowFilter(true)}
                onSelectAll={selectAllReflections} onSelectNotLowerThanMine={selectNotLowerThanMine} onSelectMy={selectMy} onCloseFilter={() => setShowFilter(false)} />
            <MillionaireMorningModule visible={state === appState.MillionaireMorning} currentUser={userRef.current} items={millionaireMorningItems}
                showContextMenu={showMillionaireMorningItemContextMenu} onOpenContextMenu={() => setShowMillionaireMorningItemContextMenu(true)} onCloseContextMenu={onCloseContextMenu}
                onDeleteMillionaireMorningItem={onDeleteMillionaireMorningItem} onStartEdit={onStartEditMillionaireMorningItem} selectedMillionaireMorningItem={selectedMillionaireMorningItem}
                onSelectMillionaireMorningItem={setSelectedMillionaireMorningItem}
            />
            <StreamsModule visible={state === appState.Streams} currentUser={userRef.current} items={streams}
                productionMode={productionMode}
                streamsUnreadCount={unreadStreamsCount} setStreamsUnreadCount={setUnreadStreamsCount}
                showContextMenu={showReflectionContextMenu} onOpenContextMenu={() => setShowReflectionContextMenu(true)} onCloseContextMenu={onCloseContextMenu}
                onDeleteReflection={onDeleteStream} onStartEdit={onStartEditReflection} onStartReply={onStartReply} selectedReflection={selectedReflection}
                onSelectReflection={setSelectedReflection} onAccountClick={setSelectedAccount}
            />
            <ReflectionModule visible={state === appState.Reflections} currentUser={userRef.current} items={reflections}
                productionMode={productionMode}
                reflectionsUnreadCount={unreadReflectionsCount} setReflectionsUnreadCount={setUnreadReflectionsCount}
                showContextMenu={showReflectionContextMenu} onOpenContextMenu={() => setShowReflectionContextMenu(true)} onCloseContextMenu={onCloseContextMenu}
                onDeleteReflection={onDeleteReflection} onStartEdit={onStartEditReflection} onStartReply={onStartReply} selectedReflection={selectedReflection}
                onSelectReflection={setSelectedReflection} onAccountClick={setSelectedAccount}
            />
            <FounderChannelModule visible={state === appState.FounderChannel} currentUser={userRef.current} items={founderChannelMessages}
                productionMode={productionMode}
                founderChannelMessagesUnreadCount={unreadFounderChannelMessagesCount} setFounderChannelMessagesUnreadCount={setUnreadFounderChannelMessagesCount}
                showContextMenu={showFounderChannelContextMenu} onOpenContextMenu={() => setShowFounderChannelContextMenu(true)} onCloseContextMenu={onCloseContextMenu}
                onDeleteFounderChannelMessage={onDeleteFounderChannelMessage} onStartEdit={onStartEditFounderChannelMessage}
                selectedFounderChannelMessage={selectedFounderChannelMessage}
                onSelectFounderChannelMessage={setSelectedFounderChannelMessage} onAccountClick={setSelectedAccount}
            />
            <ChallengeView visible={state === appState.Challenge} currentUser={userRef.current} mode={selectedChallengeMode} onAccountClick={setSelectedAccount} />
            <AccountProfile visible={state === appState.AccountProfile} account={userRef.current} onShowClassic={setShowClassic}
                onShowWalletTransactions={() => setShowWalletTransactions(true)} productionMode={productionMode}
                onShowCashbackTransactions={() => setShowCashbackTransactions(true)}
                onShowSpeakershipRewardTransactions={() => setShowSpeakershipRewardTransactions(true)}
                isSubscriptionExpiring={showExpirationText} onGoToRenewal={() => setState(appState.Renewal)}
                setAccountProfileChanges={setAccountProfileChanges}
                onReload={() => setReloadAccountProfileDummy(x => x + 1)}
                reloadAccountProfileDummy={reloadAccountProfileDummy}
                onAccountClick={setSelectedAccount}
            />
            {state === appState.Admin && <AdminMainScreen productionMode={productionMode} />}
            <InputPanel state={state} currentUser={userRef.current}
                selectedReflection={selectedReflection} selectedMillionaireMorningItem={selectedMillionaireMorningItem} selectedFounderChannelMessage={selectedFounderChannelMessage}
                onAddDefaultMillionaireMorningItemCallback={onAddDefaultMillionaireMorningItem}
                onEnrichMillionaireMorningItemCallback={onEnrichMillionaireMorningItem}
                onAddDefaultStreamCallback={onAddDefaultStream}
                onEnrichStreamCallback={onEnrichStream}
                onAddDefaultReflectionCallback={onAddDefaultReflection}
                onEnrichReflectionCallback={onEnrichReflection}
                onAddDefaultFounderChannelMessageCallback={onAddDefaultFounderChannelMessage}
                onEnrichDefaultFounderChannelMessageCallback={onEnrichFounderChannelMessage}
                showSendMenu={showSendMenu} onOpenContextMenu={() => setShowSendMenu(true)} onCloseContextMenu={() => setShowSendMenu(false)}
                editMode={editModeRef.current} replyMode={replyModeRef.current} onStopEdit={onStopEdit} onStopReply={onStopReply}
                mainLayoutRef={mainLayoutRef}
            />
            <BottomPanel currentUser={userRef.current} activeState={state}
                millionaireMorningUnreadCount={millionaireMorningItems.filter(m => m.isRead == 0 && m.postPreview.post.publishAccount.id != userRef.current.id && new Date(m.postPreview.post.publishDateTime) > new Date(userRef.current.activeFrom)).length}
                streamsUnreadCount={unreadStreamsCount}
                reflectionsUnreadCount={unreadReflectionsCount}
                founderChannelMessagesUnreadCount={unreadFounderChannelMessagesCount}
                accountProfileChanges={accountProfileChanges}
                onSelectMillionaireMorning={() => { closeTopLevelComponents(); onSelectMillionaireMorning(); }}
                onSelectStreams={() => { closeTopLevelComponents(); onSelectStreams(); }}
                onSelectReflections={() => { closeTopLevelComponents(); onSelectReflections(); }}
                onSelectFounderChannel={() => { closeTopLevelComponents(); onSelectFounderChannel(); }}
                onSelectChallenge={() => { closeTopLevelComponents(); onSelectChallenge(); }}
                onSelectAccountProfile={() => { closeTopLevelComponents(); onSelectAccountProfile(); }}
                onSelectAdmin={() => { closeTopLevelComponents(); onSelectAdmin(); }}
                showChallengeContextMenu={showChallengeModes}
                onShowChallengeModes={() => setShowChallengeModes(true)} onSelectChallengeMode={(m) => { setState(appState.Challenge); setSelectedChallengeMode(m); }}
            />
            {selectedAccount != null && <AccountView productionMode={productionMode} currentUser={userRef.current} account={selectedAccount} onClose={() => setSelectedAccount(null)} />}
            {showClassic == 1 && <ClassicShortView currentUser={userRef.current} productionMode={productionMode} onAccountClick={setSelectedAccount} onReloadProfile={() => setReloadAccountProfileDummy(x => x + 1)} onClose={() => { setShowClassic(0); }} />}
            {showClassic == 2 && <ClassicView currentUser={userRef.current} productionMode={productionMode} onAccountClick={setSelectedAccount} onClose={() => { setShowClassic(0); }} />}
            {showWalletTransactions && <WalletTransactionsView productionMode={productionMode} currentUser={userRef.current} onAccountClick={setSelectedAccount} onClose={() => { setShowWalletTransactions(false); }} />}
            {showCashbackTransactions && <CashbackTransactionsView currentUser={userRef.current} onClose={() => { setShowCashbackTransactions(false); }} />}
            {showSpeakershipRewardTransactions && <SpeakershipRewardTransactionsView currentUser={userRef.current} onClose={() => { setShowSpeakershipRewardTransactions(false); }} />}
            {state === appState.Renewal && <Renewal productionMode={productionMode} onBack={processView} currentUser={userRef.current} onFinishPayment={() => { parent.location.reload(); }} />}
            {showMotivationFact && <MotivationFact productionMode={productionMode} currentUser={userRef.current} onClose={() => setShowMotivationFact(false)} />}
        </div>
    );
};

export default MainLayout;
