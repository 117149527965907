import * as React from 'react';

const BinarSVG: React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2391_2924)">
                <path d="M24.001 12.2793C19.3798 12.2855 15.6351 16.0302 15.6289 20.6514C15.6314 22.8081 17.3792 24.5559 19.5359 24.5584H28.4661C30.6228 24.5559 32.3706 22.8081 32.3731 20.6514C32.3669 16.0302 28.6222 12.2855 24.001 12.2793Z" />
                <path d="M8.373 37.9535C12.3803 37.9535 15.6288 34.705 15.6288 30.6977C15.6288 26.6904 12.3803 23.4419 8.373 23.4419C4.36573 23.4419 1.11719 26.6904 1.11719 30.6977C1.11719 34.705 4.36573 37.9535 8.373 37.9535Z" />
                <path d="M39.6269 37.9535C43.6342 37.9535 46.8827 34.705 46.8827 30.6977C46.8827 26.6904 43.6342 23.4419 39.6269 23.4419C35.6196 23.4419 32.3711 26.6904 32.3711 30.6977C32.3711 34.705 35.6196 37.9535 39.6269 37.9535Z" />
                <path d="M24.0019 14.5116C28.0092 14.5116 31.2577 11.2631 31.2577 7.25581C31.2577 3.24854 28.0092 0 24.0019 0C19.9946 0 16.7461 3.24854 16.7461 7.25581C16.7461 11.2631 19.9946 14.5116 24.0019 14.5116Z" />
                <path d="M8.37209 35.7208C3.75087 35.7269 0.00617336 39.4716 0 44.0929C0.00245243 46.2496 1.75027 47.9974 3.90698 47.9998H12.8372C14.9939 47.9974 16.7417 46.2496 16.7442 44.0929C16.738 39.4716 12.9933 35.7269 8.37209 35.7208ZM39.6279 35.7208C35.0067 35.7269 31.262 39.4716 31.2558 44.0929C31.2583 46.2496 33.0061 47.9974 35.1628 47.9998H44.093C46.2497 47.9974 47.9975 46.2496 48 44.0929C47.9938 39.4716 44.2491 35.7269 39.6279 35.7208ZM19.5349 39.0696C18.6101 39.0695 17.8605 38.3198 17.8605 37.3951C17.8606 37.084 17.9473 36.7791 18.1109 36.5145C18.2745 36.2499 18.5086 36.0361 18.787 35.8972L22.3256 34.1267V28.4649C22.3256 27.5402 23.0753 26.7905 24 26.7905C24.9247 26.7905 25.6744 27.5402 25.6744 28.4649V35.1626C25.6742 35.4737 25.5874 35.7785 25.4237 36.0431C25.2601 36.3077 25.0261 36.5215 24.7479 36.6606L20.2828 38.8932C20.0508 39.0098 19.7946 39.0703 19.5349 39.0696Z" />
                <path d="M28.464 39.07C28.2043 39.0706 27.9481 39.0102 27.716 38.8936L23.2509 36.661C22.4204 36.2543 22.0768 35.2514 22.4835 34.4208C22.8902 33.5903 23.8931 33.2467 24.7237 33.6534L24.7467 33.6649L29.2119 35.8975C30.0392 36.3106 30.375 37.3162 29.9619 38.1436C29.8229 38.4219 29.6091 38.656 29.3446 38.8196C29.08 38.9833 28.775 39.07 28.464 39.07Z" />
            </g>
            <defs>
                <clipPath id="clip0_2391_2924">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default BinarSVG;
