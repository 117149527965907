import * as React from 'react';
import AdminCashbackManagement from './AdminCashbackManagement';
import AdminSpeakershipRewardManagement from './AdminSpeakershipRewardManagement';
import CashbackAddRecordForm from './forms/CashbackAddRecordForm';
import SpeakershipRewardAddRecordForm from './forms/SpeakershipRewardAddRecordForm';

interface AdminAdditionalResidentsIncomeManagementtProps {
    productionMode: boolean;
    onBack: () => void;
}

const AdminAdditionalResidentsIncomeManagement: React.FC<AdminAdditionalResidentsIncomeManagementtProps> = ({ productionMode, onBack }) => {

    const [mode, setMode] = React.useState<number>(0);

    return (
        <>
            {mode < 2 && <div className="payment-approval-container">
                <div className="reg-module-view-header"><b>ДОП. ДОХОД РЕЗИДЕНТОВ</b></div>
                <div className="btn-payment-add-back" onClick={onBack}><i className="fas fa-chevron-left" /></div>
                <div className="admin-panel-tab">
                    <div className={mode == 0 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setMode(0)}>Кэшбек</div>
                    <div className={mode == 1 ? "admin-panel-tab-item-selected" : "admin-panel-tab-item"} onClick={() => setMode(1)}>Вознаграждения за спикерство</div>
                </div>
                {mode == 0 && <AdminCashbackManagement productionMode={productionMode} onAddRecord={() => setMode(2)} />}
                {mode == 1 && <AdminSpeakershipRewardManagement productionMode={productionMode} onAddRecord={() => setMode(3)} />}
            </div>}
            {mode == 2 && <CashbackAddRecordForm productionMode={productionMode} onBack={() => setMode(0)} />}
            {mode == 3 && <SpeakershipRewardAddRecordForm productionMode={productionMode} onBack={() => setMode(1)} />}
        </>
        )
};

export default AdminAdditionalResidentsIncomeManagement;
