import * as React from 'react';

interface ChallengeContextMenuProps {
    visible: boolean;
    x: number;
    y: number;
    onSelectMode: (mode: number) => void;
    basicChallengeUpdates: boolean;
    commandChallengeUpdates: boolean;
    coinChallengeUpdates: boolean;
}

const ChallengeContextMenu: React.FC<ChallengeContextMenuProps> = ({ visible, x, y, onSelectMode, basicChallengeUpdates, commandChallengeUpdates, coinChallengeUpdates }) => {
    if (!visible) return null;

    const elementRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (elementRef.current != null) {
            const elementWidth = elementRef.current.offsetWidth;
            const elementHeight = elementRef.current.offsetHeight;

            // Set the element's style to position it
            elementRef.current.style.position = 'fixed';
            elementRef.current.style.left = `${x - elementWidth / 2}px`;
            elementRef.current.style.top = `${y - elementHeight}px`;
        }
    }, [visible]);

    return (
        <div ref={elementRef} className="longpress-menu longpress-menu-bordered">
            <div className="longpress-item" onClick={() => onSelectMode(0)}>
                Командный челлендж {commandChallengeUpdates && <span className="longpress-item-has-updates">&nbsp;</span>}
            </div>
            <div className="longpress-item" onClick={() => onSelectMode(2)}>
                Челлендж Января {basicChallengeUpdates && <span className="longpress-item-has-updates"></span>}
            </div>
            <div className="longpress-item" onClick={() => onSelectMode(3)}><div>Рейтинг по уровням</div></div>
            <div className="longpress-item" onClick={() => onSelectMode(5)}><div>ERA Charity</div></div>
            <div className="longpress-item" onClick={() => onSelectMode(4)}>
                Рейтинг по ERA Coin {coinChallengeUpdates && <span className="longpress-item-has-updates"></span>}
            </div>
        </div>
    );
};

export default ChallengeContextMenu;
