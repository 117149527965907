import * as React from 'react';
import { Reflection, Post, PostFragment, MillionaireMorningItem, ReflectionEvent, DataVersion, Account } from '../store/Models';
import { makeDateTime, getClassRussian, getPrevMonthAchievementTextRussian, mainOfficeTz, founderId, getReactionInfo, getReactionsInfo } from '../utils';
import DetailCard from '../components/DetailCard';
import AudioPlayer from '../components/AudioPlayer';
import YouTubeEmbed from '../components/YouTubeEmbed';
import ReflectionModal from '../components/ReflectionModal';
import CustomModal from '../components/CustomModal';
import Tooltip from './Tooltip';
import useFetch from '../hooks/useFetch';
import FileView from './FileView';
import AccountAvatar from './telegramApp/AccountAvatar';
import DanceSVG from './telegramApp/svg/DanceSVG';
import Reaction from './telegramApp/components/simple/Reaction';

interface ReflectionProps {
    currentUser: Account;
    reflection: Reflection;
    isFirst: boolean;
    isSecond: boolean;
    isExpanded: boolean;
    productionMode: boolean;
    onContextMenu: (reflection: Reflection, isImmediate: boolean) => void;
    onReactionContextMenu: (postId: number, reactionCode: number) => void;
    onTouchMove: () => void;
    onTouchStop: () => void;
    onGoToReply: (reflection: Reflection) => void;
    onAccountClick: (account: Account) => void;
    onExpand: () => void;
    onRead: () => void;
    onView?: (index: number) => void;
}

const ReflectionComponent: React.FC<ReflectionProps> = ({ currentUser, reflection, isFirst, isSecond, isExpanded,
    productionMode,
    onContextMenu, onReactionContextMenu, onTouchMove, onTouchStop, onGoToReply, onAccountClick, onExpand, onRead, onView }) => {
    const fetch = useFetch();

    const useCron = false;

    const mainElementRef = React.useRef<HTMLDivElement>(null);

    const reaction0Ref = React.useRef<HTMLDivElement>(null);
    const reaction1Ref = React.useRef<HTMLDivElement>(null);
    const reaction2Ref = React.useRef<HTMLDivElement>(null);
    const reaction3Ref = React.useRef<HTMLDivElement>(null);
    const reaction4Ref = React.useRef<HTMLDivElement>(null);
    const reaction5Ref = React.useRef<HTMLDivElement>(null);

    const [showDetail, setShowDetail] = React.useState(false);
    //const [showReflectionModal, setShowReflectionModal] = React.useState(false);
    const [replyTaskReadyState, setReplyTaskReadyState] = React.useState(false);
    const [expandText, setExpandText] = React.useState(isExpanded);
    //const [showSubscriptionRestrictionModal, setShowSubscriptionRestrictionModal] = React.useState(false);
    //const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
    //const [showNewAccountRestrictionModal, setShowNewAccountRestrictionModal] = React.useState(false);
    //const [showReflectionEditModal, setShowReflectionEditModal] = React.useState(false);
    const [showAttachedFilesModal, setShowAttachedFilesModal] = React.useState(false);
    const [attachedFiles, setAttachedFiles] = React.useState<string[]>([]);

    const openDetail = () => setShowDetail(true);
    const closeDetail = () => setShowDetail(false);

    //const openReflectionModal = () => setShowReflectionModal(true);
    //const closeReflectionModal = () => setShowReflectionModal(false);

    //const openSubscriptionRestrictionModal = () => setShowSubscriptionRestrictionModal(true);
    //const closeSubscriptionRestrictionModal = () => setShowSubscriptionRestrictionModal(false);

    //const openConfirmationModal = () => setShowConfirmationModal(true);
    //const closeConfirmationModal = () => setShowConfirmationModal(false);

    //const openNewAccountRestrictionModal = () => setShowNewAccountRestrictionModal(true);
    //const closeNewAccountRestrictionModal = () => setShowNewAccountRestrictionModal(false);

    //const openReflectionEditModal = () => setShowReflectionEditModal(true);
    //const closeReflectionEditModal = () => setShowReflectionEditModal(false);

    const openAttachedFilesModal = () => setShowAttachedFilesModal(true);
    const closeAttachedFilesModal = () => setShowAttachedFilesModal(false);

    const openText = () => {
        //not allow if reflection is not available for user
        switch (reflection.visibilityMode.toString()) {
            case '0': // by subscription
                if (reflection.class <= Math.max(currentUser.class, currentUser.bonusClass || 0) || reflection.post.publishAccount.id === currentUser.id) {
                    setExpandText(true);
                    onExpand();
                }
                else showClassRestrictionModal(reflection.class);
                break;
            case '1': // to all
                setExpandText(true);
                onExpand();
                break;
            case '2': // private
                if (reflection.post.publishAccount.id === currentUser.id || currentUser.isAdmin > 0) { //my or admin
                    setExpandText(true);
                    onExpand();
                }
                else showAdministrationRestrictionModal();
                break;
            case '3': //founder only
                if (reflection.post.publishAccount.id === currentUser.id || currentUser.id == founderId) { //my or founder
                    setExpandText(true);
                    onExpand();
                }
                else showFounderRestrictionModal();
                break;
            case '10': //founder message restriction
            case '20':
            case '25':
            case '30':
            case '40':
                if (Math.max(currentUser.class, currentUser.bonusClass || 0) >= parseInt(reflection.visibilityMode.toString())) {
                    setExpandText(true);
                    onExpand();
                }
                else showFounderClassRestrictionModal(parseInt(reflection.visibilityMode.toString()));
                break;
            default:
                break;
        }
    }

    const showClassRestrictionModal = (reflectionClass: number) => {
        window.Telegram.WebApp.showPopup({
            title: "Упс... Доступ ограничен)", message: "Резидент установил видимость данной рефлексии только для тарифа " + getClassRussian(reflectionClass), buttons: [{ text: "Понятно" }]
        });
    }

    const showAdministrationRestrictionModal = () => {
        window.Telegram.WebApp.showPopup({
            title: "Упс... Доступ ограничен)", message: "Резидент установил видимость данной рефлексии только для Администрации.", buttons: [{ text: "Понятно" }]
        });
    }

    const showFounderRestrictionModal = () => {
        window.Telegram.WebApp.showPopup({
            title: "Упс... Доступ ограничен)", message: "Резидент установил видимость данной рефлексии только для Ерлана.", buttons: [{ text: "Понятно" }]
        });
    }

    const showFounderClassRestrictionModal = (reflectionClass: number) => {
        window.Telegram.WebApp.showPopup({
            title: "Упс... Доступ ограничен)", message: "Ерлан установил видимость данного сообщения только для тарифа " + getClassRussian(reflectionClass), buttons: [{ text: "Понятно" }]
        });
    }

    const expandTextStyle: React.CSSProperties = {
        color: '#047ff9',
        cursor: 'pointer',
        fontStyle: 'italic'
    }

    const renderTextFull = (text: string) => {
        if (expandText) {
            return renderTextInstant(text);
        }
        else {
            var cutText = text.split(' ').slice(0, 10).join(' '); //10 words
            var splittedText = cutText.split(/\\n+/);
            return <div>{
                splittedText.map((paragraph, index) =>
                    <p key={index}><span dangerouslySetInnerHTML={{ __html: paragraph.length > 0 ? paragraph : '&nbsp;' }}></span>{cutText.length < text.length && index == splittedText.length - 1 ? <span>...<span style={expandTextStyle}
                        onClick={openText}>&nbsp;Читать далее</span></span> : ""}</p>)
            }
            </div>
        }
    }

    /* 
     * attached files: {reflection.fileCount > 0 && <span data-id={reflection.id} style={{ fontStyle: 'italic', cursor: 'pointer' }} onClick={openAttachedFiles}><i className="fas fa-paperclip" /> <span>Приложения к рефлексии: {reflection.fileCount} файла</span></span>}
     */

    const dangerouslySetInnerHtmlAtInstant = (paragraph: string): string => {
        var parsedSpacesParagraph = paragraph.split(' ').map((t, i) => {
            if (t.length > 0) return `<span key=${i}>${t} </span>`;
            else return `<span key=${i}>&nbsp;</span>`;
        }).join('');

        //find and parse links
        if (reflection.post.publishAccount.isAdmin) {
            return parsedSpacesParagraph.replace(/(http||https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g, t => `<a href=${t} target="_blank">${t}</a>`);
        }
        else {
            return parsedSpacesParagraph.replace(/(http||https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g, t => `(ссылка скрыта)`);
        }
    }

    const renderTextInstant = (text: string) => {
        return <div>{text.split(/\\n+/).map((paragraph, index) =>
            <p key={index} dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHtmlAtInstant(paragraph) }}></p>)}</div>
    }

    const renderFragment = (fragment: PostFragment): React.ReactNode => {
        switch (fragment.contentType.toString()) {
            case '0': //text
                return <div>
                    <div className="full-post">
                        {renderTextFull(fragment.content)}
                    </div>
                </div>
            case '1': //audio
                return <AudioPlayer src={'/assets/audio/' + fragment.content} />
            case '2': //youtube
                return <YouTubeEmbed videoId={fragment.content} width="320" height="180" />
            case '4': //image
                return <img width="320" height="200" src={'/assets/images/posters/' + fragment.content} />
            default:
                return <p>Unknown fragment content Type!</p>
        }
    }

    const renderPost = (post: Post, isInstant: boolean) => {
        switch (post.contentType.toString()) {
            case '0': //text
                return <div>
                    <div className="full-post">
                        {isInstant ? renderTextInstant(post.contentText) : renderTextFull(post.contentText)}
                    </div>
                </div>
            case '1': //audio
                return <AudioPlayer src={'/assets/audio/' + post.contentRef} />
            case '2': //youtube
                return <YouTubeEmbed videoId={post.contentRef} width="320" height="180" />
            case '4': //image
                return <img width="320" height="200" src={'/assets/images/posters/' + post.contentRef} />
            case '5': //complex
                return <div>
                    {post.fragments.map((fragment: PostFragment) => {
                        return renderFragment(fragment);
                    })
                    }
                </div>
            default:
                return <p>Unknown post content Type!</p>
        }
    }

    const checkRead = () => {
        if (reflection.visibilityMode > 100 && reflection.isRead == 0 && reflection.post.publishAccount.id != currentUser.id
            && new Date(reflection.post.publishDateTime) > new Date(currentUser.activeFrom)) {
            reflection.isRead = 1;
            onRead();
            updateState(); //force component re-render after read
            fetch('/api/counter/readPost?postId=' + reflection.post.id);
        }
    }

    const doRead = () => {
        if (reflection.visibilityMode < 100 && reflection.isRead == 0 && reflection.post.publishAccount.id != currentUser.id
            && new Date(reflection.post.publishDateTime) > new Date(currentUser.activeFrom)) {
            reflection.isRead = 1;
            onRead();
            updateState(); //force component re-render after read
            fetch('/api/counter/readPost?postId=' + reflection.post.id);
        }
        if (reflection.visibilityMode < 100 && reflection.unreadReactionsCount > 0 &&
            new Date(reflection.post.publishDateTime) > new Date(currentUser.activeFrom)) {
            reflection.unreadReactionsCount = 0;
            updateState(); //force component re-render after read
            fetch('/api/counter/readReactions?postId=' + reflection.post.id);
        }
    }

    /*
    const clickReplyTask = () => {
        const params = new URLSearchParams({
            id: reflection.replyTask.id.toString()
        });
        fetch(`api/millionairemorningitem/getItemByID?${params}`)
            .then(response => response.json() as Promise<MillionaireMorningItem>)
            .then(data => {
                reflection.replyTask = data;
                if (new Date(reflection.replyTask.postPreview.post.publishDateTime) < new Date(currentUser.activeFrom)) {
                    showNewbieRestrictionModal();
                }
                else {
                    if (data.isUnread) {
                        const event = new CustomEvent('readEvent');
                        window.dispatchEvent(event);
                        //call read request and then toggleOpen
                        fetch('api/counter/readMillionaireMorningTask', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                PostID: reflection.replyTask.postPreview.post.id
                            }),
                        }).then(() => {
                            setReplyTaskReadyState(true);
                            openDetail();
                        });
                    }
                    else {
                        setReplyTaskReadyState(true);
                        openDetail();
                    }
                }
            });
    }
    */

    /*
    const submitModal = (reflectionContent: string, reflectionVisibilityMode: number, uploadedFiles: File[], editReflection?: Reflection) => {
        if (editReflection != null) {
            var formData = new FormData();
            formData.append('ReflectionID', editReflection.id.toString());
            formData.append('PostID', editReflection.post.id.toString());
            formData.append('ReflectionContent', reflectionContent);
            formData.append('ReflectionVisibilityMode', reflectionVisibilityMode.toString());
            formData.append('IsContentChanged', (reflectionContent != editReflection.post.contentText).toString());
            for (let i = 0; i < uploadedFiles.length; i++) {
                formData.append('ReflectionFiles', uploadedFiles[i]);
            }
            fetch('api/reflection/editReflection', {
                method: 'POST',
                body: formData
            }).then(() => {
                closeReflectionEditModal();
                onUpdate();
            });
        }
        else {
            var formData = new FormData();
            formData.append('MillionaireMorningItemID', reflection.replyTask.id.toString());
            formData.append('ReflectionContent', reflectionContent);
            formData.append('ReflectionVisibilityMode', reflectionVisibilityMode.toString());
            formData.append('SetCompletion', (reflection.replyTask != null && !reflection.replyTask.isCompleted).toString());
            for (let i = 0; i < uploadedFiles.length; i++) {
                formData.append('ReflectionFiles', uploadedFiles[i]);
            }
            fetch('api/reflection/writeReflection', {
                method: 'POST',
                body: formData
            }).then(() => {
                closeReflectionModal();
                closeDetail();
                onUpdate();
            });
        }
    }
    */
    /*
    const deleteReflection = () => {
        const params = new URLSearchParams({
            id: reflection.post.id.toString()
        });
        fetch(`api/reflection/deletePost?${params}`)
            .then(() => {
                closeConfirmationModal();
                onDelete(reflection.id);
            });
    }
    */

    const editBtnStyle: React.CSSProperties = {
        position: 'absolute',
        top: '0',
        right: '40px',
        margin: '10px'
    }

    const deleteBtnStyle: React.CSSProperties = {
        position: 'absolute',
        top: '0',
        right: '0',
        margin: '10px'
    }

    const openAttachedFiles = (event: React.MouseEvent<HTMLSpanElement>) => {
        var reflectionID = event.currentTarget.getAttribute('data-id');
        if (reflectionID != null) {
            const params = new URLSearchParams({
                id: reflectionID
            });
            fetch(`api/file/getReflectionFiles?${params}`)
                .then(response => response.json() as Promise<string[]>)
                .then(data => {
                    setAttachedFiles(data);
                    openAttachedFilesModal();
                });
        }
    }

    const visibilityStyle: React.CSSProperties = {
        position: 'absolute',
        right: '10px',
        top: '10px'
    }

    const reactionsStyle: React.CSSProperties = {
        position: 'absolute',
        left: '7px',
        bottom: '5px',
        fontSize: '14px',
        display: 'flex'
    }

    const singleReactionStyle: React.CSSProperties = {
        borderRadius: '15px',
        marginLeft: '7px',
        padding: '2px 7px 2px 0',
        cursor: 'pointer',
        background: '#ccc',
        display: 'flex',
        alignItems: 'center'
    }

    const singleMyReactionStyle: React.CSSProperties = {
        borderRadius: '15px',
        marginLeft: '7px',
        padding: '2px 7px 2px 0',
        background: 'lightblue',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    }

    const hasReactions = (): boolean => {
        return reflection.reactionsInfo.length > 0;
    }

    const hasManyReactions = (): boolean => {
        return reflection.reactionsInfo.split(';').length > 5;
    }

    const setReaction = (reactionCode: number) => {
        if (currentUser.id != founderId && reactionCode >= 10000) {
            if (productionMode) {
                window.Telegram.WebApp.showPopup({
                    title: "Упс... Недоступно!", message: "Данным набором эмодзи может пользоваться только основатель Клуба Ерлан Ахметов.", buttons: [{ text: "Понятно" }]
                });
            }
            else {
                window.alert("Упс... Недоступно! Данным набором эмодзи может пользоваться только основатель Клуба Ерлан Ахметов.");
            }
            return;
        }

        let reactionInfo = getReactionInfo(reflection.reactionsInfo, reactionCode);

        var formData = new FormData();
        formData.append('ReflectionID', reflection.id.toString());
        formData.append('PostID', reflection.post.id.toString());
        formData.append('ReactionCode', reactionCode.toString());

        //if my, decrease code value, else increase
        if (reactionInfo != null) {
            if (reactionInfo.my > 0) {
                reflection.reactionsInfo =
                    reflection.reactionsInfo.replace(`${reactionCode}=${reactionInfo.count}/${reactionInfo.my}`, `${reactionCode}=${reactionInfo.count - 1}/0`);
            }
            else {
                reflection.reactionsInfo =
                    reflection.reactionsInfo.replace(`${reactionCode}=${reactionInfo.count}/${reactionInfo.my}`, `${reactionCode}=${reactionInfo.count + 1}/1`);
            }
        }
        else {
            if (reflection.reactionsInfo.length > 0) {
                reflection.reactionsInfo += `;${reactionCode}=1/1`;
            }
            else {
                reflection.reactionsInfo = `${reactionCode}=1/1`;
            }
        }

        updateState(); //force component re-render after like click

        fetch(reactionInfo != null && reactionInfo.my > 0 ? 'api/reflection/dropReaction' : 'api/reflection/setReaction', {
            method: 'POST',
            body: formData
        });
    }

    const cardBodyStyle: React.CSSProperties = {
        'borderRadius': '20px',
        'border': 'none',
        'paddingBottom': hasManyReactions() ? '20px' : hasReactions() ? '15px' : '10px',
        'paddingTop': '3px',
        'paddingLeft': '10px',
        'marginRight': '40px'
    }

    const bottomStyle: React.CSSProperties = {
        position: 'absolute',
        right: '7px',
        bottom: '5px',
        fontSize: '12px',
        textAlign: 'right',
        color: '#888'
    }

    const modifiedStyle: React.CSSProperties = {
        fontSize: '10px'
    }

    /*
     * edit btn: {reflection.post.publishAccount.id.toString() === '1000057' ? <button className="btn btn-sm btn-outline-secondary"
                style={editBtnStyle} onClick={openReflectionEditModal}><i className="fas fa-pen" /></button> : ""}
     * delete btn: {reflection.post.publishAccount.id.toString() === '1000057' ? <button className="btn btn-sm btn-outline-secondary"
                style={deleteBtnStyle} onClick={openConfirmationModal}><i className="fas fa-trash" /></button> : ""}
     * changed label: {reflection.isModified > 0 && <span style={modifiedStyle}>Изменено</span>}
     * visibility label: <i className="fas fa-eye"></i> <span>{reflectionVisibilityRussian(reflection.visibilityMode.toString())}</span>
     * level circle: <div className="level basic">{reflection.post.publishAccount.level}</div>
     * reply task:   <div style={boldStyle}>{reflection.replyTask != null ? <div><span>В ответ на: </span><span style={clickStyle} onClick={clickReplyTask}>{reflection.replyTask.taskHeader}</span></div> : <span>На: Свободную тему</span>}</div>
     * other content:
     * <div className="avatar-container">
                <table>
                    <tbody>
                    <tr>
                        <td rowSpan={2}>
                            <img src={'/assets/images/' + reflection.post.publishAccount.alias + '.jpg'} alt="Avatar" className="avatar" />
                            <div className="level basic">{reflection.post.publishAccount.level}</div>
                        </td>
                        <td>
                                {reflection.post.publishAccount.name}&nbsp;&nbsp;&nbsp;
                                <button type="button" className={
                                    reflection.class == 0 ? "btn btn-warning btn-sm" :
                                    reflection.class == 1 ? "btn btn-success btn-sm" :
                                    reflection.class == 2 ? "btn btn-info btn-sm" :
                                    "btn btn-dark btn-sm"} disabled>{reflection.subscriptionDisplayName}</button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div><i className="fas fa-calendar"></i> <span>{makeDateTime(reflection.post.publishDateTime.toString())}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<i className="fas fa-eye"></i> <span>{reflectionVisibilityRussian(reflection.visibilityMode.toString())}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={boldStyle}>{reflection.replyTask != null ? <div><span>В ответ на: </span><span style={clickStyle} onClick={clickReplyTask}>{reflection.replyTask.taskHeader}</span></div> : <span>На: Свободную тему</span>}</div>
            <div className="card-text">{renderPost(reflection.post, false)}</div>

            {reflection.replyTask != null && replyTaskReadyState && <div><DetailCard show={showDetail}>
                <h4>{reflection.replyTask.taskHeader}</h4>
                {renderPost(reflection.replyTask.postPreview.post, true)}
                <button className="btn btn-success" onClick={openReflectionModal}>Выполнить</button>
                <br />
                <button className="btn btn-outline-secondary" style={{ 'marginTop': '10px' }} onClick={closeDetail}>&lt; Назад</button>
            </DetailCard>
                <ReflectionModal show={showReflectionModal} onClose={closeReflectionModal} title='Написание рефлексии' onSubmit={submitModal} replyTask={reflection.replyTask.taskHeader} />
            </div>}
     */

    /*
     * Old modals
     * <CustomModal show={showSubscriptionRestrictionModal} onClose={closeSubscriptionRestrictionModal} title='Упс... Доступ ограничен)'>
                {reflection.visibilityMode.toString() === "0" ?
                    <div className="modal-body">Содержимое данной рефлексии доступно только для тарифа {getClassRussian(reflection.class)}.
                        <Tooltip text="Автор этой рефлексии установил для неё режим видимости только для своего тарифа и выше.">
                            <div style={{ position: 'relative' }}>
                                <div className="info-icon" style={{ position: 'absolute', top: '-18px' }}>
                                    <i className="fas fa-info"></i>
                                </div>
                            </div>
                        </Tooltip></div> :
                    <div className="modal-body">Содержимое данной рефлексии доступно только для Администрации.
                        <Tooltip text="Автор этой рефлексии установил для неё режим видимости только для Администрации.">
                            <div style={{ position: 'relative' }}>
                                <div className="info-icon" style={{ position: 'absolute', top: '-18px' }}>
                                    <i className="fas fa-info"></i>
                                </div>
                            </div>
                        </Tooltip></div>}
                <div className="modal-footer justify-content-center">
                    <button className="btn btn-primary" onClick={closeSubscriptionRestrictionModal}>Понятно</button>
                </div>
            </CustomModal>
            <CustomModal show={showConfirmationModal} onClose={closeConfirmationModal} title='Внимание!'>
                <div className="modal-body">Вы уверены, что хотите удалить эту рефлексию?</div>
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeConfirmationModal}>Отмена</button>
                    <button className="btn btn-danger" onClick={deleteReflection}>Да, удалить</button>
                </div>
            </CustomModal>
            <CustomModal show={showNewAccountRestrictionModal} onClose={closeNewAccountRestrictionModal} title='Упс... Доступ ограничен)'>
                <div className="modal-body">Данное задание недоступно для Вас, так как было опубликовано до начала Вашей активной подписки.
                    <Tooltip text="Вы не можете просматривать и выполнять задания, опубликованные до Вашего вступления на Платформу.">
                        <div style={{ position: 'relative' }}>
                            <div className="info-icon" style={{ position: 'absolute', top: '-18px' }}>
                                <i className="fas fa-info"></i>
                            </div>
                        </div>
                    </Tooltip>
                </div>
                <div className="modal-footer justify-content-center">
                    <button className="btn btn-primary" onClick={closeNewAccountRestrictionModal}>Понятно</button>
                </div>
            </CustomModal>
            <ReflectionModal show={showReflectionEditModal} onClose={closeReflectionEditModal} edit={reflection} title='Редактирование рефлексии' onSubmit={submitModal} replyTask={reflection.replyTask ? reflection.replyTask.taskHeader : ''} />
     * 
     */

    /*
     * Touch Events 
     */

    React.useEffect(() => {

        const handleTouchStart = () => {
            if (mainElementRef.current != null) {
                onContextMenu(reflection, false);
            }
        }

        const handleRightClick = (event: MouseEvent) => {
            event.preventDefault();
            if (mainElementRef.current != null) {
                onContextMenu(reflection, true);
            }
        }

        if (window.Telegram.WebApp.platform === 'ios') {
            if (mainElementRef.current != null) {
                mainElementRef.current.addEventListener('touchstart', handleTouchStart, { passive: false });
                mainElementRef.current.addEventListener('touchmove', onTouchMove, { passive: false });
                mainElementRef.current.addEventListener('touchend', onTouchStop, { passive: false });

            }
        }
        else {
            if (mainElementRef.current != null) {
                mainElementRef.current.addEventListener('contextmenu', handleRightClick, { passive: false });
            }
        }

        return () => {
            if (window.Telegram.WebApp.platform === 'ios') {
                if (mainElementRef.current != null) {
                    mainElementRef.current.removeEventListener('touchstart', handleTouchStart);
                    mainElementRef.current.removeEventListener('touchmove', onTouchMove);
                    mainElementRef.current.removeEventListener('touchend', onTouchStop);
                }
            }
            else {
                if (mainElementRef.current != null) {
                    mainElementRef.current.removeEventListener('contextmenu', handleRightClick);
                }
            }
        }
    }, []);

    const reactionTouchTimerRef = React.useRef<number>(0);

    React.useEffect(() => {
        const handleReaction = (event: MouseEvent, reactionCode: number) => {
            event.preventDefault();
            event.stopPropagation();
            onReactionContextMenu(reflection.post.id, reactionCode);
        }

        if (window.Telegram.WebApp.platform === 'ios') {
            if (reaction0Ref.current != null) {
                reaction0Ref.current.addEventListener('touchstart', (e) => {
                    e.stopPropagation();
                    reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 0), 200);
                }, { passive: false });
                reaction0Ref.current.addEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
                reaction0Ref.current.addEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
            }
            if (reaction1Ref.current != null) {
                reaction1Ref.current.addEventListener('touchstart', (e) => {
                    e.stopPropagation();
                    reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 1), 200);
                }, { passive: false });
                reaction1Ref.current.addEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
                reaction1Ref.current.addEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
            }
            if (reaction2Ref.current != null) {
                reaction2Ref.current.addEventListener('touchstart', (e) => {
                    e.stopPropagation();
                    reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 2), 200);
                }, { passive: false });
                reaction2Ref.current.addEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
                reaction2Ref.current.addEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
            }
            if (reaction3Ref.current != null) {
                reaction3Ref.current.addEventListener('touchstart', (e) => {
                    e.stopPropagation();
                    reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 3), 200);
                }, { passive: false });
                reaction3Ref.current.addEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
                reaction3Ref.current.addEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
            }
            if (reaction4Ref.current != null) {
                reaction4Ref.current.addEventListener('touchstart', (e) => {
                    e.stopPropagation();
                    reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 4), 200);
                }, { passive: false });
                reaction4Ref.current.addEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
                reaction4Ref.current.addEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
            }
            if (reaction5Ref.current != null) {
                reaction5Ref.current.addEventListener('touchstart', (e) => {
                    e.stopPropagation();
                    reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 5), 200);
                }, { passive: false });
                reaction5Ref.current.addEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
                reaction5Ref.current.addEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current), { passive: false });
            }
        }
        else {
            if (reaction0Ref.current != null) {
                reaction0Ref.current.addEventListener('contextmenu', (e) => handleReaction(e, 0), { passive: false });
            }
            if (reaction1Ref.current != null) {
                reaction1Ref.current.addEventListener('contextmenu', (e) => handleReaction(e, 1), { passive: false });
            }
            if (reaction2Ref.current != null) {
                reaction2Ref.current.addEventListener('contextmenu', (e) => handleReaction(e, 2), { passive: false });
            }
            if (reaction3Ref.current != null) {
                reaction3Ref.current.addEventListener('contextmenu', (e) => handleReaction(e, 3), { passive: false });
            }
            if (reaction4Ref.current != null) {
                reaction4Ref.current.addEventListener('contextmenu', (e) => handleReaction(e, 4), { passive: false });
            }
            if (reaction5Ref.current != null) {
                reaction5Ref.current.addEventListener('contextmenu', (e) => handleReaction(e, 5), { passive: false });
            }
        }

        return () => {
            if (window.Telegram.WebApp.platform === 'ios') {
                if (reaction0Ref.current != null) {
                    reaction0Ref.current.removeEventListener('touchstart', (e) => {
                        e.stopPropagation();
                        reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 0), 200);
                    });
                    reaction0Ref.current.removeEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current));
                    reaction0Ref.current.removeEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current));
                }
                if (reaction1Ref.current != null) {
                    reaction1Ref.current.removeEventListener('touchstart', (e) => {
                        e.stopPropagation();
                        reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 1), 200);
                    });
                    reaction1Ref.current.removeEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current));
                    reaction1Ref.current.removeEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current));
                }
                if (reaction2Ref.current != null) {
                    reaction2Ref.current.removeEventListener('touchstart', (e) => {
                        e.stopPropagation();
                        reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 2), 200);
                    });
                    reaction2Ref.current.removeEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current));
                    reaction2Ref.current.removeEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current));
                }
                if (reaction3Ref.current != null) {
                    reaction3Ref.current.removeEventListener('touchstart', (e) => {
                        e.stopPropagation();
                        reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 3), 200);
                    });
                    reaction3Ref.current.removeEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current));
                    reaction3Ref.current.removeEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current));
                }
                if (reaction4Ref.current != null) {
                    reaction4Ref.current.removeEventListener('touchstart', (e) => {
                        e.stopPropagation();
                        reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 4), 200);
                    });
                    reaction4Ref.current.removeEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current));
                    reaction4Ref.current.removeEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current));
                }
                if (reaction5Ref.current != null) {
                    reaction5Ref.current.removeEventListener('touchstart', (e) => {
                        e.stopPropagation();
                        reactionTouchTimerRef.current = window.setTimeout(() => onReactionContextMenu(reflection.post.id, 5), 200);
                    });
                    reaction5Ref.current.removeEventListener('touchmove', () => clearTimeout(reactionTouchTimerRef.current));
                    reaction5Ref.current.removeEventListener('touchend', () => clearTimeout(reactionTouchTimerRef.current));
                }
            }
            else {
                if (reaction0Ref.current != null) {
                    reaction0Ref.current.removeEventListener('contextmenu', (e) => handleReaction(e, 0));
                }
                if (reaction1Ref.current != null) {
                    reaction1Ref.current.removeEventListener('contextmenu', (e) => handleReaction(e, 1));
                }
                if (reaction2Ref.current != null) {
                    reaction2Ref.current.removeEventListener('contextmenu', (e) => handleReaction(e, 2));
                }
                if (reaction3Ref.current != null) {
                    reaction3Ref.current.removeEventListener('contextmenu', (e) => handleReaction(e, 3));
                }
                if (reaction4Ref.current != null) {
                    reaction4Ref.current.removeEventListener('contextmenu', (e) => handleReaction(e, 4));
                }
                if (reaction5Ref.current != null) {
                    reaction5Ref.current.removeEventListener('contextmenu', (e) => handleReaction(e, 5));
                }
            }
        }
    }, []);

    /* Cron Updater */
    const reflectionDataVersionRef = React.useRef<number>(0);

    React.useEffect(() => {

        const cron = () => {
            // 1st, we are requesting reflection data version and saving it
            fetch('/api/reflection/getReflectionDataVersion?id=' + reflection.id)
                .then(response => response.json() as Promise<DataVersion>)
                .then(x => {
                    //if current data version less than server data version, we are requesting updates list for each object
                    if (reflectionDataVersionRef.current < x.version) {
                        fetch('/api/reflection/getReflectionEvents?currentDataVersion=' + reflectionDataVersionRef.current)
                            .then(response => response.json() as Promise<ReflectionEvent[]>)
                            .then(reflectionEvents => {
                                reflectionEvents.forEach(applyReflectionUpdate);
                                reflectionDataVersionRef.current = x.version;
                                window.setTimeout(cron, 5000);
                            })
                            .catch(() => {
                                window.setTimeout(cron, 5000);
                            });
                    }
                    else {
                        window.setTimeout(cron, 5000);
                    }
                })
                .catch(() => {
                    window.setTimeout(cron, 5000);
                });
        };

        const applyReflectionUpdate = (reflectionEvent: ReflectionEvent) => {
            switch (reflectionEvent.fieldName) {
                case 'VisibilityMode':
                    reflection.visibilityMode = parseInt(reflectionEvent.newValue);
                    break;
                case 'Post':
                    reflection.post.contentText = reflectionEvent.newValue;
                    break;
                default:
                    break;
            }
        }

        if (useCron) {
            window.setTimeout(cron, 5000);
        }

    }, []);

    const setDefaultReaction = () => {
        setReaction(4);
    }

    const isActual = (): boolean => {
        let publishDateTime = new Date(reflection.post.publishDateTime);
        let publishTz = reflection.post.tz || mainOfficeTz;
        publishDateTime.setHours(publishDateTime.getHours() + publishTz);
        let now = new Date();
        let inTimeWindow = (publishDateTime.getHours() > 4 || publishDateTime.getHours() == 4 && publishDateTime.getMinutes() >= 30)
            && publishDateTime.getHours() < 18;
        return publishDateTime.toLocaleDateString() === now.toLocaleDateString() && inTimeWindow;
    }

    const getReflectionValueEvaluatorText = (): string => {
        if (reflection.value == null || reflection.value == -1) {
            if (isActual()) {
                return 'нет оценки';
            }
            else {
                return '0 баллов';
            }
        }
        else if (reflection.value == 0) {
            return '0 баллов';
        }
        else if (reflection.value == 1) {
            return '1 балл';
        }
        else if (reflection.value == 2) {
            return '2 балла';
        }
        else if (reflection.value == 3) {
            return '3 балла';
        }
        else return '';
    }

    const getReflectionValueResident = (): string => {
        if (reflection.value == null || reflection.value == -1) {
            if (isActual()) {
                return '';
            }
            else {
                return '0';
            }
        }
        else if (reflection.value == 0) {
            return '0';
        }
        else if (reflection.value == 1) {
            return '1';
        }
        else if (reflection.value == 2) {
            return '2';
        }
        else if (reflection.value == 3) {
            return '3';
        }
        else return '';
    }

    const [evaluationState, setEvaluationState] = React.useState<number>(0);

    const checkAdditionalPoint = (): number => {
        let publishDateTime = new Date(reflection.post.publishDateTime);
        let publishTz = reflection.post.tz || mainOfficeTz;
        publishDateTime.setHours(publishDateTime.getHours() + publishTz);
        let now = new Date();
        let today = publishDateTime.toLocaleDateString() === now.toLocaleDateString();
        let inTimeWindow = (publishDateTime.getHours() > 4 || publishDateTime.getHours() == 4 && publishDateTime.getMinutes() >= 30)
            && (publishDateTime.getHours() < 6 || publishDateTime.getHours() == 6 && publishDateTime.getMinutes() <= 40);
        if (reflection.isModified == 0 && today && inTimeWindow) {
            return 1;
        }
        else return 0;
    }

    const setEvaluationError = () => {
        reflection.value = -1;
        setEvaluationState(0);

        var message = "Ошибка #9002:\nПроизошла ошибка при сохранении оценки.\nПожалуйста, оцените рефлексию повторно или попробуйте позже.";
        if (productionMode) {
            window.Telegram.WebApp.showPopup({ title: "Упс... Что-то пошло не так)", message: message, buttons: [{ text: "Понятно" }] });
        }
        else {
            alert(message);
            console.log(message);
        }
    }

    const doEvaluate = (value: number) => {
        switch (evaluationState) {
            case 0:
                if (value == 1) {
                    reflection.value = 1;
                    setEvaluationState(1);
                    fetch('/api/reflection/evaluate?id=' + reflection.id + '&value=1')
                        .catch(setEvaluationError);
                }
                else if (value == 2) {
                    reflection.value = value + checkAdditionalPoint();
                    setEvaluationState(value);
                    fetch('/api/reflection/evaluate?id=' + reflection.id + '&value=' + reflection.value)
                        .catch(setEvaluationError);
                }
                break;
            case 1:
                if (value == 1) {
                    reflection.value = -1;
                    setEvaluationState(0);
                    fetch('/api/reflection/evaluate?id=' + reflection.id + '&value=-1')
                        .catch(setEvaluationError);
                }
                else if (value == 2) {
                    reflection.value = value + checkAdditionalPoint();
                    setEvaluationState(value);
                    fetch('/api/reflection/evaluate?id=' + reflection.id + '&value=' + reflection.value)
                        .catch(setEvaluationError);
                }
                break;
            case 2:
                if (value == 1) {
                    reflection.value = 1;
                    setEvaluationState(1);
                    fetch('/api/reflection/evaluate?id=' + reflection.id + '&value=1')
                        .catch(setEvaluationError);
                }
                else if (value == 2) {
                    reflection.value = -1;
                    setEvaluationState(0);
                    fetch('/api/reflection/evaluate?id=' + reflection.id + '&value=-1')
                        .catch(setEvaluationError);
                }
                break;
            default:
                break;
        }
        updateState(); //force component re-render after evaluation
    }

    const callEvaluationDescription = () => {
        let message = '';
        switch (reflection.value) {
            case 0:
                message = 'Оценка рефлексии: 0 баллов\nРефлексия написана\nпосле 18:00';
                break;
            case 1:
                message = 'Оценка рефлексии: 1 балл';
                break;
            case 2:
                message = 'Оценка рефлексии: 2 балла\nЗа написание до 06:40 и отсутствие изменений: 0 баллов\nИтого: 2 балла';
                break;
            case 3:
                message = 'Оценка рефлексии: 2 балла\nЗа написание до 06:40 и отсутствие изменений: +1 балл\nИтого: 3 балла';
                break;
            default:
                message = 'Рефлексия не оценена';
                break;
        }
        window.Telegram.WebApp.showPopup({
            title: "Информация об оценке", message: message, buttons: [{ text: "Понятно" }]
        });
    }

    React.useEffect(() => {
        if (reflection.value == 1)
            setEvaluationState(1);
        else if (reflection.value == 2 || reflection.value == 3)
            setEvaluationState(2);
    }, []);

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const isInView = entry.isIntersecting;

                if (isInView) {

                    doRead();
                    if (onView) {
                        onView(reflection.id);
                    }
                }
            },
            {
                threshold: 1
            }
        );

        if (mainElementRef.current) {
            observer.observe(mainElementRef.current);
        }

        return () => {
            if (mainElementRef.current) {
                observer.unobserve(mainElementRef.current);
            }
        };
    }, []);

    const [refreshState, setRefreshState] = React.useState<number>(0);

    const updateState = () => setRefreshState(x => x + 1);

    React.useEffect(() => {
        console.log('Component state was updated. Will it work?');
    }, [refreshState]);

    return (
        <div ref={mainElementRef} id={reflection.id.toString()}>
            <div className="reflection-card">
                {reflection.post.publishAccount.id != currentUser.id && <AccountAvatar size={40} account={reflection.post.publishAccount}
                    showAsAdmin={reflection.visibilityMode > 100 && reflection.post.publishAccount.isAdmin == 1}
                    classArg={reflection.class} onClick={onAccountClick} />}
                <div style={cardBodyStyle} className={reflection.post.publishAccount.id == currentUser.id ? "card card-body card-mine" :
                    (reflection.visibilityMode > 100 && reflection.isRead == 0 && new Date(reflection.post.publishDateTime) > new Date(currentUser.activeFrom)) ? "card card-body unread" :
                        "card card-body"}
                    onClick={checkRead}
                    onDoubleClick={setDefaultReaction}>
                    <div className={
                        reflection.visibilityMode > 100 ? "name admin" :
                            reflection.class == 5 ? "name exclusive" :
                                reflection.class == 10 ? "name basic" :
                                    reflection.class == 20 ? "name standard" :
                                        reflection.class == 25 ? "name stdplus" :
                                            reflection.class == 30 ? "name bus" :
                                                "name busplus"} onClick={() => { if (reflection.post.publishAccount.isAdmin == 0) onAccountClick(reflection.post.publishAccount); }}>
                        <span>{reflection.visibilityMode > 100 && reflection.post.publishAccount.isAdmin == 1 ? "Администрация Клуба" :
                            reflection.post.publishAccount.name} {reflection.post.publishAccount.isAmbassador > 0 ? '[Ambassador]' : ''}</span>

                    </div>
                    {isFirst && <div className="reflection-first"><span> Первая рефлексия!!! 📝🎉</span></div>}
                    {isSecond && <div className="reflection-first"><span> <b>Вторая</b> рефлексия! 📝🎉</span></div>}
                    <div className="reflection-badges">
                        {reflection.post.publishAccount.displayAchievements && reflection.post.publishAccount.displayAchievements.map(a => (
                            <div className={
                                reflection.class == 5 ? "achievement-badge exclusive" :
                                    reflection.class == 10 ? "achievement-badge basic" :
                                        reflection.class == 20 ? "achievement-badge standard" :
                                            reflection.class == 25 ? "achievement-badge stdplus" :
                                                reflection.class == 30 ? "achievement-badge bus" :
                                                    "achievement-badge busplus"}><span>{
                                                        a === 'DANCER' ? <><DanceSVG /> Танцор {getPrevMonthAchievementTextRussian(new Date(reflection.post.publishDateTime).getMonth())}</> :
                                                            a === 'PHYLOSOPHER' ? <>📝 Философ {getPrevMonthAchievementTextRussian(new Date(reflection.post.publishDateTime).getMonth())}</> : ''
                                                    }</span></div>
                        ))}</div>

                    {(currentUser.canEvaluate > 0 && reflection.visibilityMode < 100) &&
                        <div className={
                            !isActual() ? "reflection-value" :
                                reflection.value == null || reflection.value == -1 ?
                                    "reflection-value reflection-value-unevaluated" :
                                    "reflection-value reflection-value-evaluated"}
                            onClick={callEvaluationDescription}>
                            {getReflectionValueEvaluatorText()}</div>}

                    {(currentUser.canEvaluate == 0 && reflection.visibilityMode < 100 &&
                        (!isActual() || reflection.value != null && reflection.value > -1)) && <div className={
                            (!isActual() && (reflection.value == null || reflection.value == -1)) ? "reflection-value-resident" :
                                reflection.class == 5 ? "reflection-value-resident exclusive" :
                                    reflection.class == 10 ? "reflection-value-resident basic" :
                                        reflection.class == 20 ? "reflection-value-resident standard" :
                                            reflection.class == 25 ? "reflection-value-resident stdplus" :
                                                reflection.class == 30 ? "reflection-value-resident bus" :
                                                    "reflection-value-resident busplus"}
                            onClick={callEvaluationDescription}>
                            {(reflection.value == 0 || reflection.value == -1 || reflection.value == null) && <i className="fas fa-bolt" />}
                            {(reflection.value == 1 || reflection.value == 2 || reflection.value == 3) && <i className="fas fa-bolt" style={{ position: 'absolute', top: '4px', right: '10px' }} />}
                            {(reflection.value == 2 || reflection.value == 3) && <i className="fas fa-bolt" style={{ position: 'absolute', top: '4px', right: '14px' }} />}
                            {reflection.value == 3 && <i className="fas fa-bolt" style={{ position: 'absolute', top: '4px', right: '18px' }} />}
                            {getReflectionValueResident()}</div>}

                    {reflection.replyReflection != null && <div className="reflection-reply" onClick={() => {
                        if (reflection.replyReflection != null) {
                            onGoToReply(reflection.replyReflection)
                        }
                    }}>
                        <div className={
                            reflection.replyReflection.class == 5 ? "name name-reply exclusive" :
                                reflection.replyReflection.class == 10 ? "name name-reply basic" :
                                    reflection.replyReflection.class == 20 ? "name name-reply standard" :
                                        reflection.replyReflection.class == 25 ? "name name-reply stdplus" :
                                            reflection.replyReflection.class == 30 ? "name name-reply bus" :
                                                "name name-reply busplus"}>
                            <span>{reflection.replyReflection.post.publishAccount.name}</span> {reflection.replyReflection.post.publishAccount.isAmbassador > 0 && <i className="fas fa-award award" />}</div>
                        <span style={{ 'fontSize': '14px' }} dangerouslySetInnerHTML={{ __html: reflection.replyReflection.post.contentText.replace(/\\n/g, ' ').substring(0, 35) + (reflection.replyReflection.post.contentText.length > 35 ? "..." : "") }}></span>
                    </div>}
                    <div className="card-text">{renderPost(reflection.post, reflection.visibilityMode > 100)}</div>
                    <div style={reactionsStyle}>
                        {getReactionsInfo(reflection.reactionsInfo).filter(x => x.count > 0).map(x => (
                            <div style={x.my > 0 ? singleMyReactionStyle : singleReactionStyle} onClick={() => setReaction(x.code)}>&nbsp;
                                <Reaction code={x.code} />
                                {x.count}&nbsp;</div>
                        ))}
                    </div>

                    {(currentUser.canEvaluate > 0 && reflection.visibilityMode < 100 && isActual()) && <div className="reflection-evaluation">
                        <div className="reflection-evaluation-header">Оценить: </div>
                        <div className={evaluationState == 1 ? "reflection-evaluation-button active" : "reflection-evaluation-button"} onClick={() => doEvaluate(1)}>1 балл</div>
                        <div className={evaluationState == 2 ? "reflection-evaluation-button active" : "reflection-evaluation-button"} onClick={() => doEvaluate(2)}>2 балла</div>
                    </div>}

                    <div style={bottomStyle}>
                        {reflection.isModified > 0 && <span style={modifiedStyle}>изменено </span>}{makeDateTime(reflection.post.publishDateTime.toString())}
                        {reflection.post.publishAccount.id == currentUser.id && <span className="reflection-send-status"> {reflection.id == 0 ? <i className="fas fa-clock" /> : <i className="fas fa-check" />}</span>}
                    </div>
                </div>
            </div>
            <CustomModal show={showAttachedFilesModal} onClose={closeAttachedFilesModal} title='Просмотр приложенных к рефлексии файлов'>
                <table>
                    <tbody>
                        {attachedFiles.map((file: string, index: number) =>
                            <FileView key={index} reflectionID={reflection.id.toString()} file={file} />
                        )}
                    </tbody>
                </table>
                <div className="modal-footer justify-content-center">
                    <button className="btn btn-secondary" onClick={closeAttachedFilesModal}>Закрыть</button>
                </div>
            </CustomModal>
        </div >
    )
};

export default ReflectionComponent;
